import { AxiosPromise } from 'axios';

import { ApiService } from './api.service';
import { UserRoomResponse, UserRoomInterface, UserRoomMarkInterface } from 'app/shared/interfaces/UserRoom.interface';
import { GetParamsInterface } from 'app/shared/interfaces/RequestInterface.interface';

export const UserRoomService = {
  getUserRooms(params: GetParamsInterface = {}): AxiosPromise<UserRoomResponse> {
    return ApiService.get('user_rooms', params);
  },

  getUserRoomsById(id: number, params: GetParamsInterface = {}): AxiosPromise<UserRoomInterface> {
    return ApiService.get(`user_rooms/${id}`, params);
  },

  getUsersRoomsByRoomId(roomId: number, params: GetParamsInterface = {}): AxiosPromise<UserRoomResponse> {
    return ApiService.get(`rooms/${roomId}/user_rooms`, params);
  },

  replaceUserRoomsRound(roomId: number, data: Omit<UserRoomMarkInterface, 'mark' | 'markDescription'>): AxiosPromise {
    return ApiService.put(`user_rooms/${roomId}`, data);
  },

  replaceUserRoomsMark(roomId: number, data: UserRoomMarkInterface): AxiosPromise {
    return ApiService.put(`user_rooms/${roomId}`, data);
  },

  removeNotUnloadedMaterials(userRoomId: number): AxiosPromise {
    return ApiService.get(`user_rooms/${userRoomId}/remove_not_unloaded_materials`);
  },

  getGameProcess(userRoomId: number): AxiosPromise {
    return ApiService.get(`user_rooms/${userRoomId}/game_products`);
  },
};
