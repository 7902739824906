import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  areaContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  sliderRight: {
    paddingTop: 40,
  },
  itemsArea: {
    position: 'absolute',
    top: 40,
    left: 40,
    overflow: 'hidden',
  },
  item: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    userSelect: 'none',
    cursor: 'pointer',
  },
  tmpItem: {
    position: 'absolute',
    display: 'none',
    justifyContent: 'center',
    alignItems: 'center',
    userSelect: 'none',
    opacity: 0.5,
    border: '1px solid #000',
  },
  sliederDownContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttonContainer: {
    marginRight: 5,
    marginTop: 2,
  },
  centerButton: {
    padding: 0,
  },
  scaleContainer: {
    height: 200,
    padding: '30px 15px',
    position: 'absolute',
    bottom: 30,
    right: 0,
    border: '1px solid #ccc',
    backgroundColor: '#ededed',
  },
  scaleContainerHidden: {
    display: 'none',
  },
  scale: {
    height: 200,
  },
  notification: {
    backgroundColor: 'red',
    color: '#fff',
    fontWeight: 'bold',
    padding: 15,
    borderRadius: 10,
  },
}));
