import React, { useState } from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';

import { CustomTablePagination } from 'app/components/CustomTablePagination/CustomTablePagination';
import { getParams } from 'app/shared/helpers/pagination';
import { UserInterface } from 'app/shared/interfaces/User.interface';
import { AddUserModal } from 'app/components/AddUserModal/AddUserModal';
import { ChangePasswordUserModal } from 'app/components/ChangePasswordUserModal/ChangePasswordUserModal';

import { useStyles } from './UsersTable.styles';
import { UsersTableProps } from './UsersTable.interfaces';
import { CustomIconButton } from '../CustomIconButton/CustomIconButton';

export function UsersTable(props: UsersTableProps) {
  const { users, shouldUpdateUsers, totalRecords } = props;
  const classes = useStyles();
  const [selectedPage, setSelectedPage] = useState<number>(0);
  const [userData, setUserData] = useState<UserInterface | null>(null);

  const [openAddDialog, setOpenAddDialog] = useState<boolean>(false);
  const [openChangePassword, setOpeChangePassword] = useState<boolean>(false);

  const handleOpenEditUser = (id: number) => {
    const user = getUserById(id);
    if (user) {
      setUserData(user);
      setOpenAddDialog(true);
    }
  };

  const handleOpenPasswordChange = (id: number) => {
    const user = getUserById(id);
    if (user) {
      setUserData(user);
      setOpeChangePassword(true);
    }
  };

  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
    setUserData(null);
  };

  const handleCloseChangeDialog = () => {
    setOpeChangePassword(false);
    setUserData(null);
  };

  const handleUserAdded = () => {
    shouldUpdateUsers(getParams(selectedPage));
    setOpenAddDialog(false);
    setOpeChangePassword(false);
    setUserData(null);
  };

  const getUserById = (id: number) => users.find((user) => user.id === id);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    shouldUpdateUsers(getParams(page));
    setSelectedPage(page);
  };

  const getUserDescriptionStatus = (status: number) => (status === 0 ? 'Nieaktywny' : 'Aktywny');

  return (
    <div>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>E-mail</TableCell>
              <TableCell>Status</TableCell>
              <TableCell className={classes.buttonsTableCell} align={'right'} />
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user.id}>
                <TableCell className={classes.bold}>{user.email}</TableCell>
                <TableCell>{getUserDescriptionStatus(user.status)}</TableCell>
                <TableCell align={'right'} className={classes.buttonsTableCell}>
                  <CustomIconButton onClick={() => handleOpenEditUser(user.id)} type={'edit'} />
                  <CustomIconButton onClick={() => handleOpenPasswordChange(user.id)} type={'change'} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <CustomTablePagination
            tablePaginationConfig={{
              count: totalRecords,
              page: selectedPage,
            }}
            onChangePage={handleChangePage}
          />
        </Table>
      </TableContainer>
      <AddUserModal isOpen={openAddDialog} data={userData} onClose={handleCloseAddDialog} userAdded={handleUserAdded} />
      <ChangePasswordUserModal
        isOpen={openChangePassword}
        data={userData}
        onClose={handleCloseChangeDialog}
        passwordChanged={handleUserAdded}
      />
    </div>
  );
}
