import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';

import { MaterialTypesTable } from 'app/components/MaterialTypesTable/MaterialTypesTable';
import { HeaderButton } from 'app/components/HeaderButton/HeaderButton';
import { MaterialTypeInterface } from 'app/shared/interfaces/MaterialType.interface';
import { GetParamsInterface } from 'app/shared/interfaces/RequestInterface.interface';
import { MaterialTypeService } from 'app/services/materialType.service';
import { defaultParams } from 'app/shared/helpers/pagination';

import { useStyles } from './MaterialTypesPage.styles';

function MaterialTypesPage() {
  const classes = useStyles();
  const [materialTypes, setMaterialTypes] = useState<Array<MaterialTypeInterface>>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [addButtonClicked, setAddButtonClicked] = useState<boolean>(false);

  const getMaterialTypes = (params: GetParamsInterface = defaultParams) => {
    MaterialTypeService.getMaterialTypes(params).then(
      ({ data: { 'hydra:member': responseData, 'hydra:totalItems': totalRecords } }) => {
        setMaterialTypes(responseData);
        setTotalRecords(totalRecords);
      }
    );
  };

  const handleAddButton = () => setAddButtonClicked(true);

  useEffect(() => {
    getMaterialTypes();
  }, []);

  useEffect(() => {
    if (addButtonClicked) {
      setAddButtonClicked(false);
    }
  }, [addButtonClicked]);

  return (
    <div className={classes.root}>
      <Typography variant={'h1'}>
        <span>Typy materiałów</span>
        <div>
          <HeaderButton onClick={handleAddButton} label={'Dodaj typ materiału'} />
        </div>
      </Typography>
      <MaterialTypesTable
        materialTypes={materialTypes}
        shouldUpdateMaterialTypes={getMaterialTypes}
        totalRecords={totalRecords}
        addClick={addButtonClicked}
      />
    </div>
  );
}

export default MaterialTypesPage;
