import { gantt } from './reducer';

export const {
  setActiveAction,
  clearActiveAction,
  setGanttTemplate,
  clearGanttTemplate,
  setActiveResourceId1,
  setActiveResourceId2,
  setActiveResourceTransportId,
  clearActiveResourceId1,
  clearActiveResourceId2,
  clearActiveResourceTransportId,
  setGanttOrders,
  setActiveRoundsGanttOrders,
  setDuration,
  clearDuration,
  setEditGanttItemId,
  clearEditGanttItemId,
} = gantt.actions;
