import { AxiosPromise } from 'axios';

import { ApiService } from './api.service';
import { ActionTypeResponse } from 'app/shared/interfaces/ActionType.interface';
import { GetParamsInterface } from 'app/shared/interfaces/RequestInterface.interface';

export const ActionTypeService = {
  getActionTypes(params: GetParamsInterface = {}): AxiosPromise<ActionTypeResponse> {
    return ApiService.get('action_types', params);
  },
};
