import React, { useCallback, useEffect, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import Button from '@material-ui/core/Button';
import * as Yup from 'yup';
import TextField from '@material-ui/core/TextField';

import { AddProcessActivityInterface, AddProcessInterface } from 'app/shared/interfaces/Process.interface';
import { ProcessService } from 'app/services/process.service';
import { CustomDialog } from 'app/components/CustomDialog/CustomDialog';
import { TextFieldComponent } from 'app/components/TextFieldComponent/TextFieldComponent';
import { SelectComponent } from 'app/components/SelectComponent/SelectComponent';
import { upToTwoDecimalPlaces } from 'app/shared/helpers/validators';

import { AddProcessFormDataInterface, AddProcessModalProps } from './AddProcessModal.interfaces';
import { AddActionsToProcessModal } from 'app/components/AddActionsToProcessModal/AddActionsToProcessModal';
import { useStyles } from './AddProcessModal.styles';
import { handlingCatch } from 'app/shared/helpers/handlingCatch';
import { convertToFloat } from 'app/shared/helpers/numberConverter';

export function AddProcessModal(props: AddProcessModalProps) {
  const { isOpen, onClose, process, actions, processAdded, products, gantts } = props;
  const classes = useStyles();
  const [isOpenActionModal, setIsOpenActionModal] = useState<boolean>(false);
  const [processActivities, setProcessActivities] = useState<Array<AddProcessActivityInterface>>([]);
  const [actionsLabel, setActionsLabel] = useState<string>('');

  const formData: AddProcessFormDataInterface = {
    name: process ? process.name : '',
    description: process && process.description ? process.description : '',
    price: process ? process.price.toString() : '',
    product: process && process.product ? process.product.id.toString() : '',
    gantt: process && process.ganttTemplate ? process.ganttTemplate.id.toString() : '',
  };

  const validationShema = Yup.object().shape({
    name: Yup.string().required('Podaj nazwę'),
    price: Yup.number()
      .test('is-incorrect', 'Podaj cenę max. dwa miejsca po przecinku', upToTwoDecimalPlaces)
      .min(0, 'Podaj cenę max. dwa miejsca po przecinku')
      .typeError('Podaj cenę max. dwa miejsca po przecinku')
      .required('Podaj cenę max. dwa miejsca po przecinku'),
    product: Yup.string().required('Wybierz produkt'),
  });

  const getActionNameById = (id: string) => {
    const action = actions.find((act) => act['@id'] && act['@id'] === id);
    return action ? action.name : '';
  };

  const handleAddActinos = (actions: Array<AddProcessActivityInterface>) => {
    setProcessActivities(actions);
    setIsOpenActionModal(false);
    setActionsLabel(actions.map((act) => getActionNameById(act.activity['@id'])).join(', '));
  };

  const handleOpenAddActions = () => {
    setIsOpenActionModal(true);
  };

  const handleClose = () => setIsOpenActionModal(false);

  const setProcessActivity = useCallback(() => {
    const processActivity: Array<AddProcessActivityInterface> = process
      ? process.processActivities.map((processActivity) => ({
          '@id': processActivity['@id'] || '',
          activityOrder: processActivity.activityOrder,
          activity: { '@id': processActivity.activity['@id'] || '' },
        }))
      : [];
    setProcessActivities(processActivity);
  }, [process]);

  useEffect(() => {
    setActionsLabel(process ? process.processActivities.map((action) => action.activity.name).join(', ') : '');
    setProcessActivity();
  }, [process, setProcessActivity, isOpen]);

  return (
    <>
      <CustomDialog
        title={process ? 'Edytuj proces' : 'Dodaj proces'}
        isOpen={isOpen}
        onCloseDialog={onClose}
        body={
          <Formik
            initialValues={formData}
            validationSchema={validationShema}
            onSubmit={(values, actions) => {
              const processData: AddProcessInterface = {
                name: values.name.trim(),
                description: values.description,
                processActivities: processActivities,
                price: convertToFloat(values.price),
                product: products.find((product) => product.id === parseInt(values.product)),
                ganttTemplate: gantts.find((gantt) => gantt.id === parseInt(values.gantt)),
              };

              if (process && process.id) {
                ProcessService.replaceProcess(process.id, processData)
                  .then(() => {
                    processAdded();
                  })
                  .catch((error) => {
                    const response = handlingCatch(error.response);
                    actions.setFieldError(response.fieldError, response.message);
                  });
              } else {
                ProcessService.addProcess(processData)
                  .then(() => {
                    processAdded();
                  })
                  .catch((error) => {
                    const response = handlingCatch(error.response);
                    actions.setFieldError(response.fieldError, response.message);
                  });
              }
            }}
          >
            {({ submitForm }) => (
              <Form>
                <Field component={TextFieldComponent} name={'name'} label={'Nazwa'} fullWidth />
                <Field
                  component={TextFieldComponent}
                  name={'description'}
                  label={'Opis'}
                  multiline
                  rowsMax={4}
                  fullWidth
                />
                <Field component={TextFieldComponent} name={'price'} label={'Cena [PLN]'} fullWidth />
                <Field
                  component={SelectComponent}
                  fullWidth
                  label={'Produkt'}
                  name={'product'}
                  options={products.map((product) => ({
                    label: product.name,
                    value: product.id,
                  }))}
                />
                <Field
                  component={SelectComponent}
                  fullWidth
                  label={'Szablon Gantta'}
                  name={'gantt'}
                  options={gantts.map((gant) => ({
                    label: gant.name,
                    value: gant.id,
                  }))}
                />
                <TextField label="Czynności" disabled value={actionsLabel} fullWidth />
                <Button color={'primary'} onClick={handleOpenAddActions} className={classes.addActionButton}>
                  Dodaj czynności
                </Button>
                <div className={classes.actionButtons}>
                  <Button color={'primary'} onClick={submitForm}>
                    {process ? 'Zapisz' : 'Dodaj'}
                  </Button>
                  <Button color={'primary'} autoFocus onClick={onClose}>
                    Anuluj
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        }
      />
      <AddActionsToProcessModal
        isOpenForm={isOpen}
        isOpen={isOpenActionModal}
        actions={actions}
        onClose={handleClose}
        addActions={handleAddActinos}
        processActivity={process && process.processActivities}
      />
    </>
  );
}
