import React, { useCallback, useEffect, useState } from 'react';
import Popper from '@material-ui/core/Popper';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { EditGanttTemplateItemPopperProps } from './EditGanttTemplateItemPopper.interface';
import { useStyles } from './EditGanttTemplateItemPopper.styles';

export function EditGanttTemplateItemPopper({
  isOpen,
  anchorEl,
  onClose,
  onMove,
  onDelete,
  onEdit,
  ganttTemplateItemId,
  isTemplate,
  onMoveAll,
}: EditGanttTemplateItemPopperProps) {
  const classes = useStyles();
  const [anyButtonClicked, setAnyButtonClicked] = useState<boolean>(false);

  const handleOnMove = () => {
    setAnyButtonClicked(true);
    onMove();
  };

  const handleOnDelete = useCallback(() => {
    setAnyButtonClicked(true);
    onDelete();
  }, [onDelete, setAnyButtonClicked]);

  const handleOnClose = () => {
    setAnyButtonClicked(true);
    onClose();
  };

  const handleEdit = () => {
    onEdit(ganttTemplateItemId);
  };

  const handleMoveAll = () => {
    setAnyButtonClicked(true);
    onMoveAll(ganttTemplateItemId);
  };

  const handleDelete = useCallback(
    (e: KeyboardEvent) => {
      if (ganttTemplateItemId !== -1 && e.key === 'Delete') {
        handleOnDelete();
      }
    },
    [handleOnDelete, ganttTemplateItemId]
  );

  useEffect(() => {
    setAnyButtonClicked(false);
  }, [ganttTemplateItemId]);

  useEffect(() => {
    document.addEventListener('keydown', handleDelete);
    return () => document.removeEventListener('keydown', handleDelete);
  }, [handleDelete]);

  return (
    <Popper className={classes.root} anchorEl={anchorEl} placement={'right'} open={isOpen}>
      <div>
        <IconButton className={classes.closeButton} onClick={handleOnClose}>
          <CloseIcon fontSize={'small'} />
        </IconButton>
        <div className={classes.header}>Edycja</div>
        <div className={classes.buttonLine}>
          <Button
            size={'small'}
            color={'primary'}
            variant="contained"
            className={classes.button}
            onClick={handleMoveAll}
            disabled={anyButtonClicked}
          >
            Przesuwanie grupowe
          </Button>
        </div>
        {!isTemplate && (
          <Button
            size={'small'}
            color={'primary'}
            variant="contained"
            className={classes.button}
            onClick={handleEdit}
            disabled={anyButtonClicked}
          >
            Edytuj
          </Button>
        )}
        <Button
          size={'small'}
          color={'primary'}
          variant="contained"
          className={classes.button}
          onClick={handleOnMove}
          disabled={anyButtonClicked}
        >
          Przenieś
        </Button>
        <Button
          size={'small'}
          color={'primary'}
          variant="contained"
          className={classes.button}
          onClick={handleOnDelete}
          disabled={anyButtonClicked}
        >
          Usuń
        </Button>
        <Button
          size={'small'}
          color={'primary'}
          variant="contained"
          className={classes.button}
          onClick={handleOnClose}
          disabled={anyButtonClicked}
        >
          Anuluj
        </Button>
      </div>
    </Popper>
  );
}
