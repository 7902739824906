import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20,
  },
  tableHead: {
    fontWeight: 'bold',
  },
  materialPriceContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  tableContainer: {
    marginBottom: 25,
    borderRadius: 0,
  },
  field: {
    margin: '10px 0',
  },
});
