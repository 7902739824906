import React from 'react';
import Popper from '@material-ui/core/Popper';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import { RemoveResourceFromFactoryPopperProps } from './RemoveResourceFromFactoryPopper.interface';
import { useStyles } from './RemoveResourceFromFactoryPopper.styles';
import { FactoryResourcesService } from 'app/services/factoryResources.service';

export function RemoveResourceFromFactoryPopper({
  isOpen,
  onClose,
  anchorEl,
  factoryResourceId,
  shouldRefreshOnwResources,
}: RemoveResourceFromFactoryPopperProps) {
  const classes = useStyles();

  const handleDeleteButton = () => {
    FactoryResourcesService.deleteFactoryResource(factoryResourceId).then(() => shouldRefreshOnwResources());
  };

  return (
    <Popper open={isOpen} anchorEl={anchorEl} placement={'left'} className={classes.root}>
      <div>
        <div className={classes.header}>Usunąć zasób produkcyjny?</div>
      </div>
      <ButtonGroup color={'primary'} size={'small'} className={classes.buttonsContainer}>
        <Button onClick={handleDeleteButton}>Usuń</Button>
        <Button onClick={onClose}>Anuluj</Button>
      </ButtonGroup>
    </Popper>
  );
}
