import { roomGameStatus, scenarioValue, userRoomGameStatus } from 'app/shared/constants';
import { RoomInterface } from 'app/shared/interfaces/Room.interface';

export const getRoomConfig = (status: number) => {
  switch (status) {
    case roomGameStatus.GAME_IDLE.status:
      return roomGameStatus.GAME_IDLE;
    case roomGameStatus.GAME_PENDING.status:
      return roomGameStatus.GAME_PENDING;
    case roomGameStatus.GAME_FINISHED.status:
      return roomGameStatus.GAME_FINISHED;
    case roomGameStatus.GAME_ARCHIVED.status:
      return roomGameStatus.GAME_ARCHIVED;
  }
};

export const getRoomStatus = (room: RoomInterface) => {
  const roomConfig = getRoomConfig(room.status);
  return roomConfig && roomConfig.label ? roomConfig.label.room : '';
};
export const getRoomCellBackgroundStatus = (room: RoomInterface) => {
  const roomConfig = getRoomConfig(room.status);
  return roomConfig ? roomConfig.full_cell_color : false;
};

export const getRoomStatusColor = (room: RoomInterface) => {
  const roomConfig = getRoomConfig(room.status);
  return roomConfig ? roomConfig.cell_color : '';
};

export const getUserRoomConfigStatus = (status: number) => {
  switch (status) {
    case userRoomGameStatus.GAME_MARK_AND_END.status:
      return userRoomGameStatus.GAME_MARK_AND_END;
    case userRoomGameStatus.GAME_IDLE.status:
      return userRoomGameStatus.GAME_IDLE;
    case userRoomGameStatus.GAME_PENDING.status:
      return userRoomGameStatus.GAME_PENDING;
    case userRoomGameStatus.GAME_WAITING_FOR_MARK.status:
      return userRoomGameStatus.GAME_WAITING_FOR_MARK;
    case userRoomGameStatus.GAME_WAITING_FOR_NEXT_ROUND.status:
      return userRoomGameStatus.GAME_WAITING_FOR_NEXT_ROUND;
  }
};

export const isAutomation = (room?: RoomInterface) =>
  room && room.scenarioTemplate && room.scenarioTemplate.automation === scenarioValue.AUTOMATION_ON;
