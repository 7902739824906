import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20,
  },
  buttonsTableCell: {
    padding: 5,
  },
});
