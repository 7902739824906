import React from 'react';
import Button from '@material-ui/core/Button';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';

import { CustomDialog } from 'app/components/CustomDialog/CustomDialog';
import { BulletedList } from 'app/components/BulletedList/BulletedList';
import { ActualProcessInterface } from 'app/shared/interfaces/ActualProcess.interface';

import { useStyles } from './GameProcessDetailsModal.styles';
import { GameProcessDetailsModalInterface } from './GameProcessDetailsModal.interface';

export function GameProcessDetailsModal(props: GameProcessDetailsModalInterface) {
  const { isOpen, onClose, actualProcess, processesActivity } = props;
  const classes = useStyles();
  const getActivityTable = (actualProcessItem: ActualProcessInterface) => {
    const processesActivityToShow = processesActivity.filter(
      (processActivity) => processActivity.processActual.id === actualProcessItem.id
    );
    return (
      <Table className={classes.subTable}>
        <TableBody>
          {processesActivityToShow.map((processActivity, index) => {
            return (
              <TableRow key={`${processActivity.activityActual['@id']}-${index}`}>
                <TableCell>
                  {processActivity.activityActual.name} ({processActivity.activityNumber})
                </TableCell>
                <TableCell className={classes.activityCell}>
                  <div>{`Czas TJ: ${processActivity.activityActual.timeWorking}`}</div>
                  <div>{`Strata: ${processActivity.activityActual.loss}%`}</div>
                  <div>{`WSP TPZ: ${processActivity.activityActual.WTP}`}</div>
                </TableCell>
                <TableCell>
                  <BulletedList
                    list={processActivity.activityActual.resourcesActual.map((resource) => ({
                      id: resource.id,
                      label: resource.name,
                    }))}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  };

  const getActualProductName = (actualProcess: ActualProcessInterface) => {
    return actualProcess && actualProcess.productActual ? actualProcess.productActual.name : '';
  };

  const getMaterialTypeName = (actualProcess: ActualProcessInterface) => {
    return actualProcess && actualProcess.productActual && actualProcess.productActual.materialTypeActual
      ? actualProcess.productActual.materialTypeActual.name
      : '';
  };

  return (
    <CustomDialog
      title={'Szczegóły procesu'}
      isOpen={isOpen}
      onCloseDialog={onClose}
      maxWidth={'md'}
      body={
        <div>
          <div className={classes.tableContainer}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Nazwa</TableCell>
                  <TableCell>Opis</TableCell>
                  <TableCell>Cena [PLN]</TableCell>
                  <TableCell>Produkt</TableCell>
                  <TableCell>Typ Materiału</TableCell>
                  <TableCell>Czynności/Zasoby Produkcyjne</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {actualProcess && (
                  <TableRow key={actualProcess.id}>
                    <TableCell>{actualProcess.name}</TableCell>
                    <TableCell>{actualProcess.description}</TableCell>
                    <TableCell>{actualProcess.price.toFixed(2)}</TableCell>
                    <TableCell>{getActualProductName(actualProcess)}</TableCell>
                    <TableCell>{getMaterialTypeName(actualProcess)}</TableCell>
                    <TableCell className={classes.subTableCell}>{getActivityTable(actualProcess)}</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
          <div className={classes.actionButtons}>
            <Button color={'primary'} autoFocus onClick={onClose}>
              Zamknij
            </Button>
          </div>
        </div>
      }
    />
  );
}
