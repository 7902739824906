import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';

import { GameHeaderInterfaceProps } from './GameHeaderMark.interface';
import { AddMarkModal } from 'app/components/AddMarkModal/AddMarkModal';
import { getIsUserGameMaster } from 'app/store/user';
import { roomGameStatus, userRoomGameStatus } from 'app/shared/constants';

import { useStyles } from './GameHeaderMark.styles';

export function GameHeaderMark(props: GameHeaderInterfaceProps) {
  const { userRoom, updateRoom } = props;
  const classes = useStyles();
  const isUserGameMaster = useSelector(getIsUserGameMaster);

  const [openAddDialog, setOpenAddDialog] = useState<boolean>(false);
  const [showMarkModal, setShowMarkModal] = useState<boolean>(false);
  const [readonly, setReadonly] = useState<boolean>(false);

  const handleOpenAddMark = () => {
    setOpenAddDialog(true);
  };
  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
  };

  const getMarkStatusModal = useCallback(() => {
    if (!isUserGameMaster && userRoom.status === userRoomGameStatus.GAME_MARK_AND_END.status) {
      return true;
    } else if (isUserGameMaster) {
      return true;
    }
    return false;
  }, [userRoom, isUserGameMaster]);

  const isMarkChangingDisabled = useCallback(() => {
    return !isUserGameMaster || userRoom?.room?.status !== roomGameStatus.GAME_FINISHED.status;
  }, [userRoom, isUserGameMaster]);

  useEffect(() => {
    setShowMarkModal(getMarkStatusModal());
    setReadonly(isMarkChangingDisabled());
  }, [isMarkChangingDisabled, getMarkStatusModal, isUserGameMaster]);

  const handleMarkAdded = () => {
    setOpenAddDialog(false);
    updateRoom();
  };

  return (
    <div className={classes.root}>
      <div className={classes.title}>Twoja Ocena</div>
      <div className={classes.value}>
        {userRoom && userRoom.mark !== null ? userRoom.mark : 'brak'}
        {showMarkModal && (
          <IconButton size="small" className={classes.infoIcon} onClick={handleOpenAddMark}>
            <InfoIcon fontSize="inherit" />
          </IconButton>
        )}
      </div>
      {showMarkModal && (
        <div>
          <AddMarkModal
            isOpen={openAddDialog}
            readonly={readonly}
            userRoom={userRoom}
            onClose={handleCloseAddDialog}
            markAdded={handleMarkAdded}
          />
        </div>
      )}
    </div>
  );
}
