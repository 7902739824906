import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  select: {
    marginBottom: 20,
  },
  activeRow: {
    '& td': {
      backgroundColor: '#adffb8',
    },
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20,
  },
  tablesContainer: {
    display: 'flex',
  },
  tabContainer: {
    marginLeft: 20,
    width: 800,
  },
  suppliersContainer: {
    minWidth: 500,
  },
  marginRight: {
    marginRight: 20,
  },
  scenariosContainer: {
    maxHeight: 300,
    overflowY: 'auto',
  },
  scenarioTitle: {
    fontWeight: 'bold',
    marginBottom: 10,
  },
  scenarioContent: {
    whiteSpace: 'pre',
  },
});
