import { makeStyles } from '@material-ui/core';
import { GanttGraphProps } from './GanttGraph.interface';

export const useStyles = makeStyles({
  gantContainer: {
    display: 'flex',
  },
  ganttCell: (props: GanttGraphProps) => ({
    position: 'relative',
    width: props.cellWidth,
    height: props.height,
    borderRight: '1px dashed #000',
  }),
  legendCell: (props: GanttGraphProps) => ({
    width: props.cellWidth,
    paddingTop: 10,
    fontSize: 12,
    '&:first-child': {
      width: props.cellWidth / 2,
    },
  }),
  legendMinCell: {
    position: 'absolute',
    right: -35,
  },
  granttGraphUpContainer: (props: GanttGraphProps) => ({
    display: 'flex',
    width: '100%',
    height: props.height,
  }),
  graphLeftUpSide: {
    position: 'relative',
    width: 315,
    height: 'calc(100% - 10px)',
    borderRight: '1px solid #000',
    marginTop: 10,
    paddingTop: 10,
  },
  ordersContainer: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  graphRightUpSide: {
    display: 'flex',
  },
  ganttGraphDownContainer: {
    display: 'flex',
    position: 'relative',
    borderTop: '1px solid #000',
    height: 40,
    marginRight: 10,
  },
  graphLeftDownSide: {
    height: '100%',
    width: 315,
    borderRight: '1px solid #000',
  },
  graphRightDownSide: {
    display: 'flex',
  },
  orderLabelContainer: {
    display: 'flex',
    position: 'relative',
    marginBottom: 40,
    height: 40,
  },
  orderLabel: {
    width: '100%',
  },
  labelButtons: {
    display: 'flex',
  },
  labelButton: {
    width: 30,
    height: 30,
  },
  arrowRight: {
    position: 'absolute',
    top: -5,
    right: -10,
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '5px 0 5px 15px',
    borderColor: 'transparent transparent transparent #000',
  },
  arrowUp: {
    position: 'absolute',
    right: -5,
    top: -10,
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '0 5px 15px 5px',
    borderColor: 'transparent transparent #000 transparent',
  },
  missingMaterialWarning: {
    color: '#ff1100',
    fontWeight: 'bold',
  },
});
