import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';

import { ResourceInterface } from 'app/shared/interfaces/Resource.interface';
import { ResourceService } from 'app/services/resource.service';
import { ResourceTypeService } from 'app/services/resourceType.service';
import { HeaderButton } from 'app/components/HeaderButton/HeaderButton';
import { ResourcesTable } from 'app/components/ResourcesTable/ResourcesTable';
import { ResourceTypeInterface } from 'app/shared/interfaces/ResourceType.interface';
import { GetParamsInterface } from 'app/shared/interfaces/RequestInterface.interface';
import { defaultParams } from 'app/shared/helpers/pagination';

import { useStyles } from './Resources.styles';

function ResourcesPage() {
  const classes = useStyles();
  const [resources, setResources] = useState<Array<ResourceInterface>>([]);
  const [resourceTypes, setResourceTypes] = useState<Array<ResourceTypeInterface>>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [addButtonClicked, setAddButtonClicked] = useState<boolean>(false);

  const getResources = (params: GetParamsInterface = defaultParams) => {
    ResourceService.getResources(params).then(
      ({ data: { 'hydra:member': responseData, 'hydra:totalItems': totalRecords } }) => {
        setResources(responseData);
        setTotalRecords(totalRecords);
      }
    );
  };

  const getResourceTypes = () => {
    ResourceTypeService.getResourceTypes().then(({ data: { 'hydra:member': responseData } }) => {
      setResourceTypes(responseData);
    });
  };

  const handleAddButton = () => setAddButtonClicked(true);

  useEffect(() => {
    getResources();
    getResourceTypes();
  }, []);

  useEffect(() => {
    if (addButtonClicked) {
      setAddButtonClicked(false);
    }
  }, [addButtonClicked]);

  return (
    <div className={classes.root}>
      <Typography variant={'h1'}>
        <span>Zasoby produkcyjne</span>
        <div>
          <HeaderButton onClick={handleAddButton} label={'Dodaj zasób produkcyjny'} />
        </div>
      </Typography>
      <ResourcesTable
        resources={resources}
        resourceTypes={resourceTypes}
        totalRecords={totalRecords}
        shouldUpdateResources={getResources}
        addClick={addButtonClicked}
      />
    </div>
  );
}

export default ResourcesPage;
