import React, { useState } from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Button from '@material-ui/core/Button';
import { useSelector } from 'react-redux';

import { useStyles } from './GameOrders.styles';
import { GameOrdersProps } from './GameOrders.interface';
import { isZeroRound } from 'app/shared/helpers/isZeroRound';
import { canEdit } from 'app/shared/helpers/canEdit';
import { ActualOrderInterface } from 'app/shared/interfaces/ActualOrder.interface';
import { getCurrentRoundNumber, getGameOrders, getIsAutomation, getSelectedUserRoom } from 'app/store/userRoom';
import { AuctionModal } from 'app/components/AuctionModal/AuctionModal';
import { AddGameOrderInterface } from 'app/shared/interfaces/GameOrder.interface';
import { GameOrderService } from 'app/services/gameOrder.service';
import { UserRoomState } from '../../store/userRoom/reducer';
import { getGameRounds } from '../../store/gameRounds';
import { gameRoundPhaseStatus } from '../../shared/constants';
import { AuctionItemInterface } from '../../shared/interfaces/AuctionItem.interface';

export function GameOrders(props: GameOrdersProps) {
  const { updateRoom, actualOrders, userRoom, myAuctions } = props;
  const gameRounds = useSelector(getGameRounds);
  const gameOrders = useSelector(getGameOrders);
  const isAutomation = useSelector(getIsAutomation);
  const selectedUserRoom = useSelector(getSelectedUserRoom);
  const currentRoundNumber = useSelector(getCurrentRoundNumber);
  const classes = useStyles();
  const [openAuctionDialog, setOpenAuctionDialog] = useState<boolean>(false);
  const [selectedActualOrder, setSelectedActualOrder] = useState<ActualOrderInterface | null>(null);

  const isSetAuction = (actualOrderId: number): boolean => {
    if (myAuctions) {
      const auctionItem = getAuctionConnectedWithOrder(actualOrderId);
      return !!auctionItem;
    }
    return false;
  };

  const isAuctionForOrder = (auctionItem: AuctionItemInterface, actualOrderId: number) => {
    const selectedRoomRoundNumber = selectedUserRoom?.room?.currentRound || 0;
    const auction = auctionItem.auction;
    const auctionRound = auctionItem.round || -1;
    return auction.actualOrder.id === actualOrderId && auctionRound === selectedRoomRoundNumber;
  };

  const getAuctionConnectedWithOrder = (actualOrderId: number) =>
    myAuctions.find((auctionItem) => isAuctionForOrder(auctionItem, actualOrderId));

  const existAuctionForOrder = (actualOrderId: number) =>
    myAuctions.some((auctionItem) => isAuctionForOrder(auctionItem, actualOrderId));

  const handleTakeOrder = (productOrderActual: ActualOrderInterface) => {
    const takeOrder: Omit<AddGameOrderInterface, 'id'> = {
      productOrderActual: `/api/actual_orders/${productOrderActual.id}`,
      userRoom: `/api/user_rooms/${userRoom.id}`,
    };

    GameOrderService.takeOrder(takeOrder).then(() => {
      updateRoom();
    });
  };

  const findRound = (roundNumber: number, status: number) => {
    return gameRounds.find((gameRound) => gameRound.roundNumber === roundNumber && gameRound.status === status);
  };

  const isOrderTaken = (id: number) => {
    const gameOrder = gameOrders.find(
      (gameOrder) => gameOrder.productOrderActual && gameOrder.productOrderActual.id === id
    );
    return !!gameOrder;
  };

  const handleOpenAuctionModal = (actualOrder: ActualOrderInterface) => {
    setOpenAuctionDialog(true);
    setSelectedActualOrder(actualOrder);
  };

  const onCloseModal = () => {
    setOpenAuctionDialog(false);
    setSelectedActualOrder(null);
    if (userRoom.room) {
      updateRoom(userRoom.room.id);
    }
  };

  const isOrderTakenOrSetInAuction = (actualOrder: ActualOrderInterface) =>
    isOrderTaken(actualOrder.id) || isSetAuction(actualOrder.id);

  const getButtonDesc = (actualOrder: ActualOrderInterface) => {
    if (isAutomation) {
      return isOrderTakenOrSetInAuction(actualOrder) ? 'Licytujesz' : 'Licytuj';
    }
    return isOrderTakenOrSetInAuction(actualOrder) ? 'Wzięty' : 'Weż';
  };
  const getAuctionOffer = (actualOrder: ActualOrderInterface) => {
    const auction = getAuctionConnectedWithOrder(actualOrder.id);
    return auction && isSetAuction(actualOrder.id) ? `Twoja oferta: ${auction.offer.toFixed(2)} PLN` : '';
  };

  const findHistoricalOrders = (selectedRoundNumber: number) => {
    const historicalRound = findRound(selectedRoundNumber, gameRoundPhaseStatus.END_PHASE);
    const historicalOrders = historicalRound?.roundState?.room?.ordersActual ?? [];
    return historicalOrders.filter(
      (historicalOrder) => historicalOrder.visibility || existAuctionForOrder(historicalOrder.id)
    );
  };

  const getActualOrders = (selectedUserRoom: UserRoomState) => {
    const selectedRoundNumber = selectedUserRoom?.room?.currentRound;
    if (currentRoundNumber === selectedRoundNumber) {
      return actualOrders;
    }
    return selectedRoundNumber ? findHistoricalOrders(selectedRoundNumber) : [];
  };

  return (
    <div className={classes.root}>
      <div className={classes.tableContainer}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Produkt</TableCell>
              <TableCell align={'center'}>Jakość</TableCell>
              {isAutomation && <TableCell align={'center'}>Czas wykonania / opóźnienia</TableCell>}
              <TableCell align={'center'}>Ilość</TableCell>
              <TableCell>Akcja</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getActualOrders(selectedUserRoom).map((actualOrder) => (
              <TableRow key={actualOrder.id}>
                <TableCell>
                  <p>{`Nazwa: ${actualOrder.name}`}</p>
                  <p>{`Cena: ${actualOrder.price.toFixed(2)} PLN`}</p>
                </TableCell>
                <TableCell align={'center'}>{actualOrder.quality}</TableCell>
                {isAutomation && (
                  <TableCell align={'center'}>
                    {actualOrder.timeExecution}/{actualOrder.timeDelay}
                  </TableCell>
                )}
                <TableCell align={'center'}>{actualOrder.amount}</TableCell>
                <TableCell title={isZeroRound() ? 'Nie można licytować w turze 0' : ''}>
                  <Button
                    variant="contained"
                    color="default"
                    onClick={() => (isAutomation ? handleOpenAuctionModal(actualOrder) : handleTakeOrder(actualOrder))}
                    disabled={
                      !canEdit() ||
                      isOrderTaken(actualOrder.id) ||
                      isSetAuction(actualOrder.id) ||
                      isZeroRound() ||
                      currentRoundNumber !== selectedUserRoom?.room?.currentRound
                    }
                  >
                    {getButtonDesc(actualOrder)}
                  </Button>
                  {getAuctionOffer(actualOrder)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <AuctionModal
          isOpen={openAuctionDialog}
          userRoom={userRoom}
          onClose={onCloseModal}
          actualOrder={selectedActualOrder}
        />
      </div>
    </div>
  );
}
