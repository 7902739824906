import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '5px',
    marginBottom: '5px',
    position: 'relative',
  },
  swatchWrapper: {
    marginTop: '5px',
    width: '100%',
  },
  color: {
    width: '100%',
    height: '14px',
    borderRadius: '2px',
  },
  swatch: {
    padding: '5px',
    background: '#fff',
    borderRadius: '1px',
    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
    cursor: 'pointer',
  },
  popover: {
    top: '-290px',
    right: '0px',
    position: 'absolute',
    'z-index': '2',
  },
  cover: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  },
});
