import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import { FactoryAllResourcesProps } from './FactoryAllResources.interface';
import { AddResourceToFactoryPopper } from 'app/components/AddResourceToFactoryPopper/AddResourceToFactoryPopper';
import { ResourceInterface } from 'app/shared/interfaces/Resource.interface';
import { useStyles } from './FactoryAllResources.styles';
import { getFactory, setActiveResource, getActiveResource, clearActiveResource } from 'app/store/factory';

export function FactoryAllResources({ resources, shouldRefreshOwnResources }: FactoryAllResourcesProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const factory = useSelector(getFactory);
  const activeResource = useSelector(getActiveResource);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [openAddPopper, setAddOpenPopper] = useState<boolean>(false);
  const [selectedResource, setSelectedResource] = useState<ResourceInterface | null>(null);

  const getResourceById = (id: number) => {
    const resource = resources.find((resource) => resource.id === id);
    return resource ? resource : null;
  };

  const handleAddResource = (e: React.MouseEvent<HTMLButtonElement>, id: number) => {
    e.stopPropagation();
    setSelectedResource(getResourceById(id));
    setAnchorEl(e.currentTarget);
    setAddOpenPopper(true);
  };

  const handleRefreshOwnResources = () => {
    shouldRefreshOwnResources();
    setAddOpenPopper(false);
  };

  const handleCloseAddPopper = () => {
    setAddOpenPopper(false);
  };

  const handleRowClick = (e: React.MouseEvent<HTMLDivElement>, id: number) => {
    const resource = getResourceById(id);
    if (resource && activeResource && resource.id === activeResource.id) {
      dispatch(clearActiveResource());
    } else if (resource) {
      dispatch(setActiveResource(resource));
    }
  };

  const handleCancelAddingResource = () => {
    dispatch(clearActiveResource());
  };

  return (
    <div className={classes.root}>
      <h2>Zasoby produkcyjne</h2>
      <div className={classes.tableContainer}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Nazwa</TableCell>
              <TableCell>Cena [PLN]</TableCell>
              <TableCell>Wy.</TableCell>
              <TableCell>Sz.</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <ClickAwayListener onClickAway={handleCancelAddingResource}>
          <TableBody>
            {resources.map((resource) => (
              <TableRow
                key={resource.id}
                className={clsx(classes.tableRow, {
                  [classes.activeRow]: activeResource && activeResource.id === resource.id,
                })}
                onClick={(e) => handleRowClick(e, resource.id)}
              >
                <TableCell className={classes.tableCell}>{resource.name}</TableCell>
                <TableCell className={classes.tableCell}>{resource.price.toFixed(2)}</TableCell>
                <TableCell className={classes.tableCell}>{resource.width}</TableCell>
                <TableCell className={classes.tableCell}>{resource.length}</TableCell>
                <TableCell className={classes.tableCell} align={'right'}>
                  <IconButton size={'small'} onClick={(e) => handleAddResource(e, resource.id)}>
                    <AddIcon fontSize={'small'} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          </ClickAwayListener>
        </Table>
      </div>
      <AddResourceToFactoryPopper
        isOpen={openAddPopper}
        anchorEl={anchorEl}
        onClose={handleCloseAddPopper}
        factory={factory}
        resource={selectedResource}
        resources={resources}
        shouldRefreshOnwResources={handleRefreshOwnResources}
      />
    </div>
  );
}
