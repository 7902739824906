import React, { CSSProperties, ReactNode } from 'react';
import { throttle } from 'lodash';
import Tooltip from '@material-ui/core/Tooltip';

import { ResourceInterface } from 'app/shared/interfaces/Resource.interface';
import { FactoryAreaPropsInterfaces, FactoryResourcesInterface } from 'app/shared/interfaces/Factory.interface';
import { GameFactoryResourceInterface } from 'app/shared/interfaces/GameFactoryResource.interface';

export const initialAreaProps: FactoryAreaPropsInterfaces = {
  cellHeight: 30,
  cellWidth: 30,
  rows: 23,
  cols: 23,
};

export const gridAxisInitialSettings = {
  MAX_AXIS_VALUE: 25,
  MIN_AXIS_VALUE: -25,
};

export const calculateAreaProps = (scale: number): FactoryAreaPropsInterfaces => {
  const initAreaHeight = initialAreaProps.cellHeight * initialAreaProps.rows;
  const currentCellHeight = initialAreaProps.cellHeight - scale;

  const initAreaWidth = initialAreaProps.cellWidth * initialAreaProps.cols;
  const currentCellWidth = initialAreaProps.cellWidth - scale;

  return {
    rows: Math.floor(initAreaHeight / currentCellHeight),
    cols: Math.floor(initAreaWidth / currentCellWidth),
    cellHeight: currentCellHeight,
    cellWidth: currentCellWidth,
  };
};

interface ShowTmpItemInterface {
  resource?: ResourceInterface;
  itemEl: HTMLDivElement | null;
  areaProps: FactoryAreaPropsInterfaces;
}

export const showTmpItem = ({
  resource,
  itemEl,
  areaProps: { cellHeight, cellWidth, cols, rows },
}: ShowTmpItemInterface) => {
  if (resource && itemEl) {
    const tmpElStyle = itemEl.style;
    tmpElStyle.width = resource.length * cellWidth + 'px';
    tmpElStyle.height = resource.width * cellHeight + 'px';
    tmpElStyle.backgroundColor = resource.color;
    tmpElStyle.top = Math.floor(rows / 2) * cellHeight + 'px';
    tmpElStyle.left = Math.floor(cols / 2) * cellWidth + 'px';
    tmpElStyle.display = 'flex';
    itemEl.children[0].textContent = resource.name;
  }
};

interface CreateInlineStylesInterface {
  resource?: ResourceInterface;
  factoryResource: FactoryResourcesInterface;
  isActive: boolean;
  offsetX: number;
  offsetY: number;
  areaProps: FactoryAreaPropsInterfaces;
}

export const calculatePositionX = (
  e: React.MouseEvent<HTMLDivElement>,
  areaEl: HTMLDivElement | null,
  areaProps: FactoryAreaPropsInterfaces,
  width: number
) =>
  areaEl
    ? Math.round(
        (e.clientX - areaEl.offsetLeft + window.scrollX - 40 - (width * areaProps.cellWidth) / 2) / areaProps.cellWidth
      )
    : 0;

export const calculatePositionY = (
  e: React.MouseEvent<HTMLDivElement>,
  areaEl: HTMLDivElement | null,
  areaProps: FactoryAreaPropsInterfaces,
  height: number
) =>
  areaEl
    ? Math.round(
        (e.clientY - areaEl.offsetTop + window.scrollY - 40 - (height * areaProps.cellWidth) / 2) / areaProps.cellHeight
      )
    : 0;

export const createInlineStyles = ({
  resource,
  factoryResource,
  isActive,
  offsetX,
  offsetY,
  areaProps: { cellWidth, cellHeight },
}: CreateInlineStylesInterface) => ({
  position: 'absolute' as 'absolute',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  userSelect: 'none' as 'none',
  cursor: 'pointer',
  top: (factoryResource.y + offsetY) * cellHeight,
  left: (factoryResource.x - offsetX) * cellWidth,
  backgroundColor: resource ? resource.color : 'white',
  width: (resource ? resource.length : 1) * cellWidth,
  height: (resource ? resource.width : 1) * cellHeight,
  border: isActive ? '3px solid #7FFF00' : '1px solid #000',
  fontSize: 12,
});

interface MoveTmpElementInterface {
  e: React.MouseEvent<HTMLDivElement>;
  width: number;
  height: number;
  areaEl: HTMLDivElement | null;
  tmpItemEl: HTMLDivElement | null;
  areaProps: FactoryAreaPropsInterfaces;
  setOffsets: (offsetX: number, offsetY: number) => void;
  offsetX: number;
  offsetY: number;
}

const moveMap = throttle((offsetX: number, offsetY: number, callback: (x: number, y: number) => void) => {
  callback(offsetX, offsetY);
}, 200);

export const moveTmpElement = ({
  e,
  width,
  height,
  areaEl,
  tmpItemEl,
  areaProps,
  setOffsets,
  offsetX,
  offsetY,
}: MoveTmpElementInterface) => {
  if (areaEl && tmpItemEl) {
    const x = calculatePositionX(e, areaEl, areaProps, width);
    const y = calculatePositionY(e, areaEl, areaProps, height);

    if (x <= areaProps.cols - width && x >= 0 && y <= areaProps.rows - height && y >= 0) {
      tmpItemEl.style.left = x * areaProps.cellWidth + 'px';
      tmpItemEl.style.top = y * areaProps.cellHeight + 'px';
    } else {
      // Should move map
      let _offsetY = 0;
      let _offsetX = 0;

      // Move horizontally
      if (x < 0 && offsetX > gridAxisInitialSettings.MIN_AXIS_VALUE) {
        _offsetX = -1;
      }
      if (x > areaProps.cols - width && offsetX < gridAxisInitialSettings.MAX_AXIS_VALUE - areaProps.cols + 1) {
        _offsetX = 1;
      }

      // Move vertically
      if (y < 0 && offsetY < gridAxisInitialSettings.MAX_AXIS_VALUE) {
        _offsetY = 1;
      }
      if (y > areaProps.rows - height && offsetY > areaProps.rows - gridAxisInitialSettings.MAX_AXIS_VALUE - 1) {
        _offsetY = -1;
      }

      moveMap(_offsetX, _offsetY, setOffsets);
    }
  }
};

export const getItemAreaSize = ({ cellWidth, cellHeight, rows, cols }: FactoryAreaPropsInterfaces): CSSProperties => ({
  width: cols * cellWidth,
  height: rows * cellHeight,
});

export const getSliderDownStyle = ({ cellWidth, cols }: FactoryAreaPropsInterfaces): CSSProperties => ({
  paddingLeft: 40,
  width: cols * cellWidth + 40,
});

interface CreateItemsInterface {
  factoryResources: Array<FactoryResourcesInterface>;
  gameFactoryResources: Array<GameFactoryResourceInterface> | undefined;
  resources: Array<ResourceInterface>;
  offsetX: number;
  offsetY: number;
  activeFactoryResourceId: number | undefined;
  handleSelectItemClick: (e: React.MouseEvent<HTMLDivElement>, id: number) => void;
  areaProps: FactoryAreaPropsInterfaces;
}

const tooltipRow = (title: string, value: string | number, suffix?: string) => (
  <div>
    <span style={{ display: 'inline-block', minWidth: 120 }}>{title}</span>
    <span>
      {value} {suffix}
    </span>
  </div>
);
const tooltipMultipleRow = (title: string, value: string | number, key: number) => (
  <div key={key}>
    <span style={{ display: 'inline-block', minWidth: 80 }}>{title}</span>
    <span>{value}</span>
  </div>
);

const getHoldingFieldConnection = (gameFactoryResource: GameFactoryResourceInterface | null | undefined): string => {
  if (gameFactoryResource?.holdingFieldConnection?.resourceActual) {
    return gameFactoryResource?.holdingFieldConnection?.resourceActual.name;
  }
  return 'Brak';
};

const getStorageFillLevel = (gameFactoryResource: GameFactoryResourceInterface | null | undefined): number => {
  if (gameFactoryResource) {
    return gameFactoryResource.storageFillLevel;
  }
  return 0;
};

const getMaterialsWarehouse = (gameFactoryResource: GameFactoryResourceInterface | null | undefined) => {
  if (gameFactoryResource?.materialWarehouses) {
    return gameFactoryResource?.materialWarehouses
      .filter(
        (materialWarehouse) =>
          materialWarehouse.gameMaterial && materialWarehouse.quantity > 0 && materialWarehouse.gameProduct == null
      )
      .map((materialWarehouse) => {
        return {
          text: `${materialWarehouse.gameMaterial.actualMaterial?.name} (${materialWarehouse.gameMaterial.orderNumber}) / ${materialWarehouse.quantity} / (${materialWarehouse.gameMaterial.processStep} / ${materialWarehouse.gameMaterial.processActivityCount})  `,
          key: materialWarehouse.gameMaterial.id,
        };
      });
  }
  return [];
};

const getProductsWarehouse = (gameFactoryResource: GameFactoryResourceInterface | null | undefined) => {
  if (gameFactoryResource?.materialWarehouses) {
    return gameFactoryResource?.materialWarehouses
      .filter((materialWarehouse) => materialWarehouse.gameProduct)
      .map((materialWarehouse) => {
        return {
          text: `${materialWarehouse.gameProduct.name} / ${materialWarehouse.quantity} / ${materialWarehouse.gameProduct.quality} `,
          key: materialWarehouse.gameProduct.id,
        };
      });
  }
  return [];
};

const getGameFactoryResourcePriceAfterBuy = (
  resource: FactoryResourcesInterface,
  gameFactoryResources: Array<GameFactoryResourceInterface> | undefined
) => {
  if (gameFactoryResources) {
    const gameFactory = gameFactoryResources.find((item) => item.id === resource.id);
    return gameFactory ? gameFactory.priceAfterBuy.toFixed(2) : 0;
  }
  return 0;
};

export const createItemTooltip = (
  resource: FactoryResourcesInterface,
  gameFactoryResources: Array<GameFactoryResourceInterface> | undefined
) => {
  const gameFactoryResource = gameFactoryResources
    ? gameFactoryResources.find((gameFactoryResource) => gameFactoryResource.id === resource.id)
    : null;

  switch (resource.resource.resourceTypeActual?.name) {
    case 'WS':
    case 'WR':
      return (
        <>
          {tooltipRow('Nazwa:', `${resource.resource.name} (${resource.resourceNumber})`)}
          {tooltipRow('Długość:', resource.resource.length)}
          {tooltipRow('Szerokość:', resource.resource.width)}
          {tooltipRow('Czas TPZ:', resource.resource.timeSetup)}
          {tooltipRow('WSP TJ:', resource.resource.wtj)}
          {tooltipRow('Jakość:', resource.resource.quality)}
          {tooltipRow('Koszt stały:', resource.resource.costFixed.toFixed(2), 'PLN')}
          {tooltipRow('Koszt zmienny:', resource.resource.costVariable.toFixed(2), 'PLN')}
          {tooltipRow('Cena:', getGameFactoryResourcePriceAfterBuy(resource, gameFactoryResources), 'PLN')}
        </>
      );
    case 'KJ':
      return (
        <>
          {tooltipRow('Nazwa:', resource.resource.name)}
          {tooltipRow('Długość:', resource.resource.length)}
          {tooltipRow('Szerokość:', resource.resource.width)}
          {tooltipRow('Czas TPZ:', resource.resource.timeSetup)}
          {tooltipRow('WSP TJ:', resource.resource.wtj)}
          {tooltipRow('Jakość:', resource.resource.quality)}
          {tooltipRow('Koszt stały:', resource.resource.costFixed.toFixed(2), 'PLN')}
          {tooltipRow('Koszt zmienny:', resource.resource.costVariable.toFixed(2), 'PLN')}
          {tooltipRow('Cena:', getGameFactoryResourcePriceAfterBuy(resource, gameFactoryResources), 'PLN')}
        </>
      );
    case 'SW':
      return (
        <>
          {tooltipRow('Nazwa:', resource.resource.name)}
          {tooltipRow('Długość:', resource.resource.length)}
          {tooltipRow('Szerokość:', resource.resource.width)}
          {tooltipRow('Czas TPZ:', resource.resource.timeSetup)}
          {tooltipRow('WSP TJ:', resource.resource.wtj)}
          {tooltipRow('Jakość:', resource.resource.quality)}
          {tooltipRow('Koszt stały:', resource.resource.costFixed.toFixed(2), 'PLN')}
          {tooltipRow('Koszt zmienny:', resource.resource.costVariable.toFixed(2), 'PLN')}
          {tooltipRow('Cena:', getGameFactoryResourcePriceAfterBuy(resource, gameFactoryResources), 'PLN')}
        </>
      );
    case 'MAG':
      return (
        <>
          {tooltipRow('Nazwa:', resource.resource.name)}
          {tooltipRow('Pojemność:', `${getStorageFillLevel(gameFactoryResource)} / ${resource.resource.capacity}`)}
          {tooltipRow('Długość:', resource.resource.length)}
          {tooltipRow('Szerokość:', resource.resource.width)}
          {tooltipRow('Czas TPZ:', resource.resource.timeSetup)}
          {tooltipRow('WSP TJ:', resource.resource.wtj)}
          {tooltipRow('Jakość:', resource.resource.quality)}
          {tooltipRow('Koszt stały:', resource.resource.costFixed.toFixed(2), 'PLN')}
          {tooltipRow('Koszt zmienny:', resource.resource.costVariable.toFixed(2), 'PLN')}
          {tooltipRow('Cena:', getGameFactoryResourcePriceAfterBuy(resource, gameFactoryResources), 'PLN')}
          {tooltipRow('Materiały:', 'Nazwa / Ilość / (Stan wykonania)')}
          {getMaterialsWarehouse(gameFactoryResource).map((item) => tooltipMultipleRow('', item.text, item.key))}
          {tooltipRow('Produkty:', 'Nazwa / Ilość / Jakość')}
          {getProductsWarehouse(gameFactoryResource).map((item) => tooltipMultipleRow('', item.text, item.key))}
        </>
      );
    case 'PO':
      return (
        <>
          {tooltipRow('Nazwa:', resource.resource.name)}
          {tooltipRow('Stanowisko:', `${getHoldingFieldConnection(gameFactoryResource)}`)}
          {tooltipRow('Pojemność:', `${getStorageFillLevel(gameFactoryResource)} / ${resource.resource.capacity}`)}
          {tooltipRow('Długość:', resource.resource.length)}
          {tooltipRow('Szerokość:', resource.resource.width)}
          {tooltipRow('Czas TPZ:', resource.resource.timeSetup)}
          {tooltipRow('WSP TJ:', resource.resource.wtj)}
          {tooltipRow('Jakość:', resource.resource.quality)}
          {tooltipRow('Koszt stały:', resource.resource.costFixed.toFixed(2), 'PLN')}
          {tooltipRow('Koszt zmienny:', resource.resource.costVariable.toFixed(2), 'PLN')}
          {tooltipRow('Cena:', getGameFactoryResourcePriceAfterBuy(resource, gameFactoryResources), 'PLN')}
          {tooltipRow('Materiały:', 'Nazwa / Ilość / (Stan wykonania)')}
          {getMaterialsWarehouse(gameFactoryResource).map((item) => tooltipMultipleRow('', item.text, item.key))}
          {tooltipRow('Produkty:', 'Nazwa / Ilość / Jakość')}
          {getProductsWarehouse(gameFactoryResource).map((item) => tooltipMultipleRow('', item.text, item.key))}
        </>
      );
    case 'D':
      return (
        <>
          {tooltipRow('Nazwa:', resource.resource.name)}
          {tooltipRow('Długość:', resource.resource.length)}
          {tooltipRow('Szerokość:', resource.resource.width)}
          {tooltipRow('Cena:', getGameFactoryResourcePriceAfterBuy(resource, gameFactoryResources), 'PLN')}
        </>
      );
    default:
      return '';
  }
};

export const createItems = ({
  offsetX,
  offsetY,
  activeFactoryResourceId,
  factoryResources,
  gameFactoryResources,
  resources,
  handleSelectItemClick,
  areaProps,
}: CreateItemsInterface): Array<ReactNode> => {
  return factoryResources.map((res) => {
    const resource = resources.find((r) => r.id === res.resource.id);
    const isActive = res.id === activeFactoryResourceId;
    const style: CSSProperties = createInlineStyles({
      resource,
      factoryResource: res,
      isActive,
      offsetX,
      offsetY,
      areaProps,
    });

    const label = resource ? `${resource.name}${res.resourceNumber ? ' (' + res.resourceNumber + ')' : ''}` : '';

    return (
      <Tooltip key={res.id} title={createItemTooltip(res, gameFactoryResources)} placement={'top'}>
        <div id={`factory-recource-${res.id}`} style={style} onClick={(e) => handleSelectItemClick(e, res.id)}>
          <span>{label}</span>
        </div>
      </Tooltip>
    );
  });
};

const isInRange = (x: number, start: number, end: number) => {
  return x > start && x < end;
};

export const isCollision = (
  newX: number,
  newY: number,
  gridFactoryResource: FactoryResourcesInterface,
  gridResource?: ResourceInterface,
  newResource?: ResourceInterface
) => {
  return (
    gridResource &&
    newResource &&
    //checks collision in the X axis and the Y axis for two resources
    existsCommonPartOfTwoIntervals(
      newX,
      newX + newResource.length,
      gridFactoryResource.x,
      gridFactoryResource.x + gridResource.length
    ) &&
    existsCommonPartOfTwoIntervals(
      newY,
      newY + newResource.width,
      gridFactoryResource.y,
      gridFactoryResource.y + gridResource.width
    )
  );
};

const existsCommonPartOfTwoIntervals = (aStart: number, aEnd: number, bStart: number, bEnd: number) => {
  return (
    (aStart === bStart && aEnd === bEnd) ||
    isInRange(aStart, bStart, bEnd) ||
    isInRange(aEnd, bStart, bEnd) ||
    isInRange(bStart, aStart, aEnd) ||
    isInRange(bEnd, aStart, aEnd)
  );
};
