import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  root: {
    backgroundColor: '#ffffff',
    borderRadius: 5,
    padding: 15,
    border: '1px solid #ccc',
    zIndex: 5,
  },
  header: {
    marginBottom: 15,
    fontSize: 18,
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
});
