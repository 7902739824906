interface Violations {
  message: string;
  propertyPath: string;
}

interface HydraError {
  'hydra:description': string;
  'hydra:title': string;
  violations: Array<Violations>;
}

interface ErrorResponse {
  data: HydraError;
  status: number;
}

export const handlingCatch = (errorResponse: ErrorResponse) => {
  if (errorResponse.data.violations && errorResponse.data.violations.length) {
    const errorMessage: string = errorResponse.data.violations[0].message.toString();
    return {
      statusCode: errorResponse.status,
      fieldError: errorResponse.data.violations[0].propertyPath,
      message: getTranslationMessage(errorMessage),
    };
  } else if (errorResponse.data['hydra:description']) {
    return {
      statusCode: errorResponse.status,
      fieldError: '',
      message: getTranslationMessage(errorResponse.data['hydra:description']),
    };
  } else {
    return {
      statusCode: errorResponse.status,
      fieldError: '',
      message: 'Nieznany błąd',
    };
  }
};

const errorMessageENG = {
  usedName: 'This value is already used.',
  invalidName: 'Name should have atleast 2 characters (e.g., A1)',
  relationMaterialSupplier: 'Material is in relation with suppliers',
  relationActivityProcess: 'Activity is in relation with Process',
  relationActivityTypeActivity: 'ActionType is in relation with Activity',
  relationFactoryScenario: 'Factory is in relation with Scenario',
  relationResourceScenario: 'Resources is in relation with Activity or ScenarioTemplate',
  relationResourceTypeResource: 'ResourceType is in relation with Resource',
  relationOrderScenario: 'Order is in relation with Scenario or with Product',
  relationDistributionTemplateScenario: 'Distribution Template is in relation with scenario',
  relationProcessScenarioTemplate: 'Process is in relation with ScenarioTemplate',
  relationProductProcess: 'Product is in relation with process',
  relationSupplierScenario: 'Supplier is in relation with scenario',
  relationMaterialTypeMaterial: 'MaterialType is in relation with Material or Product',
  relationGanttProcess: 'GanttTemplate is in relation with Process',
  relationProductProductOrder: 'Product is in relation with ProductOrder',
  relationScenarioRoomDistribution: 'ScenarioTemplate is in relation with Room or Distribution',
  relationResourceActivityScenarioFactory: 'Resources is in relation with Activity, ScenarioTemplate or Factory',
  emptyValue: 'This value should not be blank.',
};
const errorMessagePL = {
  usedName: 'Podana nazwa jest zajęta.',
  invalidName: 'Nazwa powinna zawierać conajmniej dwa znaki (e.g., A1)',
  relationMaterialSupplier: 'Materiał jest powiązany z dostawcą.',
  relationActivityProcess: 'Czynność jest powiązana z gantem lub procesem.',
  relationActivityTypeActivity: 'Typ czynności jest powiązany z czynnością.',
  relationFactoryScenario: 'Fabryka jest powiązana ze scenariuszem.',
  relationResourceScenario: 'Zasób jest powiązany ze scenariuszem, czynnością, lub fabryką.',
  relationResourceTypeResource: 'Typ zasobu jest powiązany z zasobem.',
  relationOrderScenario: 'Zamówienie jest powiązane ze scenariuszem lub produktem.',
  relationDistributionTemplateScenario: 'Rozkład jest powiązany ze scenariuszem.',
  relationProcessScenarioTemplate: 'Proces jest powiązany ze scenariuszem.',
  relationProductProcess: 'Produkt jest powiązany z procesem.',
  relationSupplierScenario: 'Dostawca jest powiązany ze scenariuszem.',
  relationMaterialTypeMaterial: 'Typ materiału jest powiązany z materiałem lub produktem.',
  relationGanttProcess: 'Szablon Gantta jest powiązany z procesem.',
  relationProductProductOrder: 'Produkt jest powiązany z zamówieniem.',
  relationScenarioRoomDistribution: 'Scenariusz jest powiązany z pokojem lub rozkładem',
  relationResourceActivityScenarioFactory: 'Zasób jest powiązany z czynnością, scenariuszem lub fabryką',
  emptyValue: 'Pole nie może być puste.',
};

const getTranslationMessage = (message: string) => {
  let translateKey = message;
  let translateMessage = message;
  for (const [key, value] of Object.entries(errorMessageENG)) {
    if (value === message) {
      translateKey = key;
    }
  }
  for (const [key, value] of Object.entries(errorMessagePL)) {
    if (key === translateKey) {
      translateMessage = value;
    }
  }
  return translateMessage;
};
