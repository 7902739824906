import React from 'react';
import { useSelector } from 'react-redux';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { GameFactoryAccordionProps } from './GameFactoryAccordion.interface';
import { GameResources } from 'app/components/GameResources/GameResources';
import { GameProcesses } from 'app/components/GameProcesses/GameProcesses';
import {
  getAvailableGameProducts,
  getGameMaterials,
  getGameMaterialsQueues,
  getGameProcesses,
  getIsAutomation,
  getRoom,
  getSelectedUserRoom,
} from 'app/store/userRoom';
import { GameOrders } from 'app/components/GameOrders/GameOrders';
import { GameSuppliersAndMaterials } from 'app/components/GameSuppliersAndMaterials/GameSuppliersAndMaterials';
import { GameOwnOrders } from 'app/components/GameOwnOrders/GameOwnOrders';
import { GameOwnProcesses } from 'app/components/GameOwnProcesses/GameOwnProcesses';
import { GameOwnProducts } from 'app/components/GameOwnProducts/GameOwnProducts';
import { GameOwnMaterialQueues } from 'app/components/GameOwnMaterialQueues/GameOwnMaterialQueues';
import { GameOwnMaterials } from 'app/components/GameOwnMaterials/GameOwnMaterials';
import { GameRounds } from 'app/components/GameRounds/GameRounds';

import { useStyles } from './GameFactoryAccordion.styles';

export function GameFactoryAccordion({
  updateRoom,
  resources,
  actualProcesses,
  actualOrders,
  myAuctions,
  actualSuppliers,
  showSelectRoundComponent,
}: GameFactoryAccordionProps) {
  const classes = useStyles();
  const selectedUserRoom = useSelector(getSelectedUserRoom);
  const room = useSelector(getRoom);
  const gameProcesses = useSelector(getGameProcesses);
  const gameAvailableProducts = useSelector(getAvailableGameProducts);
  const gameMaterialQueues = useSelector(getGameMaterialsQueues);
  const gameMaterials = useSelector(getGameMaterials);
  const isAutomation = useSelector(getIsAutomation);
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [expandedExternal, setExpandedExternal] = React.useState<string | false>(false);

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) =>
    setExpanded(isExpanded ? panel : false);

  const handleChangeExternal = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) =>
    setExpandedExternal(isExpanded ? panel : false);

  return (
    <div>
      <Accordion
        expanded={expandedExternal === 'a1'}
        onChange={handleChangeExternal('a1')}
        className={classes.accordion}
      >
        <AccordionSummary
          id={'a1'}
          expandIcon={<ExpandMoreIcon className={classes.expandIconExternal} />}
          className={classes.accordionSummaryExternal}
        >
          <div>Wszystkie</div>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <Accordion expanded={expanded === 'b1'} onChange={handleChange('b1')}>
            <AccordionSummary
              id={'b1'}
              expandIcon={<ExpandMoreIcon className={classes.expandIconInternal} />}
              className={classes.accordionSummaryInternal}
            >
              <div>Zasoby</div>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <GameResources extended resources={resources} />
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'b2'} onChange={handleChange('b2')}>
            <AccordionSummary
              id={'b2'}
              expandIcon={<ExpandMoreIcon className={classes.expandIconInternal} />}
              className={classes.accordionSummaryInternal}
            >
              <div>Dostawcy / Materiały</div>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <GameSuppliersAndMaterials
                updateRoom={updateRoom}
                actualSuppliers={actualSuppliers}
                userRoom={selectedUserRoom}
                room={room}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'b3'} onChange={handleChange('b3')}>
            <AccordionSummary
              id={'b3'}
              expandIcon={<ExpandMoreIcon className={classes.expandIconInternal} />}
              className={classes.accordionSummaryInternal}
            >
              <div>Zamówienia</div>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <GameOrders
                updateRoom={updateRoom}
                actualOrders={actualOrders}
                userRoom={selectedUserRoom}
                myAuctions={myAuctions}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'b4'} onChange={handleChange('b4')}>
            <AccordionSummary
              id={'b4'}
              expandIcon={<ExpandMoreIcon className={classes.expandIconInternal} />}
              className={classes.accordionSummaryInternal}
            >
              <div>Procesy</div>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <GameProcesses updateRoom={updateRoom} actualProcesses={actualProcesses} userRoom={selectedUserRoom} />
            </AccordionDetails>
          </Accordion>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedExternal === 'a2'}
        onChange={handleChangeExternal('a2')}
        className={classes.accordion}
      >
        <AccordionSummary
          id={'a2'}
          expandIcon={<ExpandMoreIcon className={classes.expandIconExternal} />}
          className={classes.accordionSummaryExternal}
        >
          <div>Moje</div>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <Accordion expanded={expanded === 'c1'} onChange={handleChange('c1')}>
            <AccordionSummary
              id={'c1'}
              expandIcon={<ExpandMoreIcon className={classes.expandIconInternal} />}
              className={classes.accordionSummaryInternal}
            >
              <div>Moje materiały</div>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <GameOwnMaterials gameMaterials={gameMaterials} />
            </AccordionDetails>
          </Accordion>
          {!!gameMaterialQueues.length && (
            <Accordion expanded={expanded === 'c2'} onChange={handleChange('c2')}>
              <AccordionSummary
                id={'c2'}
                expandIcon={<ExpandMoreIcon className={classes.expandIconInternal} />}
                className={classes.accordionSummaryInternal}
              >
                <div>Materiały w trakcie transportu</div>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetails}>
                <GameOwnMaterialQueues gameMaterialQueues={gameMaterialQueues} />
              </AccordionDetails>
            </Accordion>
          )}
          <Accordion expanded={expanded === 'c3'} onChange={handleChange('c3')}>
            <AccordionSummary
              id={'c3'}
              expandIcon={<ExpandMoreIcon className={classes.expandIconInternal} />}
              className={classes.accordionSummaryInternal}
            >
              <div>Moje produkty</div>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <GameOwnProducts gameProducts={gameAvailableProducts} />
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'c4'} onChange={handleChange('c4')}>
            <AccordionSummary
              id={'c4'}
              expandIcon={<ExpandMoreIcon className={classes.expandIconInternal} />}
              className={classes.accordionSummaryInternal}
            >
              <div>Moje procesy</div>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <GameOwnProcesses gameProcesses={gameProcesses} />
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'c5'} onChange={handleChange('c5')}>
            <AccordionSummary
              id={'c5'}
              expandIcon={<ExpandMoreIcon className={classes.expandIconInternal} />}
              className={classes.accordionSummaryInternal}
            >
              <div>Moje zamówienia</div>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <GameOwnOrders myAuctions={myAuctions} updateRoom={updateRoom} />
            </AccordionDetails>
          </Accordion>
          {isAutomation && showSelectRoundComponent && (
            <Accordion expanded={expanded === 'c6'} onChange={handleChange('c6')}>
              <AccordionSummary
                id={'c6'}
                expandIcon={<ExpandMoreIcon className={classes.expandIconInternal} />}
                className={classes.accordionSummaryInternal}
              >
                <div>Rundy</div>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetails}>
                <GameRounds />
              </AccordionDetails>
            </Accordion>
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
