import React, { useEffect, useState } from 'react';
import { useStyles } from './Version.styles';
import { BackendConfigService } from 'app/services/backendConfig.service';
import { BackendConfigInterface } from 'app/shared/interfaces/BackendConfig.interface';

interface Props {}

function Version(props: Props) {
  const classes = useStyles();

  const [backendConfig, setBackendConfig] = useState<BackendConfigInterface>();

  useEffect(() => {
    BackendConfigService.getAppVersion().then(({ data }) => {
      setBackendConfig(data);
    });
  }, []);

  return (
    <div className={classes.mainContainer}>FE ver.0.15.101 / BE ver. {backendConfig ? backendConfig.text : ''}</div>
  );
}

export default Version;
