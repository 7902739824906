import { AxiosPromise } from 'axios';

import { ApiService } from './api.service';
import { GameMaterialAddInterface } from 'app/shared/interfaces/GameMaterial.interface';

export const GameMaterialService = {
  buyMaterials(data: Omit<GameMaterialAddInterface, 'id'>): AxiosPromise {
    return ApiService.post('game_materials', data);
  },
};
