import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableBody from '@material-ui/core/TableBody';
import Typography from '@material-ui/core/Typography';
import NumberFormat from 'react-number-format';

import { getRoundPhaseStatus } from 'app/store/gameRounds';
import { roundPhaseStatues } from 'app/store/gameRounds/reducer';

import { CustomDialog } from 'app/components/CustomDialog/CustomDialog';
import { BalanceStatistics } from 'app/components/BalanceStatistics/BalanceStatistics';
import { TextFieldComponent } from 'app/components/TextFieldComponent/TextFieldComponent';

import { AddActionModalProps } from './UserRoomStatisticModal.interface';
import { useStyles } from './UserRoomStatisticModal.styles';
import { GamePlayerRankingInterface } from 'app/shared/interfaces/GamePlayerRanking.interface';
import { GameFactoryResourceInterface } from 'app/shared/interfaces/GameFactoryResource.interface';
import { GameMaterialPurchaseHistoryInterface } from 'app/shared/interfaces/GameMaterialPurchaseHistory.interface';
import { getLatestUserRoom } from '../../store/userRoom';

export function UserRoomStatisticModal(props: AddActionModalProps) {
  const { isOpen, onClose, gameRounds, selectedUserRoom, gamePlayerRankings } = props;
  const classes = useStyles();
  const [currentGamePlayerRanking, setCurrentGamePlayerRanking] = useState<Array<GamePlayerRankingInterface>>([]);
  const [currentRound, setCurrentRound] = useState<number>(0);
  const selectedRoundPhaseStatus = useSelector(getRoundPhaseStatus);
  const latestUserRoom = useSelector(getLatestUserRoom);

  const dialogTopRef = useRef(null);

  const formData = {
    cashBalance: selectedUserRoom.cashBalance.toFixed(2),
    currentPOZ: selectedUserRoom.currentPOZ,
    currentCompanyValue: selectedUserRoom.currentCompanyValue.toFixed(2),
    currentCustomerService: selectedUserRoom.currentCustomerService,
    currentEarningsAndCurrentLoss: `${selectedUserRoom.currentEarnings.toFixed(
      2
    )} / ${selectedUserRoom.currentLoss.toFixed(2)}`,
    currentCompanyEfficiency: selectedUserRoom.currentCompanyEfficiency,
    currentAverageProductQuality: selectedUserRoom.currentAverageProductQuality,
    currentPlanningEfficiency: selectedUserRoom.currentPlanningEfficiency,
    currentOEE: selectedUserRoom.currentOEE,
    currentWWM: selectedUserRoom.currentWWM,
    currentMarketActivity: selectedUserRoom.currentMarketActivity,
  };

  const preparePlayerRanking = useCallback(() => {
    const roundStatus = selectedRoundPhaseStatus === roundPhaseStatues.END ? 1 : 0;
    const currentPlayers = gamePlayerRankings.filter(
      (gamePlayerRanking) => gamePlayerRanking.round === currentRound && gamePlayerRanking.status === roundStatus
    );
    setCurrentGamePlayerRanking(currentPlayers.sort((a, b) => b.currentPOZ - a.currentPOZ));
  }, [gamePlayerRankings, currentRound, selectedRoundPhaseStatus]);

  const getMaterialPurchasePrice = (gameMaterialPurchase: GameMaterialPurchaseHistoryInterface): number => {
    return gameMaterialPurchase ? gameMaterialPurchase.purchasePrice * gameMaterialPurchase.amount : 0;
  };

  const getMagazine = (): Array<GameFactoryResourceInterface> => {
    if (!selectedUserRoom.gameFactory) {
      return [];
    }
    return selectedUserRoom.gameFactory?.gameFactoryResources.filter((gameFactoryResource) => {
      if (gameFactoryResource.resourceActual.resourceTypeActual) {
        if (gameFactoryResource.resourceActual.resourceTypeActual.name === 'MAG') {
          return gameFactoryResource;
        }
      }
    });
  };

  const getOEEResources = (): Array<GameFactoryResourceInterface> => {
    if (!selectedUserRoom.gameFactory) {
      return [];
    }
    return selectedUserRoom.gameFactory?.gameFactoryResources.filter((gameFactoryResource) => {
      const resourceName = gameFactoryResource?.resourceActual?.resourceTypeActual?.name || '';
      if (['WS', 'WR', 'KJ', 'SW'].includes(resourceName)) {
        return gameFactoryResource;
      }
    });
  };

  const getResourceOEE = (gameFactoryResource: GameFactoryResourceInterface): string => {
    // In last round display average OEE values
    if (currentRound === selectedUserRoom.room?.rounds) {
      let oeeValues: number = 0;
      let countOee: number = 0;

      gameRounds.forEach((gameRound) => {
        gameRound.roundState.gameFactory.gameFactoryResources.forEach((factoryResource) => {
          if (factoryResource.id === gameFactoryResource.id) {
            oeeValues += factoryResource.currentOEE;
            countOee++;
          }
        });
      });
      return (oeeValues / countOee).toFixed(2);
    } else {
      // In other rounds show current OEE
      return (
        selectedUserRoom.gameFactory?.gameFactoryResources
          .find((resource) => gameFactoryResource.id === resource.id)
          ?.currentOEE.toFixed(2) || '0.00'
      );
    }
  };

  const prepareCurrentRound = useCallback(() => {
    const currentRound = selectedUserRoom && selectedUserRoom.room ? selectedUserRoom.room.currentRound : 0;
    setCurrentRound(currentRound);
  }, [selectedUserRoom]);

  const getPurchaseGameMaterial = (round: number) => {
    const gameMaterialPurchaseHistory = selectedUserRoom.gameMaterialPurchaseHistories;
    if (!gameMaterialPurchaseHistory) {
      return [];
    }
    return gameMaterialPurchaseHistory.filter((gameMaterialPurchase) => gameMaterialPurchase.round === round);
  };

  const getFactoryCost = (round: number) => {
    let sum: number = 0;
    const gameFactory = latestUserRoom.gameFactory;
    const room = latestUserRoom.room;
    let currentRoundCost: number = 0;
    const calculateRound =
      room && room?.scenarioTemplateActual ? room?.scenarioTemplateActual?.numberOfRoundToReduceBalance : 5;

    if (gameFactory && gameFactory.gameFactoryFees) {
      gameFactory.gameFactoryFees.forEach((gameFactoryFeed) => {
        if (gameFactoryFeed.roundNumber === round) {
          currentRoundCost += Number(gameFactoryFeed.cost);
        }
      });
    }
    if ((round - calculateRound) % calculateRound !== 0 || round === 0) {
      return `${currentRoundCost.toFixed(2)} PLN`;
    }

    if (gameFactory && gameFactory.gameFactoryFees) {
      gameFactory.gameFactoryFees.forEach((gameFactoryFeed) => {
        const lastRound = (round / calculateRound) * calculateRound;
        const firstRound = round / calculateRound === 1 ? 0 : lastRound - calculateRound;
        if (
          calculateRound === lastRound &&
          gameFactoryFeed.roundNumber >= firstRound &&
          gameFactoryFeed.roundNumber <= lastRound &&
          gameFactoryFeed.isPaid
        ) {
          sum += Number(gameFactoryFeed.cost);
        } else if (
          gameFactoryFeed.roundNumber > firstRound &&
          gameFactoryFeed.roundNumber <= lastRound &&
          gameFactoryFeed.isPaid
        ) {
          sum += Number(gameFactoryFeed.cost);
        }
      });
    }

    return `${currentRoundCost.toFixed(2)} PLN (${sum.toFixed(2)} PLN)`;
  };

  const getProductionFactoryCost = (round: number) => {
    const gameFactory = selectedUserRoom.gameFactory;
    let sum: number = 0;
    if (gameFactory && gameFactory.gameFactoryProductionFees) {
      gameFactory.gameFactoryProductionFees.forEach((gameFactoryProductionFeed) => {
        if (gameFactoryProductionFeed.round === round && gameFactoryProductionFeed.isPaid) {
          sum += Number(gameFactoryProductionFeed.cost);
        }
      });
    }
    return `${sum.toFixed(2)} PLN`;
  };

  const getProductsMarketPricesRows = () => {
    const rows = [];
    const room = selectedUserRoom.room;
    const productsActual = room?.productsActual || [];
    for (let i = 0; i < productsActual.length; i++) {
      const product = productsActual[i];
      rows.push(
        <TableRow key={i}>
          <TableCell className={classes.tableHead}>{product.name}</TableCell>
          <TableCell className={classes.tableHead}>
            {' '}
            <span>
              {' '}
              <NumberFormat
                displayType={'text'}
                value={product.price}
                fixedDecimalScale
                decimalScale={2}
                thousandSeparator={' '}
                suffix={' PLN'}
              />
            </span>
          </TableCell>
        </TableRow>
      );
    }
    return rows;
  };

  const getBoughtMaterialRow = () => {
    const rowArray = [];
    for (let i = 0; i <= currentRound; i++) {
      const currentRoundPurchaseMaterials = getPurchaseGameMaterial(i);
      rowArray.push(
        <TableRow key={i}>
          <TableCell className={classes.tableHead}>Runda: {i}</TableCell>
          <TableCell className={classes.tableHead}>
            {currentRoundPurchaseMaterials.map((currentRoundPurchaseMaterial) => (
              <div key={currentRoundPurchaseMaterial.id} className={classes.materialPriceContainer}>
                <span>Nazwa: {currentRoundPurchaseMaterial.actualMaterial?.name}:</span>
                <span>Ilość: {currentRoundPurchaseMaterial.amount}</span>
                <span>
                  {' '}
                  Cena:
                  <NumberFormat
                    displayType={'text'}
                    value={getMaterialPurchasePrice(currentRoundPurchaseMaterial)}
                    fixedDecimalScale
                    decimalScale={2}
                    thousandSeparator={' '}
                    suffix={' PLN'}
                  />
                </span>
              </div>
            ))}
          </TableCell>
          <TableCell className={classes.tableHead}>{getProductionFactoryCost(i)}</TableCell>
          <TableCell className={classes.tableHead}>{getFactoryCost(i)}</TableCell>
        </TableRow>
      );
    }
    return rowArray;
  };

  useEffect(() => {
    preparePlayerRanking();
    prepareCurrentRound();
  }, [isOpen, preparePlayerRanking, prepareCurrentRound]);

  useEffect(() => {
    if (isOpen) {
      //@ts-ignore
      dialogTopRef?.current?.scrollIntoView({ behavior: 'auto' });
    }
  }, [isOpen]);

  return (
    <CustomDialog
      maxWidth={'xl'}
      title={'Informacje o grze'}
      isOpen={isOpen}
      onCloseDialog={onClose}
      body={
        <div ref={dialogTopRef}>
          <Typography variant={'h1'}>Pozycja rynkowa graczy</Typography>
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHead}>Gracz</TableCell>
                  <TableCell className={classes.tableHead}>POZ</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentGamePlayerRanking.map((player) => (
                  <TableRow key={player['@id']}>
                    <TableCell className={classes.tableHead}>
                      {player.userRoom.user && player.userRoom.user.email
                        ? player.userRoom.user.email
                        : `BOT ${player.userRoom.id}`}
                    </TableCell>
                    <TableCell className={classes.tableHead}>{player.currentPOZ}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Typography variant={'h1'}>Cena rynkowa produktów</Typography>
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHead}>Nazwa</TableCell>
                  <TableCell className={classes.tableHead}>Cena rynkowa</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{getProductsMarketPricesRows()}</TableBody>
            </Table>
          </TableContainer>
          <Typography variant={'h1'}>Zakupione materiały</Typography>
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHead}>Runda</TableCell>
                  <TableCell className={classes.tableHead}>Zakupione Materiały</TableCell>
                  <TableCell className={classes.tableHead}>Koszty produkcji</TableCell>
                  <TableCell className={classes.tableHead}>Koszty stałe</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{getBoughtMaterialRow()}</TableBody>
            </Table>
          </TableContainer>
          {
            <BalanceStatistics
              currentCompanyValue={selectedUserRoom.currentCompanyValue}
              cashBalance={selectedUserRoom.cashBalance}
              currentEarnings={selectedUserRoom.currentEarnings}
              currentLoss={selectedUserRoom.currentLoss}
              gameOrders={selectedUserRoom.gameOrders}
            />
          }
          <Typography variant={'h1'}>Współczynnik wykorzystania magazynów</Typography>
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHead}>Magazyn</TableCell>
                  <TableCell className={classes.tableHead}>WWM</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {getMagazine().map((factoryResource) => (
                  <TableRow key={factoryResource['@id']}>
                    <TableCell className={classes.tableHead}>{factoryResource.resourceActual.name}</TableCell>
                    <TableCell className={classes.tableHead}>{factoryResource.wwmFactor} %</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Typography variant={'h1'}>Wskaźnik OEE</Typography>
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHead}>Typ</TableCell>
                  <TableCell className={classes.tableHead}>Zasób</TableCell>
                  <TableCell className={classes.tableHead}>OEE</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {getOEEResources().map((factoryResource) => (
                  <TableRow key={factoryResource['@id']}>
                    <TableCell className={classes.tableHead}>
                      {factoryResource.resourceActual.resourceTypeActual?.name}
                    </TableCell>
                    <TableCell className={classes.tableHead}>{factoryResource.resourceActual.name}</TableCell>
                    <TableCell className={classes.tableHead}>{getResourceOEE(factoryResource)} %</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Typography variant={'h1'}>Statystyki gracza</Typography>
          <Formik initialValues={formData} onSubmit={() => {}}>
            {() => (
              <Form>
                <Field
                  disabled={true}
                  component={TextFieldComponent}
                  name={'currentPOZ'}
                  label={'Pozycja rynkowa'}
                  fullWidth
                  className={classes.field}
                />
                <Field
                  disabled={true}
                  component={TextFieldComponent}
                  name={'cashBalance'}
                  label={'Gotówka [PLN]'}
                  fullWidth
                  className={classes.field}
                />
                <Field
                  disabled={true}
                  component={TextFieldComponent}
                  name={'currentCompanyValue'}
                  label={'Wartość firmy [PLN]'}
                  fullWidth
                  className={classes.field}
                />
                <Field
                  disabled={true}
                  component={TextFieldComponent}
                  name={'currentEarningsAndCurrentLoss'}
                  label={'Zysk/Strata [PLN]'}
                  fullWidth
                  className={classes.field}
                />
                <Field
                  disabled={true}
                  component={TextFieldComponent}
                  name={'currentCompanyEfficiency'}
                  label={'Efektywność Firmy'}
                  fullWidth
                  className={classes.field}
                />
                <Field
                  disabled={true}
                  component={TextFieldComponent}
                  name={'currentAverageProductQuality'}
                  label={'Średnia jakość produktu'}
                  fullWidth
                  className={classes.field}
                />
                <Field
                  disabled={true}
                  component={TextFieldComponent}
                  name={'currentPlanningEfficiency'}
                  label={'Skuteczność planowania [%]'}
                  fullWidth
                  className={classes.field}
                />
                <Field
                  disabled={true}
                  component={TextFieldComponent}
                  name={'currentCustomerService'}
                  label={'Współczynnik obsługi klienta'}
                  fullWidth
                  className={classes.field}
                />
                <Field
                  disabled={true}
                  component={TextFieldComponent}
                  name={'currentMarketActivity'}
                  label={'Współczynnik aktywności rynkowej'}
                  fullWidth
                  className={classes.field}
                />
              </Form>
            )}
          </Formik>
        </div>
      }
      dialogActions={
        <div className={classes.actionButtons}>
          <Button color={'primary'} autoFocus onClick={onClose}>
            OK
          </Button>
        </div>
      }
    />
  );
}
