import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20,
  },
  subTable: {
    height: '100%',
  },
  subTableCell: {
    padding: 0,
    height: 80,
  },
  root: {
    width: '100%',
    border: '1px solid #ccc',
    borderRadius: 5,
    marginBottom: 20,
  },
  tableContainer: {
    maxHeight: 500,
    overflow: 'auto',
  },
  tableCell: {
    padding: '5px 20px',
  },
  activityCell: {
    minWidth: 150,
  },
});
