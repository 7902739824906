import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';

import { useStyles } from './GameGanttResources.styles';
import {
  getActiveResourceId1,
  getActiveResourceId2,
  getActiveResourceTransportId,
  setActiveResourceId1,
  setActiveResourceId2,
  setActiveResourceTransportId,
  clearActiveResourceId1,
  clearActiveResourceId2,
  clearActiveResourceTransportId,
} from 'app/store/gantt';
import { GameGanttResourcesProps } from './GameGanttResources.interface';
import { storingPlaceResourceTypeNames } from 'app/shared/constants';

export function GameGanttResources({
  resources,
  destination,
  transportType,
  title,
  availableFactoryResource,
  isTransport,
}: GameGanttResourcesProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const activeResourceId = useSelector(
    transportType ? getActiveResourceTransportId : destination ? getActiveResourceId2 : getActiveResourceId1
  );

  const handleRowClick = (e: React.MouseEvent<HTMLDivElement>, id: number) => {
    if (activeResourceId && activeResourceId === id) {
      dispatch(
        transportType
          ? clearActiveResourceTransportId()
          : destination
          ? clearActiveResourceId2()
          : clearActiveResourceId1()
      );
    } else {
      dispatch(
        transportType
          ? setActiveResourceTransportId(id)
          : destination
          ? setActiveResourceId2(id)
          : setActiveResourceId1(id)
      );
    }
  };

  const getResourceNameById = (id: number, resourceNumber: number) => {
    const resource = resources.find((resource) => resource.id === id);
    return resource ? `${resource.name} (${resourceNumber})` : '';
  };

  return (
    <div className={classes.root}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>{title}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(isTransport
            ? availableFactoryResource.filter((resource) => {
                return storingPlaceResourceTypeNames.includes(resource.resource.resourceTypeActual?.name || '');
              })
            : availableFactoryResource
          ).map((resource) => (
            <TableRow
              key={resource.id}
              className={clsx(classes.tableRow, {
                [classes.activeRow]: activeResourceId && activeResourceId === resource.id,
              })}
              onClick={(e) => handleRowClick(e, resource.id)}
            >
              <TableCell className={classes.tableCell}>
                {getResourceNameById(resource.resource.id, resource.resourceNumber)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
