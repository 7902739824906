import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';

import { DistributionTemplateService } from 'app/services/distributionTemplate.service';
import { DistributionTemplatesTable } from 'app/components/DistributionTemplatesTable/DistributionTemplatesTable';
import { GetParamsInterface } from 'app/shared/interfaces/RequestInterface.interface';
import { defaultParams } from 'app/shared/helpers/pagination';
import { DistributionTemplateInterface } from 'app/shared/interfaces/DistributionTemplate.interface';
import { DistributionAttributeTemplateTypeService } from 'app/services/distributionAttributeTemplateType.service';
import { DistributionAttributesTemplateTypeInterface } from 'app/shared/interfaces/DistributionAttributesTemplateType.interface';
import { HeaderButton } from 'app/components/HeaderButton/HeaderButton';

import { useStyles } from './DistributionTemplatesPage.styles';

function DistributionTemplatesPage() {
  const classes = useStyles();
  const [distributionTemplates, setDistributionTemplates] = useState<Array<DistributionTemplateInterface>>([]);
  const [distributionAttributesTemplatesType, setDistributionAttributesTemplatesType] = useState<
    Array<DistributionAttributesTemplateTypeInterface>
  >([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [addButtonClicked, setAddButtonClicked] = useState<boolean>(false);

  const getDistributionTemplates = (params: GetParamsInterface = defaultParams) => {
    DistributionTemplateService.getDistributionTemplate(params).then(
      ({ data: { 'hydra:member': responseData, 'hydra:totalItems': totalRecords } }) => {
        setDistributionTemplates(responseData);
        setTotalRecords(totalRecords);
      }
    );
  };
  const getDistributionAttributesTemplateType = () => {
    DistributionAttributeTemplateTypeService.getDistributionAttributeTemplateType().then(
      ({ data: { 'hydra:member': responseData } }) => {
        setDistributionAttributesTemplatesType(responseData);
      }
    );
  };

  const handleAddButton = () => setAddButtonClicked(true);

  useEffect(() => {
    getDistributionTemplates();
    getDistributionAttributesTemplateType();
  }, []);

  useEffect(() => {
    if (addButtonClicked) {
      setAddButtonClicked(false);
    }
  }, [addButtonClicked]);

  return (
    <div className={classes.root}>
      <Typography variant={'h1'}>
        <span>Rozkłady</span>{' '}
        <div>
          <HeaderButton onClick={handleAddButton} label={'Dodaj rozkład'} />
        </div>
      </Typography>
      <DistributionTemplatesTable
        distributionTemplates={distributionTemplates}
        totalRecords={totalRecords}
        shouldUpdateDistributionTemplates={getDistributionTemplates}
        distributionAttributesTemplatesType={distributionAttributesTemplatesType}
        addClick={addButtonClicked}
      />
    </div>
  );
}

export default DistributionTemplatesPage;
