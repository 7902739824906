import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { orderBy } from 'lodash';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { SnackbarAlert } from 'app/components/SnackbarAlert/SnackbarAlert';
import Snackbar from '@material-ui/core/Snackbar';
import NumberFormat from 'react-number-format';

import { handlingCatch } from 'app/shared/helpers/handlingCatch';
import { ProcessService } from 'app/services/process.service';
import { ProcessActivityInterface, ProcessInterface } from 'app/shared/interfaces/Process.interface';
import { CustomDialog } from 'app/components/CustomDialog/CustomDialog';
import { ProcessesTableProps } from './ProcessesTable.interfaces';
import { CustomIconButton } from 'app/components/CustomIconButton/CustomIconButton';
import { AddProcessModal } from 'app/components/AddProcessModal/AddProcessModal';
import { CustomTablePagination } from 'app/components/CustomTablePagination/CustomTablePagination';
import { getParams } from 'app/shared/helpers/pagination';
import { ProductInterface } from 'app/shared/interfaces/Product.interface';

import { useStyles } from './ProcessesTable.styles';

export function ProcessesTable(props: ProcessesTableProps) {
  const { processes, shouldUpdateProcesses, actions, totalRecords, availableProducts, gantts, addClick } = props;
  const classes = useStyles();
  const [selectedProcessId, setSelectedProcessId] = useState<number>(0);
  const [selectedPage, setSelectedPage] = useState<number>(0);
  const [processData, setProcessData] = useState<ProcessInterface | null>(null);
  const [availableProductsWithSelectProcess, setAvailableProductsWithSelectProcess] = useState<Array<ProductInterface>>(
    []
  );

  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [openAddDialog, setOpenAddDialog] = useState<boolean>(false);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [notificationText, setNotificationText] = useState<string>('');

  const handleSnackbarClose = () => {
    setShowNotification(false);
  };

  const handleAddProcess = useCallback(() => {
    setAvailableProductsWithSelectProcess(availableProducts);
    setProcessData(null);
    setOpenAddDialog(true);
  }, [availableProducts]);

  const handleOpenEditProcess = (id: number) => {
    const process = getProcessById(id);
    if (process) {
      process.processActivities = orderedProcessActivities(process.processActivities);
      setAvailableProductsWithSelectProcess([...availableProducts, process.product]);
      setProcessData(process);
      setOpenAddDialog(true);
    }
  };

  const handleOpenDeleteProcess = (id: number) => {
    setSelectedProcessId(id);
    setOpenDeleteDialog(true);
  };

  const handleDeleteProcess = () => {
    ProcessService.deleteProcess(selectedProcessId)
      .then(() => {
        setOpenDeleteDialog(false);
        shouldUpdateProcesses(getParams(selectedPage));
      })
      .catch((error) => {
        const response = handlingCatch(error.response);
        const { status } = error.response;
        if (status === 400) {
          setNotificationText(response.message);
          setShowNotification(true);
          setOpenDeleteDialog(false);
        }
      });
  };

  const handleCloseDeleteProcessDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
    setProcessData(null);
  };

  const handleProcessAdded = () => {
    shouldUpdateProcesses(getParams(selectedPage));
    setOpenAddDialog(false);
    setProcessData(null);
  };

  const getProcessById = (id: number) => processes.find((process) => process.id === id);

  const getProcessNameById = (id: number) => {
    const process = getProcessById(id);
    return process ? process.name : '';
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    shouldUpdateProcesses(getParams(page));
    setSelectedPage(page);
  };

  const getActionById = (id: number) => actions.find((action) => action.id === id);

  const getActionDescriptionById = (id: number) => {
    const action = getActionById(id);
    return action && action.description ? action.description : '';
  };

  const getProductName = (process: ProcessInterface) => {
    return process && process.product ? process.product.name : '';
  };

  const getGanttName = (process: ProcessInterface) => {
    return process && process.ganttTemplate ? process.ganttTemplate.name : '';
  };

  const orderedProcessActivities = (processActivities: Array<ProcessActivityInterface>) =>
    orderBy(processActivities, ['activityOrder']);

  useEffect(() => {
    if (addClick) {
      handleAddProcess();
    }
  }, [addClick, handleAddProcess]);

  return (
    <div>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Nazwa</TableCell>
              <TableCell>Opis</TableCell>
              <TableCell>Czynności</TableCell>
              <TableCell>Produkt</TableCell>
              <TableCell align={'right'}>Cena [PLN]</TableCell>
              <TableCell>Szablon Gantta</TableCell>
              <TableCell align={'right'} className={classes.buttonsTableCell} />
            </TableRow>
          </TableHead>
          <TableBody>
            {processes.map((process) => (
              <TableRow key={process.id}>
                <TableCell className={clsx(classes.wrap, classes.bold)}>{process.name}</TableCell>
                <TableCell className={classes.wrap}>{process.description}</TableCell>
                <TableCell>
                  {orderedProcessActivities(process.processActivities).map((processActivity, index) => (
                    <Tooltip
                      key={processActivity.id}
                      title={getActionDescriptionById(processActivity.activity.id)}
                      placement={'top'}
                    >
                      <span>
                        {index !== 0 && ', '}
                        {processActivity.activity.name} ({processActivity.activityNumber})
                      </span>
                    </Tooltip>
                  ))}
                </TableCell>
                <TableCell className={classes.wrap}>{getProductName(process)}</TableCell>
                <TableCell align={'right'}>
                  <NumberFormat
                    displayType={'text'}
                    value={process.price}
                    fixedDecimalScale
                    decimalScale={2}
                    thousandSeparator={' '}
                  />
                </TableCell>
                <TableCell className={classes.wrap}>{getGanttName(process)}</TableCell>
                <TableCell align={'right'} className={classes.buttonsTableCell}>
                  <CustomIconButton onClick={() => handleOpenEditProcess(process.id)} type={'edit'} />
                  <CustomIconButton onClick={() => handleOpenDeleteProcess(process.id)} type={'delete'} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <CustomTablePagination
            tablePaginationConfig={{
              count: totalRecords,
              page: selectedPage,
            }}
            onChangePage={handleChangePage}
          />
        </Table>
      </TableContainer>
      <AddProcessModal
        isOpen={openAddDialog}
        gantts={gantts}
        products={availableProductsWithSelectProcess}
        process={processData}
        actions={actions}
        onClose={handleCloseAddDialog}
        processAdded={handleProcessAdded}
      />
      <CustomDialog
        title={'Usuwanie procesu'}
        onCloseDialog={handleCloseDeleteProcessDialog}
        body={
          <>
            {'Na pewno chcesz usunąć proces: '}
            {getProcessNameById(selectedProcessId)}
          </>
        }
        isOpen={openDeleteDialog}
        dialogActions={
          <>
            <Button color={'primary'} onClick={handleDeleteProcess}>
              Tak
            </Button>
            <Button color={'primary'} autoFocus onClick={handleCloseDeleteProcessDialog}>
              Nie
            </Button>
          </>
        }
      />
      <Snackbar
        open={showNotification}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={handleSnackbarClose}
      >
        <SnackbarAlert message={notificationText} />
      </Snackbar>
    </div>
  );
}
