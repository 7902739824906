import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';

import { canEdit } from 'app/shared/helpers/canEdit';
import { isZeroRound } from 'app/shared/helpers/isZeroRound';
import { BuyMaterialModal } from 'app/components/BuyMaterialModal/BuyMaterialModal';
import { MaterialInterface } from 'app/shared/interfaces/Material.interface';
import { GameSuppliersAndMaterialsInterface } from './GameSuppliersAndMaterials.interface';
import { GamePrepareModal } from 'app/components/GamePrepareModal/GamePrepareModal';
import { getCurrentRoundNumber, getGameFactory, getSelectedUserRoom } from 'app/store/userRoom';
import { useStyles } from './GameSuppliersAndMaterials.styles';

export function GameSuppliersAndMaterials(props: GameSuppliersAndMaterialsInterface) {
  const classes = useStyles();
  const { actualSuppliers, userRoom, updateRoom, room } = props;
  const [openBuyMaterialDialog, setBuyMaterialDialog] = useState<boolean>(false);
  const [selectedMaterial, setSelectedMaterial] = useState<MaterialInterface | null>(null);
  const [showGamePrepareModal, setShowGamePrepareModal] = useState<boolean>(false);
  const selectedUserRoom = useSelector(getSelectedUserRoom);
  const currentRoundNumber = useSelector(getCurrentRoundNumber);

  const gameFactory = useSelector(getGameFactory);

  const handleOpenBuyMaterial = (material: MaterialInterface) => {
    setSelectedMaterial(material);
    setBuyMaterialDialog(true);
  };

  const handleMaterialBought = () => {
    setSelectedMaterial(null);
    setBuyMaterialDialog(false);
    updateRoom();
  };

  const handleCloseBuyMaterialModal = () => {
    setBuyMaterialDialog(false);
    setSelectedMaterial(null);
  };

  const handeOpen = () => setShowGamePrepareModal(true);

  const handleClose = () => setShowGamePrepareModal(false);

  const getMaterialPrice = (supplierId: number, material: MaterialInterface): number => {
    if (!(selectedUserRoom && selectedUserRoom.room && currentRoundNumber !== selectedUserRoom.room.currentRound)) {
      return material.price;
    }
    if (!selectedUserRoom.room.actualSuppliers) {
      return material.price;
    }
    const supplier = selectedUserRoom.room.actualSuppliers.find((supplier) => supplier.id === supplierId);
    if (!supplier) {
      return material.price;
    }
    const materialFromSupplier = supplier.actualMaterials.find(
      (supplierMaterial) => supplierMaterial.id === material.id
    );
    return materialFromSupplier ? materialFromSupplier.price : material.price;
  };

  return (
    <div className={classes.root}>
      <h2 className={classes.title}>
        Dostawcy/Materiały
        <div className={classes.showSuppliersButton} onClick={handeOpen}>
          Moja pozycja
        </div>
      </h2>
      <div className={classes.tableContainer}>
        {actualSuppliers.map((supplier) => (
          <div key={supplier.id}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHead}>{supplier.name}</TableCell>
                </TableRow>
              </TableHead>
            </Table>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHead}>Nazwa</TableCell>
                  <TableCell className={classes.tableHead} align={'center'}>
                    Jakość
                  </TableCell>
                  <TableCell className={classes.tableHead} align={'right'}>
                    Cena [PLN]
                  </TableCell>
                  <TableCell className={classes.tableHead} align={'center'}>
                    Czas zamawiania
                  </TableCell>
                  <TableCell className={classes.tableHead}>Akcja</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {supplier.actualMaterials.map((material) => (
                  <TableRow key={material.id}>
                    <TableCell className={classes.nameCell}>{material.name}</TableCell>
                    <TableCell align={'center'}>{material.quality}</TableCell>
                    <TableCell align={'right'}>{getMaterialPrice(supplier.id, material).toFixed(2)}</TableCell>
                    <TableCell align={'center'}>{material.timeOrder}</TableCell>
                    <TableCell
                      className={classes.actionCell}
                      title={isZeroRound() ? 'Nie można kupować w turze 0' : ''}
                    >
                      <Button
                        variant="contained"
                        color="default"
                        onClick={() => handleOpenBuyMaterial(material)}
                        disabled={!canEdit() || isZeroRound()}
                      >
                        KUP
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        ))}
        <BuyMaterialModal
          isOpen={openBuyMaterialDialog}
          material={selectedMaterial}
          userRoom={userRoom}
          onClose={handleCloseBuyMaterialModal}
          materialBought={handleMaterialBought}
          room={room}
        />
      </div>
      {showGamePrepareModal && (
        <GamePrepareModal
          isOpen={showGamePrepareModal}
          suppliers={actualSuppliers}
          gameFactoryId={gameFactory ? gameFactory.id : -1}
          supplierChosen={handleClose}
          showMode
        />
      )}
    </div>
  );
}
