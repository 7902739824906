import React from 'react';
import { Field, Form, Formik } from 'formik';
import Button from '@material-ui/core/Button';
import * as Yup from 'yup';

import { ProductInterface } from 'app/shared/interfaces/Product.interface';
import { ProductService } from 'app/services/product.service';
import { CustomDialog } from 'app/components/CustomDialog/CustomDialog';
import { TextFieldComponent } from 'app/components/TextFieldComponent/TextFieldComponent';
import { SelectComponent } from 'app/components/SelectComponent/SelectComponent';

import { useStyles } from './AddProductModal.styles';
import { AddProductModalProps, AddProductFormDataInterface } from './AddProductModal.interfaces';
import { handlingCatch } from 'app/shared/helpers/handlingCatch';
import { upToTwoDecimalPlaces, upToThreeDecimalPlaces } from 'app/shared/helpers/validators';
import { convertToFloat, convertToInt } from 'app/shared/helpers/numberConverter';

export function AddProductModal(props: AddProductModalProps) {
  const { isOpen, onClose, product, productAdded, materialTypes } = props;
  const classes = useStyles();

  const formData: AddProductFormDataInterface = {
    name: product ? product.name : '',
    materialType: product && product.materialType ? product.materialType.id.toString() : '',
    description: product && product.description ? product.description : '',
    quality: product ? product.quality.toString() : '',
    amount: product ? product.amount.toString() : '',
    price: product ? product.price.toString() : '',
  };

  const validationShema = Yup.object().shape({
    name: Yup.string().required('Podaj nazwę').max(255, 'Maksymalna długość nazwy to 255 znaków'),
    description: Yup.string().max(255, 'Maksymalna długość opisu to 255 znaków'),
    amount: Yup.number()
      .test(
        'is-incorrect',
        'Podaj wielkość, wartość powinna być liczbą rzeczywistą, max. dwa miejsca po przecinku',
        upToTwoDecimalPlaces
      )
      .min(0, 'Podaj wielkość, wartość powinna być liczbą rzeczywistą, max. dwa miejsca po przecinku')
      .typeError('Podaj wielkość, wartość powinna być liczbą rzeczywistą, max. dwa miejsca po przecinku')
      .required('Podaj wielkość, wartość powinna być liczbą rzeczywistą, max. dwa miejsca po przecinku'),
    quality: Yup.number()
      .test('is-incorrect', 'Podaj jakość w zakresie od 0 do 1 max. trzy miejsca po przecinku', upToThreeDecimalPlaces)
      .min(0, 'Podaj jakość w zakresie od 0 do 1 max. trzy miejsca po przecinku')
      .max(1, 'Podaj jakość w zakresie od 0 do 1 max. trzy miejsca po przecinku')
      .typeError('Podaj jakość w zakresie od 0 do 1 max. trzy miejsca po przecinku')
      .required('Podaj jakość w zakresie od 0 do 1 max. trzy miejsca po przecinku'),
    materialType: Yup.number().required('Wybierz typ materiału'),
    price: Yup.number()
      .test('is-incorrect', 'Podaj cenę max. dwa miejsca po przecinku', upToTwoDecimalPlaces)
      .min(0, 'Podaj cenę max. dwa miejsca po przecinku')
      .typeError('Podaj cenę max. dwa miejsca po przecinku')
      .required('Podaj cenę max. dwa miejsca po przecinku'),
  });

  return (
    <CustomDialog
      title={product ? 'Edytuj produkt' : 'Dodaj produkt'}
      isOpen={isOpen}
      onCloseDialog={onClose}
      body={
        <Formik
          initialValues={formData}
          validationSchema={validationShema}
          onSubmit={(values, actions) => {
            const productData: Omit<ProductInterface, 'id'> = {
              name: values.name.trim(),
              description: values.description,
              amount: convertToInt(values.amount),
              quality: convertToFloat(values.quality) || 0,
              materialType: materialTypes.find((materialType) => materialType.id === parseInt(values.materialType)),
              price: convertToFloat(values.price) || 0,
            };

            if (product && product.id) {
              ProductService.replaceProduct(product.id, productData)
                .then(() => {
                  productAdded();
                })
                .catch((error) => {
                  const response = handlingCatch(error.response);
                  actions.setFieldError(response.fieldError, response.message);
                });
            } else {
              ProductService.addProduct(productData)
                .then(() => {
                  productAdded();
                })
                .catch((error) => {
                  const response = handlingCatch(error.response);
                  actions.setFieldError(response.fieldError, response.message);
                });
            }
          }}
        >
          {({ submitForm }) => (
            <Form>
              <Field component={TextFieldComponent} name={'name'} label={'Nazwa'} fullWidth />
              <Field
                component={TextFieldComponent}
                name={'description'}
                label={'Opis'}
                multiline
                rowsMax={4}
                fullWidth
              />
              <Field
                component={SelectComponent}
                fullWidth
                label={'Typ materiału'}
                name={'materialType'}
                options={materialTypes.map((materialType) => ({
                  label: materialType.name,
                  value: materialType.id,
                }))}
              />
              <Field component={TextFieldComponent} name={'quality'} label={'Jakość'} fullWidth />
              <Field component={TextFieldComponent} name={'amount'} label={'Wielkość [jw]'} fullWidth />
              <Field component={TextFieldComponent} name={'price'} label={'Cena [PLN]'} fullWidth />
              <div className={classes.actionButtons}>
                <Button color={'primary'} onClick={submitForm}>
                  {product ? 'Zapisz' : 'Dodaj'}
                </Button>
                <Button color={'primary'} autoFocus onClick={onClose}>
                  Anuluj
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      }
    />
  );
}
