import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  notification: {
    backgroundColor: 'red',
    color: '#fff',
    fontWeight: 'bold',
    padding: 15,
    borderRadius: 10,
  },
  link: {
    textDecoration: 'none',
  },
  headerButton: {
    borderRadius: 20,
  },
  ganttContainer: {
    height: 400,
    width: 1160,
    marginTop: 20,
  },
  actionsContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '400px',
  },
  container: {
    padding: '0 20px',
  },
  buttonContainer: {
    marginLeft: 20,
  },
  borderTop: {
    marginTop: 10,
  },
  showMoveContainer: {
    display: 'flex',
  },
  showMoveFirstColumn: {
    width: '150px',
  },
  showMoveSecondColumn: {
    marginLeft: '2%',
  },
  showMoveSubSection: {
    width: '100%',
  },
  textfield: {
    width: 130,
  },
});
