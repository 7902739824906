import { AxiosPromise } from 'axios';

import { ApiService } from './api.service';
import {
  DistributionAttributesTemplateTypeResponse,
  DistributionAttributesTemplateTypeInterface,
} from 'app/shared/interfaces/DistributionAttributesTemplateType.interface';
import { GetParamsInterface } from 'app/shared/interfaces/RequestInterface.interface';

export const DistributionAttributeTemplateTypeService = {
  getDistributionAttributeTemplateType(
    params: GetParamsInterface = {}
  ): AxiosPromise<DistributionAttributesTemplateTypeResponse> {
    return ApiService.get('distribution_attributes_template_types', params);
  },
  addDistributionAttributeTemplateType(data: Omit<DistributionAttributesTemplateTypeInterface, 'id'>): AxiosPromise {
    return ApiService.post('distribution_attributes_template_types', data);
  },
  replaceDistributionAttributeTemplateType(
    id: number,
    data: Omit<DistributionAttributesTemplateTypeInterface, 'id'>
  ): AxiosPromise {
    return ApiService.put(`distribution_attributes_template_types/${id}`, data);
  },
  deleteDistributionAttributeTemplateType(id: number): AxiosPromise {
    return ApiService.delete(`distribution_attributes_template_types/${id}`);
  },
};
