import { AxiosPromise } from 'axios';

import { ApiService } from './api.service';
import { AddProcessInterface, ProcessResponse } from 'app/shared/interfaces/Process.interface';
import { GetParamsInterface } from 'app/shared/interfaces/RequestInterface.interface';

export const ProcessService = {
  getProcesses(params: GetParamsInterface = {}): AxiosPromise<ProcessResponse> {
    return ApiService.get('processes', params);
  },
  addProcess(data: AddProcessInterface): AxiosPromise {
    return ApiService.post('processes', data);
  },
  replaceProcess(id: number, data: AddProcessInterface): AxiosPromise {
    return ApiService.put(`processes/${id}`, data);
  },
  deleteProcess(id: number): AxiosPromise {
    return ApiService.delete(`processes/${id}`);
  },
};
