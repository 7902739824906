import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { GameRoomInterface } from 'app/shared/interfaces/GameRoom.interface';

export const roundPhaseStatues = {
  NOT_LOADED: 1,
  START: 2,
  END: 3,
  ACTIVE: 4,
};

export interface RoundStates {
  roundsStates: Array<GameRoomInterface>;
  roundPhaseStatus: number;
}

const initialState: RoundStates = {
  roundsStates: [],
  roundPhaseStatus: roundPhaseStatues.NOT_LOADED,
};

export const gameRounds = createSlice({
  name: 'userRoom',
  initialState,
  reducers: {
    setGameRounds: (state, action: PayloadAction<Array<GameRoomInterface>>) => {
      state.roundsStates = action.payload;
    },
    clearGameRounds: (state) => {
      state.roundsStates = [];
      state.roundPhaseStatus = roundPhaseStatues.NOT_LOADED;
    },
    setRoundPhaseStatus: (state, action: PayloadAction<number>) => {
      state.roundPhaseStatus = action.payload;
    },
  },
});

export default gameRounds.reducer;
