import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Snackbar from '@material-ui/core/Snackbar';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import { SnackbarAlert } from 'app/components/SnackbarAlert/SnackbarAlert';
import { canEdit } from 'app/shared/helpers/canEdit';
import { clearActiveResource, getActiveResource, setActiveResource } from 'app/store/factory';
import { GameResourcesProps } from './GameResources.interface';
import { useStyles } from './GameResources.styles';
import { getSelectedUserRoom } from 'app/store/userRoom';
import { messages } from 'app/shared/constants';

export function GameResources({ resources, extended }: GameResourcesProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const activeResource = useSelector(getActiveResource);
  const selectedUserRoom = useSelector(getSelectedUserRoom);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [notificationText, setNotificationText] = useState<string>('');

  const handleSnackbarClose = () => {
    setShowNotification(false);
  };

  const getResourceById = (id: number) => resources.find((resource) => resource.id === id);

  const handleRowClick = (e: React.MouseEvent<HTMLDivElement>, id: number) => {
    const resource = getResourceById(id);
    if (resource && activeResource && resource.id === activeResource.id && canEdit()) {
      dispatch(clearActiveResource());
    } else if (resource && canEdit()) {
      if (resource.price <= selectedUserRoom.cashBalance) {
        dispatch(setActiveResource(resource));
      } else {
        setNotificationText(messages.NOT_ENOUGH_CASH);
        setShowNotification(true);
      }
    }
  };

  const handleCancelAddingResource = () => {
    dispatch(clearActiveResource());
  };

  return (
    <div className={classes.root}>
      {!extended && <h2>Zasoby</h2>}
      <div className={classes.tableContainer}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHead}>Nazwa</TableCell>
              <TableCell className={classes.tableHead}>Dł.</TableCell>
              <TableCell className={classes.tableHead}>Sz.</TableCell>
              {extended && <TableCell className={classes.tableHead}>Czas TPZ</TableCell>}
              {extended && <TableCell className={classes.tableHead}>WSP TJ</TableCell>}
              {extended && <TableCell className={classes.tableHead}>Jakość</TableCell>}
              {extended && <TableCell className={classes.tableHead}>Koszt stały [PLN]</TableCell>}
              {extended && <TableCell className={classes.tableHead}>Koszt zmienny [PLN]</TableCell>}
              {extended && <TableCell className={classes.tableHead}>Pojemność</TableCell>}
              <TableCell className={classes.tableHead}>Cena [PLN]</TableCell>
            </TableRow>
          </TableHead>
          <ClickAwayListener onClickAway={handleCancelAddingResource}>
            <TableBody>
              {resources.map((resource) => (
                <TableRow
                  key={resource.id}
                  onClick={(e) => handleRowClick(e, resource.id)}
                  className={clsx(classes.tableRow, {
                    [classes.activeRow]: activeResource && activeResource.id === resource.id,
                  })}
                >
                  <TableCell>{resource.name}</TableCell>
                  <TableCell>{resource.length}</TableCell>
                  <TableCell>{resource.width}</TableCell>
                  {extended && <TableCell>{resource.timeSetup}</TableCell>}
                  {extended && <TableCell>{resource.wtj}</TableCell>}
                  {extended && <TableCell>{resource.quality}</TableCell>}
                  {extended && <TableCell>{resource.costFixed.toFixed(2)}</TableCell>}
                  {extended && <TableCell>{resource.costVariable.toFixed(2)}</TableCell>}
                  {extended && <TableCell>{resource.capacity}</TableCell>}
                  <TableCell>{resource.price.toFixed(2)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </ClickAwayListener>
        </Table>
      </div>
      <Snackbar
        open={showNotification}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={handleSnackbarClose}
      >
        <SnackbarAlert message={notificationText} />
      </Snackbar>
    </div>
  );
}
