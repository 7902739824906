import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  root: {
    padding: '0 20px',
    margin: '0 auto',
  },
  factoryContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  factoryAreaContainer: {
    width: 780,
  },
  factoryToolbarContainer: {
    width: 'calc(100% - 810px)',
    minWidth: 400,
    height: 770,
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  loading: {
    marginTop: 40,
    fontSize: 20,
    fontWeight: 'bold',
  },
});
