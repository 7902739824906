import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  root: {
    backgroundColor: '#ffffff',
    borderRadius: 5,
    padding: 15,
    border: '1px solid #ccc',
    zIndex: 5,
  },
  form: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  header: {
    marginBottom: 15,
    fontSize: 18,
  },
  textField: {
    width: 50,
    marginRight: 15,
  },
  notification: {
    backgroundColor: 'red',
    color: '#fff',
    fontWeight: 'bold',
    padding: 15,
    borderRadius: 10,
  },
});
