import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';

import { getCurrentUser } from 'app/store/user';
import { RoomService } from 'app/services/room.service';
import { GetParamsInterface } from 'app/shared/interfaces/RequestInterface.interface';
import { defaultParams } from 'app/shared/helpers/pagination';
import { UserRoomsTable } from 'app/components/UserRoomsTable/UserRoomsTable';
import { RoomInterface } from 'app/shared/interfaces/Room.interface.js';

import { useStyles } from './PlayerRoomsPage.styles';

function PlayerRoomsPage() {
  const classes = useStyles();
  const currentUser = useSelector(getCurrentUser);
  const [rooms, setRooms] = useState<Array<RoomInterface>>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);

  const getRooms = (params: GetParamsInterface = defaultParams) => {
    RoomService.getRooms(params).then(
      ({ data: { 'hydra:member': responseData, 'hydra:totalItems': totalRecords } }) => {
        setRooms(responseData);
        setTotalRecords(totalRecords);
      }
    );
  };

  useEffect(() => {
    getRooms();
  }, []);

  return (
    <div className={classes.root}>
      <Typography variant={'h1'}>Pokoje Gracza</Typography>
      <UserRoomsTable
        shouldUpdateRooms={getRooms}
        rooms={rooms}
        totalRecords={totalRecords}
        currentUser={currentUser}
      />
    </div>
  );
}

export default PlayerRoomsPage;
