import { AxiosPromise } from 'axios';

import { ApiService } from './api.service';
import { AddGameOrderInterface } from 'app/shared/interfaces/GameOrder.interface';

export const GameOrderService = {
  takeOrder(data: Omit<AddGameOrderInterface, 'id'>): AxiosPromise {
    return ApiService.post('game_orders', data);
  },
};
