import React from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';

import { MaterialTypeInterface } from 'app/shared/interfaces/MaterialType.interface';
import { MaterialTypeService } from 'app/services/materialType.service';
import { CustomDialog } from 'app/components/CustomDialog/CustomDialog';
import { TextFieldComponent } from 'app/components/TextFieldComponent/TextFieldComponent';

import { useStyles } from './AddMaterialTypeModal.styles';
import { AddMaterialTypeFormDataInterface, AddMaterialTypeModalProps } from './AddMaterialTypeModal.interfaces';
import { handlingCatch } from 'app/shared/helpers/handlingCatch';

export function AddMaterialTypeModal(props: AddMaterialTypeModalProps) {
  const { isOpen, onClose, data, materialTypeAdded } = props;
  const classes = useStyles();

  const formData: AddMaterialTypeFormDataInterface = {
    name: data ? data.name : '',
    description: data && data.description ? data.description : '',
  };

  const validationShema = Yup.object().shape({
    name: Yup.string().required('Podaj nazwę'),
  });

  return (
    <CustomDialog
      title={data ? 'Edytuj typ materiału' : 'Dodaj typ materiału'}
      isOpen={isOpen}
      onCloseDialog={onClose}
      body={
        <Formik
          initialValues={formData}
          validationSchema={validationShema}
          onSubmit={(values, actions) => {
            const materialTypeData: Omit<MaterialTypeInterface, 'id'> = {
              name: values.name.trim(),
              description: values.description,
            };

            if (data && data.id) {
              MaterialTypeService.replaceMaterialType(data.id, materialTypeData)
                .then(() => {
                  materialTypeAdded();
                })
                .catch((error) => {
                  const response = handlingCatch(error.response);
                  actions.setFieldError(response.fieldError, response.message);
                });
            } else {
              MaterialTypeService.addMaterialType(materialTypeData)
                .then(() => {
                  materialTypeAdded();
                })
                .catch((error) => {
                  const response = handlingCatch(error.response);
                  actions.setFieldError(response.fieldError, response.message);
                });
            }
          }}
        >
          {({ submitForm }) => (
            <Form>
              <Field component={TextFieldComponent} name={'name'} label={'Nazwa'} fullWidth />
              <Field
                component={TextFieldComponent}
                name={'description'}
                label={'Opis'}
                multiline
                rowsMax={4}
                fullWidth
              />
              <div className={classes.actionButtons}>
                <Button color={'primary'} onClick={submitForm}>
                  {data ? 'Zapisz' : 'Dodaj'}
                </Button>
                <Button color={'primary'} autoFocus onClick={onClose}>
                  Anuluj
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      }
    />
  );
}
