import React from 'react';
import { Field, Form, Formik } from 'formik';
import Button from '@material-ui/core/Button';
import * as Yup from 'yup';

import { ResourceInterface } from 'app/shared/interfaces/Resource.interface';
import { ResourceService } from 'app/services/resource.service';
import { CustomDialog } from 'app/components/CustomDialog/CustomDialog';
import { TextFieldComponent } from 'app/components/TextFieldComponent/TextFieldComponent';
import { SelectComponent } from 'app/components/SelectComponent/SelectComponent';
import { InputColorPicker } from 'app/components/InputColorPicker/InputColorPicker';
import { AddResourceFormDataInterface, AddResourceModalProps } from './AddResourceModal.interfaces';
import { handlingCatch } from 'app/shared/helpers/handlingCatch';
import { upToThreeDecimalPlaces, upToTwoDecimalPlaces } from 'app/shared/helpers/validators';

import { useStyles } from './AddResourceModal.styles';
import { convertToFloat, convertToInt } from 'app/shared/helpers/numberConverter';

export function AddResourceModal(props: AddResourceModalProps) {
  const { isOpen, onClose, resource, resourceTypes, resourceAdded } = props;
  const classes = useStyles();

  const formData: AddResourceFormDataInterface = {
    resourceType: resource && resource.resourceType ? resource.resourceType.id.toString() : '',
    name: resource ? resource.name : '',
    description: resource && resource.description ? resource.description : '',
    width: resource ? resource.width.toString() : '',
    length: resource ? resource.length.toString() : '',
    timeSetup: resource ? resource.timeSetup.toString() : '',
    wtj: resource ? resource.wtj.toString() : '',
    quality: resource ? resource.quality.toString() : '',
    costFixed: resource ? resource.costFixed.toString() : '',
    costVariable: resource ? resource.costVariable.toString() : '',
    price: resource ? resource.price.toString() : '',
    color: resource ? resource.color : '#ffffff',
    capacity: resource ? resource.capacity.toString() : '',
  };

  const validationShema = Yup.object().shape({
    resourceType: Yup.number().required('Podaj typ zasobu'),
    name: Yup.string().required('Podaj nazwę'),
    width: Yup.number()
      .min(0, 'Podaj szerokość, wartość powinna być liczbą całkowitą, (1m = 1 kratka)')
      .typeError('Podaj szerokość, wartość powinna być liczbą całkowitą, (1m = 1 kratka)')
      .integer('Podaj szerokość, wartość powinna być liczbą całkowitą, (1m = 1 kratka)')
      .required('Podaj szerokość, wartość powinna być liczbą całkowitą, (1m = 1 kratka)'),
    length: Yup.number()
      .min(0, 'Podaj długość, wartość powinna być liczbą całkowitą, (1m = 1 kratka)')
      .typeError('Podaj długość, wartość powinna być liczbą całkowitą, (1m = 1 kratka)')
      .integer('Podaj długość, wartość powinna być liczbą całkowitą, (1m = 1 kratka)')
      .required('Podaj długość, wartość powinna być liczbą całkowitą, (1m = 1 kratka)'),
    timeSetup: Yup.number()
      .test(
        'is-incorrect',
        'Podaj czas TPZ, wartość powinna być liczbą rzeczywistą, max. dwa miejsca po przecinku',
        upToTwoDecimalPlaces
      )
      .min(0, 'Podaj czas TPZ, wartość powinna być liczbą rzeczywistą, max. dwa miejsca po przecinku')
      .typeError('Podaj czas TPZ, wartość powinna być liczbą rzeczywistą, max. dwa miejsca po przecinku')
      .required('Podaj czas TPZ, wartość powinna być liczbą rzeczywistą, max. dwa miejsca po przecinku'),
    wtj: Yup.number()
      .test(
        'is-incorrect',
        'Podaj czas WSP TJ, wartość powinna być liczbą rzeczywistą, max. dwa miejsca po przecinku',
        upToTwoDecimalPlaces
      )
      .min(0, 'Podaj czas WSP TJ, wartość powinna być liczbą rzeczywistą, max. dwa miejsca po przecinku')
      .typeError('Podaj czas WSP TJ, wartość powinna być liczbą rzeczywistą, max. dwa miejsca po przecinku')
      .required('Podaj czas WSP TJ, wartość powinna być liczbą rzeczywistą, max. dwa miejsca po przecinku'),
    quality: Yup.number()
      .test('is-incorrect', 'Podaj jakość w zakresie od 0 do 1 max. trzy miejsca po przecinku', upToThreeDecimalPlaces)
      .min(0, 'Podaj jakość w zakresie od 0 do 1 max. trzy miejsca po przecinku')
      .max(1, 'Podaj jakość w zakresie od 0 do 1 max. trzy miejsca po przecinku')
      .typeError('Podaj jakość w zakresie od 0 do 1 max. trzy miejsca po przecinku')
      .required('Podaj jakość w zakresie od 0 do 1 max. trzy miejsca po przecinku'),
    costFixed: Yup.number()
      .test('is-incorrect', 'Podaj koszt stały max. dwa miejsca po przecinku', upToTwoDecimalPlaces)
      .min(0, 'Podaj koszt stały max. dwa miejsca po przecinku')
      .typeError('Podaj koszt stały max. dwa miejsca po przecinku')
      .required('Podaj koszt stały max. dwa miejsca po przecinku'),
    costVariable: Yup.number()
      .test('is-incorrect', 'Podaj koszt zmienny max. dwa miejsca po przecinku', upToTwoDecimalPlaces)
      .min(0, 'Podaj koszt zmienny max. dwa miejsca po przecinku')
      .typeError('Podaj koszt zmienny max. dwa miejsca po przecinku')
      .required('Podaj koszt zmienny max. dwa miejsca po przecinku'),
    price: Yup.number()
      .test('is-incorrect', 'Podaj cenę max. dwa miejsca po przecinku', upToTwoDecimalPlaces)
      .min(0, 'Podaj cenę max. dwa miejsca po przecinku')
      .typeError('Podaj cenę max. dwa miejsca po przecinku')
      .required('Podaj cenę max. dwa miejsca po przecinku'),
    color: Yup.string().required('Podaj kolor'),
    capacity: Yup.number()
      .test(
        'is-incorrect',
        'Podaj pojemność zasobu, wartość powinna być liczbą rzeczywistą, max. dwa miejsca po przecinku',
        upToTwoDecimalPlaces
      )
      .min(0, 'Podaj pojemność zasobu, wartość powinna być liczbą rzeczywistą, max. dwa miejsca po przecinku')
      .typeError('Podaj pojemność zasobu, wartość powinna być liczbą rzeczywistą, max. dwa miejsca po przecinku')
      .required('Podaj pojemność zasobu, wartość powinna być liczbą rzeczywistą, max. dwa miejsca po przecinku'),
  });

  return (
    <CustomDialog
      title={resource ? 'Edytuj zasób produkcyjny' : 'Dodaj zasób produkcyjny'}
      isOpen={isOpen}
      onCloseDialog={onClose}
      body={
        <Formik
          initialValues={formData}
          validationSchema={validationShema}
          onSubmit={(values, actions) => {
            const resourceData: Omit<ResourceInterface, 'id'> = {
              resourceType: resourceTypes.find((resourceType) => resourceType.id === parseInt(values.resourceType)),
              name: values.name.trim(),
              description: values.description,
              length: convertToInt(values.length),
              width: convertToInt(values.width),
              timeSetup: convertToFloat(values.timeSetup) || 0,
              wtj: convertToFloat(values.wtj) || 0,
              quality: convertToFloat(values.quality) || 0,
              costFixed: convertToFloat(values.costFixed) || 0,
              costVariable: convertToFloat(values.costVariable) || 0,
              price: convertToFloat(values.price) || 0,
              color: values.color,
              capacity: convertToFloat(values.capacity) || 0,
            };

            if (resource && resource.id) {
              ResourceService.replaceResource(resource.id, resourceData)
                .then(() => {
                  resourceAdded();
                })
                .catch((error) => {
                  const response = handlingCatch(error.response);
                  actions.setFieldError(response.fieldError, response.message);
                });
            } else {
              ResourceService.addResource(resourceData)
                .then(() => {
                  resourceAdded();
                })
                .catch((error) => {
                  const response = handlingCatch(error.response);
                  actions.setFieldError(response.fieldError, response.message);
                });
            }
          }}
        >
          {({ submitForm, setFieldValue }) => (
            <Form>
              <Field component={TextFieldComponent} name={'name'} label={'Nazwa'} fullWidth />
              <Field
                component={TextFieldComponent}
                name={'description'}
                label={'Opis'}
                multiline
                rowsMax={4}
                fullWidth
              />
              <Field
                component={SelectComponent}
                fullWidth
                onChange={(event: { target: { value: number } }) => {
                  const resourceTypeId: number = event.target.value;
                  setFieldValue('resourceType', resourceTypeId);
                }}
                label={'Typ zasobu'}
                name={'resourceType'}
                options={resourceTypes.map((resourceType) => ({
                  label: resourceType.name,
                  value: resourceType.id,
                }))}
              />
              <Field component={TextFieldComponent} name={'length'} label={'Długość [m]'} fullWidth />
              <Field component={TextFieldComponent} name={'width'} label={'Szerokość [m]'} fullWidth />
              <Field component={InputColorPicker} name={'color'} label={'Kolor'} />
              <Field component={TextFieldComponent} name={'timeSetup'} label={'Czas TPZ'} fullWidth />
              <Field component={TextFieldComponent} name={'wtj'} label={'WSP TJ'} fullWidth />
              <Field component={TextFieldComponent} name={'quality'} label={'Jakość'} fullWidth />
              <Field component={TextFieldComponent} name={'costFixed'} label={'Koszt stały [PLN]'} fullWidth />
              <Field component={TextFieldComponent} name={'costVariable'} label={'Koszt zmienny [PLN]'} fullWidth />
              <Field component={TextFieldComponent} name={'price'} label={'Cena [PLN]'} fullWidth />
              <Field component={TextFieldComponent} name={'capacity'} label={'Pojemność zasobu'} fullWidth />
              <div className={classes.actionButtons}>
                <Button color={'primary'} onClick={submitForm}>
                  {resource ? 'Zapisz' : 'Dodaj'}
                </Button>
                <Button color={'primary'} autoFocus onClick={onClose}>
                  Anuluj
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      }
    />
  );
}
