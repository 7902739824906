import React, { useEffect, useState } from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';

import { ActionService } from 'app/services/action.service';
import { ActionInterface } from 'app/shared/interfaces/Action.interface';
import { AddActionModal } from 'app/components/AddActionModal/AddActionModal';
import { CustomDialog } from 'app/components/CustomDialog/CustomDialog';
import { ActionsTableProps } from './ActionsTable.interfaces';
import { CustomIconButton } from 'app/components/CustomIconButton/CustomIconButton';
import { CustomTablePagination } from 'app/components/CustomTablePagination/CustomTablePagination';
import { getParams } from 'app/shared/helpers/pagination';
import { BulletedList } from 'app/components/BulletedList/BulletedList';
import { transportOptions } from 'app/shared/constants';
import { SnackbarAlert } from 'app/components/SnackbarAlert/SnackbarAlert';
import { handlingCatch } from 'app/shared/helpers/handlingCatch';

import { useStyles } from './ActionsTable.styles';

export function ActionsTable(props: ActionsTableProps) {
  const { actions, shouldUpdateActions, resources, actionTypes, totalRecords, addClick } = props;
  const classes = useStyles();

  const [selectedAction, setSelectedAction] = useState<number>(0);
  const [selectedPage, setSelectedPage] = useState<number>(0);
  const [actionData, setActionData] = useState<ActionInterface | null>(null);

  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [openAddDialog, setOpenAddDialog] = useState<boolean>(false);

  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [notificationText, setNotificationText] = useState<string>('');

  const handleAddAction = () => {
    setActionData(null);
    setOpenAddDialog(true);
  };

  const handleOpenEditAction = (id: number) => {
    const action = getActionById(id);
    if (action) {
      setActionData(action);
      setOpenAddDialog(true);
    }
  };

  const handleOpenDeleteAction = (id: number) => {
    setSelectedAction(id);
    setOpenDeleteDialog(true);
  };

  const handleDeleteAction = () => {
    ActionService.deleteAction(selectedAction)
      .then(() => {
        setOpenDeleteDialog(false);
        shouldUpdateActions(getParams(selectedPage));
      })
      .catch((error) => {
        const response = handlingCatch(error.response);
        const { status } = error.response;
        if (status === 400) {
          setNotificationText(response.message);
          setShowNotification(true);
          setOpenDeleteDialog(false);
        }
      });
  };

  const handleCloseDeleteActionDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
    setActionData(null);
  };

  const handleActionAdded = () => {
    shouldUpdateActions(getParams(selectedPage));
    setOpenAddDialog(false);
    setActionData(null);
  };

  const getActionById = (id: number) => actions.find((action) => action.id === id);

  const getActionName = (id: number) => {
    const action = getActionById(id);
    return action ? action.name : '';
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    shouldUpdateActions(getParams(page));
    setSelectedPage(page);
  };

  const getTransportOption = (isTransport: Boolean) => {
    const transportOption = transportOptions.find((transportOption) => transportOption.value === isTransport);
    return transportOption ? transportOption.label : '';
  };

  const handleSnackbarClose = () => {
    setShowNotification(false);
  };

  useEffect(() => {
    if (addClick) {
      handleAddAction();
    }
  }, [addClick]);

  return (
    <div>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Nazwa</TableCell>
              <TableCell>Opis</TableCell>
              <TableCell>Zasoby produkcyjne</TableCell>
              <TableCell className={classes.actionsTypeCell}>Typy czynności</TableCell>
              <TableCell align={'center'}>Czas TJ</TableCell>
              <TableCell align={'center'}>Strata %</TableCell>
              <TableCell className={classes.wptCell} align={'center'}>
                WSP TPZ
              </TableCell>
              <TableCell>Właściwość transportu</TableCell>
              <TableCell className={classes.buttonsTableCell} align={'right'} />
            </TableRow>
          </TableHead>
          <TableBody>
            {actions.map((action) => (
              <TableRow key={action.id}>
                <TableCell style={{ background: action.color }} className={classes.colorCell} />
                <TableCell className={classes.bold}>{action.name}</TableCell>
                <TableCell>{action.description}</TableCell>
                <TableCell>
                  {action.resources && (
                    <BulletedList
                      list={action.resources.map((resource) => ({ id: resource.id, label: resource.name }))}
                    />
                  )}
                </TableCell>
                <TableCell>{action.actionTypes.map((actionType) => actionType.name).join(', ')}</TableCell>
                <TableCell align={'center'}>{action.timeWorking}</TableCell>
                <TableCell align={'center'}>{action.loss}</TableCell>
                <TableCell align={'center'}>{action.WTP}</TableCell>
                <TableCell>{getTransportOption(action.isTransport)}</TableCell>
                <TableCell align={'right'} className={classes.buttonsTableCell}>
                  <CustomIconButton onClick={() => handleOpenEditAction(action.id)} type={'edit'} />
                  <CustomIconButton onClick={() => handleOpenDeleteAction(action.id)} type={'delete'} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <CustomTablePagination
            tablePaginationConfig={{
              count: totalRecords,
              page: selectedPage,
            }}
            onChangePage={handleChangePage}
          />
        </Table>
      </TableContainer>
      <AddActionModal
        isOpen={openAddDialog}
        action={actionData}
        resources={resources}
        actionTypes={actionTypes}
        onClose={handleCloseAddDialog}
        actionAdded={handleActionAdded}
      />
      <CustomDialog
        title={'Usuwanie czynności'}
        onCloseDialog={handleCloseDeleteActionDialog}
        body={
          <>
            {'Na pewno chcesz usunąć czynność: '}

            {getActionName(selectedAction)}
          </>
        }
        isOpen={openDeleteDialog}
        dialogActions={
          <>
            <Button color={'primary'} onClick={handleDeleteAction}>
              Tak
            </Button>
            <Button color={'primary'} autoFocus onClick={handleCloseDeleteActionDialog}>
              Nie
            </Button>
          </>
        }
      />
      <Snackbar
        open={showNotification}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={handleSnackbarClose}
      >
        <SnackbarAlert message={notificationText} />
      </Snackbar>
    </div>
  );
}
