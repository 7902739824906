import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';

import { ActionTypesTable } from 'app/components/ActionTypesTable/ActionTypesTable';
import { ActionTypeInterface } from 'app/shared/interfaces/ActionType.interface';
import { ActionTypeService } from 'app/services/actionType.service';
import { GetParamsInterface } from 'app/shared/interfaces/RequestInterface.interface';
import { defaultParams } from 'app/shared/helpers/pagination';

import { useStyles } from './ActionTypesPage.styles';

function ActionTypesPage() {
  const classes = useStyles();
  const [actionTypes, setActionTypes] = useState<Array<ActionTypeInterface>>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);

  const getActionTypes = (params: GetParamsInterface = defaultParams) => {
    ActionTypeService.getActionTypes(params).then(
      ({ data: { 'hydra:member': responseData, 'hydra:totalItems': totalRecords } }) => {
        setActionTypes(responseData);
        setTotalRecords(totalRecords);
      }
    );
  };

  useEffect(() => {
    getActionTypes();
  }, []);

  return (
    <div className={classes.root}>
      <Typography variant={'h1'}>Typy czynności</Typography>
      <ActionTypesTable
        actionTypes={actionTypes}
        totalRecords={totalRecords}
        shouldUpdateActionTypes={getActionTypes}
      />
    </div>
  );
}

export default ActionTypesPage;
