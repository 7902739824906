import { AxiosPromise } from 'axios';

import { ApiService } from './api.service';
import { GetParamsInterface } from 'app/shared/interfaces/RequestInterface.interface';
import { ActualSupplierInterfaceResponse } from 'app/shared/interfaces/ActualSupplier.interface';

export const ActualSupplierService = {
  getActualSuppliers(
    gameFactoryId: number,
    params: GetParamsInterface = {}
  ): AxiosPromise<ActualSupplierInterfaceResponse> {
    return ApiService.get(`actual_suppliers?gameFactory=${gameFactoryId}`);
  },
};
