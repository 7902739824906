import { AxiosPromise } from 'axios';

import { ApiService } from './api.service';
import { GameRoomResponse } from 'app/shared/interfaces/GameRoom.interface';

export const GameRoomService = {
  getGameRoomRounds(userRoomId: number): AxiosPromise<GameRoomResponse> {
    return ApiService.get(`user_rooms/${userRoomId}/game_rounds`);
  },
};
