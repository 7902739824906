import React from 'react';
import { Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { intersection } from 'lodash';

import { getCurrentUser } from 'app/store/user';

import { PrivateRouteProps } from './PrivateRoute.interfaces';

export function PrivateRoute(props: PrivateRouteProps) {
  const currentUser = useSelector(getCurrentUser);
  const haveAccess = (access: string[] | undefined) =>
    currentUser && (!access || intersection(currentUser?.roles, access).length > 0);
  return haveAccess(props.route.access) ? <Route exact path={props.route.route} component={props.component} /> : <></>;
}
