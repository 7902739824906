import { AxiosPromise } from 'axios';

import { ApiService } from './api.service';
import { FactoryResourceInterface } from 'app/shared/interfaces/FactoryResource.interface';

export const FactoryResourcesService = {
  addFactoryResource(data: Omit<FactoryResourceInterface, 'id'>): AxiosPromise {
    return ApiService.post('factory_resources', data);
  },

  replaceFactoryResource(id: number, data: Omit<FactoryResourceInterface, 'id' | 'factory'>): AxiosPromise {
    return ApiService.put(`factory_resources/${id}`, data);
  },

  deleteFactoryResource(id: number): AxiosPromise {
    return ApiService.delete(`factory_resources/${id}`);
  },
};
