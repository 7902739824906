import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import { GameGanttContainerProps } from './GameGanttContainer.interface';
import { GanttArea } from 'app/components/GanttArea/GanttArea';
import { GameGanttAllActivities } from 'app/components/GameGanttAllActivities/GameGanttAllActivities';
import { AddGanttOrderModal } from 'app/components/AddGanttOrderModal/AddGanttOrderModal';
import { GameGanttResources } from 'app/components/GameGanttResources/GameGanttResources';
import {
  getActiveAction,
  setDuration,
  clearDuration,
  getDuration,
  replaceGanttItemInOrder,
  getActiveResourceId1,
  getActiveResourceId2,
  getGanttOrders,
  setActiveResourceId1,
  setActiveResourceId2,
  fetchGanttOrders,
  getActiveResourceTransportId,
  setActiveResourceTransportId,
} from 'app/store/gantt';
import { isZeroRound } from 'app/shared/helpers/isZeroRound';
import { GanttSerivce } from 'app/components/GanttArea/Gantt.service';
import { canEdit } from 'app/shared/helpers/canEdit';
import { clearActiveAction, clearEditGanttItemId, getEditGanttItemId } from 'app/store/gantt';
import { AddGameGanttOrderItemInterface } from 'app/shared/interfaces/GameGanttOrderItem.interface';
import { getGameGantt, getIsAutomation, getLatestUserRoom, getSelectedUserRoom } from 'app/store/userRoom';
import { GameGanttOrderItemService } from 'app/services/gameGanttOrderItem.service';
import Snackbar from '@material-ui/core/Snackbar';
import { useStyles } from './GameGanttContainer.styles';
import { getFactoryResources } from 'app/store/factory';
import { FactoryResourcesInterface } from 'app/shared/interfaces/Factory.interface';
import { routeNameResourceType, maxGanttLength, routeNameResourceTransportType } from 'app/shared/constants';
import { GameHeader } from 'app/components/GameHeader/GameHeader';
import { GameService } from 'app/services/game.service';
import { GameGanttAccordion } from 'app/components/GameGanttAccordion/GameGanttAccordion';
import { convertToInt } from 'app/shared/helpers/numberConverter';
import { getIsUserGameMaster } from 'app/store/user';
import { SnackbarAlert } from '../SnackbarAlert/SnackbarAlert';
import { GanttRecalculationMessages } from './GanttRecalculationMessages';

export function GameGanttContainer({
  resources,
  actualActivities,
  viewFactory,
  onTimeLeft,
  timeLeftInMillis,
  updateRoom,
  gamePlayerRankings,
  actualScenario,
  actualProcesses,
}: GameGanttContainerProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const editGanttItemId = useSelector(getEditGanttItemId);
  const gameGantt = useSelector(getGameGantt);
  const ganttOrders = useSelector(getGanttOrders);
  const isAutomation = useSelector(getIsAutomation);
  const [isOpenOrderModal, setIsOpenOrderModal] = useState<boolean>(false);
  const [validatePosition, setValidatePosition] = useState<boolean>(true);
  const [validateMaxPosition, setValidateMaxPosition] = useState<boolean>(true);
  const [validateDuration, setValidateDuration] = useState<boolean>(true);
  const [validateMove, setValidateMove] = useState<boolean>(true);
  const [dur, setDur] = useState<string>('');
  const [position, setPosition] = useState<string>('');
  const [move, setMove] = useState<string>('');
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [showGanttRecaluclatedNotification, setShowGanttRecaluclatedNotification] = useState<boolean>(false);
  const [showGanttRecalculationError, setShowGanttRecalculationError] = useState<boolean>(false);
  const [showMove, setShowMove] = useState<boolean>(false);
  const [itemId, setItemId] = useState<number>(-1);
  const [showActivitiesSection, setShowActivitiesSection] = useState<boolean>(true);
  const activeAction = useSelector(getActiveAction);
  const gameGanttDuration = useSelector(getDuration);
  const activeResourceId1 = useSelector(getActiveResourceId1);
  const activeResourceId2 = useSelector(getActiveResourceId2);
  const activeResourceTransportId = useSelector(getActiveResourceTransportId);
  const factoryResources = useSelector(getFactoryResources);
  const latestUserRoom = useSelector(getLatestUserRoom);
  const selectedUserRoom = useSelector(getSelectedUserRoom);
  const isUserGameMaster = useSelector(getIsUserGameMaster);
  const [ganttRecalculationError, setGanttRecalculationError] = useState<string>('');

  const handleAddOrder = () => {
    setIsOpenOrderModal(true);
  };

  const handleCloseOrderModal = () => {
    setIsOpenOrderModal(false);
  };

  const handleEditCancel = () => {
    dispatch(clearEditGanttItemId());
    dispatch(clearActiveAction());
    setValidateDuration(true);
    setValidatePosition(true);
    setValidateMaxPosition(true);
    setValidateMove(true);
    setShowActivitiesSection(false);
  };

  const getGanttOrderIdByItemId = useCallback(
    (id: number) =>
      ganttOrders && ganttOrders.filter((order) => order.gameGanttOrderItems.find((item) => item.id === id)).pop(),
    [ganttOrders]
  );

  const getGanttItemById = useCallback(
    (id: number) => {
      const ganttOrder = getGanttOrderIdByItemId(id);
      return ganttOrder && ganttOrder.gameGanttOrderItems.find((item) => item.id === id);
    },
    [getGanttOrderIdByItemId]
  );

  const isCollision = () => {
    const order = getGanttOrderIdByItemId(editGanttItemId ? editGanttItemId : -1);
    return GanttSerivce.isCollision({
      duration: gameGanttDuration ? gameGanttDuration : 0,
      position: parseInt(position),
      orderId: order ? order.id : 0,
      ganttOrders,
      itemId: editGanttItemId ? editGanttItemId : -1,
    });
  };

  const handleEdit = () => {
    if (isCollision()) {
      setShowNotification(true);
      return;
    }
    const item: AddGameGanttOrderItemInterface = {
      duration: gameGanttDuration,
      position: parseInt(position),
      gameFactoryResourceFirst: activeResourceId1
        ? { '@id': `/api/game_factory_resources/${activeResourceId1}` }
        : undefined,
      gameFactoryResourceSecond: activeResourceId2
        ? { '@id': `/api/game_factory_resources/${activeResourceId2}` }
        : undefined,
      gameFactoryResourceTransport: activeResourceTransportId
        ? { '@id': `/api/game_factory_resources/${activeResourceTransportId}` }
        : undefined,
      activityActual: activeAction
        ? {
            '@id': `/api/actual_activities/${activeAction.id}`,
          }
        : undefined,
    };

    dispatch(replaceGanttItemInOrder(item, editGanttItemId ? editGanttItemId : -1, gameGantt ? gameGantt.id : -1));
    handleEditCancel();
  };

  const handleViewFactory = () => viewFactory();

  const handeDurationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const reg = /^\d+$/;
    const test = reg.test(e.target.value);
    setDur(e.target.value);
    if (test) {
      const value = convertToInt(e.target.value);
      if (value > 0) {
        dispatch(setDuration(value));
        setValidateDuration(true);
      } else {
        setValidateDuration(false);
        dispatch(clearDuration());
      }
    } else {
      setValidateDuration(false);
      dispatch(clearDuration());
    }
  };

  const handeStartChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const reg = /^\d+$/;
    const test = reg.test(e.target.value);
    setPosition(e.target.value);
    if (test) {
      const value = parseInt(e.target.value);
      if (value >= 0) {
        setValidatePosition(true);
        if (value > maxGanttLength) {
          setValidateMaxPosition(false);
        } else {
          setValidateMaxPosition(true);
        }
      } else {
        setValidatePosition(false);
      }
    } else {
      setValidatePosition(false);
    }
  };

  const handeMoveChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const reg = /^-?\d+$/;
    const test = reg.test(e.target.value);
    setMove(e.target.value);
    if (test) {
      setValidateMove(true);
    } else {
      setValidateMove(false);
    }
  };

  const handleSnackbarClose = () => {
    setShowNotification(false);
    setShowGanttRecaluclatedNotification(false);
    setShowGanttRecalculationError(false);
  };

  const handleMove = () => {
    const ganttOrder = getGanttOrderIdByItemId(itemId);
    const ganttItem = getGanttItemById(itemId);

    if (ganttOrder && ganttItem) {
      GameGanttOrderItemService.moveAllGameGanttOrders(itemId, {
        gameGanttOrder: { '@id': `/api/game_gantt_orders/${ganttOrder.id}` },
        groupShift: parseInt(move),
        activityActual: {
          '@id': `/api/actual_activities/${ganttItem.activityActual.id}`,
        },
      })
        .then(() => {
          dispatch(fetchGanttOrders(gameGantt ? gameGantt.id : -1));
        })
        .catch(() => {
          setShowNotification(true);
        });
    }
    setShowMove(false);
    setMove('');
    setItemId(-1);
    setShowActivitiesSection(false);
  };

  const handleMoveAll = (id: number) => {
    setItemId(id);
    setShowMove(true);
    setShowActivitiesSection(true);
  };

  const handleMoveAllCancel = () => {
    if (showMove) {
      setShowMove(false);
      setItemId(-1);
      setMove('');
      setValidateMove(true);
      setShowActivitiesSection(false);
    }
  };

  useEffect(() => {
    if (editGanttItemId) {
      const ganttItem = getGanttItemById(editGanttItemId);
      if (ganttItem) {
        setDur(ganttItem.duration);
        setPosition(ganttItem.position);
        dispatch(setDuration(ganttItem.duration));
        if (ganttItem.gameFactoryResourceFirst) {
          dispatch(setActiveResourceId1(ganttItem.gameFactoryResourceFirst.id));
        }
        if (ganttItem.gameFactoryResourceSecond) {
          dispatch(setActiveResourceId2(ganttItem.gameFactoryResourceSecond.id));
        }
        if (ganttItem.gameFactoryResourceTransport) {
          dispatch(setActiveResourceTransportId(ganttItem.gameFactoryResourceTransport.id));
        }
      }
    } else {
      setDur('');
      setPosition('');
    }
  }, [editGanttItemId, dispatch, getGanttItemById]);

  const getFactoryResourcesWithoutRoute = (factoryResources: Array<FactoryResourcesInterface>) => {
    const resourcesWithoutRouteIds: Array<number> = [];
    resources.forEach((resource) => {
      if (
        resource.resourceTypeActual &&
        resource.resourceTypeActual.name !== routeNameResourceType &&
        !routeNameResourceTransportType.includes(resource.resourceTypeActual.name)
      ) {
        resourcesWithoutRouteIds.push(resource.id);
      }
    });
    return factoryResources
      .filter((factoryResource) => resourcesWithoutRouteIds.includes(factoryResource.resource.id))
      .sort((a, b) => a.resource.name.localeCompare(b.resource.name));
  };

  const getFactoryResourcesOnlyTransportType = (factoryResources: Array<FactoryResourcesInterface>) => {
    const resourcesWithoutRouteIds: Array<number> = [];
    resources.forEach((resource) => {
      if (resource.resourceTypeActual && routeNameResourceTransportType.includes(resource.resourceTypeActual.name)) {
        resourcesWithoutRouteIds.push(resource.id);
      }
    });
    return factoryResources.filter((factoryResource) => resourcesWithoutRouteIds.includes(factoryResource.resource.id));
  };

  const getAvailableResources = () => {
    if (activeAction && activeAction.isTransport) {
      return getFactoryResourcesWithoutRoute(factoryResources);
    }
    const actionResourcesId =
      activeAction && activeAction.resourcesActual
        ? activeAction.resourcesActual.map((actionResource) => actionResource.id)
        : [];

    return factoryResources.filter((factoryResource) => actionResourcesId.includes(factoryResource.resource.id));
  };
  const getTransportResources = () => {
    if (activeAction && activeAction.isTransport) {
      return getFactoryResourcesOnlyTransportType(factoryResources);
    }
    const actionResourcesId =
      activeAction && activeAction.resourcesActual
        ? activeAction.resourcesActual.map((actionResource) => actionResource.id)
        : [];

    return factoryResources.filter((factoryResource) => actionResourcesId.includes(factoryResource.resource.id));
  };

  const getHelperText = () => {
    if (!validatePosition) {
      return 'Podaj liczbę 0 lub większą';
    }
    if (!validateMaxPosition) {
      return `Maksymalna możliwa pozycja to ${maxGanttLength}`;
    }
    return '';
  };

  const handleShowActivitiesSection = () => {
    setShowActivitiesSection(true);
  };

  const handleCancelButton = () => {
    dispatch(clearActiveAction());
    setShowActivitiesSection(false);
  };

  const handleRecalculateGantt = () => {
    GameService.recalculateGantt(latestUserRoom.id)
      .then(() => {
        dispatch(fetchGanttOrders(gameGantt ? gameGantt.id : -1));
        setShowGanttRecaluclatedNotification(true);
      })
      .catch((error) => {
        const errorMessage =
          error.response?.status === 400
            ? GanttRecalculationMessages.GANTT_TIME_EXCEEDED
            : GanttRecalculationMessages.UNKNOWN_ERROR;
        setGanttRecalculationError(errorMessage);
        setShowGanttRecalculationError(true);
      });
  };

  const hideActivitiesSection = () => setShowActivitiesSection(false);

  const renderGanttActivitiesSection = () => {
    return (
      <>
        <GameGanttAllActivities actualActivities={actualActivities} afterActivitySelected={handleMoveAllCancel} />
        {activeAction && (
          <GameGanttResources
            resources={resources}
            availableFactoryResource={getAvailableResources()}
            title={activeAction.isTransport ? 'Miejsce początkowe OD' : 'Zasób'}
            isTransport={activeAction.isTransport}
          />
        )}
        {activeAction && activeAction.isTransport && (
          <GameGanttResources
            resources={resources}
            availableFactoryResource={getAvailableResources()}
            title={'Miejsce docelowe DO'}
            destination
            isTransport
          />
        )}
        {activeAction && activeAction.isTransport && (
          <GameGanttResources
            resources={resources}
            availableFactoryResource={getTransportResources()}
            title={'Sposób transportu'}
            transportType
          />
        )}
        {(activeAction || editGanttItemId || showMove) && (
          <div className={classes.editButtonsContainer}>
            {activeAction && (
              <>
                {editGanttItemId && (
                  <TextField
                    label="Start [min]"
                    variant="outlined"
                    className={clsx(classes.textfield, classes.borderBottom)}
                    onChange={handeStartChange}
                    error={!validatePosition || !validateMaxPosition}
                    value={position}
                    helperText={getHelperText()}
                    disabled={isAutomation}
                  />
                )}
                <TextField
                  label="Czas trwania [min]"
                  variant="outlined"
                  className={classes.textfield}
                  onChange={handeDurationChange}
                  error={!validateDuration}
                  value={dur}
                  helperText={validateDuration ? '' : 'Podaj czas w minutach, wartość powinna być liczbą całkowitą'}
                  disabled={isAutomation && !!editGanttItemId}
                />
              </>
            )}
            {editGanttItemId && (
              <>
                <Button
                  color={'primary'}
                  variant={'contained'}
                  onClick={handleEdit}
                  disabled={
                    !activeAction ||
                    !gameGanttDuration ||
                    !activeResourceId1 ||
                    !validatePosition ||
                    !validateMaxPosition
                  }
                  className={classes.borderTop}
                >
                  Zmień
                </Button>
                <Button
                  color={'primary'}
                  variant={'contained'}
                  onClick={handleEditCancel}
                  className={classes.borderTop}
                >
                  Anuluj
                </Button>
              </>
            )}
            {showMove && !editGanttItemId && (
              <div className={classes.showMoveContainer}>
                <div className={classes.showMoveFirstColumn}>
                  <TextField
                    label="Przesunięcie"
                    variant="outlined"
                    className={clsx(classes.textfield, classes.borderTop, classes.showMoveSubSection)}
                    onChange={handeMoveChange}
                    error={!validateMove}
                    value={move}
                    helperText={validateMove ? '' : 'Podaj liczbę'}
                  />
                  <div>
                    <Button
                      style={{ width: '100%' }}
                      color={'primary'}
                      variant={'contained'}
                      onClick={handleMove}
                      disabled={!validateMove}
                      className={clsx(classes.borderTop, classes.showMoveSubSection)}
                    >
                      Przesuń
                    </Button>
                    <Button
                      style={{ width: '100%' }}
                      color={'primary'}
                      variant={'contained'}
                      onClick={handleMoveAllCancel}
                      className={clsx(classes.borderTop, classes.showMoveSubSection)}
                    >
                      Anuluj
                    </Button>
                  </div>
                </div>
                <div className={classes.showMoveSecondColumn}>
                  <p>Przykład:</p>
                  <p>
                    “-5” - Przesunięcie czynności znajdujących się po prawej stronie od wybranej pozycji, o wpisaną
                    wartość w lewą stronę.
                  </p>
                  <p>
                    ”5” - Przesunięcie czynności znajdujących się po prawej stronie od wybranej pozycji, o wpisaną
                    wartość w prawą stronę.
                  </p>
                </div>
              </div>
            )}
          </div>
        )}
        {!editGanttItemId && !showMove && (
          <div className={classes.cancelButtonContainer}>
            <Button color={'primary'} variant={'contained'} className={classes.button} onClick={handleCancelButton}>
              Anuluj
            </Button>
          </div>
        )}
      </>
    );
  };

  useEffect(() => {
    setShowActivitiesSection(editGanttItemId !== undefined);
  }, [editGanttItemId]);

  return (
    <div className={classes.root}>
      <GameHeader
        latestUserRoom={latestUserRoom}
        selectedUserRoom={selectedUserRoom}
        updateRoom={updateRoom}
        timeLeftInMillis={timeLeftInMillis}
        onTimeLeft={onTimeLeft}
        gamePlayerRankings={gamePlayerRankings}
        showGantt={handleViewFactory}
        actualScenario={actualScenario}
      />
      <div className={classes.container}>
        <div className={classes.ganttContainer}>
          <div className={classes.buttonContanier}>
            {!isUserGameMaster && (
              <Button
                color={'primary'}
                variant={'contained'}
                onClick={handleAddOrder}
                disabled={!canEdit() || isZeroRound()}
              >
                Dodaj zlecenie
              </Button>
            )}
          </div>
          <GanttArea
            actions={actualActivities}
            onMoveAll={handleMoveAll}
            hideActivitiesSection={hideActivitiesSection}
          />
        </div>
        <div className={classes.accordionContainer}>
          <GameGanttAccordion actualProcesses={actualProcesses} updateRoom={updateRoom} />
        </div>
      </div>
      <div className={classes.tablesContainer}>
        {showActivitiesSection
          ? renderGanttActivitiesSection()
          : !(isUserGameMaster || !ganttOrders?.length) && (
              <div>
                <Button
                  color={'primary'}
                  variant={'contained'}
                  className={classes.button}
                  onClick={handleShowActivitiesSection}
                >
                  Dodaj czynność
                </Button>
                <Button
                  color={'primary'}
                  variant={'contained'}
                  className={classes.button}
                  onClick={handleRecalculateGantt}
                >
                  Przelicz wykres
                </Button>
              </div>
            )}
      </div>

      <AddGanttOrderModal isOpen={isOpenOrderModal} onClose={handleCloseOrderModal} />
      <Snackbar
        open={showNotification}
        autoHideDuration={2000}
        message={'Nie można postawić czynności w tym miejscu'}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={handleSnackbarClose}
      >
        <div className={classes.notification}>Nie można postawić czynności w tym miejscu</div>
      </Snackbar>
      <Snackbar
        open={showGanttRecaluclatedNotification}
        autoHideDuration={2000}
        message={'Wykres został przeliczony'}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={handleSnackbarClose}
      >
        <div className={classes.successNotification}>{GanttRecalculationMessages.SUCCESS}</div>
      </Snackbar>
      <Snackbar
        open={showGanttRecalculationError}
        autoHideDuration={3000}
        message={'Błąd podczas przeliczania wykresu'}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={handleSnackbarClose}
      >
        <SnackbarAlert message={ganttRecalculationError} />
      </Snackbar>
    </div>
  );
}
