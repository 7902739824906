import { AxiosPromise } from 'axios';

import { ApiService } from './api.service';
import {
  AddGameFactoryResourceInterface,
  GameFactoryResourceInterface,
  GameFactoryResourceResponseInterface,
} from 'app/shared/interfaces/GameFactoryResource.interface';

export const GameFactoryResourceService = {
  getGameFactoryResources(): AxiosPromise<GameFactoryResourceInterface> {
    return ApiService.get('game_factory_resources');
  },

  getGameFactoryWarehouses(userRoomId: number): AxiosPromise<GameFactoryResourceResponseInterface> {
    return ApiService.get(
      `game_factory_resources?resourceActual.resourceTypeActual.name=MAG&gameFactory.userRoom.id=${userRoomId}`
    );
  },

  addGameFactoryResource(data: AddGameFactoryResourceInterface): AxiosPromise {
    return ApiService.post('game_factory_resources', data);
  },

  replaceGameFactoryResource(
    id: number,
    data: Omit<AddGameFactoryResourceInterface, 'resourceActual' | 'gameFactory'>
  ): AxiosPromise {
    return ApiService.put(`game_factory_resources/${id}`, data);
  },

  deleteGameFactoryResource(id: number): AxiosPromise {
    return ApiService.delete(`game_factory_resources/${id}`);
  },
};
