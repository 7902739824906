import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  tableHead: {
    fontWeight: 'bold',
  },
  tableContainer: {
    marginBottom: 25,
    borderRadius: 0,
  },
});
