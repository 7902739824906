import { AxiosPromise } from 'axios';

import { ApiService } from './api.service';
import { ActionInterface, ActionResponse } from 'app/shared/interfaces/Action.interface';
import { GetParamsInterface } from 'app/shared/interfaces/RequestInterface.interface';

export const ActionService = {
  getActions(params: GetParamsInterface = {}): AxiosPromise<ActionResponse> {
    return ApiService.get('actions', params);
  },
  addAction(data: Omit<ActionInterface, 'id'>): AxiosPromise {
    return ApiService.post('actions', data);
  },
  replaceAction(id: number, data: Omit<ActionInterface, 'id'>): AxiosPromise {
    return ApiService.put(`actions/${id}`, data);
  },
  deleteAction(id: number): AxiosPromise {
    return ApiService.delete(`actions/${id}`);
  },
};
