import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';

import { OrderInterface } from 'app/shared/interfaces/Order.interface';
import { GetParamsInterface } from 'app/shared/interfaces/RequestInterface.interface';
import { defaultParams } from 'app/shared/helpers/pagination';
import { OrderService } from 'app/services/order.service';
import { ProductService } from 'app/services/product.service';
import { ProductInterface } from 'app/shared/interfaces/Product.interface';
import { HeaderButton } from 'app/components/HeaderButton/HeaderButton';
import { OrdersTable } from 'app/components/OrdersTable/OrdersTable';

import { useStyles } from './OrderPage.styles';

function OrderPage() {
  const classes = useStyles();
  const [orders, setOrders] = useState<Array<OrderInterface>>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [products, setProducts] = useState<Array<ProductInterface>>([]);
  const [addButtonClicked, setAddButtonClicked] = useState<boolean>(false);

  const getOrders = (params: GetParamsInterface = defaultParams) =>
    OrderService.getOrders(params).then(
      ({ data: { 'hydra:member': responseData, 'hydra:totalItems': totalRecords } }) => {
        setOrders(responseData);
        setTotalRecords(totalRecords);
      }
    );

  const getProducts = () => {
    ProductService.getProducts().then(({ data: { 'hydra:member': responseData } }) => {
      setProducts(responseData);
    });
  };

  const handleUpdateOrders = (params: GetParamsInterface) => getOrders(params);

  const handleAddButton = () => setAddButtonClicked(true);

  useEffect(() => {
    getOrders();
    getProducts();
  }, []);

  useEffect(() => {
    if (addButtonClicked) {
      setAddButtonClicked(false);
    }
  }, [addButtonClicked]);

  return (
    <div className={classes.root}>
      <Typography variant={'h1'}>
        <span>Zamówienia</span>
        <div>
          <HeaderButton onClick={handleAddButton} label={'Dodaj zamówienie'} />
        </div>
      </Typography>
      <OrdersTable
        orders={orders}
        totalRecords={totalRecords}
        shouldUpdateOrders={handleUpdateOrders}
        products={products}
        addClick={addButtonClicked}
      />
    </div>
  );
}

export default OrderPage;
