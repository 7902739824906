import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';

import { getSelectErrorProp } from 'app/shared/helpers/selectValidator';

import { MultipleSelectComponentProps } from './MultipleSelectComponent.interfaces';

import FormHelperText from '@material-ui/core/FormHelperText';

export function MultipleSelectComponent(props: MultipleSelectComponentProps) {
  const {
    fullWidth,
    options,
    form: { errors, touched, setFieldValue },
    field,
    onChange,
    disabled = false,
  } = props;
  const selectValues = props.field.value;
  const { fieldError, showError } = getSelectErrorProp(field, errors, touched);

  return (
    <FormControl error={showError} fullWidth={fullWidth}>
      <Autocomplete
        disabled={disabled}
        onChange={
          onChange
            ? onChange
            : (e, val) => {
                setFieldValue(
                  field.name,
                  val.map((v) => v.value)
                );
              }
        }
        value={selectValues.map((selecteValue: number) => options.find((option) => option.value === selecteValue))}
        getOptionSelected={(option, value) => option && value && option.value === value.value}
        multiple
        disableCloseOnSelect
        getOptionLabel={(option) => (option ? option.label : '')}
        renderInput={(params) => <TextField {...params} label={props.label} error={showError} />}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              key={option && option.value ? option.value : index}
              label={option && option.label ? option.label : ''}
              {...getTagProps({ index })}
              disabled={option && option.disabled ? option.disabled : false}
            />
          ))
        }
        options={options}
        renderOption={(option) => (
          <>
            <Checkbox checked={selectValues.includes(option.value)} />
            {option.label}
          </>
        )}
      />
      <FormHelperText>{showError ? fieldError : ''}</FormHelperText>
    </FormControl>
  );
}
