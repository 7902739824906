import { AxiosPromise } from 'axios';

import { ApiService } from './api.service';
import { OrderResponse, AddOrderInterface } from 'app/shared/interfaces/Order.interface';
import { GetParamsInterface } from 'app/shared/interfaces/RequestInterface.interface';

export const OrderService = {
  getOrders(params: GetParamsInterface = {}): AxiosPromise<OrderResponse> {
    return ApiService.get('orders', params);
  },
  addOrder(data: AddOrderInterface): AxiosPromise {
    return ApiService.post('orders', data);
  },
  replaceOrder(id: number, data: AddOrderInterface): AxiosPromise {
    return ApiService.put(`orders/${id}`, data);
  },
  deleteOrder(id: number): AxiosPromise {
    return ApiService.delete(`orders/${id}`);
  },
};
