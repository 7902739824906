import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';

import { MaterialTypeService } from 'app/services/materialType.service';
import { CustomIconButton } from 'app/components/CustomIconButton/CustomIconButton';
import { MaterialTypeInterface } from 'app/shared/interfaces/MaterialType.interface';
import { CustomDialog } from 'app/components/CustomDialog/CustomDialog';
import { AddMaterialTypeModal } from 'app/components/AddMaterialTypeModal/AddMaterialTypeModal';
import { CustomTablePagination } from 'app/components/CustomTablePagination/CustomTablePagination';
import { getParams } from 'app/shared/helpers/pagination';
import { handlingCatch } from 'app/shared/helpers/handlingCatch';
import { SnackbarAlert } from 'app/components/SnackbarAlert/SnackbarAlert';

import { useStyles } from './MaterialTypesTable.styles';
import { MaterialTypesTableProps } from './MaterialTypesTable.interfaces';

export function MaterialTypesTable(props: MaterialTypesTableProps) {
  const { materialTypes, shouldUpdateMaterialTypes, totalRecords, addClick } = props;
  const classes = useStyles();

  const [selectedMaterialType, setSelectedMaterialType] = useState<number>(0);
  const [selectedPage, setSelectedPage] = useState<number>(0);
  const [materialTypeData, setMaterialTypeData] = useState<MaterialTypeInterface | null>(null);

  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [openAddDialog, setOpenAddDialog] = useState<boolean>(false);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [notificationText, setNotificationText] = useState<string>('');

  const handleSnackbarClose = () => {
    setShowNotification(false);
  };

  const handleAddMaterialType = () => {
    setMaterialTypeData(null);
    setOpenAddDialog(true);
  };

  const handleOpenEditMaterialType = (id: number) => {
    const materialType = getMaterialTypeById(id);
    if (materialType) {
      setMaterialTypeData(materialType);
      setOpenAddDialog(true);
    }
  };

  const handleOpenDeleteMaterialType = (id: number) => {
    setSelectedMaterialType(id);
    setOpenDeleteDialog(true);
  };

  const handleDeleteMaterialType = () => {
    MaterialTypeService.deleteMaterialType(selectedMaterialType)
      .then(() => {
        setOpenDeleteDialog(false);
        shouldUpdateMaterialTypes(getParams(selectedPage));
      })
      .catch((error) => {
        const response = handlingCatch(error.response);
        const { status } = error.response;
        if (status === 400) {
          setNotificationText(response.message);
          setShowNotification(true);
          setOpenDeleteDialog(false);
        }
      });
  };

  const handleCloseDeleteMaterialTypeDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
    setMaterialTypeData(null);
  };

  const handleMaterialTypeAdded = () => {
    shouldUpdateMaterialTypes(getParams(selectedPage));
    setOpenAddDialog(false);
    setMaterialTypeData(null);
  };

  const getMaterialTypeById = (id: number) => materialTypes.find((materialType) => materialType.id === id);

  const getMaterialTypeName = (id: number) => {
    const materialType = getMaterialTypeById(id);
    return materialType ? materialType.name : '';
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    shouldUpdateMaterialTypes(getParams(page));
    setSelectedPage(page);
  };

  useEffect(() => {
    if (addClick) {
      handleAddMaterialType();
    }
  }, [addClick]);

  return (
    <div>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Nazwa</TableCell>
              <TableCell>Opis</TableCell>
              <TableCell className={classes.buttonsTableCell} />
            </TableRow>
          </TableHead>
          <TableBody>
            {materialTypes.map((materialType) => (
              <TableRow key={materialType.id}>
                <TableCell className={clsx(classes.wrap, classes.bold)}>{materialType.name}</TableCell>
                <TableCell className={classes.wrap}>{materialType.description}</TableCell>
                <TableCell align={'right'} className={classes.buttonsTableCell}>
                  <CustomIconButton onClick={() => handleOpenEditMaterialType(materialType.id)} type={'edit'} />
                  <CustomIconButton onClick={() => handleOpenDeleteMaterialType(materialType.id)} type={'delete'} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <CustomTablePagination
            tablePaginationConfig={{
              count: totalRecords,
              page: selectedPage,
            }}
            onChangePage={handleChangePage}
          />
        </Table>
      </TableContainer>
      <AddMaterialTypeModal
        isOpen={openAddDialog}
        data={materialTypeData}
        onClose={handleCloseAddDialog}
        materialTypeAdded={handleMaterialTypeAdded}
      />
      <CustomDialog
        title={'Usuwanie typu materiału'}
        onCloseDialog={handleCloseDeleteMaterialTypeDialog}
        body={
          <>
            {'Na pewno chcesz usunąć typ materiału: '}
            {getMaterialTypeName(selectedMaterialType)}
          </>
        }
        isOpen={openDeleteDialog}
        dialogActions={
          <>
            <Button color={'primary'} onClick={handleDeleteMaterialType}>
              Tak
            </Button>
            <Button color={'primary'} autoFocus onClick={handleCloseDeleteMaterialTypeDialog}>
              Nie
            </Button>
          </>
        }
      />
      <Snackbar
        open={showNotification}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={handleSnackbarClose}
      >
        <SnackbarAlert message={notificationText} />
      </Snackbar>
    </div>
  );
}
