import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  addActionButton: {
    marginTop: 10,
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20,
  },
});
