import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';

import { TablePaginationActionsProps } from './TablePaginationActions.interfaces';
import { useStyles } from './TablePaginationActions.styles';

export const TablePaginationActions = (props: TablePaginationActionsProps) => {
  const { count, page, rowsPerPage, onChangePage } = props;

  const classes = useStyles();

  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  const firstVisibleRow = () => (count ? page * rowsPerPage + 1 : 0);

  const lastVisibleRow = () => {
    const maxRows = (page + 1) * rowsPerPage;
    return maxRows < count ? maxRows : count;
  };

  return (
    <div>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="pierwsza strona"
        className={classes.arrow}
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="poprzednia strona"
        className={classes.arrow}
      >
        <KeyboardArrowLeft />
      </IconButton>
      <span>{`${firstVisibleRow()}-${lastVisibleRow()} z ${count}`}</span>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="następna strona"
        className={classes.arrow}
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="ostatnia strona"
        className={classes.arrow}
      >
        <LastPageIcon />
      </IconButton>
    </div>
  );
};
