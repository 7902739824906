import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';

import { MaterialInterface } from 'app/shared/interfaces/Material.interface';
import { MaterialService } from 'app/services/material.service';
import { TextFieldComponent } from 'app/components/TextFieldComponent/TextFieldComponent';
import { CustomDialog } from 'app/components/CustomDialog/CustomDialog';
import { SelectComponent } from 'app/components/SelectComponent/SelectComponent';
import { handlingCatch } from 'app/shared/helpers/handlingCatch';
import { upToThreeDecimalPlaces, upToTwoDecimalPlaces } from 'app/shared/helpers/validators';
import { AddMaterialFormDataInterface, AddMaterialModalProps } from './AddMaterialModal.interfaces';

import { useStyles } from './AddMaterialModal.styles';
import { convertToFloat } from 'app/shared/helpers/numberConverter';

export function AddMaterialModal(props: AddMaterialModalProps) {
  const { isOpen, material, materialTypes, onClose, materialAdded } = props;
  const classes = useStyles();

  const formData: AddMaterialFormDataInterface = {
    name: material ? material.name : '',
    description: material && material.description ? material.description : '',
    materialType: material && material.materialType ? material.materialType.id.toString() : '',
    quality: material ? material.quality.toString() : '',
    amount: material ? material.amount.toString() : '',
    price: material ? material.price.toString() : '',
    timeOrder: material ? material.timeOrder.toString() : '',
  };

  const validationShema = Yup.object().shape({
    name: Yup.string().required('Podaj nazwę'),
    quality: Yup.number()
      .test('is-incorrect', 'Podaj jakość w zakresie od 0 do 1 max. trzy miejsca po przecinku', upToThreeDecimalPlaces)
      .min(0, 'Podaj jakość w zakresie od 0 do 1 max. trzy miejsca po przecinku')
      .max(1, 'Podaj jakość w zakresie od 0 do 1 max. trzy miejsca po przecinku')
      .typeError('Podaj jakość w zakresie od 0 do 1 max. trzy miejsca po przecinku')
      .required('Podaj jakość w zakresie od 0 do 1 max. trzy miejsca po przecinku'),
    amount: Yup.number()
      .test(
        'is-incorrect',
        'Podaj wielkość, wartość powinna być liczbą rzeczywistą, max. dwa miejsca po przecinku',
        upToTwoDecimalPlaces
      )
      .min(0, 'Podaj wielkość, wartość powinna być liczbą rzeczywistą, max. dwa miejsca po przecinku')
      .typeError('Podaj wielkość, wartość powinna być liczbą rzeczywistą, max. dwa miejsca po przecinku')
      .required('Podaj wielkość, wartość powinna być liczbą rzeczywistą, max. dwa miejsca po przecinku'),
    price: Yup.number()
      .test('is-incorrect', 'Podaj cenę max. dwa miejsca po przecinku', upToTwoDecimalPlaces)
      .min(0, 'Podaj cenę max. dwa miejsca po przecinku')
      .typeError('Podaj cenę max. dwa miejsca po przecinku')
      .required('Podaj cenę max. dwa miejsca po przecinku'),
    timeOrder: Yup.number()
      .min(0, 'Podaj czas zamawiania w liczbie całkowitej')
      .typeError('Podaj czas zamawiania w liczbie całkowitej')
      .integer('Podaj czas zamawiania w liczbie całkowitej')
      .required('Podaj czas zamawiania w liczbie całkowitej'),
    materialType: Yup.number().required('Podaj typ materiału'),
  });

  return (
    <CustomDialog
      title={material ? 'Edytuj materiał' : 'Dodaj materiał'}
      isOpen={isOpen}
      onCloseDialog={onClose}
      body={
        <Formik
          initialValues={formData}
          validationSchema={validationShema}
          onSubmit={(values, actions) => {
            const materialData: Omit<MaterialInterface, 'id'> = {
              name: values.name.trim(),
              description: values.description,
              materialType: materialTypes.find((materialType) => materialType.id === parseInt(values.materialType)),
              amount: convertToFloat(values.amount),
              quality: convertToFloat(values.quality),
              price: convertToFloat(values.price),
              timeOrder: parseInt(values.timeOrder) || 0,
            };

            if (material && material.id) {
              MaterialService.replaceMaterial(material.id, materialData)
                .then(() => {
                  materialAdded();
                })
                .catch((error) => {
                  const response = handlingCatch(error.response);
                  actions.setFieldError(response.fieldError, response.message);
                });
            } else {
              MaterialService.addMaterial(materialData)
                .then(() => {
                  materialAdded();
                })
                .catch((error) => {
                  const response = handlingCatch(error.response);
                  actions.setFieldError(response.fieldError, response.message);
                });
            }
          }}
        >
          {({ submitForm }) => (
            <Form>
              <Field component={TextFieldComponent} name={'name'} label={'Nazwa'} fullWidth />
              <Field
                component={TextFieldComponent}
                name={'description'}
                label={'Opis'}
                multiline
                rowsMax={4}
                fullWidth
              />
              <Field
                component={SelectComponent}
                fullWidth
                label={'Typ materiału'}
                name={'materialType'}
                options={materialTypes.map((materialType) => ({
                  label: materialType.name,
                  value: materialType.id,
                }))}
              />
              <Field component={TextFieldComponent} name={'quality'} label={'Jakość'} fullWidth />
              <Field component={TextFieldComponent} name={'amount'} label={'Wielkość [jw]'} fullWidth />
              <Field component={TextFieldComponent} name={'price'} label={'Cena [PLN]'} fullWidth />
              <Field component={TextFieldComponent} name={'timeOrder'} label={'Czas zamawiania [tura]'} fullWidth />
              <div className={classes.actionButtons}>
                <Button color={'primary'} onClick={submitForm}>
                  {material ? 'Zapisz' : 'Dodaj'}
                </Button>
                <Button color={'primary'} autoFocus onClick={onClose}>
                  Anuluj
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      }
    />
  );
}
