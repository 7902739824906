import { AxiosPromise } from 'axios';

import { ApiService } from './api.service';
import { BackendConfigInterface } from 'app/shared/interfaces/BackendConfig.interface';

export const BackendConfigService = {
  getAppVersion(): AxiosPromise<BackendConfigInterface> {
    return ApiService.get('debug/version');
  },
};
