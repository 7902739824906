import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';

import { useStyles } from './OrdersTable.styles';

import { OrdersTableProps } from './OrdersTable.interfaces';
import { CustomDialog } from 'app/components/CustomDialog/CustomDialog';
import { OrderService } from 'app/services/order.service';
import { AddOrderModal } from 'app/components/AddOrderModal/AddOrderModal';
import { CustomTablePagination } from 'app/components/CustomTablePagination/CustomTablePagination';
import { OrderInterface } from 'app/shared/interfaces/Order.interface';
import { getParams } from 'app/shared/helpers/pagination';
import { handlingCatch } from 'app/shared/helpers/handlingCatch';
import { SnackbarAlert } from 'app/components/SnackbarAlert/SnackbarAlert';
import { CustomIconButton } from '../CustomIconButton/CustomIconButton';

export function OrdersTable(props: OrdersTableProps) {
  const { orders, shouldUpdateOrders, totalRecords, products, addClick } = props;
  const classes = useStyles();

  const [selectedOrderId, setSelectedOrderId] = useState<number>(0);
  const [selectedPage, setSelectedPage] = useState<number>(0);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [openAddDialog, setOpenAddDialog] = useState<boolean>(false);
  const [orderData, setOrderData] = useState<OrderInterface | null>(null);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [notificationText, setNotificationText] = useState<string>('');

  const handleSnackbarClose = () => {
    setShowNotification(false);
  };

  const handleOpenAddOrder = () => {
    setOrderData(null);
    setOpenAddDialog(true);
  };

  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
    setOrderData(null);
  };

  const handleOpenEditOrder = (id: number) => {
    const order = getOrderById(id);
    if (order) {
      setOrderData(order);
      setOpenAddDialog(true);
    }
  };

  const handleOrderAdded = () => {
    shouldUpdateOrders(getParams(selectedPage));
    setOrderData(null);
    setOpenAddDialog(false);
  };

  const handleOpenDeleteOrder = (id: number) => {
    setSelectedOrderId(id);
    setOpenDeleteDialog(true);
  };

  const handleDeleteOrder = () => {
    OrderService.deleteOrder(selectedOrderId)
      .then(() => {
        setOpenDeleteDialog(false);
        shouldUpdateOrders(getParams(selectedPage));
      })
      .catch((error) => {
        const response = handlingCatch(error.response);
        const { status } = error.response;
        if (status === 400) {
          setNotificationText(response.message);
          setShowNotification(true);
          setOpenDeleteDialog(false);
        }
      });
  };

  const handleCloseDeleteDialog = () => setOpenDeleteDialog(false);

  const getOrderById = (id: number) => orders.find((order) => order.id === id);

  const getOrderNameById = (id: number) => {
    const order = getOrderById(id);
    return order ? order.name : '';
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    shouldUpdateOrders(getParams(page));
    setSelectedPage(page);
  };

  const showProduct = (order: OrderInterface) =>
    order && order.product ? (
      <p key={order.product.id}>
        Nazwa: {order.product.name} Jakość: {order.product.quality}
      </p>
    ) : (
      ''
    );

  useEffect(() => {
    if (addClick) {
      handleOpenAddOrder();
    }
  }, [addClick]);

  return (
    <div>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Nazwa</TableCell>
              <TableCell>Opis</TableCell>
              <TableCell align={'center'}>Wymagana jakość</TableCell>
              <TableCell align={'center'}>Ilość</TableCell>
              <TableCell>Produkt</TableCell>
              <TableCell align={'right'} className={classes.buttonsTableCell} />
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((order) => (
              <TableRow key={order.id}>
                <TableCell className={clsx(classes.wrap, classes.bold)}>{order.name}</TableCell>
                <TableCell className={classes.wrap}>{order.description}</TableCell>
                <TableCell align={'center'}>{order.quality}</TableCell>
                <TableCell align={'center'}>{order.amount}</TableCell>
                <TableCell>{showProduct(order)}</TableCell>
                <TableCell align={'right'} className={classes.buttonsTableCell}>
                  <CustomIconButton onClick={() => handleOpenEditOrder(order.id)} type={'edit'} />
                  <CustomIconButton onClick={() => handleOpenDeleteOrder(order.id)} type={'delete'} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <CustomTablePagination
            tablePaginationConfig={{
              count: totalRecords,
              page: selectedPage,
            }}
            onChangePage={handleChangePage}
          />
        </Table>
      </TableContainer>
      <AddOrderModal
        isOpen={openAddDialog}
        orderData={orderData}
        onClose={handleCloseAddDialog}
        orderAdded={handleOrderAdded}
        products={products}
      />
      <CustomDialog
        title={'Usuwanie Zamówienia'}
        onCloseDialog={handleCloseDeleteDialog}
        body={
          <>
            {'Na pewno chcesz usunąć zamówienie: '}
            {getOrderNameById(selectedOrderId)}
          </>
        }
        isOpen={openDeleteDialog}
        dialogActions={
          <>
            <Button color={'primary'} onClick={handleDeleteOrder}>
              Tak
            </Button>
            <Button color={'primary'} autoFocus onClick={handleCloseDeleteDialog}>
              Nie
            </Button>
          </>
        }
      />
      <Snackbar
        open={showNotification}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={handleSnackbarClose}
      >
        <SnackbarAlert message={notificationText} />
      </Snackbar>
    </div>
  );
}
