import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';

import { SupplierInterface } from 'app/shared/interfaces/Supplier.interface';
import { SupplierService } from 'app/services/supplier.service';
import { TextFieldComponent } from 'app/components/TextFieldComponent/TextFieldComponent';
import { MultipleSelectComponent } from 'app/components/MultipleSelectComponent/MultipleSelectComponent';
import { CustomDialog } from 'app/components/CustomDialog/CustomDialog';

import { AddSupplierFormDataInterface, AddSupplierModalProps } from './AddSupplierModal.interfaces';
import { useStyles } from './AddSupplierModal.styles';
import { handlingCatch } from 'app/shared/helpers/handlingCatch';

export function AddSupplierModal(props: AddSupplierModalProps) {
  const { isOpen, onClose, supplier, materials, supplierAdded } = props;
  const classes = useStyles();

  const formData: AddSupplierFormDataInterface = {
    name: supplier ? supplier.name : '',
    description: supplier && supplier.description ? supplier.description : '',
    materials: supplier ? supplier.materials.map((material) => material.id) : [],
  };

  const validationShema = Yup.object().shape({
    name: Yup.string().required('Podaj nazwę'),
    materials: Yup.string().required('Wybierz materiał'),
  });

  return (
    <CustomDialog
      title={supplier ? 'Edytuj dostawcę' : 'Dodaj dostawcę'}
      isOpen={isOpen}
      onCloseDialog={onClose}
      body={
        <Formik
          initialValues={formData}
          validationSchema={validationShema}
          onSubmit={(values, actions) => {
            const supplierData: Omit<SupplierInterface, 'id'> = {
              name: values.name.trim(),
              description: values.description,
              materials: materials.filter((material) => values.materials.includes(material.id || -1)),
            };

            if (supplier && supplier.id) {
              SupplierService.replaceSupplier(supplier.id, supplierData)
                .then(() => {
                  supplierAdded();
                })
                .catch((error) => {
                  const response = handlingCatch(error.response);
                  actions.setFieldError(response.fieldError, response.message);
                });
            } else {
              SupplierService.addSupplier(supplierData)
                .then(() => {
                  supplierAdded();
                })
                .catch((error) => {
                  const response = handlingCatch(error.response);
                  actions.setFieldError(response.fieldError, response.message);
                });
            }
          }}
        >
          {({ submitForm }) => (
            <Form>
              <Field component={TextFieldComponent} name={'name'} label={'Nazwa'} fullWidth />
              <Field
                component={TextFieldComponent}
                name={'description'}
                label={'Opis'}
                multiline
                rowsMax={4}
                fullWidth
              />
              <Field
                component={MultipleSelectComponent}
                fullWidth
                label={'Materiały'}
                name={'materials'}
                options={materials.map((material) => ({
                  label: material.name,
                  value: material.id,
                }))}
              />
              <div className={classes.actionButtons}>
                <Button color={'primary'} onClick={submitForm}>
                  {supplier ? 'Zapisz' : 'Dodaj'}
                </Button>
                <Button color={'primary'} autoFocus onClick={onClose}>
                  Anuluj
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      }
    />
  );
}
