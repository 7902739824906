import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  root: {
    padding: '0 20px',
    width: 1300,
    margin: '0 auto',
  },
  factoryContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  factoryAreaContainer: {
    width: 780,
  },
  factoryToolbarContainer: {
    width: 400,
  },
});
