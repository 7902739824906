import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import Slider from '@material-ui/core/Slider';
import IconButton from '@material-ui/core/IconButton';
import FilterCenterFocusIcon from '@material-ui/icons/FilterCenterFocus';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import { FactoryAreaProps } from './FactoryArea.interfaces';
import {
  addFactoryResource,
  addResourceToUserRoom,
  clearActiveResource,
  deleteFactoryResourceById,
  deleteGameFactoryResource,
  getActiveFactoryResourceId,
  getActiveResource,
  getFactory,
  getFactoryId,
  getFactoryResources,
  replaceFactoryById,
  replaceGameFactoryResources,
  reloadGameFactoryResource,
  setGridOptions,
} from 'app/store/factory';
import { canEdit } from 'app/shared/helpers/canEdit';
import { getGameFactory, getGameFactoryResource, getUserRoomId } from 'app/store/userRoom';
import { FactoryResourceInterface } from 'app/shared/interfaces/FactoryResource.interface';
import { EditResourceFromFactoryPopper } from 'app/components/EditResourceFromFactoryPopper/EditResourceFromFactoryPopper';
import { ResourceInterface } from 'app/shared/interfaces/Resource.interface';
import { Grid } from 'app/components/Grid/Grid';
import {
  calculateAreaProps,
  calculatePositionX,
  calculatePositionY,
  createItems,
  getItemAreaSize,
  getSliderDownStyle,
  initialAreaProps,
  isCollision,
  moveTmpElement,
  showTmpItem,
  gridAxisInitialSettings,
} from './GridFunctions';
import { FactoryAreaPropsInterfaces } from 'app/shared/interfaces/Factory.interface';
import {
  AddGameFactoryResourceInterface,
  GameFactoryResourceInterface,
} from 'app/shared/interfaces/GameFactoryResource.interface';
import { useStyles } from './FactoryArea.styles';
import Snackbar from '@material-ui/core/Snackbar';
import { getGridOptions } from 'app/store/factory/selectors';

export function FactoryArea({ resources }: FactoryAreaProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const gameFactory = useSelector(getGameFactory);
  const factoryResources = useSelector(getFactoryResources);
  const activeFactoryResourceId = useSelector(getActiveFactoryResourceId);
  const activeResource = useSelector(getActiveResource);
  const factoryId = useSelector(getFactoryId);
  const factory = useSelector(getFactory);
  const gameFactoryResources = useSelector(getGameFactoryResource);
  const userRoomId = useSelector(getUserRoomId);
  const rootEl = useRef<HTMLDivElement>(null);
  const tmpItemEl = useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [selectedFactoryResourceId, setSelectedFactoryResourceId] = useState<number>(-1);
  const [selectedFactoryResource, setSelectedFactoryResource] = useState<ResourceInterface | undefined>();
  const [isElementMoving, setIsElementMoving] = useState<boolean>(false);
  const [areaProps, setAreaProps] = useState<FactoryAreaPropsInterfaces>(initialAreaProps);
  const [openScale, setOpenScale] = useState<boolean>(false);
  const [scale, setScale] = useState<number | null>(-10);
  const [initialized, setInitialized] = useState<boolean>(true);
  const [showCollisionNotification, setCollisionShowNotification] = useState<boolean>(false);
  const [
    selectedGameFactoryResourceData,
    setSelectedGameFactoryResourceData,
  ] = useState<GameFactoryResourceInterface | null>(null);

  const gridOptions = useSelector(getGridOptions);

  const setOffsetX = useCallback(
    (value: number) => {
      dispatch(
        setGridOptions({
          x: value,
        })
      );
    },
    [dispatch]
  );

  const setOffsetY = useCallback(
    (value: number) => {
      dispatch(
        setGridOptions({
          y: value,
        })
      );
    },
    [dispatch]
  );

  const gridSliderSettings = {
    STEP: 1,
    X_AXIS_MAX_VALUE: gridAxisInitialSettings.MAX_AXIS_VALUE - areaProps.cols + 1,
    X_AXIS_MIN_VALUE: gridAxisInitialSettings.MIN_AXIS_VALUE,
    Y_AXIS_MAX_VALUE: gridAxisInitialSettings.MAX_AXIS_VALUE,
    Y_AXIS_MIN_VALUE: areaProps.rows - gridAxisInitialSettings.MAX_AXIS_VALUE - 1,
  };

  const findGameFactoryResource = (id: number) =>
    gameFactoryResources ? gameFactoryResources.find((gameFactoryResource) => gameFactoryResource.id === id) : null;

  const getResourceById = (id: number) => resources.find((resource) => resource.id === id);

  const getFactoryResourceById = (id: number) => factoryResources.find((res) => res.id === id);

  const getResourceFromFactoryResoureById = (id: number) => {
    const factoryResource = getFactoryResourceById(id);
    return factoryResource ? getResourceById(factoryResource.resource.id) : undefined;
  };

  const showWholeItem = (id: number) => {
    const factoryResource = getFactoryResourceById(id);
    const resourceFromFactoryResoureById = getResourceFromFactoryResoureById(id);
    const x = factoryResource?.x || 0;
    const y = factoryResource?.y || 0;
    const length = resourceFromFactoryResoureById?.length || 0;
    const width = resourceFromFactoryResoureById?.width || 0;
    if (x < gridOptions.x) {
      setOffsetX(x);
    }
    if (x + length > areaProps.cols + gridOptions.x) {
      setOffsetX(x + length - areaProps.cols);
    }
    if (y < -gridOptions.y) {
      setOffsetY(-y);
    }
    if (y + width > areaProps.rows - gridOptions.y) {
      setOffsetY(-(y + width - areaProps.rows));
    }
  };

  const handleSelectItemClick = (e: React.MouseEvent<HTMLDivElement>, id: number) => {
    if (!activeResource && (canEdit() || !gameFactory)) {
      setSelectedFactoryResourceId(id);
      setSelectedFactoryResource(getResourceFromFactoryResoureById(id));
      setAnchorEl(e.currentTarget);
      const gameFactoryResource = findGameFactoryResource(id);
      setSelectedGameFactoryResourceData(gameFactoryResource ? gameFactoryResource : null);
      setOpenEditModal(true);
      showWholeItem(id);
    }
  };

  const handleSetOffsets = (_offsetX: number, _offsetY: number) => {
    if (_offsetX) {
      setOffsetX(gridOptions.x + _offsetX);
    }
    if (_offsetY) {
      setOffsetY(gridOptions.y + _offsetY);
    }
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (activeResource || (isElementMoving && selectedFactoryResource)) {
      moveTmpElement({
        e,
        width: activeResource ? activeResource.length : selectedFactoryResource!.length,
        height: activeResource ? activeResource.width : selectedFactoryResource!.width,
        areaEl: rootEl.current,
        tmpItemEl: tmpItemEl.current,
        areaProps,
        setOffsets: handleSetOffsets,
        offsetX: gridOptions.x,
        offsetY: gridOptions.y,
      });
    }
  };

  const hideTmpItem = () => {
    if (tmpItemEl && tmpItemEl.current) {
      tmpItemEl.current.style.display = 'none';
    }
  };

  const addResourceToFactory = (x: number, y: number) => {
    if (activeResource) {
      const data: Omit<FactoryResourceInterface, 'id'> = {
        factory: factory,
        resource: activeResource,
        x: x + gridOptions.x,
        y: y - gridOptions.y,
      };
      const dataGame: AddGameFactoryResourceInterface = {
        resourceActual: {
          '@id': activeResource['@id'] ? activeResource['@id'] : '',
        },
        gameFactory: {
          '@id': gameFactory && gameFactory['@id'] ? gameFactory['@id'] : '',
        },
        x: x + gridOptions.x,
        y: y - gridOptions.y,
      };
      gameFactory
        ? dispatch(addResourceToUserRoom(dataGame, gameFactory.id, userRoomId))
        : dispatch(addFactoryResource(factoryId, data));
    }
  };

  const editResourceFromFactory = (x: number, y: number) => {
    if (isElementMoving) {
      const factoryResource = getFactoryResourceById(selectedFactoryResourceId);
      setIsElementMoving(false);
      hideTmpItem();

      if (factoryResource) {
        const data: Omit<FactoryResourceInterface, 'id' | 'factory'> = {
          resource: factoryResource.resource,
          x: x + gridOptions.x,
          y: y - gridOptions.y,
        };

        const dataGame: Omit<AddGameFactoryResourceInterface, 'resourceActual' | 'gameFactory'> = {
          x: x + gridOptions.x,
          y: y - gridOptions.y,
        };
        gameFactory
          ? dispatch(replaceGameFactoryResources(factoryResource.id, dataGame, gameFactory.id, userRoomId))
          : dispatch(replaceFactoryById(selectedFactoryResourceId, factoryId, data));
        setSelectedFactoryResourceId(-1);
      }
    }
  };

  const isResourceCollisionOnTheFactoryArea = (newX: number, newY: number) => {
    const activeResourceId = isElementMoving
      ? getFactoryResourceById(selectedFactoryResourceId)?.resource?.id
      : activeResource?.id;
    const collisionResource =
      activeResourceId &&
      factoryResources.find((gridFactoryResource) => {
        const isTheSameResource = isElementMoving && selectedFactoryResourceId === gridFactoryResource.id;
        if (isTheSameResource) {
          //allow moving the same resouce on the same place
          return false;
        }
        const gridResource = getResourceById(gridFactoryResource.resource.id);
        const newResource = getResourceById(activeResourceId);
        return isCollision(newX, newY, gridFactoryResource, gridResource, newResource);
      });
    return !!collisionResource;
  };

  const handleCollisionSnackbarClose = () => {
    setCollisionShowNotification(false);
  };

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!activeResource && !selectedFactoryResource) {
      return;
    }
    const width = activeResource ? activeResource.length : selectedFactoryResource!.length;
    const height = activeResource ? activeResource.width : selectedFactoryResource!.width;
    let x = calculatePositionX(e, rootEl.current, areaProps, width);
    let y = calculatePositionY(e, rootEl.current, areaProps, height);

    // If resource would be placed outside the area, place it next to the edge instead
    while (x + gridOptions.x < gridAxisInitialSettings.MIN_AXIS_VALUE) x++;
    while (x + width - 1 + gridOptions.x > gridAxisInitialSettings.MAX_AXIS_VALUE) x--;

    while (y - gridOptions.y < gridAxisInitialSettings.MIN_AXIS_VALUE) y++;
    while (y + height - 1 - gridOptions.y > gridAxisInitialSettings.MAX_AXIS_VALUE) y--;

    if (isResourceCollisionOnTheFactoryArea(x + gridOptions.x, y - gridOptions.y)) {
      setCollisionShowNotification(true);
      return;
    }

    if (activeResource) {
      addResourceToFactory(x, y);
    }
    if (isElementMoving) {
      editResourceFromFactory(x, y);
    }
  };

  const handleOnCloseEditModal = () => {
    setOpenEditModal(false);
    setSelectedFactoryResourceId(-1);
  };

  const handleOnMove = () => {
    setOpenEditModal(false);
    setIsElementMoving(true);
    showTmpItem({
      resource: selectedFactoryResource,
      itemEl: tmpItemEl.current,
      areaProps,
    });
  };

  const handleOnDelete = () => {
    gameFactory
      ? dispatch(deleteGameFactoryResource(selectedFactoryResourceId, gameFactory.id, userRoomId))
      : dispatch(deleteFactoryResourceById(selectedFactoryResourceId, factoryId));
    setOpenEditModal(false);
    setSelectedFactoryResourceId(-1);
  };

  const handleOnReload = () => {
    if (gameFactory) {
      dispatch(reloadGameFactoryResource(gameFactory.id, userRoomId));
      setOpenEditModal(false);
    }
  };

  const handleSliderX = (e: React.ChangeEvent<{}>, value: number | number[]) => {
    setOffsetX(typeof value === 'number' ? value : 0);
  };
  const handleSliderY = (e: React.ChangeEvent<{}>, value: number | number[]) =>
    setOffsetY(typeof value === 'number' ? value : 0);

  const handleCenterButton = () => {
    setOffsetX(-Math.floor(areaProps.cols / 2));
    setOffsetY(Math.floor(areaProps.rows / 2));
  };

  const handleEscepe = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        if (isElementMoving) {
          setIsElementMoving(false);
          hideTmpItem();
          setSelectedFactoryResourceId(-1);
        }
        if (activeResource) {
          dispatch(clearActiveResource());
        }
        if (openEditModal) {
          handleOnCloseEditModal();
        }
      }
    },
    [isElementMoving, activeResource, openEditModal, dispatch]
  );

  const handleScaleSlider = (e: React.ChangeEvent<{}>, value: number | number[]) => {
    const val = typeof value === 'number' ? value : 0;
    setScale(val);
  };

  const initScale = useCallback(
    (scale: number) => {
      dispatch(setGridOptions({ scale: scale }));
      setInitialized(false);
    },
    [dispatch]
  );

  // Keep view inside bounds after scaling
  useEffect(() => {
    if (initialized) {
      const initScaleValue = gridOptions.scale === null ? -10 : gridOptions.scale;
      initScale(initScaleValue);
      setInitialized(false);
    }
    if (gridOptions.x > gridSliderSettings.X_AXIS_MAX_VALUE) {
      setOffsetX(gridSliderSettings.X_AXIS_MAX_VALUE);
    }
    if (gridOptions.y < gridSliderSettings.Y_AXIS_MIN_VALUE) {
      setOffsetY(gridSliderSettings.Y_AXIS_MIN_VALUE);
    }
  }, [initScale, initialized, areaProps, gridSliderSettings, gridOptions, setOffsetX, setOffsetY]);

  const scaleButton = () => setOpenScale(true);

  const handleScaleClose = () => setOpenScale(false);

  const handleSaveScale = () => dispatch(setGridOptions({ scale: scale }));

  useEffect(() => {
    setAreaProps(calculateAreaProps(scale || 0));
  }, [scale]);

  useEffect(() => {
    setScale(gridOptions.scale);
  }, [gridOptions.scale]);

  useEffect(() => {
    if (activeResource) {
      if (tmpItemEl && tmpItemEl.current) {
        showTmpItem({
          resource: activeResource,
          itemEl: tmpItemEl.current,
          areaProps,
        });
      }
    } else {
      hideTmpItem();
    }
  }, [activeResource, areaProps]);

  useEffect(() => {
    document.addEventListener('keydown', handleEscepe);
    return () => document.removeEventListener('keydown', handleEscepe);
  }, [handleEscepe]);

  const handleCancelMoving = () => {
    setIsElementMoving(false);
    if (!activeResource) {
      hideTmpItem();
      setSelectedFactoryResourceId(-1);
    }
  };

  return (
    <ClickAwayListener onClickAway={handleCancelMoving}>
      <div className={classes.root} ref={rootEl}>
        <div className={classes.areaContainer}>
          <div>
            <Grid areaProps={areaProps} offsetX={gridOptions.x} offsetY={gridOptions.y} />
            <div
              id={'factoryClickContainer'}
              className={classes.itemsArea}
              style={getItemAreaSize(areaProps)}
              onMouseMove={handleMouseMove}
              onClick={(e) => handleClick(e)}
            >
              {createItems({
                resources,
                factoryResources,
                gameFactoryResources,
                offsetX: gridOptions.x,
                offsetY: gridOptions.y,
                handleSelectItemClick,
                activeFactoryResourceId,
                areaProps,
              })}
              <div className={classes.tmpItem} ref={tmpItemEl}>
                <span />
              </div>
            </div>
          </div>
          <div className={classes.sliderRight}>
            <Slider
              step={gridSliderSettings.STEP}
              min={gridSliderSettings.Y_AXIS_MIN_VALUE}
              max={gridSliderSettings.Y_AXIS_MAX_VALUE}
              value={gridOptions.y}
              onChange={handleSliderY}
              orientation={'vertical'}
              track={false}
            />
          </div>
        </div>
        <div className={classes.sliederDownContainer}>
          <div style={getSliderDownStyle(areaProps)}>
            <Slider
              step={gridSliderSettings.STEP}
              min={gridSliderSettings.X_AXIS_MIN_VALUE}
              max={gridSliderSettings.X_AXIS_MAX_VALUE}
              value={gridOptions.x}
              onChange={handleSliderX}
              track={false}
            />
          </div>
          <div className={classes.buttonContainer}>
            <IconButton className={classes.centerButton} onClick={scaleButton}>
              <ZoomInIcon fontSize={'small'} />
            </IconButton>
            <IconButton className={classes.centerButton} onClick={handleCenterButton}>
              <FilterCenterFocusIcon fontSize={'small'} />
            </IconButton>
          </div>
        </div>
        <EditResourceFromFactoryPopper
          isOpen={openEditModal}
          anchorEl={anchorEl}
          onMove={handleOnMove}
          onDelete={handleOnDelete}
          onClose={handleOnCloseEditModal}
          idFactoryResource={selectedFactoryResourceId}
          selectedGameFactoryResourceData={selectedGameFactoryResourceData}
          onReload={handleOnReload}
        />
        {openScale && (
          <ClickAwayListener onClickAway={handleScaleClose}>
            <div className={clsx(classes.scaleContainer, { [classes.scaleContainerHidden]: !openScale })}>
              <Slider
                className={classes.scale}
                step={1}
                min={-10}
                max={10}
                value={scale || 0}
                onChange={handleScaleSlider}
                onChangeCommitted={handleSaveScale}
                track={false}
                orientation={'vertical'}
              />
            </div>
          </ClickAwayListener>
        )}
        <Snackbar
          open={showCollisionNotification}
          autoHideDuration={2000}
          message={'Brak wystarczającej powierzchni do umieszczenia zasobu'}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          onClose={handleCollisionSnackbarClose}
        >
          <div className={classes.notification}>Brak wystarczającej powierzchni do umieszczenia zasobu</div>
        </Snackbar>
      </div>
    </ClickAwayListener>
  );
}
