import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  appBar: {
    position: 'relative',
    zIndex: 1300,
    backgroundColor: '#535EDB',
  },
  toolbar: {
    minHeight: 50,
  },
  title: {
    display: 'flex',
    flexGrow: 1,
    textAlign: 'left',
    paddingLeft: 15,
    fontWeight: 'bold',
    fontSize: 18,
  },
  menuIcon: {
    marginLeft: -20,
  },
  logoutButton: {
    color: '#fff',
    textDecoration: 'none',
    fontWeight: 'bold',
  },
  userName: {
    marginRight: 15,
    fontSize: 13,
    color: '#c3c7f2',
  },
  accountIcon: {
    color: '#c3c7f2',
    marginRight: 20,
  },
  logo: {
    marginLeft: 40,
    marginTop: -3,
    width: 21,
    height: 21,
    '& polygon': {
      fill: '#fff',
    },
  },
});
