import React, { useEffect, useState } from 'react';
import Timer, { TimerControls } from 'react-compound-timer';
import AlarmIcon from '@material-ui/icons/Alarm';

import { GameHeaderTimeInterface } from './GameHeaderTime.interface';

import { useStyles } from './GameHeaderTime.styles';

export function GameHeaderTime(props: GameHeaderTimeInterface) {
  const classes = useStyles();
  const { timeLeftInMillis, onTimeLeft } = props;
  const alertTimeInMilis = 60000;
  const [timerTextStyle, setTimerTextStyle] = useState(classes.normalTimer);

  useEffect(() => {
    setTimerTextStyle(timeLeftInMillis <= alertTimeInMilis ? classes.alertTimer : classes.normalTimer);
  }, [timeLeftInMillis, classes.alertTimer, classes.normalTimer]);

  return (
    <div className={classes.root}>
      <AlarmIcon className={classes.alarmIcon} />
      <Timer
        formatValue={(value) => `${value < 10 ? `0${value}` : value}`}
        initialTime={timeLeftInMillis}
        direction="backward"
        checkpoints={[
          {
            time: 0,
            callback: () => onTimeLeft(),
          },
          {
            time: 60000,
            callback: () => setTimerTextStyle(classes.alertTimer),
          },
        ]}
      >
        {({ getTime }: TimerControls) => (
          <>
            <span className={timerTextStyle}>
              {getTime() > 3600000 && (
                <>
                  <Timer.Hours />
                  {':'}
                </>
              )}
              <Timer.Minutes />
              {':'}
              <Timer.Seconds />
            </span>
          </>
        )}
      </Timer>
    </div>
  );
}
