import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';

import { canEdit } from 'app/shared/helpers/canEdit';
import { GameGanttOrderService } from 'app/services/gameGanttOrder';
import { CustomDialog } from 'app/components/CustomDialog/CustomDialog';
import { getGameGantt, getGameMaterials } from 'app/store/userRoom';
import { fetchGanttOrders } from 'app/store/gantt';
import { GanttGraphProps } from './GanttGraph.interface';
import { useStyles } from './GanttGraph.styles';
import { GameGanttOrderInterface } from 'app/shared/interfaces/GameGanttOrder.interface';
import { UpdateGameGanttOrderModal } from '../UpdateGameGanttOrderModal/UpdateGameGanttOrderModal';
import { getIsUserGameMaster } from 'app/store/user';

export function GanttGraph(props: GanttGraphProps) {
  const { numberOfCell, offsetX, offsetY, ganttOrders } = props;
  const classes = useStyles(props);
  const dispatch = useDispatch();
  const gameGantt = useSelector(getGameGantt);
  const gameMaterials = useSelector(getGameMaterials);

  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [openUpdateDialog, setOpenUpdateDialog] = useState<boolean>(false);
  const [activeOrderId, setActiveOrderId] = useState<number>(-1);
  const isUserGameMaster = useSelector(getIsUserGameMaster);

  const createGraph = (): Array<JSX.Element> => {
    const graph: Array<JSX.Element> = [];

    for (let x = 0; x < numberOfCell; x++) {
      graph.push(<div key={x} className={classes.ganttCell} />);
    }

    return graph;
  };

  const createLegend = (): Array<JSX.Element> => {
    const legend: Array<JSX.Element> = [];

    legend.push(<div key={-1} className={classes.legendCell} />);
    for (let x = 0; x < numberOfCell; x++) {
      legend.push(
        <div key={x} className={classes.legendCell}>
          {(x + 1 + offsetX) * 10}
        </div>
      );
    }
    legend.push(
      <div key={'min'} className={clsx(classes.legendCell, classes.legendMinCell)}>
        {'[min.]'}
      </div>
    );
    return legend;
  };

  const getProductName = (ganttOrder: GameGanttOrderInterface) => {
    return ganttOrder && ganttOrder.actualProcess && ganttOrder.actualProcess.productActual
      ? ganttOrder.actualProcess.productActual.name
      : '';
  };

  const getGameMaterial = (ganttOrder: GameGanttOrderInterface) => {
    if (gameMaterials && ganttOrder.gameMaterial) {
      const gameMaterial = gameMaterials.find((gameMaterial) =>
        gameMaterial.actualMaterial
          ? gameMaterial.actualMaterial.id === ganttOrder.gameMaterial.actualMaterial.id
          : false
      );
      return gameMaterial && gameMaterial.actualMaterial
        ? `${gameMaterial.actualMaterial.name}(${ganttOrder.gameMaterial.orderNumber})`
        : '';
    }
    return 'BRAK MATERIAŁU';
  };

  const handleOpenDeleteDialog = (id: number) => {
    setOpenDeleteDialog(true);
    setActiveOrderId(id);
  };

  const closeDeleteDialog = () => {
    setActiveOrderId(-1);
    setOpenDeleteDialog(false);
  };

  const closeUpdateDialog = () => {
    setActiveOrderId(-1);
    setOpenUpdateDialog(false);
  };

  const handleConfirmDeleteDialog = () => {
    GameGanttOrderService.deleteGameGanttOrder(activeOrderId).then(() => {
      dispatch(fetchGanttOrders(gameGantt ? gameGantt.id : -1));
      setActiveOrderId(-1);
      closeDeleteDialog();
    });
  };

  const handleOpenUpdateDialog = (id: number) => {
    setOpenUpdateDialog(true);
    setActiveOrderId(id);
  };

  const getGameGanttOrderAmount = () => {
    const order = ganttOrders && ganttOrders.find((order) => order.id === activeOrderId);
    return order ? order.amount : 0;
  };
  const getGameGanttOrderSaveToNextRound = () => {
    const order = ganttOrders && ganttOrders.find((order) => order.id === activeOrderId);
    return order ? order.saveToNextRound : 0;
  };
  const getGameGanttOrderMaterial = () => {
    const order = ganttOrders && ganttOrders.find((order) => order.id === activeOrderId);
    return order ? order?.gameMaterial?.id : 0;
  };

  const drawGraph = (): JSX.Element => {
    return (
      <div>
        <div className={classes.granttGraphUpContainer}>
          <div className={classes.graphLeftUpSide}>
            <div className={classes.ordersContainer}>
              {ganttOrders &&
                ganttOrders.map((order, index) =>
                  index >= offsetY && index - offsetY < 3 ? (
                    <div key={order.id} className={classes.orderLabelContainer}>
                      {!isUserGameMaster ? (
                        <div className={classes.labelButtons}>
                          <IconButton
                            className={classes.labelButton}
                            onClick={() => handleOpenUpdateDialog(order.id)}
                            disabled={!canEdit()}
                          >
                            <EditIcon fontSize={'small'} />
                          </IconButton>
                          <IconButton
                            className={classes.labelButton}
                            onClick={() => handleOpenDeleteDialog(order.id)}
                            disabled={!canEdit()}
                          >
                            <DeleteIcon fontSize={'small'} />
                          </IconButton>
                        </div>
                      ) : null}
                      <div className={classes.orderLabel}>
                        <div>
                          Zlecenie {index + 1} Ilość: {order.amount}
                        </div>
                        <div>Produkt: {getProductName(order)}</div>
                        {gameMaterials && order.gameMaterial && <div>Materiał: {getGameMaterial(order)}</div>}
                        {(!gameMaterials || !order.gameMaterial) && (
                          <div className={classes.missingMaterialWarning}>BRAK MATERIAŁU!</div>
                        )}
                      </div>
                    </div>
                  ) : null
                )}
            </div>
            <div className={classes.arrowUp} />
          </div>
          <div className={classes.graphRightUpSide}>{createGraph()}</div>
        </div>
        <div className={classes.ganttGraphDownContainer}>
          <div className={classes.graphLeftDownSide} />
          <div className={classes.graphRightDownSide}>{createLegend()}</div>
          <div className={classes.arrowRight} />
        </div>
        <CustomDialog
          title={'Usuwanie zlecenia'}
          body={<>{'Na pewno chcesz usunąć zlecenie?'}</>}
          isOpen={openDeleteDialog}
          onCloseDialog={closeDeleteDialog}
          dialogActions={
            <>
              <Button color={'primary'} onClick={handleConfirmDeleteDialog}>
                Usuń
              </Button>
              <Button color={'primary'} autoFocus onClick={closeDeleteDialog}>
                Anuluj
              </Button>
            </>
          }
        />
        {openUpdateDialog && (
          <UpdateGameGanttOrderModal
            onClose={closeUpdateDialog}
            gameGanttOrderId={activeOrderId}
            gameGanttId={gameGantt ? gameGantt.id : -1}
            amount={getGameGanttOrderAmount()}
            saveToNextRound={getGameGanttOrderSaveToNextRound()}
            gameMaterialId={getGameGanttOrderMaterial()}
          />
        )}
      </div>
    );
  };

  return <>{drawGraph()}</>;
}
