import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Linkify from 'react-linkify';
import Snackbar from '@material-ui/core/Snackbar';

import { SnackbarAlert } from 'app/components/SnackbarAlert/SnackbarAlert';
import { handlingCatch } from 'app/shared/helpers/handlingCatch';
import { ScenarioSerivce } from 'app/services/scenario.service';
import { ScenarioInterface } from 'app/shared/interfaces/Scenario.interface';
import { getParams } from 'app/shared/helpers/pagination';
import { CustomIconButton } from 'app/components/CustomIconButton/CustomIconButton';
import { AddScenarioModal } from 'app/components/AddScenarioModal/AddScenarioModal';
import { CustomDialog } from 'app/components/CustomDialog/CustomDialog';
import { CustomTablePagination } from 'app/components/CustomTablePagination/CustomTablePagination';
import { BulletedList } from 'app/components/BulletedList/BulletedList';
import { scenarioOptions, scenarioValue } from 'app/shared/constants';
import { componentDecorator } from 'app/shared/helpers/linkify';

import { ScenariosTableProps } from './ScenariosTable.interfaces';
import { useStyles } from './ScenariosTable.styles';
import NumberFormat from 'react-number-format';

export function ScenariosTable(props: ScenariosTableProps) {
  const {
    scenarios,
    suppliers,
    resources,
    factories,
    processes,
    shouldUpdateScenarios,
    totalRecords,
    orders,
    distributionTemplate,
    addClick,
  } = props;
  const classes = useStyles();
  const [openAddDialog, setOpenAddDialog] = useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [scenarioData, setScenarioData] = useState<ScenarioInterface | null>(null);
  const [selectedScenarioId, setSelectedScenarioId] = useState<number>(0);
  const [selectedPage, setSelectedPage] = useState<number>(0);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [notificationText, setNotificationText] = useState<string>('');

  const handleSnackbarClose = () => {
    setShowNotification(false);
  };

  const handleAddScenario = () => {
    setScenarioData(null);
    setOpenAddDialog(true);
  };

  const handleOpenEditScenario = (id: number) => {
    const scenario = getScenarioById(id);
    if (scenario) {
      setScenarioData(scenario);
      setOpenAddDialog(true);
    }
  };

  const handleScenarioAdded = () => {
    shouldUpdateScenarios(getParams(selectedPage));
    setOpenAddDialog(false);
    setScenarioData(null);
  };

  const handleOpenDeleteScenario = (id: number) => {
    setSelectedScenarioId(id);
    setOpenDeleteDialog(true);
  };

  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
    setScenarioData(null);
  };

  const handleDeleteScenario = () => {
    ScenarioSerivce.deleteScenario(selectedScenarioId)
      .then(() => {
        shouldUpdateScenarios(getParams(selectedPage));
        setOpenDeleteDialog(false);
      })
      .catch((error) => {
        const response = handlingCatch(error.response);
        const { status } = error.response;
        if (status === 400) {
          setNotificationText(response.message);
          setShowNotification(true);
          setOpenDeleteDialog(false);
        }
      });
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const getScenarioById = (id: number) => scenarios.find((scenario) => scenario.id === id);

  const getScenarioNameById = (id: number) => {
    const scenario = getScenarioById(id);
    return scenario ? scenario.name : '';
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    shouldUpdateScenarios(getParams(page));
    setSelectedPage(page);
  };

  const getAutomationStatus = (scenario: ScenarioInterface) => {
    const scenarioOption = scenarioOptions.find((scenarioOption) => scenarioOption.value === scenario.automation);
    return scenarioOption ? scenarioOption.label : 'Brak Informacji';
  };

  const getProductDistribution = (scenario: ScenarioInterface) => {
    if (scenario.processScenarioTemplateDistributionTemplates) {
      return scenario.processScenarioTemplateDistributionTemplates.map((productDistribution) => {
        const product = processes.find((process) => process.product.id === productDistribution.process.product.id);
        const productName = product ? product.name : '';
        const distribution = distributionTemplate.find(
          (distribution) => distribution.id === productDistribution.distributionTemplate.id
        );
        const distributionName = distribution ? distribution.name : '';
        return {
          id: productDistribution.id,
          label: `${productName} / ${distributionName}`,
        };
      });
    }
    return [];
  };

  useEffect(() => {
    if (addClick) {
      handleAddScenario();
    }
  }, [addClick]);

  return (
    <div>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Nazwa</TableCell>
              <TableCell>Zawartość</TableCell>
              <TableCell align={'right'}>Gotówka [PLN]</TableCell>
              <TableCell align={'center'}>Współ-czynnik zmiany ceny</TableCell>
              <TableCell align={'center'}>Strata wartości [%]</TableCell>
              <TableCell align={'right'}>Koszt stały fabryki [PLN]</TableCell>
              <TableCell align={'right'}>Wskaźnik rundy kosztów stałych </TableCell>
              <TableCell>Typ scenariusza</TableCell>
              <TableCell>Dostawcy</TableCell>
              <TableCell>Procesy</TableCell>
              <TableCell>Zasoby</TableCell>
              <TableCell>Fabryka</TableCell>
              <TableCell>Produkty/Rozkłady</TableCell>
              <TableCell>Zamówienia</TableCell>
              <TableCell align={'right'} className={classes.buttonsTableCell} />
            </TableRow>
          </TableHead>
          <TableBody>
            {scenarios.map((scenario) => (
              <TableRow key={scenario.id}>
                <TableCell className={clsx(classes.scenarioName, classes.bold)}>{scenario.name}</TableCell>
                <TableCell className={clsx(classes.scenarioContent, classes.pre)}>
                  <Linkify componentDecorator={componentDecorator}>{scenario.content}</Linkify>
                </TableCell>
                <TableCell align={'right'} className={classes.cashCell}>
                  <NumberFormat
                    displayType={'text'}
                    value={scenario.cash}
                    fixedDecimalScale
                    decimalScale={2}
                    thousandSeparator={' '}
                  />
                </TableCell>
                <TableCell align={'center'}>{scenario.priceChangeFactor}</TableCell>
                <TableCell align={'center'}>{scenario.lossRatio}</TableCell>
                <TableCell align={'right'}>
                  {scenario.automation === scenarioValue.AUTOMATION_ON ? (
                    <NumberFormat
                      displayType={'text'}
                      value={scenario.factoryUnitCost}
                      fixedDecimalScale
                      decimalScale={2}
                      thousandSeparator={' '}
                    />
                  ) : (
                    ''
                  )}
                </TableCell>
                <TableCell align={'right'}>
                  {scenario.automation === scenarioValue.AUTOMATION_ON ? scenario.numberOfRoundToReduceBalance : ''}
                </TableCell>
                <TableCell>{getAutomationStatus(scenario)}</TableCell>
                <TableCell className={classes.supplierCell}>
                  <BulletedList
                    list={scenario.scenarioTemplateSuppliers.map((supplier) => ({
                      id: supplier.supplier.id,
                      label: supplier.supplier.name,
                    }))}
                  />
                </TableCell>
                <TableCell>
                  <BulletedList list={scenario.processes.map((process) => ({ id: process.id, label: process.name }))} />
                </TableCell>
                <TableCell className={classes.resourceCell}>
                  <BulletedList
                    list={scenario.resources.map((resource) => ({ id: resource.id, label: resource.name }))}
                  />
                </TableCell>
                <TableCell>
                  <BulletedList list={scenario.factories.map((factory) => ({ id: factory.id, label: factory.name }))} />
                </TableCell>
                <TableCell>
                  <BulletedList list={getProductDistribution(scenario)} />
                </TableCell>
                <TableCell>
                  <BulletedList list={scenario.orders.map((order) => ({ id: order.id, label: order.name }))} />
                </TableCell>
                <TableCell align={'right'} className={classes.buttonsTableCell}>
                  <CustomIconButton onClick={() => handleOpenEditScenario(scenario.id)} type={'edit'} />
                  <CustomIconButton onClick={() => handleOpenDeleteScenario(scenario.id)} type={'delete'} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <CustomTablePagination
            tablePaginationConfig={{
              count: totalRecords,
              page: selectedPage,
            }}
            onChangePage={handleChangePage}
          />
        </Table>
      </TableContainer>
      <CustomDialog
        title={'Usuwanie scenariusza'}
        onCloseDialog={handleCloseDeleteDialog}
        body={
          <>
            {'Na pewno chcesz usunąć scenariusz: '}
            {getScenarioNameById(selectedScenarioId)}
          </>
        }
        isOpen={openDeleteDialog}
        dialogActions={
          <>
            <Button color={'primary'} onClick={handleDeleteScenario}>
              Tak
            </Button>
            <Button color={'primary'} autoFocus onClick={handleCloseDeleteDialog}>
              Nie
            </Button>
          </>
        }
      />
      <AddScenarioModal
        isOpen={openAddDialog}
        onClose={handleCloseAddDialog}
        scenario={scenarioData}
        suppliers={suppliers}
        resources={resources}
        factories={factories}
        processes={processes}
        distributionTemplate={distributionTemplate}
        orders={orders}
        scenarioAdded={handleScenarioAdded}
      />
      <Snackbar
        open={showNotification}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={handleSnackbarClose}
      >
        <SnackbarAlert message={notificationText} />
      </Snackbar>
    </div>
  );
}
