import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0 20px',
  },
  title: {
    color: '#B4B4B4',
    marginBottom: 5,
  },
  value: {
    color: '#767577',
    fontWeight: 'bold',
  },
  infoIcon: {
    color: '#535EDB',
  },
}));
