import React from 'react';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';

import { SelectComponentProps } from './SelectComponent.interfaces';
import { getSelectErrorProp, SelectProperites } from 'app/shared/helpers/selectValidator';

export function SelectComponent(props: SelectComponentProps) {
  const {
    fullWidth,
    options,
    form: { errors, touched },
    field,
    onChange,
  } = props;

  const selectProp = onChange ? { ...SelectProperites(props), onChange } : { ...SelectProperites(props) };
  const { fieldError, showError } = getSelectErrorProp(field, errors, touched);
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 300,
        marginTop: 33,
      },
    },
    getContentAnchorEl: null,
  };

  return (
    <FormControl fullWidth={fullWidth} error={showError}>
      <InputLabel>{props.label}</InputLabel>
      <Select {...selectProp} input={<Input />} MenuProps={MenuProps}>
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value} disabled={option.disabled}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{showError ? fieldError : ''}</FormHelperText>
    </FormControl>
  );
}
