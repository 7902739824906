import { makeStyles, Theme } from '@material-ui/core';

const drawerWidth = 260;

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  paper: {
    overflow: 'visible',
    boxShadow: '0px 0px 8px #ccc',
  },
  drawerOpen: {
    width: drawerWidth,
  },
  drawerClose: {
    overflowX: 'hidden',
    width: 65,
    [theme.breakpoints.up('sm')]: {
      width: 65,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  nested: {
    paddingLeft: theme.spacing(3),
  },
  list: {
    marginTop: 50,
  },
  submenuIcon: {
    color: '#c3c7f2',
    '& span': {
      color: '#939ae8',
    },
  },
  submenuIconActive: {
    color: '#535edb',
    '& span': {
      color: '#535edb',
      fontWeight: 'bold',
    },
  },
  menuIcon: {
    color: '#b4b4b4',
  },
  menuIconActive: {
    color: '#767577',
    '& span': {
      fontWeight: 'bold',
    },
  },
  link: {
    textDecoration: 'none',
  },
  closeButton: {
    position: 'absolute',
    right: -16,
    zIndex: 1500,
    bottom: 200,
    border: '1px solid #ccc',
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#f7f7f7',
    },
  },
  closeButtonHidden: {
    display: 'none',
  },
}));
