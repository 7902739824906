import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  root: {
    maxHeight: 300,
    overflow: 'auto',
  },
  tableHead: {
    fontWeight: 'bold',
  },
  tableCell: {
    padding: '5px 20px',
  },
}));
