import { FieldInputProps, FormikErrors, FormikTouched } from 'formik/dist/types';
import { FieldProps, getIn } from 'formik';
import { SelectProps } from '@material-ui/core/Select';

interface SelectComponentProps extends FieldProps, Omit<SelectProps, 'name' | 'value'> {
  options: Array<{ label: string; value: string | number }>;
}

export const getSelectErrorProp = (
  field: FieldInputProps<any>,
  errors: FormikErrors<any>,
  touched: FormikTouched<any>
) => {
  const fieldError = getIn(errors, field.name);
  const showError = getIn(touched, field.name) && !!fieldError;
  return {
    fieldError,
    showError,
  };
};

export const SelectProperites = ({
  field,
  form: { isSubmitting, touched, errors },
  ...props
}: SelectComponentProps): SelectProps => {
  const { showError } = getSelectErrorProp(field, errors, touched);
  return {
    ...props,
    ...field,
    error: showError,
  };
};
