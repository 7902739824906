import React from 'react';

import { MenuInterface, SubmenuInterface } from 'app/shared/interfaces/Menu.interface';
import {
  AccountTree as AccountTreeIcon,
  Apps as AppsIcon,
  Assessment as AssessmentIcon,
  Assignment,
  Autorenew as AutorenewIcon,
  Ballot,
  Build as BuildIcon,
  DirectionsCar,
  Domain as DomainIcon,
  Extension as ExtensionIcon,
  Home,
  Layers,
  MeetingRoom as MeetingRoomIcon,
  PeopleAlt as PeopleAltIcon,
  Settings as SettingsIcon,
  ShoppingCart as ShoppingCartIcon,
  SportsEsports as SportsEsportsIcon,
  SportsHandball as SportsHandballIcon,
} from '@material-ui/icons';
import { roles, routes } from './constants';

export const menu: Array<MenuInterface> = [
  {
    label: 'Home',
    icon: <Home />,
    id: 0,
    link: routes.HOME.route,
    access: [roles.ROLE_GAME_MASTER],
  },
  {
    label: 'Materiały',
    icon: <Layers />,
    id: 1,
    access: [roles.ROLE_GAME_MASTER],
  },
  {
    label: 'Produkty',
    icon: <ExtensionIcon />,
    id: 2,
    access: [roles.ROLE_GAME_MASTER],
  },
  {
    label: 'Fabryki',
    icon: <DomainIcon />,
    id: 3,
    access: [roles.ROLE_GAME_MASTER],
  },
  {
    label: 'Zamówienia',
    icon: <ShoppingCartIcon />,
    id: 4,
    access: [roles.ROLE_GAME_MASTER],
  },
  {
    label: 'Rozgrywka',
    icon: <SportsEsportsIcon />,
    id: 5,
  },
  {
    label: 'Użytkownicy',
    icon: <PeopleAltIcon />,
    id: 6,
    access: [roles.ROLE_GAME_MASTER],
  },
];

export const submenu: Array<SubmenuInterface> = [
  {
    label: 'Materiały',
    icon: <Layers />,
    menuItemId: 1,
    link: routes.MATERIALS.route,
    access: routes.MATERIALS.access,
  },
  {
    label: 'Typy materiałów',
    icon: <AppsIcon />,
    menuItemId: 1,
    link: routes.MATERIAL_TYPES.route,
    access: routes.MATERIAL_TYPES.access,
  },
  {
    label: 'Dostawcy',
    icon: <DirectionsCar />,
    menuItemId: 1,
    link: routes.SUPPLIERS.route,
    access: routes.SUPPLIERS.access,
  },
  {
    label: 'Produkty',
    icon: <ExtensionIcon />,
    menuItemId: 2,
    link: routes.PRODUCTS.route,
    access: routes.PRODUCTS.access,
  },
  {
    label: 'Procesy',
    icon: <AutorenewIcon />,
    menuItemId: 2,
    link: routes.PROCESSES.route,
    access: routes.PROCESSES.access,
  },
  {
    label: 'Czynności',
    icon: <SportsHandballIcon />,
    menuItemId: 2,
    link: routes.ACTIONS.route,
    access: routes.ACTIONS.access,
  },
  {
    label: 'Typy czynności',
    icon: <AccountTreeIcon />,
    menuItemId: 2,
    link: routes.ACTION_TYPES.route,
    access: routes.ACTION_TYPES.access,
  },
  {
    label: 'Zamówienia',
    icon: <ShoppingCartIcon />,
    menuItemId: 4,
    link: routes.ORDERS.route,
    access: routes.ORDERS.access,
  },
  {
    label: 'Rozkłady',
    icon: <AssessmentIcon />,
    menuItemId: 4,
    link: routes.DISTRIBUTION_TEMPLATE.route,
    access: routes.DISTRIBUTION_TEMPLATE.access,
  },
  {
    label: 'Fabryki',
    icon: <DomainIcon />,
    menuItemId: 3,
    link: routes.FACTORIES.route,
    access: routes.FACTORIES.access,
  },
  {
    label: 'Gantt',
    icon: <Ballot />,
    menuItemId: 3,
    link: routes.GANTTS.route,
    access: routes.GANTTS.access,
  },
  {
    label: 'Zasoby produkcyjne',
    icon: <BuildIcon />,
    menuItemId: 3,
    link: routes.RESOURCES.route,
    access: routes.RESOURCES.access,
  },
  {
    label: 'Typy zasobów',
    icon: <SettingsIcon />,
    menuItemId: 3,
    link: routes.RESOURCE_TYPES.route,
    access: routes.RESOURCE_TYPES.access,
  },
  {
    label: 'Scenariusze',
    icon: <Assignment />,
    menuItemId: 5,
    link: routes.SCENARIOS.route,
    access: routes.SCENARIOS.access,
  },
  {
    label: 'Pokoje',
    icon: <MeetingRoomIcon />,
    menuItemId: 5,
    link: routes.ROOMS.route,
    access: routes.ROOMS.access,
  },
  {
    label: 'Pokoje zarchiwizowane',
    icon: <MeetingRoomIcon />,
    menuItemId: 5,
    link: routes.ROOMS_ARCHIVED.route,
    access: routes.ROOMS_ARCHIVED.access,
  },
  {
    label: 'Pokoje Gracza',
    icon: <MeetingRoomIcon />,
    menuItemId: 5,
    link: routes.PLAYER_ROOMS.route,
    access: routes.PLAYER_ROOMS.access,
  },
  {
    label: 'Użytkownicy',
    icon: <PeopleAltIcon />,
    menuItemId: 6,
    link: routes.USERS.route,
    access: routes.USERS.access,
  },
];
