import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    borderRadius: 5,
    marginBottom: 20,
  },
  actionWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  tableContainer: {
    maxHeight: 300,
    overflow: 'auto',
  },
  tableContainerHeight: {
    height: 300,
  },
  tableCell: {
    padding: '5px 20px',
  },
}));
