import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import NumberFormat from 'react-number-format';

import { GameHeaderRound } from 'app/components/GameHeaderRound/GameHeaderRound';
import { GameHeaderTime } from 'app/components/GameHeaderTime/GameHeaderTime';
import { GameHeaderWallet } from 'app/components/GameHeaderWallet/GameHeaderWallet';
import { GameHeaderMark } from 'app/components/GameHeaderMark/GameHeaderMark';
import { GameHeaderInterface } from './GameHeader.interface';
import { getIsAutomation } from 'app/store/userRoom';
import { TaskModal } from 'app/components/TaskModal/TaskModal';
import { GameHeaderWidget } from 'app/components/GameHeaderWidget/GameHeaderWidget';
import { UserRoomStatisticModal } from 'app/components/UserRoomStatisticModal/UserRoomStatisticModal';
import { getGameRounds } from 'app/store/gameRounds';

import { useStyles } from './GameHeader.styles';

export function GameHeader({
  selectedUserRoom,
  latestUserRoom,
  updateRoom,
  onTimeLeft,
  timeLeftInMillis,
  gamePlayerRankings,
  showGantt,
  isFactory,
  actualScenario,
}: GameHeaderInterface) {
  const classes = useStyles();
  const gameRounds = useSelector(getGameRounds);
  const isAutomation = useSelector(getIsAutomation);
  const [showStatistic, setShowStatistic] = useState<boolean>(false);
  const [isOpenTaskModal, setIsOpenTaskModal] = useState<boolean>(false);

  const handleOpenShowStatistic = () => setShowStatistic(true);
  const handleCloseShowStatistic = () => setShowStatistic(false);
  const handleOpenTaskModal = () => setIsOpenTaskModal(true);
  const handleCloseTaskModal = () => setIsOpenTaskModal(false);

  return (
    <div className={classes.mainContainer}>
      <div className={classes.buttonContainer}>
        <div className={classes.title}>{isFactory ? 'Fabryka' : 'Gantt'}</div>
        <Button
          color={'primary'}
          variant={'contained'}
          className={classes.button}
          endIcon={<ArrowForwardIosIcon />}
          onClick={() => showGantt(true)}
        >
          {isFactory ? 'Gantt' : 'Fabryka'}
        </Button>
        <div className={classes.buttons}>
          {isAutomation && (
            <Button color={'primary'} size={'small'} onClick={handleOpenShowStatistic}>
              Statystyki
            </Button>
          )}
          <Button color={'primary'} size={'small'} onClick={handleOpenTaskModal}>
            Treść zadania
          </Button>
        </div>
      </div>
      <div className={classes.widgetContainer}>
        <GameHeaderWidget title={'W rankingu'} value={selectedUserRoom.currentRankingPosition} />
        <GameHeaderWidget
          title={'Wartość firmy'}
          value={
            <NumberFormat
              displayType={'text'}
              value={selectedUserRoom.currentCompanyValue}
              fixedDecimalScale
              decimalScale={2}
              thousandSeparator={' '}
              suffix={' PLN'}
            />
          }
        />
        <GameHeaderMark userRoom={latestUserRoom} updateRoom={updateRoom} />
        <GameHeaderTime timeLeftInMillis={timeLeftInMillis} onTimeLeft={onTimeLeft} />
        <GameHeaderRound
          isAutomation={isAutomation}
          room={selectedUserRoom.room ? selectedUserRoom.room : null}
          updateRoom={updateRoom}
        />
        <GameHeaderWallet cashBalance={selectedUserRoom.cashBalance} />
      </div>
      <UserRoomStatisticModal
        gamePlayerRankings={gamePlayerRankings}
        gameRounds={gameRounds}
        selectedUserRoom={selectedUserRoom}
        isOpen={showStatistic}
        onClose={handleCloseShowStatistic}
      />
      <TaskModal isOpen={isOpenTaskModal} onClose={handleCloseTaskModal} actualScenario={actualScenario} />
    </div>
  );
}
