import { AxiosPromise } from 'axios';

import { ApiService } from './api.service';
import { SupplierInterface, SupplierResponse } from 'app/shared/interfaces/Supplier.interface';
import { GetParamsInterface } from 'app/shared/interfaces/RequestInterface.interface';

export const SupplierService = {
  getSuppliers(params: GetParamsInterface = {}): AxiosPromise<SupplierResponse> {
    return ApiService.get('suppliers', params);
  },

  addSupplier(data: Omit<SupplierInterface, 'id'>): AxiosPromise {
    return ApiService.post('suppliers', data);
  },

  replaceSupplier(id: number, data: Omit<SupplierInterface, 'id'>): AxiosPromise {
    return ApiService.put(`suppliers/${id}`, data);
  },

  deleteSupplier(id: number): AxiosPromise {
    return ApiService.delete(`suppliers/${id}`);
  },
};
