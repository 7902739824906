import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';

import { ActionInterface } from 'app/shared/interfaces/Action.interface';
import { ResourceInterface } from 'app/shared/interfaces/Resource.interface';
import { ActionTypeInterface } from 'app/shared/interfaces/ActionType.interface';
import { ActionService } from 'app/services/action.service';
import { HeaderButton } from 'app/components/HeaderButton/HeaderButton';
import { ActionTypeService } from 'app/services/actionType.service';
import { ResourceService } from 'app/services/resource.service';
import { ActionsTable } from 'app/components/ActionsTable/ActionsTable';
import { GetParamsInterface } from 'app/shared/interfaces/RequestInterface.interface';
import { defaultParams } from 'app/shared/helpers/pagination';

import { useStyles } from './Actions.styles';

function ActionsPage() {
  const classes = useStyles();
  const [actions, setActions] = useState<Array<ActionInterface>>([]);
  const [resources, setResources] = useState<Array<ResourceInterface>>([]);
  const [actionTypes, setActionType] = useState<Array<ActionTypeInterface>>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [addButtonClicked, setAddButtonClicked] = useState<boolean>(false);

  const getActions = (params: GetParamsInterface = defaultParams) => {
    ActionService.getActions(params).then(
      ({ data: { 'hydra:member': responseData, 'hydra:totalItems': totalRecords } }) => {
        setActions(responseData);
        setTotalRecords(totalRecords);
      }
    );
  };

  const getActionTypes = () => {
    ActionTypeService.getActionTypes().then(({ data: { 'hydra:member': responseData } }) => {
      setActionType(responseData);
    });
  };

  const getResources = () => {
    ResourceService.getResources().then(({ data: { 'hydra:member': responseData } }) => {
      setResources(responseData);
    });
  };

  const handleAddButton = () => setAddButtonClicked(true);

  useEffect(() => {
    getActions();
    getResources();
    getActionTypes();
  }, []);

  useEffect(() => {
    if (addButtonClicked) {
      setAddButtonClicked(false);
    }
  }, [addButtonClicked]);

  return (
    <div className={classes.root}>
      <Typography variant={'h1'}>
        <span>Czynności</span>
        <div>
          <HeaderButton onClick={handleAddButton} label={'Dodaj czynność'} />
        </div>
      </Typography>
      <ActionsTable
        actionTypes={actionTypes}
        resources={resources}
        actions={actions}
        totalRecords={totalRecords}
        shouldUpdateActions={getActions}
        addClick={addButtonClicked}
      />
    </div>
  );
}

export default ActionsPage;
