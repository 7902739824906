import { AppThunk } from 'app/store/store';

import { setCurrentUser } from './actions';
import AuthService from 'app/services/auth.service';

export const fetchCurrentUser = (): AppThunk => (dispatch) => {
  AuthService.getCurrentUser().then(({ data }) => {
    dispatch(setCurrentUser(data));
  });
};
