import React from 'react';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

import { RadioGroupComponentProps } from './RadioGroupComponent.interfaces';
import { getSelectErrorProp } from 'app/shared/helpers/selectValidator';

export const RadioGroupComponent = ({
  field,
  form: { touched, errors },
  name,
  options,
  label,
  ...props
}: RadioGroupComponentProps) => {
  const fieldName = name || field.name;

  const { fieldError, showError } = getSelectErrorProp(field, errors, touched);
  return (
    <FormControl component="fieldset" error={showError}>
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup {...field} {...props} name={fieldName}>
        {options.map((option) => (
          <FormControlLabel key={option.value} value={option.value} control={<Radio />} label={option.label} />
        ))}
      </RadioGroup>
      <FormHelperText>{showError ? fieldError : ''}</FormHelperText>
    </FormControl>
  );
};
