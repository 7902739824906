import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import TableFooter from '@material-ui/core/TableFooter';

import { CustomTablePaginationProps } from './CustomTablePagination.inteface';
import { TablePaginationActions } from 'app/components/TablePaginationActions/TablePaginationActions';

import { useStyles } from './CustomTablePagination.styles';

const defaultRowsPerPage = 20;

export const CustomTablePagination = (props: CustomTablePaginationProps) => {
  const classes = useStyles();

  const {
    tablePaginationConfig: { count, page, rowsPerPage = defaultRowsPerPage, colSpan },
    onChangePage,
    onChangeRowsPerPage,
  } = props;

  return (
    <TableFooter>
      <TableRow>
        <TablePagination
          rowsPerPageOptions={[]}
          colSpan={colSpan}
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: { 'aria-label': 'Wierszy na stronę' },
            native: true,
          }}
          classes={{
            toolbar: classes.toolbar,
            spacer: classes.spacer,
            caption: classes.caption,
          }}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </TableRow>
    </TableFooter>
  );
};
