import { AxiosPromise } from 'axios';

import { ApiService } from './api.service';
import { AddGameGanttOrderInterface, GameGanttOrderResponse, GameGanttOrderReplaceInterface } from 'app/shared/interfaces/GameGanttOrder.interface';

export const GameGanttOrderService = {
  addGameGanttOrder(data: AddGameGanttOrderInterface): AxiosPromise {
    return ApiService.post('game_gantt_orders', data);
  },

  getGameGanttOrders(id: number): AxiosPromise<GameGanttOrderResponse> {
    return ApiService.get(`game_gantts/${id}/game_gantt_orders`);
  },

  updateGameGanttOrder(id: number, data: GameGanttOrderReplaceInterface): AxiosPromise {
    return ApiService.put(`game_gantt_orders/${id}`, data);
  },

  deleteGameGanttOrder(id: number): AxiosPromise {
    return ApiService.delete(`game_gantt_orders/${id}`);
  },
};
