import { AxiosPromise } from 'axios';

import { ApiService } from './api.service';
import {
  GanttTemplateItemInterface,
  MoveAllGanttTemplateItems,
} from 'app/shared/interfaces/GanttTemplateItem.interface';

export const GanttTemplateItemService = {
  addGanttTemplateItem(data: Omit<GanttTemplateItemInterface, 'id'>): AxiosPromise {
    return ApiService.post('gantt_template_items', data);
  },

  replaceGanttTemplateItem(
    id: number,
    data: Omit<GanttTemplateItemInterface, 'id' | 'ganttTemplate' | 'action'>
  ): AxiosPromise {
    return ApiService.put(`gantt_template_items/${id}`, data);
  },

  deleteGanttTemplateItem(id: number): AxiosPromise {
    return ApiService.delete(`gantt_template_items/${id}`);
  },

  moveAllGanttTemplateItems(id: number, data: MoveAllGanttTemplateItems): AxiosPromise {
    return ApiService.put(`gantt_template_items/${id}`, data);
  },
};
