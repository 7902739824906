import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Paper } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { useStyles } from './RegisterPage.styles';
import AuthService from 'app/services/auth.service';

import { TextFieldComponent } from 'app/components/TextFieldComponent/TextFieldComponent';
import { CustomDialog } from 'app/components/CustomDialog/CustomDialog';
import logoUE from 'app/shared/images/logo_ue.jpg';

function RegisterPage() {
  const classes = useStyles();
  const history = useHistory();
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);

  const handleClickDialog = () => {
    setOpenDeleteDialog(false);
    history.push('/login');
  };
  return (
    <div className={classes.registerPageContainer}>
      <div className={classes.containerItems}>
        <Paper className={classes.paper}>
          <Typography variant={'h1'}>Rejestracja</Typography>
          <div>
            <span>
              Masz konto? <Link to={'/login'}>Zaloguj się</Link>
            </span>
          </div>
          <Formik
            initialValues={{
              email: '',
              password: '',
              passwordConfirmation: '',
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email('Błędny format maila').required('Podaj adres email'),
              password: Yup.string().min(8, 'Hasło powinno zawierać minimalnie 8 znaków').required('Podaj hasło'),
              passwordConfirmation: Yup.string()
                .oneOf([Yup.ref('password'), null], 'Hasła nie mogą różnić się od siebie')
                .required('Powtórz hasło'),
            })}
            onSubmit={(values, actions) => {
              AuthService.register(values)
                .then((tokenResponse) => {
                  AuthService.setToken(tokenResponse?.data.token);
                  setOpenDeleteDialog(true);
                })
                .catch((error) => {
                  const { status } = error.response;
                  if (status === 409) {
                    actions.setFieldError('email', 'E-mail jest już zajęty');
                  } else {
                    actions.setFieldError('passwordConfirmation', 'Problem z rejestracją');
                  }
                });
            }}
          >
            <Form>
              <Field component={TextFieldComponent} name={'email'} label={'Email'} fullWidth />
              <Field component={TextFieldComponent} name={'password'} type={'password'} label={'Hasło'} fullWidth />
              <Field
                component={TextFieldComponent}
                name={'passwordConfirmation'}
                type={'password'}
                label={'Powtórz hasło'}
                fullWidth
              />
              <Button color={'primary'} type={'submit'}>
                Zarejestruj
              </Button>
            </Form>
          </Formik>
          <CustomDialog
            title={'Aktywacja Konta'}
            body={<>{'W celu aktywowania konta skontaktuj się z administratorem.'}</>}
            isOpen={openDeleteDialog}
            dialogActions={
              <>
                <Button color={'primary'} onClick={handleClickDialog}>
                  ok
                </Button>
              </>
            }
          />
        </Paper>
      </div>
      <div className={classes.containerItems}>
        <img src={logoUE} alt={'logo_ue'} />
      </div>
    </div>
  );
}

export default RegisterPage;
