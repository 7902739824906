import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';

import { FactoryInterface } from 'app/shared/interfaces/Factory.interface';
import { GetParamsInterface } from 'app/shared/interfaces/RequestInterface.interface';
import { FactoryService } from 'app/services/factory.service';
import { HeaderButton } from 'app/components/HeaderButton/HeaderButton';
import { FactoriesTable } from 'app/components/FactoriesTable/FactoriesTable';
import { defaultParams } from 'app/shared/helpers/pagination';

import { useStyles } from './FactoriesPage.styles';

function FactoriesPage() {
  const classes = useStyles();
  const [factories, setFactories] = useState<Array<FactoryInterface>>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [addButtonClicked, setAddButtonClicked] = useState<boolean>(false);

  const getFactories = (params: GetParamsInterface = defaultParams) =>
    FactoryService.getFactories(params).then(
      ({ data: { 'hydra:member': responseData, 'hydra:totalItems': totalRecords } }) => {
        setFactories(responseData);
        setTotalRecords(totalRecords);
      }
    );

  const handleAddButton = () => setAddButtonClicked(true);

  useEffect(() => {
    getFactories();
  }, []);

  useEffect(() => {
    if (addButtonClicked) {
      setAddButtonClicked(false);
    }
  }, [addButtonClicked]);

  return (
    <div className={classes.root}>
      <Typography variant={'h1'}>
        <span>Fabryki</span>
        <div>
          <HeaderButton onClick={handleAddButton} label={'Dodaj fabrykę'} />
        </div>
      </Typography>
      <FactoriesTable
        factories={factories}
        totalRecords={totalRecords}
        shouldUpdateFactories={getFactories}
        addClick={addButtonClicked}
      />
    </div>
  );
}

export default FactoriesPage;
