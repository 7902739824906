import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  accordion: {
    marginBottom: 16,
  },
  accordionDetails: {
    width: '100%',
    padding: 0,
    display: 'block',
  },
  accordionSummaryExternal: {
    color: '#fff',
    backgroundColor: '#4f7eff',
    fontSize: 16,
    fontWeight: 'bold',
  },
  expandIconExternal: {
    color: '#fff',
  },
  accordionSummaryInternal: {
    color: '#535EDB',
    fontWeight: 'bold',
    fontSize: 16,
  },
  expandIconInternal: {
    color: '#535EDB',
  },
}));
