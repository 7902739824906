import React from 'react';
import Linkify from 'react-linkify';

import { TaskModalProps } from './TaskModal.interface';
import { CustomDialog } from 'app/components/CustomDialog/CustomDialog';
import { componentDecorator } from 'app/shared/helpers/linkify';

import { useStyles } from './TaskModal.styles';

export function TaskModal({ isOpen, onClose, actualScenario }: TaskModalProps) {
  const classes = useStyles();
  return (
    <CustomDialog
      title={'Zadanie'}
      maxWidth={'lg'}
      onCloseDialog={onClose}
      body={
        <Linkify componentDecorator={componentDecorator}>
          <div className={classes.scenarioContent}>{actualScenario?.content}</div>
        </Linkify>
      }
      isOpen={isOpen}
    />
  );
}
