import { RootState } from '../store';

export const getFactory = (state: RootState) => state.factory.selectedFactory;

export const getFactoryResources = (state: RootState) => getFactory(state).factoryResources;

export const getActiveRoundFactoryResources = (state: RootState) => state.factory.activeRoundFactoryResources;

export const getFactoryName = (state: RootState) => getFactory(state).name;

export const getFactoryId = (state: RootState) => getFactory(state).id;

export const getActiveFactoryResourceId = (state: RootState) => getFactory(state).activeFactoryResourceId;

export const getActiveResource = (state: RootState) => getFactory(state).activeResource;

export const getActualSupplier = (state: RootState) => getFactory(state).actualSupplier;

export const getGridOptions = (state: RootState) => state.factory.gridOptions;
