import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  notification: {
    backgroundColor: 'red',
    color: '#fff',
    fontWeight: 'bold',
    padding: 15,
    borderRadius: 10,
  },
});
