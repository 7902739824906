import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  expandIcon: {
    color: '#535EDB',
  },
  accordionSummary: {
    color: '#535EDB',
    fontWeight: 'bold',
    fontSize: 16,
  },
  accordionDetails: {
    width: '100%',
    padding: 0,
    display: 'block',
  },
}));
