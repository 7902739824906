import React from 'react';
import { SnackbarAlertProps } from './SnackbarAlert.interfaces';
import { useStyles } from './SnackbarAlert.styles';

export const SnackbarAlert = (props: SnackbarAlertProps) => {
  const classes = useStyles();

  const { message } = props;

  return <div className={classes.notification}>{message}</div>;
};
