import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';

import { SupplierService } from 'app/services/supplier.service';
import { MaterialService } from 'app/services/material.service';
import { HeaderButton } from 'app/components/HeaderButton/HeaderButton';
import { SuppliersTable } from 'app/components/SuppliersTable/SuppliersTable';
import { SupplierInterface } from 'app/shared/interfaces/Supplier.interface';
import { MaterialInterface } from 'app/shared/interfaces/Material.interface';
import { GetParamsInterface } from 'app/shared/interfaces/RequestInterface.interface';
import { defaultParams } from 'app/shared/helpers/pagination';

import { useStyles } from './SuppliersPage.styles';

function SuppliersPage() {
  const classes = useStyles();
  const [suppliers, setSuppliers] = useState<Array<SupplierInterface>>([]);
  const [materials, setMaterials] = useState<Array<MaterialInterface>>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [addButtonClicked, setAddButtonClicked] = useState<boolean>(false);

  const getSuppliers = (params: GetParamsInterface = defaultParams) => {
    SupplierService.getSuppliers(params).then(
      ({ data: { 'hydra:member': responseData, 'hydra:totalItems': totalRecords } }) => {
        setSuppliers(responseData);
        setTotalRecords(totalRecords);
      }
    );
  };

  const getMaterials = () => {
    MaterialService.getMaterials().then(({ data: { 'hydra:member': responseData } }) => {
      setMaterials(responseData);
    });
  };

  const handleAddButton = () => setAddButtonClicked(true);

  useEffect(() => {
    getSuppliers();
    getMaterials();
  }, []);

  useEffect(() => {
    if (addButtonClicked) {
      setAddButtonClicked(false);
    }
  }, [addButtonClicked]);

  return (
    <div className={classes.root}>
      <Typography variant={'h1'}>
        <span>Dostawcy</span>
        <div>
          <HeaderButton onClick={handleAddButton} label={'Dodaj dostawcę'} />
        </div>
      </Typography>
      <SuppliersTable
        shouldUpdateSuppliers={getSuppliers}
        suppliers={suppliers}
        materials={materials}
        totalRecords={totalRecords}
        addClick={addButtonClicked}
      />
    </div>
  );
}

export default SuppliersPage;
