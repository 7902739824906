import { RootState } from 'app/store/store';
import { userRoomGameStatus, resourceTypeForCreateRelationWithProduction } from 'app/shared/constants';

export const getLatestUserRoom = (state: RootState) => state.userRoom.latestRoundState;

export const getCurrentRoundNumber = (state: RootState) => getLatestUserRoom(state).room?.currentRound || 0;

export const getUserRoom = (state: RootState) => state.userRoom;

export const getUser = (state: RootState) => state.user;

export const getSelectedUserRoom = (state: RootState) => getUserRoom(state).selectedRoundState;

export const getUserRoomId = (state: RootState) => getSelectedUserRoom(state).id;

export const getGameMaterials = (state: RootState) => getSelectedUserRoom(state).gameMaterials;

export const getGameMaterialsQueues = (state: RootState) => getSelectedUserRoom(state).gameMaterialQueues;

export const getGameProcesses = (state: RootState) => getSelectedUserRoom(state).gameProcesses;

export const getGameActivities = (state: RootState) => getSelectedUserRoom(state).gameActivities;

export const getGameProducts = (state: RootState) => getSelectedUserRoom(state).gameProducts;

export const getAvailableGameProducts = (state: RootState) =>
  getGameProducts(state).filter((gameProduct) => gameProduct.warehouseAmount > 0);

export const getActivitiesActualFromGameActivities = (state: RootState) =>
  getGameActivities(state).map((gameActivity) => gameActivity.actualActivity);

export const getGameOrders = (state: RootState) => getSelectedUserRoom(state).gameOrders;

export const getRoom = (state: RootState) => getSelectedUserRoom(state).room;

export const getGameFactory = (state: RootState) => getSelectedUserRoom(state).gameFactory;

export const getGameFactoryResource = (state: RootState) =>
  getSelectedUserRoom(state).gameFactory?.gameFactoryResources;

export const getGameFactoryResourceForProductionModal = (state: RootState) => {
  const gameFactoryResources = getGameFactoryResource(state)?.filter((gameFactoryResource) => {
    if (gameFactoryResource.resourceActual?.resourceTypeActual?.name) {
      return resourceTypeForCreateRelationWithProduction.includes(
        gameFactoryResource.resourceActual?.resourceTypeActual?.name
      );
    }
    return false;
  });
  return gameFactoryResources ? gameFactoryResources : [];
};

export const getIsGamePending = (state: RootState) =>
  state.userRoom && state.userRoom.selectedRoundState.status === userRoomGameStatus.GAME_PENDING.status;

export const getLoadingRoom = (state: RootState) => state.userRoom.isLoading;

export const getGameGantt = (state: RootState) => getSelectedUserRoom(state).gameGantt;

export const getIsActiveRound = (state: RootState) => state.userRoom.isActiveRound;

export const getIsGameActive = (state: RootState) =>
  getLatestUserRoom(state)?.room?.status === userRoomGameStatus.GAME_PENDING.status;

export const getIsAutomation = (state: RootState) => getLatestUserRoom(state)?.room?.scenarioTemplate?.automation === 1;
