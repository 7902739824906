import { AxiosPromise } from 'axios';

import { ApiService } from './api.service';
import { UserChangeInterface, UserInterface, UserResponse } from 'app/shared/interfaces/User.interface';
import { GetParamsInterface } from 'app/shared/interfaces/RequestInterface.interface';

export const UserService = {
  getUsers(params: GetParamsInterface = {}): AxiosPromise<UserResponse> {
    return ApiService.get('users', params);
  },
  addUser(data: Omit<UserInterface, 'id' | 'roles'>): AxiosPromise {
    return ApiService.post('users', data);
  },
  replaceUser(id: number, data: Omit<UserInterface, 'id' | 'roles'>): AxiosPromise {
    return ApiService.put(`users/${id}`, data);
  },
  replacePassword(id: number, data: Omit<UserChangeInterface, 'id' | 'roles'>): AxiosPromise {
    return ApiService.put(`user/${id}/reset-password`, data);
  },
  deleteUser(id: number): AxiosPromise {
    return ApiService.delete(`users/${id}`);
  },
};
