import { AxiosPromise } from 'axios';

import { ApiService } from './api.service';
import { GameProcessInterface } from 'app/shared/interfaces/GameProcess.interface';

export const GameProcessService = {
  buyProcesses(data: Omit<GameProcessInterface, 'id'>): AxiosPromise {
    return ApiService.post('game_processes', data);
  },
};
