import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  ganttWithSliderContainer: {
    display: 'flex',
  },
  ganttGraphContainer: {
    position: 'relative',
  },
  ganttOrdersContainer: {
    position: 'absolute',
    width: 'calc(100% - 332px)',
    top: 20,
    left: 315,
    height: 280,
    overflow: 'hidden',
  },
  ganttOrderContainer: {
    position: 'relative',
    marginBottom: 40,
    height: 40,
  },
  ganttOrdersContainerHidden: {
    display: 'none',
  },
  sliderXContainer: {
    marginLeft: 315,
  },
  sliderYContainer: {
    paddingBottom: 40,
  },
  tooltipRoot: {
    fontSize: 12,
    lineHeight: 1.5,
  },
  item: {
    position: 'absolute',
    height: '100%',
    backgroundColor: '#80ff80',
    border: '1px solid #000',
    cursor: 'pointer',
    padding: 1,
    wordBreak: 'break-word',
    fontSize: 9,
    userSelect: 'none',
  },
  itemBorder: {
    border: '2px solid red',
  },
  tmpItem: {
    display: 'none',
    position: 'absolute',
    height: 40,
    width: 40,
    backgroundColor: '#b3ecff',
    border: '1px solid #000',
    opacity: 0.7,
  },
  tmpItemText: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
