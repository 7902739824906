import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import clsx from 'clsx';
import Snackbar from "@material-ui/core/Snackbar";

import { GanttArea } from 'app/components/GanttArea/GanttArea';
import { GanttActions } from 'app/components/GanttActions/GanttActions';
import { ActionService } from 'app/services/action.service';
import { GanttTemplateItemService } from 'app/services/ganttTemplateItem.service';
import { ActionInterface } from 'app/shared/interfaces/Action.interface';
import { fetchGanttTemplateById, clearGanttTemplate, clearActiveAction } from 'app/store/gantt';
import { convertToInt } from 'app/shared/helpers/numberConverter';

import { useStyles } from './GanttPage.styles';

function GanttPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { ganttTemplateId } = useParams();
  const [actions, setActions] = useState<Array<ActionInterface>>([]);
  const [showActionSection, setShowActionSection] = useState<boolean>(false);
  const [showMove, setShowMove] = useState<boolean>(false);
  const [itemId, setItemId] = useState<number>(-1);
  const [move, setMove] = useState<string>('');
  const [validateMove, setValidateMove] = useState<boolean>(true);
  const [showNotification, setShowNotification] = useState<boolean>(false);

  const currentGanttTemplateId = ganttTemplateId && !isNaN(parseInt(ganttTemplateId)) ? parseInt(ganttTemplateId) : -1;

  const getActions = () =>
    ActionService.getActions().then(({ data: { 'hydra:member': responseData } }) => {
      setActions(responseData);
    });

  const getGanttTemplateById = useCallback(
    (id: number) => {
      dispatch(fetchGanttTemplateById(id));
    },
    [dispatch]
  );

  const handleSnackbarClose = () => {
    setShowNotification(false);
  };

  const handleCancelButton = () => {
    setShowActionSection(false);
    dispatch(clearActiveAction());
  };

  const handleShowActionSectionButton = () => {
    setShowActionSection(true);
    handleMoveAllCancel();
  };

  useEffect(() => {
    getActions();
    getGanttTemplateById(currentGanttTemplateId);
    return () => {
      dispatch(clearGanttTemplate());
    };
  }, [currentGanttTemplateId, getGanttTemplateById, dispatch]);

  const handleMoveAll = (id: number) => {
    setItemId(id);
    setShowMove(true);
    handleCancelButton();
  };

  const handleMoveAllCancel = () => {
    if (showMove) {
      setShowMove(false);
      setItemId(-1);
      setMove('');
      setValidateMove(true);
    }
  };

  const handleMoveChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const reg = /^-?\d+$/;
    const test = reg.test(e.target.value);
    setMove(e.target.value);
    if (test) {
      setValidateMove(true);
    } else {
      setValidateMove(false);
    }
  };

  const handleMove = () => {
    GanttTemplateItemService.moveAllGanttTemplateItems(itemId, {
      groupShift: convertToInt(move),
    }).then(() => {
      dispatch(fetchGanttTemplateById(currentGanttTemplateId));
    }).catch(() => {
      setShowNotification(true);
    });
    setShowMove(false);
    setMove('');
    setItemId(-1);
  };

  return (
    <div>
      <Typography variant={'h1'}>
        <span>Gantt</span>
        <div>
          <Link to={'/gantts'} className={classes.link}>
            <Button color={'primary'} variant={'contained'} className={classes.headerButton}>
              Wróć do listy templatek
            </Button>
          </Link>
        </div>
      </Typography>

      <div className={classes.container}>
        <div className={classes.ganttContainer}>
          <GanttArea actions={actions} template hideActivitiesSection={handleCancelButton} onMoveAll={handleMoveAll} />
        </div>
        <div className={classes.actionsContainer}>
          {showActionSection ? (
            <>
              <GanttActions actions={actions} />
              <div className={classes.buttonContainer}>
                <Button color={'primary'} variant={'contained'} onClick={handleCancelButton}>
                  Anuluj
                </Button>
              </div>
            </>
          ) : (
            !showMove && (
              <Button color={'primary'} variant={'contained'} onClick={handleShowActionSectionButton}>
                Dodaj czynność
              </Button>
            )
          )}
        </div>
        {showMove && (
          <div className={classes.showMoveContainer}>
            <div className={classes.showMoveFirstColumn}>
              <TextField
                label="Przesunięcie"
                variant="outlined"
                className={clsx(classes.textfield, classes.showMoveSubSection)}
                onChange={handleMoveChange}
                error={!validateMove}
                value={move}
                helperText={validateMove ? '' : 'Podaj liczbę'}
              />
              <div>
                <Button
                  style={{ width: '100%' }}
                  color={'primary'}
                  variant={'contained'}
                  onClick={handleMove}
                  disabled={!validateMove}
                  className={clsx(classes.borderTop, classes.showMoveSubSection)}
                >
                  Przesuń
                </Button>
                <Button
                  style={{ width: '100%' }}
                  color={'primary'}
                  variant={'contained'}
                  onClick={handleMoveAllCancel}
                  className={clsx(classes.borderTop, classes.showMoveSubSection)}
                >
                  Anuluj
                </Button>
              </div>
            </div>
            <div className={classes.showMoveSecondColumn}>
              <p>Przykład:</p>
              <p>
                “-5” - Przesunięcie czynności znajdujących się po prawej stronie od wybranej pozycji, o wpisaną wartość
                w lewą stronę.
              </p>
              <p>
                ”5” - Przesunięcie czynności znajdujących się po prawej stronie od wybranej pozycji, o wpisaną wartość w
                prawą stronę.
              </p>
            </div>
          </div>
        )}
        <Snackbar
          open={showNotification}
          autoHideDuration={2000}
          message={'Nie można postawić czynności w tym miejscu'}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          onClose={handleSnackbarClose}
        >
          <div className={classes.notification}>Nie można postawić czynności w tym miejscu</div>
        </Snackbar>
      </div>
    </div>
  );
}

export default GanttPage;
