import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';

import { CustomDialog } from 'app/components/CustomDialog/CustomDialog';
import { TextFieldComponent } from 'app/components/TextFieldComponent/TextFieldComponent';
import { GameGanttOrderService } from 'app/services/gameGanttOrder';
import { fetchGanttOrders } from 'app/store/gantt';
import {
  UpdateGameGanttOrderModalFormDataInterface,
  UpdateGameGanttOrderModalProps,
} from './UpdateGameGanttOrderModal.interface';

import { useStyles } from './UpdateGameGanttOrderModal.styles';
import { SelectComponent } from 'app/components/SelectComponent/SelectComponent';
import { getGameMaterials } from 'app/store/userRoom';
import { getMaterialNotInQueue } from 'app/shared/helpers/filters';
import { GameGanttOrderReplaceInterface } from 'app/shared/interfaces/GameGanttOrder.interface';
import { transportOptions } from 'app/shared/constants';
import { convertToInt } from 'app/shared/helpers/numberConverter';

export function UpdateGameGanttOrderModal({
  onClose,
  gameGanttOrderId,
  gameGanttId,
  amount,
  gameMaterialId,
  saveToNextRound,
}: UpdateGameGanttOrderModalProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const gameMaterials = useSelector(getGameMaterials);

  const formData: UpdateGameGanttOrderModalFormDataInterface = {
    amount: amount.toString(),
    gameMaterial: gameMaterialId,
    saveToNextRound: saveToNextRound.toString(),
  };
  const validationShema = Yup.object().shape({
    amount: Yup.number()
      .min(1, 'Liczba musi być większa od 0')
      .integer('Ilość musi być liczbą całkowitą')
      .typeError('Wartość musi być liczbą')
      .required('Podaj ilość'),
    gameMaterial: Yup.string().required('Wybierz materiał'),
  });

  return (
    <CustomDialog
      title={'Edycja zamówienia'}
      onCloseDialog={onClose}
      body={
        <div className={classes.container}>
          <Formik
            initialValues={formData}
            validationSchema={validationShema}
            onSubmit={(values) => {
              const body: GameGanttOrderReplaceInterface = {
                amount: convertToInt(values.amount),
                gameMaterial: `/api/game_materials/${values.gameMaterial}`,
                saveToNextRound: values.saveToNextRound.toString() === 'true',
              };
              GameGanttOrderService.updateGameGanttOrder(gameGanttOrderId, body).then(() => {
                dispatch(fetchGanttOrders(gameGanttId));
                onClose();
              });
            }}
          >
            {({ submitForm }) => (
              <Form>
                <Field component={TextFieldComponent} name={'amount'} label={'Ilość'} fullWidth />
                <Field
                  component={SelectComponent}
                  fullWidth
                  label={'Materiał'}
                  name={'gameMaterial'}
                  options={getMaterialNotInQueue(gameMaterials)
                    .filter((value) => value.amount > 0)
                    .map((gameMaterial) => ({
                      label: gameMaterial.actualMaterial
                        ? `${gameMaterial.actualMaterial.name} (${gameMaterial.orderNumber}) (${gameMaterial.processStep} / ${gameMaterial.processActivityCount})`
                        : '',
                      value: gameMaterial.id,
                    }))}
                />
                <Field
                  component={SelectComponent}
                  fullWidth
                  label={'Zapisać zlecenie?'}
                  name={'saveToNextRound'}
                  options={transportOptions.map((transportOption) => ({
                    label: transportOption.label,
                    value: transportOption.value,
                  }))}
                />
                <div className={classes.actionButtons}>
                  <Button color={'primary'} onClick={submitForm}>
                    Zapisz
                  </Button>
                  <Button color={'primary'} autoFocus onClick={onClose}>
                    Anuluj
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      }
      isOpen={true}
    />
  );
}
