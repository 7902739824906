import { AxiosPromise } from 'axios';

import { ApiService } from './api.service';
import { RoomInterface, RoomResponse, RoomUpdateInterface } from 'app/shared/interfaces/Room.interface';
import { GetParamsInterface } from 'app/shared/interfaces/RequestInterface.interface';
import { ActualSupplierInterfaceResponse } from 'app/shared/interfaces/ActualSupplier.interface';
import { ScenarioActualInterface } from 'app/shared/interfaces/ScenarioActual.interface';
import { ResourceResponse } from 'app/shared/interfaces/Resource.interface';
import { ActualProcessResponse } from 'app/shared/interfaces/ActualProcess.interface';
import { ProcessActivityResponse } from 'app/shared/interfaces/Process.interface';
import { ActualOrderResponse } from 'app/shared/interfaces/ActualOrder.interface';
import { AuctionItemResponse } from 'app/shared/interfaces/AuctionItem.interface';
import { GamePlayerRankingResponse } from 'app/shared/interfaces/GamePlayerRanking.interface';

export const RoomService = {
  getRooms(params: GetParamsInterface = {}): AxiosPromise<RoomResponse> {
    return ApiService.get('rooms', params);
  },
  getRoomSuppliers(id: number, params: GetParamsInterface = {}): AxiosPromise<ActualSupplierInterfaceResponse> {
    return ApiService.get(`rooms/${id}/actual_suppliers`, params);
  },
  getRoomProcesses(id: number, params: GetParamsInterface = {}): AxiosPromise<ActualProcessResponse> {
    return ApiService.get(`rooms/${id}/actual_processes`, params);
  },

  getRoomProcessesAndActivity(id: number, params: GetParamsInterface = {}): AxiosPromise<ProcessActivityResponse> {
    return ApiService.get(`rooms/${id}/process_activities_actuals`, params);
  },
  getRoomScenario(id: number, params: GetParamsInterface = {}): AxiosPromise<ScenarioActualInterface> {
    return ApiService.get(`rooms/${id}/scenario_template_actual`, params);
  },
  addRoom(data: Omit<RoomInterface, 'id' | 'userRooms'>): AxiosPromise {
    return ApiService.post('rooms', data);
  },

  replaceRoom(id: number, data: RoomUpdateInterface): AxiosPromise {
    return ApiService.put(`rooms/${id}`, data);
  },

  deleteRoom(id: number): AxiosPromise {
    return ApiService.delete(`rooms/${id}`);
  },

  getActualResources(roomId: number): AxiosPromise<ResourceResponse> {
    return ApiService.get(`rooms/${roomId}/actual_resources`);
  },

  getActualOrders(roomId: number, params: GetParamsInterface = {}): AxiosPromise<ActualOrderResponse> {
    return ApiService.get(`rooms/${roomId}/orders_actuals`, params);
  },

  getGamePlayerRanking(roomId: number, params: GetParamsInterface = {}): AxiosPromise<GamePlayerRankingResponse> {
    return ApiService.get(`rooms/${roomId}/game_player_rankings`, params);
  },

  getMyAuctions(
    roomId: number,
    userRoomId: number,
    params: GetParamsInterface = {}
  ): AxiosPromise<AuctionItemResponse> {
    return ApiService.get(`rooms/${roomId}/user_rooms/${userRoomId}/auction_items`, params);
  },
};
