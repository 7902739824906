import React from 'react';
import { GameHeaderWalletInterface } from './GameHeaderWallet.inteface';

import { useStyles } from './GameHeaderWallet.styles';

export function GameHeaderWallet({ cashBalance }: GameHeaderWalletInterface) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      Portfel:<span className={classes.cash}>{cashBalance.toFixed(2)} PLN</span>
    </div>
  );
}
