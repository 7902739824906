import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';

import { CustomDialog } from 'app/components/CustomDialog/CustomDialog';
import { TextFieldComponent } from 'app/components/TextFieldComponent/TextFieldComponent';
import { GameMaterialAddInterface } from 'app/shared/interfaces/GameMaterial.interface';
import { GameMaterialService } from 'app/services/gameMaterial.service';
import { RadioGroupComponent } from 'app/components/RadioGroupComponent/RadioGroupComponent';
import { deliveryMaterialOptions, messages } from 'app/shared/constants';
import { isAutomation } from 'app/shared/helpers/userGame';
import { getSelectedUserRoom } from 'app/store/userRoom';

import { useStyles } from './BuyMaterialModal.styles';
import { BuyMaterialModalProps, BuyMaterialFormDataInterface } from './BuyMaterialModal.interfaces';
import { convertToInt } from 'app/shared/helpers/numberConverter';

export function BuyMaterialModal(props: BuyMaterialModalProps) {
  const { isOpen, onClose, material, userRoom, materialBought, room } = props;
  const classes = useStyles();
  const [buyButtonDisabled, setBuyButtonDisabled] = useState<boolean>(false);
  const selectedUserRoom = useSelector(getSelectedUserRoom);

  const formData: BuyMaterialFormDataInterface = {
    amount: '1',
    deliveryType: '',
  };

  const validationShema = isAutomation(room)
    ? Yup.object().shape({
        amount: Yup.number()
          .test(
            'hasEnoughMoney',
            messages.NOT_ENOUGH_CASH,
            (amount) => !!material && material.price * amount <= selectedUserRoom.cashBalance
          )
          .min(1, 'Liczba musi być większa od 0')
          .typeError('Wartość musi być liczbą')
          .required('Podaj ilość materiałów'),
        deliveryType: Yup.number().required('Wybierz tryb dostawy'),
      })
    : Yup.object().shape({
        amount: Yup.number()
          .min(1, 'Liczba musi być większa od 0')
          .typeError('Wartość musi być liczbą')
          .required('Podaj ilość materiałów'),
      });

  return (
    <CustomDialog
      title={`Zakup materiału ${material ? material.name : ''}`}
      isOpen={isOpen}
      onCloseDialog={onClose}
      body={
        <Formik
          initialValues={formData}
          validationSchema={validationShema}
          onSubmit={(values) => {
            setBuyButtonDisabled(true);
            const boughtMaterialData: Omit<GameMaterialAddInterface, 'id'> = {
              amount: convertToInt(values.amount),
              actualMaterial: material,
              userRoom: userRoom,
              deliveryType: values.deliveryType ? parseInt(values.deliveryType) : undefined,
            };

            GameMaterialService.buyMaterials(boughtMaterialData)
              .then(() => {
                materialBought();
              })
              .finally(() => setBuyButtonDisabled(false));
          }}
        >
          {({ submitForm }) => (
            <Form>
              {isAutomation(room) && (
                <Field
                  component={RadioGroupComponent}
                  options={deliveryMaterialOptions}
                  name={'deliveryType'}
                  label={'Tryb dostawy'}
                />
              )}
              <Field component={TextFieldComponent} name={'amount'} label={'Ilość'} fullWidth />
              <div className={classes.actionButtons}>
                <Button color={'primary'} onClick={submitForm} disabled={buyButtonDisabled}>
                  Kup
                </Button>
                <Button color={'primary'} autoFocus onClick={onClose}>
                  Anuluj
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      }
    />
  );
}
