import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UserInterface } from 'app/shared/interfaces/User.interface';
import { RoomInterface } from 'app/shared/interfaces/Room.interface';
import { GameGanttInterface, UserRoomInterface } from 'app/shared/interfaces/UserRoom.interface';
import { GameMaterialInterface } from 'app/shared/interfaces/GameMaterial.interface';
import { GameProcessInterface } from 'app/shared/interfaces/GameProcess.interface';
import { GameOrderInterface } from 'app/shared/interfaces/GameOrder.interface';
import { GameActivityInterface } from 'app/shared/interfaces/GameActivity.interface';
import { GameMaterialQueuesInterface } from 'app/shared/interfaces/GameMaterialQueues.interface';
import { GameFactoryInterface } from 'app/shared/interfaces/GameFactory.interface';
import { GameProductInterface } from 'app/shared/interfaces/GameProduct.interface';
import { GameMaterialPurchaseHistoryInterface } from 'app/shared/interfaces/GameMaterialPurchaseHistory.interface';

export interface UserRoomState {
  id: number;
  isLoading?: boolean;
  cashBalance: number;
  status: number;
  mark: number;
  markDescription: string;
  user?: UserInterface;
  room?: RoomInterface;
  gameFactory?: GameFactoryInterface;
  gameMaterials: Array<GameMaterialInterface>;
  gameMaterialQueues: Array<GameMaterialQueuesInterface>;
  gameProcesses: Array<GameProcessInterface>;
  gameActivities: Array<GameActivityInterface>;
  gameProducts: Array<GameProductInterface>;
  gameMaterialPurchaseHistories: Array<GameMaterialPurchaseHistoryInterface>;
  gameGantt?: GameGanttInterface;
  gameOrders: Array<GameOrderInterface>;
  roundTimeLeft: number;
  currentPOZ: number;
  currentCompanyValue: number;
  currentCustomerService: number;
  currentBilans: number;
  currentEarnings: number;
  currentLoss: number;
  currentCompanyEfficiency: number;
  currentAverageProductQuality: number;
  currentPlanningEfficiency: number;
  currentRankingPosition: number;
  currentOEE: number;
  currentWWM: number;
  currentMarketActivity: number;
}

export interface UserRoomRoundsStates {
  selectedRoundState: UserRoomState;
  latestRoundState: UserRoomState;
  isLoading: boolean;
  isActiveRound: boolean;
}

const initialUserRoomState: UserRoomState = {
  id: 0,
  isLoading: true,
  cashBalance: 0,
  mark: 0,
  markDescription: '',
  status: 0,
  gameMaterials: [],
  gameProcesses: [],
  gameActivities: [],
  gameMaterialQueues: [],
  gameOrders: [],
  gameMaterialPurchaseHistories: [],
  gameProducts: [],
  roundTimeLeft: 0,
  currentPOZ: 0,
  currentRankingPosition: 0,
  currentCompanyValue: 0,
  currentCustomerService: 0,
  currentBilans: 0,
  currentEarnings: 0,
  currentLoss: 0,
  currentCompanyEfficiency: 0,
  currentAverageProductQuality: 0,
  currentPlanningEfficiency: 0,
  currentOEE: 0,
  currentWWM: 0,
  currentMarketActivity: 0,
};

const initialState: UserRoomRoundsStates = {
  selectedRoundState: initialUserRoomState,
  latestRoundState: initialUserRoomState,
  isLoading: true,
  isActiveRound: false,
};

export const userRoom = createSlice({
  name: 'userRoom',
  initialState,
  reducers: {
    clearUserRoom: (state) => {
      return { ...initialState };
    },
    setLatestUserRoomState: (state, action: PayloadAction<UserRoomInterface>) => {
      state.latestRoundState = action.payload;
    },
    setSelectedUserRoomState: (state, action: PayloadAction<UserRoomState>) => {
      state.selectedRoundState = action.payload;
    },
    setIsLoadingRoom: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setIsActiveRound: (state, action: PayloadAction<boolean>) => {
      state.isActiveRound = action.payload;
    },
  },
});

export default userRoom.reducer;
