import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';

import { TextFieldComponent } from 'app/components/TextFieldComponent/TextFieldComponent';
import { CustomDialog } from 'app/components/CustomDialog/CustomDialog';

import { AddGanttTemplateFormDataInterface, AddGanttTemplatesModalProps } from './AddGanttTemplatesModal.interface';
import { GanttInterface } from 'app/shared/interfaces/GanttTemplate.interface';
import { GanttTemplateService } from 'app/services/ganttTemplate.service';
import { useStyles } from './AddGanttTemplatesModal.styles';
import { handlingCatch } from 'app/shared/helpers/handlingCatch';

export function AddGanttTemplateModal({
  isOpen,
  ganttTemplateData,
  onClose,
  ganttTemplateAdded,
}: AddGanttTemplatesModalProps) {
  const classes = useStyles();
  const initialValues: AddGanttTemplateFormDataInterface = {
    name: ganttTemplateData ? ganttTemplateData.name : '',
    description: ganttTemplateData && ganttTemplateData.description ? ganttTemplateData.description : '',
  };

  const validationShema = Yup.object().shape({
    name: Yup.string().required('Podaj nazwę'),
  });

  return (
    <CustomDialog
      title={ganttTemplateData ? 'Edytowanie templatki gantta' : 'Dodawanie templatki gantta'}
      onCloseDialog={onClose}
      body={
        <Formik
          initialValues={initialValues}
          validationSchema={validationShema}
          onSubmit={(values, actions) => {
            const data: Omit<GanttInterface, 'id' | 'ganttTemplateItems'> = {
              name: values.name.trim(),
              description: values.description,
            };

            if (ganttTemplateData) {
              GanttTemplateService.replaceGanttTemplate(ganttTemplateData.id, data)
                .then(() => {
                  ganttTemplateAdded();
                })
                .catch((error) => {
                  const response = handlingCatch(error.response);
                  actions.setFieldError(response.fieldError, response.message);
                });
            } else {
              GanttTemplateService.addGanttTemplate(data)
                .then(() => {
                  ganttTemplateAdded();
                })
                .catch((error) => {
                  const response = handlingCatch(error.response);
                  actions.setFieldError(response.fieldError, response.message);
                });
            }
          }}
        >
          {({ submitForm }) => (
            <Form>
              <Field component={TextFieldComponent} name={'name'} label={'Nazwa'} fullWidth />
              <Field
                component={TextFieldComponent}
                name={'description'}
                label={'Opis'}
                multiline
                rowsMax={4}
                fullWidth
              />
              <div className={classes.actionButtons}>
                <Button color={'primary'} onClick={submitForm}>
                  {ganttTemplateData ? 'Zapisz' : 'Dodaj'}
                </Button>
                <Button color={'primary'} autoFocus onClick={onClose}>
                  Anuluj
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      }
      isOpen={isOpen}
    />
  );
}
