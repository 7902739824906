import React, { useCallback, useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';

import { ProcessInterface } from 'app/shared/interfaces/Process.interface';
import { GetParamsInterface } from 'app/shared/interfaces/RequestInterface.interface';
import { ActionInterface } from 'app/shared/interfaces/Action.interface';
import { actionTypeProductionAlias } from 'app/shared/constants';

import { ProcessService } from 'app/services/process.service';
import { ProcessesTable } from 'app/components/ProcessesTable/ProcessesTable';
import { defaultParams } from 'app/shared/helpers/pagination';
import { ActionService } from 'app/services/action.service';
import { HeaderButton } from 'app/components/HeaderButton/HeaderButton';
import { ProductInterface } from 'app/shared/interfaces/Product.interface';
import { ProductService } from 'app/services/product.service';
import { GanttTemplateService } from 'app/services/ganttTemplate.service';
import { GanttInterface } from 'app/shared/interfaces/GanttTemplate.interface';

import { useStyles } from './Processes.styles';

function ProcessesPage() {
  const classes = useStyles();
  const [processes, setProcesses] = useState<Array<ProcessInterface>>([]);
  const [actions, setActions] = useState<Array<ActionInterface>>([]);
  const [availableProducts, setAvailableProduct] = useState<Array<ProductInterface>>([]);
  const [gantts, setGantts] = useState<Array<GanttInterface>>([]);
  const [addButtonClicked, setAddButtonClicked] = useState<boolean>(false);

  const [totalRecords, setTotalRecords] = useState<number>(0);

  const getProcesses = (params: GetParamsInterface = defaultParams) => {
    ProcessService.getProcesses(params).then(
      ({ data: { 'hydra:member': responseData, 'hydra:totalItems': totalRecords } }) => {
        setProcesses(responseData);
        setTotalRecords(totalRecords);
      }
    );
  };

  const getProductionTypeActionsType = (actions: Array<ActionInterface>) =>
    actions.filter((action) => {
      const actionTypes = action.actionTypes;
      return actionTypes.find((actionType) => actionType.name === actionTypeProductionAlias);
    });

  const getActions = useCallback(() => {
    ActionService.getActions().then(({ data: { 'hydra:member': responseData } }) => {
      const productionActions = getProductionTypeActionsType(responseData);
      setActions(productionActions);
    });
  }, []);

  const getGantts = () => {
    GanttTemplateService.getGanttTemplate().then(({ data: { 'hydra:member': responseData } }) => {
      setGantts(responseData);
    });
  };

  const getAvailableProducts = () => {
    const param: GetParamsInterface = { showAvailableProducts: true };
    ProductService.getProducts(param).then(({ data: { 'hydra:member': responseData } }) => {
      setAvailableProduct(responseData);
    });
  };

  const updateProcesses = (params: GetParamsInterface) => {
    getProcesses(params);
    getAvailableProducts();
  };

  const handleAddButton = () => setAddButtonClicked(true);

  useEffect(() => {
    getProcesses();
    getActions();
    getAvailableProducts();
    getGantts();
  }, [getActions]);

  useEffect(() => {
    if (addButtonClicked) {
      setAddButtonClicked(false);
    }
  }, [addButtonClicked]);

  return (
    <div className={classes.root}>
      <Typography variant={'h1'}>
        <span>Procesy</span>
        <div>
          <HeaderButton onClick={handleAddButton} label={'Dodaj proces'} />
        </div>
      </Typography>
      <ProcessesTable
        processes={processes}
        totalRecords={totalRecords}
        actions={actions}
        gantts={gantts}
        availableProducts={availableProducts}
        shouldUpdateProcesses={updateProcesses}
        addClick={addButtonClicked}
      />
    </div>
  );
}

export default ProcessesPage;
