import React, { useEffect, useState } from 'react';
import { Field, Form, Formik, FieldArray } from 'formik';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';

import { CustomDialog } from 'app/components/CustomDialog/CustomDialog';
import { TextFieldComponent } from 'app/components/TextFieldComponent/TextFieldComponent';
import { AddDistributionTemplateInterface } from 'app/shared/interfaces/DistributionTemplate.interface';
import { DistributionTemplateService } from 'app/services/distributionTemplate.service';

import { useStyles } from './AddDistributionTemplateModal.styles';
import {
  AddDistributionTemplateModalProps,
  AddDistributionTemplateFormDataInterface,
} from './AddDistributionTemplateModal.interfaces';
import { handlingCatch } from 'app/shared/helpers/handlingCatch';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import { DistributionNumbersTemplateInterface } from 'app/shared/interfaces/DistributionNumbersTemplate.interface';
import { DistributionAttributesTemplateInterface } from 'app/shared/interfaces/DistributionAttributesTemplate.interface';
import { upToThreeDecimalPlaces } from 'app/shared/helpers/validators';
import { convertToInt, convertToFloat } from 'app/shared/helpers/numberConverter';

export function AddDistributionTemplateModal(props: AddDistributionTemplateModalProps) {
  const { isOpen, onClose, data, distributionTemplateAdded, distributionAttributesTemplatesType } = props;
  const [columnNumber, setColumnNumber] = useState<number>(1);

  const classes = useStyles();

  useEffect(() => {
    setColumnNumber(1);
  }, [isOpen, setColumnNumber]);

  const getDistributionNumbersProbabilityArray = (
    distributionNumbersTemplates: Array<DistributionNumbersTemplateInterface>
  ) => {
    return distributionNumbersTemplates
      .sort((a, b) => a.sequence - b.sequence)
      .map((distributionNumbersTemplate) => distributionNumbersTemplate.probability.toString());
  };

  const getDistributionNumbersNewOrdersArray = (
    distributionNumbersTemplates: Array<DistributionNumbersTemplateInterface>
  ) => {
    return distributionNumbersTemplates
      .sort((a, b) => a.sequence - b.sequence)
      .map((distributionNumbersTemplate) => distributionNumbersTemplate.newOrders.toString());
  };

  const getDistributionNumbersCumulativeProbabilityArray = (
    distributionNumbersTemplates: Array<DistributionNumbersTemplateInterface>
  ) => {
    return distributionNumbersTemplates
      .sort((a, b) => a.sequence - b.sequence)
      .map((distributionNumbersTemplate) => distributionNumbersTemplate.cumulativeProbability.toFixed(3));
  };

  const getDistributionAttributesObject = (
    distributionAttributesTemplates: Array<DistributionAttributesTemplateInterface>,
    typeKey: string
  ) => {
    const numberOfProductObject = distributionAttributesTemplates.find(
      (distributionAttributesTemplate) => distributionAttributesTemplate?.type?.code === typeKey
    );
    return numberOfProductObject
      ? {
          xMinimum: numberOfProductObject.xMinimum.toString(),
          yModa: numberOfProductObject.yModa.toString(),
          zMaximum: numberOfProductObject.zMaximum.toString(),
        }
      : { xMinimum: '', yModa: '', zMaximum: '' };
  };

  const formData: AddDistributionTemplateFormDataInterface = {
    name: data ? data.name : '',
    description: data && data.description ? data.description : '',
    probability:
      data && data.distributionNumbersTemplates
        ? getDistributionNumbersProbabilityArray(data.distributionNumbersTemplates)
        : [''],
    newOrders:
      data && data.distributionNumbersTemplates
        ? getDistributionNumbersNewOrdersArray(data.distributionNumbersTemplates)
        : [''],
    cumulativeProbability:
      data && data.distributionNumbersTemplates
        ? getDistributionNumbersCumulativeProbabilityArray(data.distributionNumbersTemplates)
        : [''],
    number_of_products_required:
      data && data.distributionAttributesTemplates
        ? getDistributionAttributesObject(data.distributionAttributesTemplates, 'number_of_products_required')
        : { xMinimum: '', yModa: '', zMaximum: '' },
    minimal_quality_in_order:
      data && data.distributionAttributesTemplates
        ? getDistributionAttributesObject(data.distributionAttributesTemplates, 'minimal_quality_in_order')
        : { xMinimum: '', yModa: '', zMaximum: '' },
    execution_time:
      data && data.distributionAttributesTemplates
        ? getDistributionAttributesObject(data.distributionAttributesTemplates, 'execution_time')
        : { xMinimum: '', yModa: '', zMaximum: '' },
  };

  const validationDistributionNumbersQuality = Yup.object().shape({
    xMinimum: Yup.number()
      .test('is-incorrect', 'Podaj jakość w zakresie od 0 do 1 max. trzy miejsca po przecinku', upToThreeDecimalPlaces)
      .min(0, 'Podaj jakość w zakresie od 0 do 1 max. trzy miejsca po przecinku')
      .max(1, 'Podaj jakość w zakresie od 0 do 1 max. trzy miejsca po przecinku')
      .required('Podaj jakość w zakresie od 0 do 1 max. trzy miejsca po przecinku')
      .typeError('Podaj jakość w zakresie od 0 do 1 max. trzy miejsca po przecinku'),
    yModa: Yup.number()
      .test('is-incorrect', 'Podaj jakość w zakresie od 0 do 1 max. trzy miejsca po przecinku', upToThreeDecimalPlaces)
      .min(0, 'Podaj jakość w zakresie od 0 do 1 max. trzy miejsca po przecinku')
      .max(1, 'Podaj jakość w zakresie od 0 do 1 max. trzy miejsca po przecinku')
      .max(Yup.ref('zMaximum'), 'Wartość Y nie może być większa od wartości Z')
      .min(Yup.ref('xMinimum'), 'Wartość Y nie może być mniejsza od wartości X')
      .required('Podaj jakość w zakresie od 0 do 1 max. trzy miejsca po przecinku')
      .typeError('Podaj jakość w zakresie od 0 do 1 max. trzy miejsca po przecinku'),
    zMaximum: Yup.number()
      .test('is-incorrect', 'Podaj jakość w zakresie od 0 do 1 max. trzy miejsca po przecinku', upToThreeDecimalPlaces)
      .min(0, 'Podaj jakość w zakresie od 0 do 1 max. trzy miejsca po przecinku')
      .max(1, 'Podaj jakość w zakresie od 0 do 1 max. trzy miejsca po przecinku')
      .min(Yup.ref('xMinimum'), 'Wartość Z nie może być mniejsza od wartości X')
      .required('Podaj jakość w zakresie od 0 do 1 max. trzy miejsca po przecinku')
      .typeError('Podaj jakość w zakresie od 0 do 1 max. trzy miejsca po przecinku'),
  });
  const validationDistributionNumbers = Yup.object().shape({
    xMinimum: Yup.number()
      .min(0, 'Podaj liczbę produktów, wartość nie może być ujemna')
      .required('Podaj liczbę produktów, wartość powinna być liczbą całkowitą')
      .integer('Podaj liczbę produktów, wartość powinna być liczbą całkowitą')
      .typeError('Podaj liczbę produktów, wartość powinna być liczbą całkowitą'),
    yModa: Yup.number()
      .min(0, 'Podaj liczbę produktów, wartość nie może być ujemna')
      .max(Yup.ref('zMaximum'), 'Wartość Y nie może być większa od wartości Z')
      .min(Yup.ref('xMinimum'), 'Wartość Y nie może być mniejsza od wartości X')
      .required('Podaj liczbę produktów, wartość powinna być liczbą całkowitą')
      .integer('Podaj liczbę produktów, wartość powinna być liczbą całkowitą')
      .typeError('Podaj liczbę produktów, wartość powinna być liczbą całkowitą'),
    zMaximum: Yup.number()
      .min(0, 'Podaj liczbę produktów, nie może być ujemna')
      .min(Yup.ref('xMinimum'), 'Wartość Z nie może być mniejsza od wartości X')
      .required('Podaj liczbę produktów, wartość powinna być liczbą całkowitą')
      .integer('Podaj liczbę produktów, wartość powinna być liczbą całkowitą')
      .typeError('Podaj liczbę produktów, wartość powinna być liczbą całkowitą'),
  });
  const validationDistributionExecutionTime = Yup.object().shape({
    xMinimum: Yup.number()
      .min(0, 'Podaj czas wykonania, wartość nie może być ujemna')
      .required('Podaj czas wykonania, wartość powinna być liczbą całkowitą')
      .integer('Podaj czas wykonania, wartość powinna być liczbą całkowitą')
      .typeError('Podaj czas wykonania, wartość powinna być liczbą całkowitą'),
    yModa: Yup.number()
      .min(0, 'Podaj czas wykonania, wartość nie może być ujemna')
      .max(Yup.ref('zMaximum'), 'Wartość Y nie może być większa od wartości Z')
      .min(Yup.ref('xMinimum'), 'Wartość Y nie może być mniejsza od wartości X')
      .required('Podaj czas wykonania, wartość powinna być liczbą całkowitą')
      .integer('Podaj czas wykonania, wartość powinna być liczbą całkowitą')
      .typeError('Podaj czas wykonania, wartość powinna być liczbą całkowitą'),
    zMaximum: Yup.number()
      .min(0, 'Podaj czas wykonania, wartość nie może być ujemna')
      .min(Yup.ref('xMinimum'), 'Wartość Z nie może być mniejsza od wartości X')
      .required('Podaj czas wykonania, wartość powinna być liczbą całkowitą')
      .integer('Podaj czas wykonania, wartość powinna być liczbą całkowitą')
      .typeError('Podaj czas wykonania, wartość powinna być liczbą całkowitą'),
  });
  const validationShema = Yup.object().shape({
    name: Yup.string().required('Podaj nazwę'),
    probability: Yup.array(
      Yup.number()
        .test('is-incorrect', 'Podaj prawdopodobieństwo, max. trzy miejsca po przecinku', upToThreeDecimalPlaces)
        .min(0, 'Podaj prawdopodobieństwo w zakresie od 0 do 1, max. trzy miejsca po przecinku')
        .max(1, 'Podaj prawdopodobieństwo w zakresie od 0 do 1, max. trzy miejsca po przecinku')
        .required('Podaj prawdopodobieństwo, max. trzy miejsca po przecinku')
        .typeError('Podaj prawdopodobieństwo, max. trzy miejsca po przecinku')
    ).test('test-0', 'test-msg', function (value) {
      const sumProbability = value.reduce((sum: number, item: number) => (sum += item ? item : 0), 0);
      const valid = sumProbability === 1;
      return (
        valid ||
        this.createError({
          path: `cumulativeProbability[${value.length - 1}]`,
          message: 'Suma prawdopodobieństwa musi być równa 1',
        })
      );
    }),
    cumulativeProbability: Yup.array(
      Yup.number()
        .min(0, 'Liczba musi być większa lub równa 0')
        .max(1, 'Liczba musi być mniejsza lub równa 1')
        .typeError('Wartość musi być liczbą')
    ),
    newOrders: Yup.array(
      Yup.number()
        .min(0, 'Podaj liczbę zamówień, wartość nie może być ujemna')
        .required('Podaj liczbę zamówień, wartość powinna być liczbą całkowitą')
        .typeError('Podaj liczbę zamówień, wartość powinna być liczbą całkowitą')
        .integer('Podaj liczbę zamówień, wartość powinna być liczbą całkowitą')
    ),
    number_of_products_required: validationDistributionNumbers,
    minimal_quality_in_order: validationDistributionNumbersQuality,
    execution_time: validationDistributionExecutionTime,
  });

  const isEditable = () => !data;

  const getColumnProbability = (values: AddDistributionTemplateFormDataInterface) => (
    <FieldArray
      name="probability"
      render={(arrayHelpers) => (
        <>
          {values.probability && values.probability.length > 0
            ? values.probability.map((probability, index) => (
                <TableCell key={`probability.${index}`}>
                  {' '}
                  <Field
                    onChange={(event: { target: { value: string } }) => {
                      const probabilityValue: string = event.target.value;
                      arrayHelpers.replace(index, probabilityValue);
                      const floatProbabilityValue: number = convertToFloat(probabilityValue);
                      let counter: number = 0;
                      values.probability.forEach((item, indexProbability) => {
                        if (!isNaN(parseFloat(item))) {
                          if (indexProbability === index) {
                            counter += floatProbabilityValue;
                          } else {
                            counter += parseFloat(item);
                          }
                          if (!isNaN(counter)) {
                            values.cumulativeProbability[indexProbability] = counter.toFixed(3);
                          }
                        } else if (indexProbability === index) {
                          if (!isNaN(floatProbabilityValue)) {
                            counter += floatProbabilityValue;
                          }
                          if (!isNaN(counter)) {
                            values.cumulativeProbability[indexProbability] = counter.toFixed(3);
                          }
                        }
                      });
                    }}
                    disabled={!isEditable()}
                    component={TextFieldComponent}
                    name={`probability.${index}`}
                    label={'Wartość'}
                  />
                </TableCell>
              ))
            : ''}
        </>
      )}
    />
  );

  const getNewOrdersy = (values: AddDistributionTemplateFormDataInterface) => (
    <FieldArray
      name="newOrders"
      render={() => (
        <>
          {values.newOrders && values.newOrders.length > 0
            ? values.newOrders.map((newOrder, index) => (
                <TableCell key={`newOrders.${index}`}>
                  <Field
                    disabled={!isEditable()}
                    component={TextFieldComponent}
                    name={`newOrders.${index}`}
                    label={'Wartość'}
                  />
                </TableCell>
              ))
            : ''}
        </>
      )}
    />
  );
  const getColumnCumulativeProbability = (values: AddDistributionTemplateFormDataInterface) => (
    <FieldArray
      name="cumulativeProbability"
      render={() => (
        <>
          {values.cumulativeProbability && values.cumulativeProbability.length > 0
            ? values.cumulativeProbability.map((cumulativeProbability, index) => (
                <TableCell key={`cumulativeProbability.${index}`}>
                  {' '}
                  <Field
                    disabled={true}
                    component={TextFieldComponent}
                    name={`cumulativeProbability.${index}`}
                    label={'Wartość'}
                  />
                </TableCell>
              ))
            : ''}
        </>
      )}
    />
  );
  const getDistributionNumbers = (values: AddDistributionTemplateFormDataInterface) => (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell>Liczba nowych zamówień</TableCell>
          {getNewOrdersy(values)}
        </TableRow>
        <TableRow>
          <TableCell>Prawdopodobieństwo</TableCell>
          {getColumnProbability(values)}
        </TableRow>
        <TableRow>
          <TableCell>Prawdopodobieństwo skumulowane</TableCell>
          {getColumnCumulativeProbability(values)}
        </TableRow>
      </TableBody>
    </Table>
  );

  const addColumn = (values: AddDistributionTemplateFormDataInterface) => {
    values.newOrders.push('');
    values.cumulativeProbability.push('');
    values.probability.push('');
    setColumnNumber(columnNumber + 1);
  };

  const removeColumn = (values: AddDistributionTemplateFormDataInterface) => {
    if (columnNumber > 1) {
      values.newOrders.pop();
      values.cumulativeProbability.pop();
      values.probability.pop();
      setColumnNumber(columnNumber - 1);
    }
  };

  const getDistributionNumbersArray = (values: AddDistributionTemplateFormDataInterface) => {
    const distributionNumbersBody: Array<Omit<
      DistributionNumbersTemplateInterface,
      'id' | 'distributionTemplate'
    >> = [];
    for (let i = 0; i < columnNumber; i++) {
      const body = {
        newOrders: convertToInt(values.newOrders[i]),
        probability: convertToFloat(values.probability[i]),
        cumulativeProbability: convertToFloat(values.cumulativeProbability[i]),
        sequence: i,
      };
      distributionNumbersBody.push(body);
    }
    return distributionNumbersBody;
  };

  const getDistributionAttributesArray = (values: AddDistributionTemplateFormDataInterface) => {
    const distributionNumbersBody: Array<Omit<
      DistributionAttributesTemplateInterface,
      'id' | 'distributionTemplate'
    >> = [];
    const numberOfProductBody = {
      xMinimum: convertToInt(values.number_of_products_required.xMinimum),
      yModa: convertToInt(values.number_of_products_required.yModa),
      zMaximum: convertToInt(values.number_of_products_required.zMaximum),
      type: distributionAttributesTemplatesType.find(
        (attributionType) => attributionType.code === 'number_of_products_required'
      ),
    };
    const minimalQualityInOrderBody = {
      xMinimum: convertToFloat(values.minimal_quality_in_order.xMinimum),
      yModa: convertToFloat(values.minimal_quality_in_order.yModa),
      zMaximum: convertToFloat(values.minimal_quality_in_order.zMaximum),
      type: distributionAttributesTemplatesType.find(
        (attributionType) => attributionType.code === 'minimal_quality_in_order'
      ),
    };
    const executionTimeBody = {
      xMinimum: convertToInt(values.execution_time.xMinimum),
      yModa: convertToInt(values.execution_time.yModa),
      zMaximum: convertToInt(values.execution_time.zMaximum),
      type: distributionAttributesTemplatesType.find((attributionType) => attributionType.code === 'execution_time'),
    };
    distributionNumbersBody.push(numberOfProductBody);
    distributionNumbersBody.push(minimalQualityInOrderBody);
    distributionNumbersBody.push(executionTimeBody);
    return distributionNumbersBody;
  };
  return (
    <CustomDialog
      title={data ? 'Podgląd rozkładu' : 'Dodaj rozkład'}
      maxWidth={'md'}
      isOpen={isOpen}
      onCloseDialog={onClose}
      body={
        <Formik
          initialValues={formData}
          validationSchema={validationShema}
          onSubmit={(values, actions, ...rest) => {
            const distributionTemplateData: AddDistributionTemplateInterface = {
              name: values.name.trim(),
              description: values.description,
              distributionAttributesTemplates: getDistributionAttributesArray(values),
              distributionNumbersTemplates: getDistributionNumbersArray(values),
            };
            DistributionTemplateService.addDistributionTemplate(distributionTemplateData)
              .then(() => {
                distributionTemplateAdded();
              })
              .catch((error) => {
                const response = handlingCatch(error.response);
                actions.setFieldError(response.fieldError, response.message);
              });
          }}
        >
          {({ submitForm, values }) => (
            <Form>
              <Field disabled={!isEditable()} component={TextFieldComponent} name={'name'} label={'Nazwa'} fullWidth />
              <Field
                disabled={!isEditable()}
                component={TextFieldComponent}
                name={'description'}
                label={'Opis'}
                multiline
                rowsMax={4}
                fullWidth
              />
              {isEditable() && (
                <div className={classes.actionButtons}>
                  <Button color={'primary'} onClick={() => addColumn(values)}>
                    Dodaj kolumnę
                  </Button>
                  <Button color={'primary'} autoFocus onClick={() => removeColumn(values)}>
                    Usuń kolumnę
                  </Button>
                </div>
              )}
              <div>
                <TableContainer component={Paper}>{getDistributionNumbers(values)}</TableContainer>
              </div>
              <div>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        <TableCell>x minimum</TableCell>
                        <TableCell>y moda</TableCell>
                        <TableCell>z maksimum</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>Wymagana liczba produktów</TableCell>
                        <TableCell>
                          <Field
                            disabled={!isEditable()}
                            component={TextFieldComponent}
                            name={'number_of_products_required.xMinimum'}
                            label={'Wartość'}
                            fullWidth
                          />
                        </TableCell>
                        <TableCell>
                          <Field
                            disabled={!isEditable()}
                            component={TextFieldComponent}
                            name={'number_of_products_required.yModa'}
                            label={'Wartość'}
                            fullWidth
                          />
                        </TableCell>
                        <TableCell>
                          <Field
                            disabled={!isEditable()}
                            component={TextFieldComponent}
                            name={'number_of_products_required.zMaximum'}
                            label={'Wartość'}
                            fullWidth
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Minimalna jakość</TableCell>
                        <TableCell>
                          <Field
                            disabled={!isEditable()}
                            component={TextFieldComponent}
                            name={'minimal_quality_in_order.xMinimum'}
                            label={'Wartość'}
                            fullWidth
                          />
                        </TableCell>
                        <TableCell>
                          <Field
                            disabled={!isEditable()}
                            component={TextFieldComponent}
                            name={'minimal_quality_in_order.yModa'}
                            label={'Wartość'}
                            fullWidth
                          />
                        </TableCell>
                        <TableCell>
                          <Field
                            disabled={!isEditable()}
                            component={TextFieldComponent}
                            name={'minimal_quality_in_order.zMaximum'}
                            label={'Wartość'}
                            fullWidth
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Czas wykonania </TableCell>
                        <TableCell>
                          <Field
                            disabled={!isEditable()}
                            component={TextFieldComponent}
                            name={'execution_time.xMinimum'}
                            label={'Wartość'}
                            fullWidth
                          />
                        </TableCell>
                        <TableCell>
                          <Field
                            disabled={!isEditable()}
                            component={TextFieldComponent}
                            name={'execution_time.yModa'}
                            label={'Wartość'}
                            fullWidth
                          />
                        </TableCell>
                        <TableCell>
                          <Field
                            disabled={!isEditable()}
                            component={TextFieldComponent}
                            name={'execution_time.zMaximum'}
                            label={'Wartość'}
                            fullWidth
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>

              <div className={classes.actionButtons}>
                {isEditable() && (
                  <Button color={'primary'} onClick={submitForm}>
                    Zapisz
                  </Button>
                )}
                <Button color={'primary'} autoFocus onClick={onClose}>
                  {isEditable() ? 'Anuluj' : 'Zamknij'}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      }
    />
  );
}
