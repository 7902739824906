import { AxiosPromise } from 'axios';
import { ApiService } from './api.service';
import { MaterialTypeInterface, ActionTypeResponse } from 'app/shared/interfaces/MaterialType.interface';
import { GetParamsInterface } from 'app/shared/interfaces/RequestInterface.interface';

export const MaterialTypeService = {
  getMaterialTypes(params: GetParamsInterface = {}): AxiosPromise<ActionTypeResponse> {
    return ApiService.get('material_types', params);
  },

  addMaterialType(data: Omit<MaterialTypeInterface, 'id'>): AxiosPromise {
    return ApiService.post('material_types', data);
  },

  replaceMaterialType(id: number, data: Omit<MaterialTypeInterface, 'id'>) {
    return ApiService.put(`material_types/${id}`, data);
  },

  deleteMaterialType(id: number): AxiosPromise {
    return ApiService.delete(`material_types/${id}`);
  },
};
