import React, { useState } from 'react';
import { useHistory } from 'react-router';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import GamesIcon from '@material-ui/icons/Games';

import { RoomInterface } from 'app/shared/interfaces/Room.interface';
import { getParams } from 'app/shared/helpers/pagination';
import { CustomTablePagination } from 'app/components/CustomTablePagination/CustomTablePagination';
import { UserRoomInterface } from 'app/shared/interfaces/UserRoom.interface';
import { UserInterface } from 'app/shared/interfaces/User.interface';
import { roomGameStatus } from 'app/shared/constants';
import {
  getRoomCellBackgroundStatus,
  getRoomStatus,
  getRoomStatusColor,
  getUserRoomConfigStatus,
} from 'app/shared/helpers/userGame';

import { useStyles } from './UserRoomsTable.styles';
import { UserRoomsTableProps } from './UserRoomsTable.interfaces';

export function UserRoomsTable(props: UserRoomsTableProps) {
  const { rooms, shouldUpdateRooms, totalRecords, currentUser } = props;
  const classes = useStyles();
  const [selectedPage, setSelectedPage] = useState<number>(0);
  const history = useHistory();

  const handleOpenSelectUserDialog = (userRooms: Array<UserRoomInterface>) => {
    findUserRoomToRedirect(userRooms);
  };

  const getCurrentUserId = (currentUser: UserInterface | null) => {
    return currentUser ? currentUser.id : -1;
  };

  const getUserRoomFromUserRooms = (userRooms: Array<UserRoomInterface>) =>
    userRooms.find((userRoom) => userRoom.user.id === getCurrentUserId(currentUser));

  const findUserRoomToRedirect = (userRooms: Array<UserRoomInterface>) => {
    const userRoom = getUserRoomFromUserRooms(userRooms);
    if (userRoom) {
      handleRedirectToGame(userRoom.id);
    }
  };

  const handleRedirectToGame = (id: number) => {
    history.push(`/game_view/${id}`);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    shouldUpdateRooms(getParams(page));
    setSelectedPage(page);
  };

  const getUserGameStatus = (userRoom: UserRoomInterface) => {
    const userGameStatus = getUserRoomConfigStatus(userRoom.status);
    const userGameMark = userRoom.mark ? `Wynik: ${userRoom.mark}` : '';
    return userGameStatus ? `${userGameStatus.label.room} ${userGameMark}` : '';
  };

  const getUserRoomStatusColor = (userRoom: UserRoomInterface) => {
    const userGameStatus = getUserRoomConfigStatus(userRoom.status);
    return userGameStatus ? userGameStatus.cell_color : '';
  };

  const getCurrentUserRoomView = (room: RoomInterface, userRoom: UserRoomInterface, showStatus: boolean = false) => {
    const cellBackground = getRoomCellBackgroundStatus(room)
      ? getRoomStatusColor(room)
      : getUserRoomStatusColor(userRoom);
    return (
      <Table className={classes.subTable}>
        <TableBody>
          <TableRow key={userRoom.id} style={{ backgroundColor: cellBackground }}>
            {showStatus ? (
              <TableCell>{getUserGameStatus(userRoom)}</TableCell>
            ) : (
              <TableCell>{userRoom.user.email}</TableCell>
            )}
          </TableRow>
        </TableBody>
      </Table>
    );
  };

  const gameIsAvailable = (roomStatus: number) => roomStatus !== roomGameStatus.GAME_IDLE.status;

  const getScenarioName = (room: RoomInterface) => {
    return room.scenarioTemplateActual ? room.scenarioTemplateActual.name : '';
  };

  const getCurrentUserGameInfo = (room: RoomInterface, showStatus: boolean = false) => {
    const userRooms: Array<UserRoomInterface> = room.userRooms;
    const userRoom = getUserRoomFromUserRooms(userRooms);
    return userRoom ? getCurrentUserRoomView(room, userRoom, showStatus) : '';
  };

  return (
    <div>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Nazwa</TableCell>
              <TableCell>Gracze</TableCell>
              <TableCell>Status Gracza</TableCell>
              <TableCell>Status gry</TableCell>
              <TableCell>Scenariusze</TableCell>
              <TableCell align={'center'}>Czas trwania tury</TableCell>
              <TableCell>Akcja</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rooms.map((room) => (
              <TableRow key={room.id}>
                <TableCell className={classes.bold}>{room.name}</TableCell>
                <TableCell className={classes.subTableCell}>{getCurrentUserGameInfo(room)}</TableCell>
                <TableCell className={classes.subTableCell}>{getCurrentUserGameInfo(room, true)}</TableCell>
                <TableCell style={{ backgroundColor: getRoomStatusColor(room) }}>
                  <div>{getRoomStatus(room)}</div>
                  <div>{`Runda ${room.currentRound}`}</div>
                </TableCell>
                <TableCell>{getScenarioName(room)}</TableCell>
                <TableCell align={'center'}>{room.timeRound}</TableCell>
                <TableCell align={'right'} className={classes.buttonsTableCell}>
                  {gameIsAvailable(room.status) && (
                    <IconButton onClick={() => handleOpenSelectUserDialog(room.userRooms)}>
                      <GamesIcon fontSize={'small'} />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <CustomTablePagination
            tablePaginationConfig={{
              count: totalRecords,
              page: selectedPage,
            }}
            onChangePage={handleChangePage}
          />
        </Table>
      </TableContainer>
    </div>
  );
}
