import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import NumberFormat from 'react-number-format';
import { SnackbarAlert } from 'app/components/SnackbarAlert/SnackbarAlert';
import Snackbar from '@material-ui/core/Snackbar';

import { handlingCatch } from 'app/shared/helpers/handlingCatch';
import { ProductService } from 'app/services/product.service';
import { ProductInterface } from 'app/shared/interfaces/Product.interface';
import { getParams } from 'app/shared/helpers/pagination';
import { ProductsTableProps } from './ProductsTable.interfaces';
import { CustomIconButton } from 'app/components/CustomIconButton/CustomIconButton';
import { CustomDialog } from 'app/components/CustomDialog/CustomDialog';
import { AddProductModal } from 'app/components/AddProductModal/AddProductModal';
import { CustomTablePagination } from 'app/components/CustomTablePagination/CustomTablePagination';

import { useStyles } from './ProductsTable.styles';

export function ProductsTable(props: ProductsTableProps) {
  const { products, materialTypes, shouldUpdateProducts, totalRecords, addClick } = props;
  const classes = useStyles();
  const [selectedProduct, setSelectedProduct] = useState<number>(0);
  const [selectedPage, setSelectedPage] = useState<number>(0);
  const [productData, setProductData] = useState<ProductInterface | null>(null);

  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [openAddDialog, setOpenAddDialog] = useState<boolean>(false);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [notificationText, setNotificationText] = useState<string>('');

  const handleSnackbarClose = () => {
    setShowNotification(false);
  };

  const handleAddProduct = () => {
    setProductData(null);
    setOpenAddDialog(true);
  };

  const handleOpenEditProduct = (id: number) => {
    const product = getProductById(id);
    if (product) {
      setProductData(product);
      setOpenAddDialog(true);
    }
  };

  const handleOpenDeleteProduct = (id: number) => {
    setSelectedProduct(id);
    setOpenDeleteDialog(true);
  };

  const handleDeleteProduct = () => {
    ProductService.deleteProduct(selectedProduct)
      .then(() => {
        setOpenDeleteDialog(false);
        shouldUpdateProducts(getParams(selectedPage));
      })
      .catch((error) => {
        const response = handlingCatch(error.response);
        const { status } = error.response;
        if (status === 400) {
          setNotificationText(response.message);
          setShowNotification(true);
          setOpenDeleteDialog(false);
        }
      });
  };

  const handleCloseDeleteProductDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
    setProductData(null);
  };

  const handleProductAdded = () => {
    shouldUpdateProducts(getParams(selectedPage));
    setOpenAddDialog(false);
    setProductData(null);
  };

  const getProductById = (id: number) => products.find((product) => product.id === id);

  const getProductName = (id: number) => {
    const product = getProductById(id);
    return product ? product.name : '';
  };

  const getMaterialTypeName = (product: ProductInterface) => {
    return product.materialType ? product.materialType.name : '';
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    shouldUpdateProducts(getParams(page));
    setSelectedPage(page);
  };

  useEffect(() => {
    if (addClick) {
      handleAddProduct();
    }
  }, [addClick]);

  return (
    <div>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Nazwa</TableCell>
              <TableCell>Opis</TableCell>
              <TableCell>Typ materiału</TableCell>
              <TableCell align={'center'}>Jakość</TableCell>
              <TableCell align={'center'}>Wielkość [jw]</TableCell>
              <TableCell align={'right'}>Cena [PLN]</TableCell>
              <TableCell align={'right'} className={classes.buttonsTableCell} />
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map((product) => (
              <TableRow key={product.id}>
                <TableCell className={clsx(classes.wrap, classes.bold)}>{product.name}</TableCell>
                <TableCell className={clsx(classes.wrap)}>{product.description}</TableCell>
                <TableCell>{getMaterialTypeName(product)}</TableCell>
                <TableCell align={'center'}>{product.quality}</TableCell>
                <TableCell align={'center'}>{product.amount}</TableCell>
                <TableCell align={'right'}>
                  <NumberFormat
                    displayType={'text'}
                    value={product.price}
                    fixedDecimalScale
                    decimalScale={2}
                    thousandSeparator={' '}
                  />
                </TableCell>
                <TableCell align={'right'} className={classes.buttonsTableCell}>
                  <CustomIconButton onClick={() => handleOpenEditProduct(product.id)} type={'edit'} />
                  <CustomIconButton onClick={() => handleOpenDeleteProduct(product.id)} type={'delete'} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <CustomTablePagination
            tablePaginationConfig={{
              count: totalRecords,
              page: selectedPage,
            }}
            onChangePage={handleChangePage}
          />
        </Table>
      </TableContainer>
      <AddProductModal
        isOpen={openAddDialog}
        product={productData}
        materialTypes={materialTypes}
        onClose={handleCloseAddDialog}
        productAdded={handleProductAdded}
      />
      <CustomDialog
        title={'Usuwanie produktu'}
        onCloseDialog={handleCloseDeleteProductDialog}
        body={
          <>
            {'Na pewno chcesz usunąć produkt: '}
            {getProductName(selectedProduct)}
          </>
        }
        isOpen={openDeleteDialog}
        dialogActions={
          <>
            <Button color={'primary'} onClick={handleDeleteProduct}>
              Tak
            </Button>
            <Button color={'primary'} autoFocus onClick={handleCloseDeleteProductDialog}>
              Nie
            </Button>
          </>
        }
      />
      <Snackbar
        open={showNotification}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={handleSnackbarClose}
      >
        <SnackbarAlert message={notificationText} />
      </Snackbar>
    </div>
  );
}
