import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles(({ maxHeightTable }: Theme) => ({
  tableContainer: {
    margin: '0 20px',
    width: 'calc(100% - 40px)',
    maxHeight: maxHeightTable,
  },
  buttonsTableCell: {
    padding: 5,
    width: 100,
    minWidth: 100,
  },
  pre: {
    whiteSpace: 'pre-wrap',
  },
  listItem: {
    padding: 0,
  },
  cashCell: {
    minWidth: 110,
  },
  resourceCell: {
    minWidth: 230,
  },
  supplierCell: {
    minWidth: 160,
  },
  bold: {
    fontWeight: 'bold',
  },
  scenarioName: {
    maxWidth: 400,
    wordWrap: 'break-word',
  },
  scenarioContent: {
    maxWidth: 500,
    wordWrap: 'break-word',
  },
}));
