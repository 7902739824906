import React from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';

import { CustomDialog } from 'app/components/CustomDialog/CustomDialog';
import { SelectComponent } from 'app/components/SelectComponent/SelectComponent';

import { SelectUserRoomFormDataInterface, SelectUserRoomModalProps } from './SelectUserRoomModal.interfaces';
import { useStyles } from './SelectUserRoomModal.styles';

export function SelectUserRoomModal(props: SelectUserRoomModalProps) {
  const { isOpen, onClose, usersRoom, roomSelected } = props;
  const classes = useStyles();
  const formData: SelectUserRoomFormDataInterface = {
    userRoom: '',
  };
  const validationShema = Yup.object().shape({
    userRoom: Yup.string().required('Wybierz Gracza'),
  });
  return (
    <CustomDialog
      title={'Wybierz gracza'}
      isOpen={isOpen}
      onCloseDialog={onClose}
      body={
        <Formik
          initialValues={formData}
          validationSchema={validationShema}
          onSubmit={(values) => {
            const userRoom: number = parseInt(values.userRoom);
            roomSelected(userRoom);
          }}
        >
          {({ submitForm }) => (
            <Form>
              <Field
                component={SelectComponent}
                fullWidth
                label={'Gracze'}
                name={'userRoom'}
                options={usersRoom.map((userRoom, index) => {
                  return {
                    label: userRoom.user && userRoom.user.email ? userRoom.user.email : `BOT ${userRoom.id}`,
                    value: userRoom.id,
                  };
                })}
              />
              <div className={classes.actionButtons}>
                <Button color={'primary'} onClick={submitForm}>
                  Wybierz
                </Button>
                <Button color={'primary'} autoFocus onClick={onClose}>
                  Anuluj
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      }
    />
  );
}
