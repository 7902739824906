import React from 'react';
import { Field, Form, Formik } from 'formik';
import Button from '@material-ui/core/Button';
import * as Yup from 'yup';

import { TextFieldComponent } from 'app/components/TextFieldComponent/TextFieldComponent';
import { CustomDialog } from 'app/components/CustomDialog/CustomDialog';

import { AddAuctionFormDataInterface, AuctionModalProps } from './AuctionModal.interfaces';
import { useStyles } from './AuctionModal.styles';
import { AuctionItemInterface } from 'app/shared/interfaces/AuctionItem.interface';
import { AuctionItemService } from 'app/services/auctionItem.service';
import { upToTwoDecimalPlaces } from 'app/shared/helpers/validators';
import { convertToFloat } from 'app/shared/helpers/numberConverter';

export function AuctionModal(props: AuctionModalProps) {
  const { isOpen, onClose, actualOrder, userRoom } = props;
  const classes = useStyles();

  const formData: AddAuctionFormDataInterface = {
    offer: '',
  };
  const orderPrice = actualOrder ? actualOrder.price : 1;
  const maxPriceForOrder = orderPrice.toFixed(2);
  const validationShema = Yup.object().shape({
    offer: Yup.number()
      .test('is-incorrect', 'Podaj ofertę max. dwa miejsca po przecinku', upToTwoDecimalPlaces)
      .min(1, 'Liczba musi być większa od 0')
      .max(parseFloat(maxPriceForOrder), `Maksymalna oferta to: ${parseFloat(maxPriceForOrder)}`)
      .typeError('Wartość musi być liczbą')
      .required('Podaj ofertę'),
  });

  return (
    <CustomDialog
      title={'Licytacja'}
      isOpen={isOpen}
      onCloseDialog={onClose}
      body={
        <Formik
          initialValues={formData}
          validationSchema={validationShema}
          onSubmit={(values, actions) => {
            if (actualOrder && actualOrder.auctions && userRoom) {
              const auctionData: Omit<AuctionItemInterface, 'id'> = {
                auction: actualOrder.auctions[0],
                participant: userRoom,
                offer: convertToFloat(values.offer),
              };
              AuctionItemService.addAuctionItem(auctionData).then(() => {
                onClose();
              });
            }
          }}
        >
          {({ submitForm }) => (
            <Form>
              <Field component={TextFieldComponent} name={'offer'} label={'Twoja oferta'} fullWidth />
              <div className={classes.actionButtons}>
                <Button color={'primary'} onClick={submitForm}>
                  Zapisz
                </Button>
                <Button color={'primary'} autoFocus onClick={onClose}>
                  Anuluj
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      }
    />
  );
}
