import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';

import { GetParamsInterface } from 'app/shared/interfaces/RequestInterface.interface';
import { defaultParams } from 'app/shared/helpers/pagination';
import { UserService } from 'app/services/user.service';
import { UserInterface } from 'app/shared/interfaces/User.interface';
import { UsersTable } from 'app/components/UsersTable/UsersTable';

import { useStyles } from './UsersPage.styles';

function UsersPage() {
  const classes = useStyles();
  const [users, setUsers] = useState<Array<UserInterface>>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);

  const getUsers = (params: GetParamsInterface = defaultParams) => {
    UserService.getUsers({ ...params, onlyRegularUsers: true }).then(
      ({ data: { 'hydra:member': responseData, 'hydra:totalItems': totalRecords } }) => {
        setUsers(responseData);
        setTotalRecords(totalRecords);
      }
    );
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <div className={classes.root}>
      <Typography variant={'h1'}>Użytkownicy</Typography>
      <UsersTable users={users} totalRecords={totalRecords} shouldUpdateUsers={getUsers} />
    </div>
  );
}

export default UsersPage;
