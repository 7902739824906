import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import clsx from 'clsx';
import { Form, Formik } from 'formik';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

import { CustomDialog } from 'app/components/CustomDialog/CustomDialog';
import { fetchGameFactoryById, getActualSupplier } from 'app/store/factory';
import { GameProcesses } from 'app/components/GameProcesses/GameProcesses';
import { getSelectedUserRoom } from 'app/store/userRoom';
import { GamePrepareModalInterface, TabPanelProps } from './GamePrepareModal.interface';
import { GameFactoryService } from 'app/services/gameFactory.service';
import { ActualSupplierService } from 'app/services/actualSupplier.service';
import { ActualSupplierInterface } from 'app/shared/interfaces/ActualSupplier.interface';
import { useStyles } from './GamePrepareModal.styles';
import { GameResources } from 'app/components/GameResources/GameResources';
import { GameProducts } from 'app/components/GameProducts/GameProducts';

export function GamePrepareModal(props: GamePrepareModalInterface) {
  const {
    isOpen,
    suppliers,
    gameFactoryId,
    supplierChosen,
    showMode,
    actualProcesses,
    actualScenario,
    resources,
  } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const [actualSuppliers, setActualSuppliers] = useState<Array<ActualSupplierInterface>>([]);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [tab, setTab] = React.useState<number>(0);

  const actualSupplier = useSelector(getActualSupplier);
  const selectedUserRoom = useSelector(getSelectedUserRoom);

  const getFirstSupplierId = useCallback(() => (suppliers[0] ? suppliers[0].id : -1), [suppliers]);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
  };

  const formData: { suppliers: string } = {
    suppliers: getFirstSupplierId().toString(),
  };

  const validationShema = Yup.object().shape({
    suppliers: Yup.string().required('Wybierz dostawcę'),
  });

  const handleSelectChange = (id: number, setFieldValue: any) => {
    setFieldValue('suppliers', id);
    setGameFactoryActualSupplier(id);
  };

  const setGameFactoryActualSupplier = useCallback(
    (id: number) => {
      GameFactoryService.setGameFactoryActualSupplier(gameFactoryId, id).then((data) => {
        dispatch(fetchGameFactoryById(gameFactoryId));
        getActualSuppliers(gameFactoryId);
      });
    },
    [dispatch, gameFactoryId]
  );

  const getActualSuppliers = (factoryId: number) => {
    ActualSupplierService.getActualSuppliers(factoryId).then(({ data: { 'hydra:member': responseData } }) => {
      setActualSuppliers(responseData);
    });
  };

  const getTimeDelivery = (id: number) => {
    const actualSupplier = actualSuppliers.find((supplier) => supplier.id === id);
    return actualSupplier ? actualSupplier.timeDelivery : '---';
  };

  const setGameFactoryFirstActualSupplier = useCallback(() => {
    const firstSupplierId = getFirstSupplierId();
    if (firstSupplierId !== -1 && !showMode) {
      setGameFactoryActualSupplier(firstSupplierId);
    }
  }, [showMode, getFirstSupplierId, setGameFactoryActualSupplier]);

  const initActualSupplier = useCallback(() => {
    if (showMode && actualSupplier) {
      getActualSuppliers(gameFactoryId);
    }
  }, [showMode, actualSupplier, gameFactoryId]);

  const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
  };

  const a11yProps = (index: number) => {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  };

  useEffect(() => {
    if (!isInitialized) {
      setGameFactoryFirstActualSupplier();
      initActualSupplier();
      setIsInitialized(true);
    }
  }, [setGameFactoryFirstActualSupplier, initActualSupplier, isInitialized]);

  return (
    <CustomDialog
      title={showMode ? 'Pozycja Twojej fabryki' : 'Wybierz gdzie chcesz postawić fabrykę'}
      isOpen={isOpen}
      hideCloseButton
      maxWidth={'xl'}
      body={
        <div>
          <Formik
            initialValues={formData}
            validationSchema={validationShema}
            onSubmit={(values) => {
              supplierChosen();
            }}
          >
            {({ submitForm, values, setFieldValue }) => (
              <Form>
                <div className={classes.tablesContainer}>
                  <div className={clsx(classes.suppliersContainer, { [classes.marginRight]: !showMode })}>
                    {!showMode && (
                      <FormControl fullWidth className={classes.select}>
                        <InputLabel>Dostawcy</InputLabel>
                        <Select
                          value={values.suppliers}
                          onChange={(e) => {
                            const id = e.target.value as number;
                            handleSelectChange(id, setFieldValue);
                          }}
                        >
                          {suppliers.map((supplier) => (
                            <MenuItem value={supplier.id} key={supplier.id}>
                              {supplier.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Nazwa</TableCell>
                            <TableCell>Materiały</TableCell>
                            <TableCell>Odległość</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {suppliers.map((supplier) => (
                            <TableRow
                              key={supplier.id}
                              className={clsx({
                                [classes.activeRow]: supplier.id === ((actualSupplier && actualSupplier.id) || -1),
                              })}
                            >
                              {/*<TableRow key={supplier.id}>*/}
                              <TableCell>{supplier.name}</TableCell>
                              <TableCell>
                                {supplier.actualMaterials.map((material) => (
                                  <div key={material.id}>
                                    {`${material.name}
                                    Typ materiału: ${material?.materialTypeActual?.name || '-'}
                                    Cena: ${material.price.toFixed(2)} PLN
                                    Jakość: ${material.quality}`}
                                  </div>
                                ))}
                              </TableCell>
                              <TableCell>{getTimeDelivery(supplier.id)}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                  {!showMode && actualProcesses && actualScenario && resources && (
                    <div className={classes.tabContainer}>
                      <AppBar position="static" color="default">
                        <Tabs
                          value={tab}
                          onChange={handleTabChange}
                          indicatorColor="primary"
                          textColor="primary"
                          variant="scrollable"
                          scrollButtons="auto"
                          aria-label="scrollable auto tabs example"
                        >
                          <Tab label={'Zadanie'} {...a11yProps(0)} />
                          <Tab label={'Zasoby'} {...a11yProps(1)} />
                          <Tab label={'Procesy'} {...a11yProps(2)} />
                          <Tab label={'Ceny'} {...a11yProps(3)} />
                        </Tabs>
                      </AppBar>
                      <TabPanel index={0} value={tab}>
                        <div className={classes.scenariosContainer}>
                          <div className={classes.scenarioContent}>{actualScenario.content}</div>
                        </div>
                      </TabPanel>
                      <TabPanel index={1} value={tab}>
                        <GameResources resources={resources} extended />
                      </TabPanel>
                      <TabPanel index={2} value={tab}>
                        <GameProcesses actualProcesses={actualProcesses} userRoom={selectedUserRoom} showMode />
                      </TabPanel>
                      <TabPanel index={3} value={tab}>
                        <GameProducts actualProcesses={actualProcesses} />
                      </TabPanel>
                    </div>
                  )}
                </div>
                <div className={classes.actionButtons}>
                  <Button color={'primary'} onClick={submitForm}>
                    {showMode ? 'Zamknij' : 'Zapisz'}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      }
    />
  );
}
