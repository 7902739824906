import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 20px',
  },
  alertTimer: {
    color: 'red',
    fontWeight: 'bold',
  },
  normalTimer: {
    color: '#767577',
    fontWeight: 'bold',
  },
  alarmIcon: {
    color: '#B4B4B4',
    marginBottom: 5,
  },
}));
