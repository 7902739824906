import { AxiosPromise } from 'axios';

import { ApiService } from './api.service';
import { MaterialInterface, MaterialResponse } from 'app/shared/interfaces/Material.interface';
import { GetParamsInterface } from 'app/shared/interfaces/RequestInterface.interface';

export const MaterialService = {
  getMaterials(params: GetParamsInterface = {}): AxiosPromise<MaterialResponse> {
    return ApiService.get('materials', params);
  },

  addMaterial(data: Omit<MaterialInterface, 'id'>): AxiosPromise {
    return ApiService.post('materials', data);
  },

  replaceMaterial(id: number, data: Omit<MaterialInterface, 'id'>) {
    return ApiService.put(`materials/${id}`, data);
  },

  deleteMaterial(id: number): AxiosPromise {
    return ApiService.delete(`materials/${id}`);
  },
};
