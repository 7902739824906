import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';

import { RoomService } from 'app/services/room.service';
import { UserService } from 'app/services/user.service';
import { ScenarioSerivce } from 'app/services/scenario.service';
import { GetParamsInterface } from 'app/shared/interfaces/RequestInterface.interface';
import { defaultParams } from 'app/shared/helpers/pagination';
import { RoomsTable } from 'app/components/RoomsTable/RoomsTable';
import { RoomInterface } from 'app/shared/interfaces/Room.interface.js';
import { UserInterface } from 'app/shared/interfaces/User.interface';
import { ScenarioInterface } from 'app/shared/interfaces/Scenario.interface';

import { useStyles } from './RoomArchivePage.styles';

function RoomArchivePage() {
  const classes = useStyles();
  const [rooms, setRooms] = useState<Array<RoomInterface>>([]);
  const [users, setUsers] = useState<Array<UserInterface>>([]);
  const [scenarios, setScenarios] = useState<Array<ScenarioInterface>>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);

  const getRooms = (params: GetParamsInterface = { ...defaultParams, showArchivedRoom: true }) => {
    RoomService.getRooms(params).then(
      ({ data: { 'hydra:member': responseData, 'hydra:totalItems': totalRecords } }) => {
        setRooms(responseData);
        setTotalRecords(totalRecords);
      }
    );
  };

  const getUsers = () => {
    UserService.getUsers().then(({ data: { 'hydra:member': responseData } }) => {
      setUsers(responseData);
    });
  };

  const getScenarios = () => {
    ScenarioSerivce.getScenarios().then(({ data: { 'hydra:member': responseData } }) => {
      setScenarios(responseData);
    });
  };

  useEffect(() => {
    getRooms();
    getUsers();
    getScenarios();
  }, []);

  return (
    <div className={classes.root}>
      <Typography variant={'h1'}>Pokoje zarchiwizowane</Typography>
      <RoomsTable
        shouldUpdateRooms={getRooms}
        scenarios={scenarios}
        users={users}
        rooms={rooms}
        totalRecords={totalRecords}
        showAddButton={false}
      />
    </div>
  );
}

export default RoomArchivePage;
