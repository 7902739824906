import React from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';

import { CustomDialog } from 'app/components/CustomDialog/CustomDialog';
import { TextFieldComponent } from 'app/components/TextFieldComponent/TextFieldComponent';
import { UserInterface } from 'app/shared/interfaces/User.interface';
import { UserService } from 'app/services/user.service';
import { SelectComponent } from 'app/components/SelectComponent/SelectComponent';
import { userStatusOptions } from 'app/shared/constants';

import { useStyles } from './AddUserModal.styles';
import { AddUserFormDataInterface, AddUserModalProps } from './AddUserModal.interfaces';

export function AddUserModal(props: AddUserModalProps) {
  const { isOpen, onClose, data, userAdded } = props;
  const classes = useStyles();

  const formData: AddUserFormDataInterface = {
    status: data ? data.status : 0,
    email: data ? data.email.toString() : '',
  };

  const validationShema = Yup.object().shape({
    status: Yup.string().required('Wybierz status'),
    email: Yup.string().required('Podja e-mail'),
  });

  return (
    <CustomDialog
      title={data ? 'Edytuj użytkownika' : 'Dodaj użytkownika'}
      isOpen={isOpen}
      onCloseDialog={onClose}
      body={
        <Formik
          initialValues={formData}
          validationSchema={validationShema}
          onSubmit={(values) => {
            const userData: Omit<UserInterface, 'id' | 'roles'> = {
              email: values.email,
              status: values.status,
            };

            if (data && data.id) {
              UserService.replaceUser(data.id, userData).then(() => {
                userAdded();
              });
            } else {
              UserService.addUser(userData).then(() => {
                userAdded();
              });
            }
          }}
        >
          {({ submitForm }) => (
            <Form>
              <Field disabled component={TextFieldComponent} name={'email'} label={'email'} fullWidth />
              <Field
                component={SelectComponent}
                fullWidth
                label={'Status konta'}
                name={'status'}
                options={userStatusOptions.map((userStatusOption) => ({
                  label: userStatusOption.label,
                  value: userStatusOption.value,
                }))}
              />
              <div className={classes.actionButtons}>
                <Button color={'primary'} onClick={submitForm}>
                  {data ? 'Zapisz' : 'Dodaj'}
                </Button>
                <Button color={'primary'} autoFocus onClick={onClose}>
                  Anuluj
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      }
    />
  );
}
