import { AppThunk } from 'app/store/store';

import { GanttTemplateService } from 'app/services/ganttTemplate.service';
import { setGanttTemplate, setGanttOrders, setActiveRoundsGanttOrders } from 'app/store/gantt';
import { GanttTemplateItemService } from 'app/services/ganttTemplateItem.service';
import { GanttTemplateItemInterface } from 'app/shared/interfaces/GanttTemplateItem.interface';
import { GameGanttOrderItemService } from 'app/services/gameGanttOrderItem.service';
import { AddGameGanttOrderItemInterface } from 'app/shared/interfaces/GameGanttOrderItem.interface';
import { GameGanttOrderService } from 'app/services/gameGanttOrder';

export const fetchGanttTemplateById = (id: number): AppThunk => (dispatch) => {
  GanttTemplateService.getGanttTemplateById(id).then(({ data }) => {
    dispatch(setGanttTemplate(data));
  });
};

export const addGanttTemplateItem = (
  ganttTemplateId: number,
  data: Omit<GanttTemplateItemInterface, 'id'>
): AppThunk => (dispatch) => {
  GanttTemplateItemService.addGanttTemplateItem(data).then(() => {
    dispatch(fetchGanttTemplateById(ganttTemplateId));
  });
};

export const deleteGanttTemplateItem = (ganttTemplateId: number, ganttTemplateItemId: number): AppThunk => (
  dispatch
) => {
  GanttTemplateItemService.deleteGanttTemplateItem(ganttTemplateItemId).then(() => {
    dispatch(fetchGanttTemplateById(ganttTemplateId));
  });
};

export const replaceGanttTemplateItem = (
  ganttTemplateId: number,
  ganttTemplateItemId: number,
  data: Omit<GanttTemplateItemInterface, 'id' | 'ganttTemplate' | 'action'>
): AppThunk => (dispatch) => {
  GanttTemplateItemService.replaceGanttTemplateItem(ganttTemplateItemId, data).then(() => {
    dispatch(fetchGanttTemplateById(ganttTemplateId));
  });
};

export const fetchGanttOrders = (ganttId: number): AppThunk => (dispatch) => {
  GameGanttOrderService.getGameGanttOrders(ganttId).then(({ data: { 'hydra:member': responseData } }) => {
    dispatch(setGanttOrders(responseData));
    dispatch(setActiveRoundsGanttOrders(responseData));
  });
};

export const addGanttItemToOrder = (item: AddGameGanttOrderItemInterface, gameGanttId: number): AppThunk => (
  dispatch
) => {
  GameGanttOrderItemService.addGameGanttOrderItem(item).then(() => {
    dispatch(fetchGanttOrders(gameGanttId));
  });
};

export const replaceGanttItemInOrder = (
  item: AddGameGanttOrderItemInterface,
  ganttItemId: number,
  gameGanttId: number
): AppThunk => (dispatch) => {
  GameGanttOrderItemService.replaceGameGanttOrder(ganttItemId, item).then(() => {
    dispatch(fetchGanttOrders(gameGanttId));
  });
};

export const deleteGanttItem = (ganttItemId: number, gameGanttId: number): AppThunk => (dispatch) => {
  GameGanttOrderItemService.deleteGameGanttOrder(ganttItemId).then(() => {
    dispatch(fetchGanttOrders(gameGanttId));
  });
};
