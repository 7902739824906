import { AxiosPromise } from 'axios';

import { ApiService } from './api.service';
import { AddScenarioInterface, ScenarioResponse } from 'app/shared/interfaces/Scenario.interface';
import { GetParamsInterface } from 'app/shared/interfaces/RequestInterface.interface';

export const ScenarioSerivce = {
  getScenarios(params: GetParamsInterface = {}): AxiosPromise<ScenarioResponse> {
    return ApiService.get('scenario_templates', params);
  },

  addScenario(data: AddScenarioInterface): AxiosPromise {
    return ApiService.post('scenario_templates', data);
  },

  replaceScenario(id: number, data: AddScenarioInterface): AxiosPromise {
    return ApiService.put(`scenario_templates/${id}`, data);
  },

  deleteScenario(id: number): AxiosPromise {
    return ApiService.delete(`scenario_templates/${id}`);
  },
};
