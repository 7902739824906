import React from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';

import { ActionInterface } from 'app/shared/interfaces/Action.interface';
import { ActionService } from 'app/services/action.service';
import { TextFieldComponent } from 'app/components/TextFieldComponent/TextFieldComponent';
import { MultipleSelectComponent } from 'app/components/MultipleSelectComponent/MultipleSelectComponent';
import { CustomDialog } from 'app/components/CustomDialog/CustomDialog';
import { InputColorPicker } from 'app/components/InputColorPicker/InputColorPicker';
import { SelectComponent } from 'app/components/SelectComponent/SelectComponent';
import { transportOptions } from 'app/shared/constants';
import { upToTwoDecimalPlaces } from 'app/shared/helpers/validators';

import { AddActionFormDataInterface, AddActionModalProps } from './AddActionModal.interfaces';
import { useStyles } from './AddActionModal.styles';
import { handlingCatch } from 'app/shared/helpers/handlingCatch';
import { convertToInt, convertToFloat } from 'app/shared/helpers/numberConverter';

export function AddActionModal(props: AddActionModalProps) {
  const { isOpen, onClose, action, actionAdded, resources, actionTypes } = props;
  const classes = useStyles();

  const formData: AddActionFormDataInterface = {
    name: action ? action.name : '',
    timeWorking: action ? action.timeWorking.toString() : '',
    resources: action && action.resources ? action.resources.map((resource) => resource.id) : [],
    actionType: action && action.actionTypes[0] ? action.actionTypes[0].id.toString() : '',
    loss: action ? action.loss.toString() : '',
    WTP: action ? action.WTP.toString() : '',
    description: action && action.description ? action.description : '',
    color: action ? action.color : '#ffffff',
    isTransport: action ? action.isTransport.toString() : '',
  };

  const validationShema = Yup.object().shape({
    name: Yup.string().required('Podaj nazwę'),
    timeWorking: Yup.number()
      .test(
        'is-incorrect',
        'Podaj czas TJ, wartość powinna być liczbą rzeczywistą, max. dwa miejsca po przecinku',
        upToTwoDecimalPlaces
      )
      .min(0, 'Podaj czas TJ, wartość powinna być liczbą rzeczywistą, max. dwa miejsca po przecinku')
      .typeError('Podaj czas TJ, wartość powinna być liczbą rzeczywistą, max. dwa miejsca po przecinku')
      .required('Podaj czas TJ, wartość powinna być liczbą rzeczywistą, max. dwa miejsca po przecinku'),
    WTP: Yup.number()
      .test(
        'is-incorrect',
        'Podaj WSP TPZ, wartość powinna być liczbą rzeczywistą, max. dwa miejsca po przecinku',
        upToTwoDecimalPlaces
      )
      .min(0, 'Podaj WSP TPZ, wartość powinna być liczbą rzeczywistą, max. dwa miejsca po przecinku')
      .typeError('Podaj WSP TPZ, wartość powinna być liczbą rzeczywistą, max. dwa miejsca po przecinku')
      .required('Podaj WSP TPZ, wartość powinna być liczbą rzeczywistą, max. dwa miejsca po przecinku'),
    actionType: Yup.string().required('Wybierz typ czynności'),
    resources: Yup.array().required('Wybierz co najmniej jeden zasób'),
    loss: Yup.number()
      .min(0, 'Podaj wartość straty w procentach 0-100')
      .max(100, 'Podaj wartość straty w procentach 0-100')
      .typeError('Podaj wartość straty w procentach 0-100')
      .integer('Podaj wartość straty w procentach 0-100')
      .required('Podaj wartość straty w procentach 0-100'),
    isTransport: Yup.string().required('Wybierz właściwość zasobu'),
  });

  return (
    <CustomDialog
      title={action ? 'Edycja czynności' : 'Dodaj czynność'}
      isOpen={isOpen}
      onCloseDialog={onClose}
      body={
        <Formik
          initialValues={formData}
          validationSchema={validationShema}
          onSubmit={(values, actions) => {
            const actionData: Omit<ActionInterface, 'id'> = {
              name: values.name.trim(),
              timeWorking: convertToFloat(values.timeWorking) || 0,
              loss: convertToInt(values.loss) || 0,
              WTP: convertToFloat(values.WTP) || 0,
              description: values.description,
              resources: resources.filter((resource) => values.resources.includes(resource.id || -1)),
              actionTypes: actionTypes.filter((actionType) =>
                [parseInt(values.actionType)].includes(actionType.id || -1)
              ),
              isTransport: values.isTransport.toString() === 'true',
              color: values.color,
            };
            if (action && action.id) {
              ActionService.replaceAction(action.id, actionData)
                .then(() => {
                  actionAdded();
                })
                .catch((error) => {
                  const response = handlingCatch(error.response);
                  actions.setFieldError(response.fieldError, response.message);
                });
            } else {
              ActionService.addAction(actionData)
                .then(() => {
                  actionAdded();
                })
                .catch((error) => {
                  const response = handlingCatch(error.response);
                  actions.setFieldError(response.fieldError, response.message);
                });
            }
          }}
        >
          {({ submitForm }) => (
            <Form>
              <Field component={TextFieldComponent} name={'name'} label={'Nazwa'} fullWidth />
              <Field
                component={TextFieldComponent}
                name={'description'}
                label={'Opis'}
                multiline
                rowsMax={4}
                fullWidth
              />
              <Field
                component={MultipleSelectComponent}
                fullWidth
                label={'Zasoby produkcyjne'}
                name={'resources'}
                options={resources.map((resource) => ({
                  label: resource.name,
                  value: resource.id,
                }))}
              />
              <Field
                component={SelectComponent}
                fullWidth
                label={'Typy czynności'}
                name={'actionType'}
                options={actionTypes.map((actionType) => ({
                  label: actionType.name,
                  value: actionType.id,
                }))}
              />
              <Field component={TextFieldComponent} name={'timeWorking'} label={'Czas TJ'} fullWidth />
              <Field component={TextFieldComponent} name={'loss'} label={'Strata %'} fullWidth />
              <Field component={TextFieldComponent} name={'WTP'} label={'WSP TPZ'} fullWidth />
              <Field component={InputColorPicker} name={'color'} label={'Kolor'} />
              <Field
                component={SelectComponent}
                fullWidth
                label={'Właściwość transportu'}
                name={'isTransport'}
                options={transportOptions.map((transportOption) => ({
                  label: transportOption.label,
                  value: transportOption.value,
                }))}
              />
              <div className={classes.actionButtons}>
                <Button color={'primary'} onClick={submitForm}>
                  {action ? 'Zapisz' : 'Dodaj'}
                </Button>
                <Button color={'primary'} autoFocus onClick={onClose}>
                  Anuluj
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      }
    />
  );
}
