import React from 'react';
import { useSelector } from 'react-redux';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import { GameGanttAccordionProps } from './GameGanttAccordion.interface';
import { GameOwnMaterials } from 'app/components/GameOwnMaterials/GameOwnMaterials';
import { getGameMaterials, getSelectedUserRoom } from 'app/store/userRoom';
import { GameOwnResources } from 'app/components/GameOwnResources/GameOwnResources';
import { GameProcesses } from 'app/components/GameProcesses/GameProcesses';

import { useStyles } from './GameGanttAccordion.styles';

export function GameGanttAccordion({ updateRoom, actualProcesses }: GameGanttAccordionProps) {
  const classes = useStyles();
  const gameMaterials = useSelector(getGameMaterials);
  const selectedUserRoom = useSelector(getSelectedUserRoom);
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) =>
    setExpanded(isExpanded ? panel : false);

  return (
    <div>
      <Accordion expanded={expanded === 'b1'} onChange={handleChange('b1')}>
        <AccordionSummary
          id={'b1'}
          expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
          className={classes.accordionSummary}
        >
          <div>Procesy</div>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <GameProcesses updateRoom={updateRoom} actualProcesses={actualProcesses} userRoom={selectedUserRoom} />
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'b2'} onChange={handleChange('b2')}>
        <AccordionSummary
          id={'b2'}
          expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
          className={classes.accordionSummary}
        >
          <div>Zasoby</div>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <GameOwnResources />
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'b3'} onChange={handleChange('b3')}>
        <AccordionSummary
          id={'b3'}
          expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
          className={classes.accordionSummary}
        >
          <div>Materiały</div>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <GameOwnMaterials gameMaterials={gameMaterials} />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
