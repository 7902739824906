import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Popper from '@material-ui/core/Popper';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import {
  AddResoucreToFactoryFormDataInterface,
  AddResourceToFactoryPopperProps,
} from './AddResourceToFactoryPopper.interface';
import { TextFieldComponent } from 'app/components/TextFieldComponent/TextFieldComponent';
import { FactoryResourcesService } from 'app/services/factoryResources.service';
import { FactoryResourceInterface } from 'app/shared/interfaces/FactoryResource.interface';
import { useStyles } from './AddResourceToFactoryPopper.styles';
import { gridAxisInitialSettings, isCollision } from 'app/components/FactoryArea/GridFunctions';
import { convertToInt } from 'app/shared/helpers/numberConverter';
import { getFactoryResources } from 'app/store/factory';
import Snackbar from '@material-ui/core/Snackbar';

export function AddResourceToFactoryPopper({
  isOpen,
  anchorEl,
  onClose,
  factory,
  resource,
  resources,
  shouldRefreshOnwResources,
}: AddResourceToFactoryPopperProps) {
  const classes = useStyles();
  const maxCoordinateValue = gridAxisInitialSettings.MAX_AXIS_VALUE;
  const factoryResources = useSelector(getFactoryResources);
  const [showCollisionNotification, setShowCollisionNotification] = useState<boolean>(false);

  const formData: AddResoucreToFactoryFormDataInterface = {
    x: '',
    y: '',
  };

  const resourceLenght = resource ? resource.length : 0;
  const resourceWidth = resource ? resource.width : 0;

  const validationShema = Yup.object().shape({
    x: Yup.number()
      .min(
        gridAxisInitialSettings.MIN_AXIS_VALUE,
        `Wartość musi być większa lub równa ${gridAxisInitialSettings.MIN_AXIS_VALUE}`
      )
      .max(
        maxCoordinateValue - resourceLenght,
        `Wartość musi być mniejsza lub równa ${maxCoordinateValue - resourceLenght}`
      )
      .typeError('Wartość musi być liczbą')
      .required('Podaj x'),
    y: Yup.number()
      .min(
        gridAxisInitialSettings.MIN_AXIS_VALUE,
        `Wartość musi być większa lub równa ${gridAxisInitialSettings.MIN_AXIS_VALUE}`
      )
      .max(
        maxCoordinateValue - resourceWidth,
        `Wartość musi być mniejsza lub równa ${maxCoordinateValue - resourceWidth}`
      )
      .typeError('Wartość musi być liczbą')
      .required('Podaj y'),
  });

  const getResourceById = (id: number) => resources.find((resource) => resource.id === id);

  const isResourceCollisionOnTheFactoryArea = (newX: number, newY: number) =>
    !!(
      resource &&
      factoryResources.find((gridFactoryResource) =>
        isCollision(newX, newY, gridFactoryResource, getResourceById(gridFactoryResource.resource.id), resource)
      )
    );

  const handleCollisionSnackbarClose = () => {
    setShowCollisionNotification(false);
  };

  return (
    <div>
      <Popper open={isOpen} anchorEl={anchorEl} placement={'left'} className={classes.root}>
        <div>
          <div className={classes.header}>Podaj współrzędne</div>
          <Formik
            initialValues={formData}
            validationSchema={validationShema}
            onSubmit={(values) => {
              if (resource) {
                const data: Omit<FactoryResourceInterface, 'id'> = {
                  factory: factory,
                  resource: resource,
                  x: convertToInt(values.x),
                  y: convertToInt(values.y),
                };
                if (isResourceCollisionOnTheFactoryArea(data.x, data.y)) {
                  setShowCollisionNotification(true);
                } else {
                  FactoryResourcesService.addFactoryResource(data).then(() => {
                    shouldRefreshOnwResources();
                  });
                }
              }
            }}
          >
            {({ submitForm }) => (
              <Form className={classes.form}>
                <Field component={TextFieldComponent} name={'x'} label={'x'} className={classes.textField} />
                <Field component={TextFieldComponent} name={'y'} label={'y'} className={classes.textField} />
                <ButtonGroup color={'primary'} size={'small'}>
                  <Button onClick={submitForm}>Dodaj</Button>
                  <Button onClick={onClose}>Anuluj</Button>
                </ButtonGroup>
              </Form>
            )}
          </Formik>
        </div>
      </Popper>
      <Snackbar
        open={showCollisionNotification}
        autoHideDuration={2000}
        message={'Brak wystarczającej powierzchni do umieszczenia zasobu'}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={handleCollisionSnackbarClose}
      >
        <div className={classes.notification}>Brak wystarczającej powierzchni do umieszczenia zasobu</div>
      </Snackbar>
    </div>
  );
}
