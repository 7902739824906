import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';

import { GanttActionsProps } from './GanttActions.interface';
import { getActiveAction, setActiveAction, clearActiveAction } from 'app/store/gantt';
import { useStyles } from './GanttActions.styles';

export function GanttActions({ actions }: GanttActionsProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const activeAction = useSelector(getActiveAction);

  const handleRowClick = (e: React.MouseEvent<HTMLDivElement>, id: number) => {
    const action = getActionById(id);

    if (action && activeAction && action.id === activeAction.id) {
      dispatch(clearActiveAction());
    } else if (action) {
      dispatch(setActiveAction(action));
    }
  };

  const getActionById = (id: number) => actions.find((act) => act.id === id);

  return (
    <div className={classes.root}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Nazwa</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {actions.map((action) => (
            <TableRow
              key={action.id}
              className={clsx(classes.tableRow, {
                [classes.activeRow]: activeAction && activeAction.id === action.id,
              })}
              onClick={(e) => handleRowClick(e, action.id)}
            >
              <TableCell className={classes.tableCell}>
                {action.description ? action.description : action.name}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
