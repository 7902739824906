import { AppThunk } from 'app/store/store';
import { GameRoomInterface } from 'app/shared/interfaces/GameRoom.interface';
import { setGameRounds, clearGameRounds, setRoundPhaseStatus } from 'app/store/gameRounds';

export const setRounds = (gameRounds: Array<GameRoomInterface>): AppThunk => (dispatch) => {
  dispatch(setGameRounds(gameRounds));
};

export const clearRounds = (): AppThunk => (dispatch) => {
  dispatch(clearGameRounds);
};

export const setSelectedRoundPhase = (roundPhaseStatus: number): AppThunk => (dispatch) => {
  dispatch(setRoundPhaseStatus(roundPhaseStatus));
};
