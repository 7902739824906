import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  body: {
    display: 'flex',
    height: 450,
  },
  title: {
    fontSize: 15,
    fontWeight: 'bold',
    padding: 5,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: 25,
    width: 120,
    marginTop: 50,
  },
  addButton: {
    marginBottom: 15,
  },
  listContainer: {
    width: 200,
    height: 400,
    overflow: 'auto',
    border: '1px solid #ccc',
  },
  item: {
    userSelect: 'none',
    padding: 5,
    borderBottom: '1px solid #ccc',
    cursor: 'pointer',
  },
  itemActive: {
    backgroundColor: '#adffb8',
  },
  itemDragging: {
    backgroundColor: 'lightgreen',
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20,
  },
}));
