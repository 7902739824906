import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import SettingsIcon from '@material-ui/icons/Settings';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';

import { GanttTemplatesTableProps } from './GanttTemplatesTable.interface';
import { CustomDialog } from 'app/components/CustomDialog/CustomDialog';
import { CustomTablePagination } from 'app/components/CustomTablePagination/CustomTablePagination';
import { getParams } from 'app/shared/helpers/pagination';
import { CustomIconButton } from '../CustomIconButton/CustomIconButton';
import { GanttTemplateService } from 'app/services/ganttTemplate.service';
import { AddGanttTemplateModal } from 'app/components/AddGanttTemplatesModal/AddGanttTemplatesModal';
import { GanttInterface } from 'app/shared/interfaces/GanttTemplate.interface';
import { handlingCatch } from 'app/shared/helpers/handlingCatch';
import { SnackbarAlert } from 'app/components/SnackbarAlert/SnackbarAlert';

import { useStyles } from './GanttTemplatesTable.styles';

export function GanttTemplatesTable({
  ganttTemplates,
  shouldUpdateGanttTemplates,
  totalRecords,
  addClick,
}: GanttTemplatesTableProps) {
  const classes = useStyles();
  const [selectedGanttTemplateId, setSelectedGanttTemplateId] = useState<number>(0);
  const [selectedPage, setSelectedPage] = useState<number>(0);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [openAddDialog, setOpenAddDialog] = useState<boolean>(false);
  const [ganttTemplateData, setGanttTemplateData] = useState<GanttInterface | null>(null);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [notificationText, setNotificationText] = useState<string>('');

  const handleSnackbarClose = () => {
    setShowNotification(false);
  };

  const handleOpenAddGanttTemplate = () => {
    setGanttTemplateData(null);
    setOpenAddDialog(true);
  };

  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
    setGanttTemplateData(null);
  };

  const handleOpenEditGanttTemplate = (id: number) => {
    const ganttTemplate = getGanttTemplateById(id);
    if (ganttTemplate) {
      setGanttTemplateData(ganttTemplate);
      setOpenAddDialog(true);
    }
  };

  const handleGanttTemplateAdded = () => {
    shouldUpdateGanttTemplates(getParams(selectedPage));
    setGanttTemplateData(null);
    setOpenAddDialog(false);
  };

  const handleOpenDeleteGanttTemplate = (id: number) => {
    setSelectedGanttTemplateId(id);
    setOpenDeleteDialog(true);
  };

  const handleDeleteGanttTemplate = () => {
    GanttTemplateService.deleteGanttTemplate(selectedGanttTemplateId)
      .then(() => {
        setOpenDeleteDialog(false);
        shouldUpdateGanttTemplates(getParams(selectedPage));
      })
      .catch((error) => {
        const response = handlingCatch(error.response);
        const { status } = error.response;
        if (status === 400) {
          setNotificationText(response.message);
          setShowNotification(true);
          setOpenDeleteDialog(false);
        }
      });
  };

  const handleCloseDeleteDialog = () => setOpenDeleteDialog(false);

  const getGanttTemplateById = (id: number) => ganttTemplates.find((gantt) => gantt.id === id);

  const getGanttTemplateNameById = (id: number) => {
    const gantt = getGanttTemplateById(id);
    return gantt ? gantt.name : '';
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    shouldUpdateGanttTemplates(getParams(page));
    setSelectedPage(page);
  };

  useEffect(() => {
    if (addClick) {
      handleOpenAddGanttTemplate();
    }
  }, [addClick]);

  return (
    <div>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Nazwa</TableCell>
              <TableCell>Opis</TableCell>
              <TableCell align={'right'} className={classes.buttonsTableCell} />
            </TableRow>
          </TableHead>
          <TableBody>
            {ganttTemplates.map((gantt) => (
              <TableRow key={gantt.id}>
                <TableCell className={clsx(classes.wrap, classes.bold)}>{gantt.name}</TableCell>
                <TableCell className={clsx(classes.wrap)}>{gantt.description}</TableCell>
                <TableCell align={'right'} className={classes.buttonsTableCell}>
                  <Link to={`gantt/${gantt.id}`}>
                    <IconButton>
                      <SettingsIcon fontSize={'small'} />
                    </IconButton>
                  </Link>
                  <CustomIconButton onClick={() => handleOpenEditGanttTemplate(gantt.id)} type={'edit'} />
                  <CustomIconButton onClick={() => handleOpenDeleteGanttTemplate(gantt.id)} type={'delete'} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <CustomTablePagination
            tablePaginationConfig={{
              count: totalRecords,
              page: selectedPage,
            }}
            onChangePage={handleChangePage}
          />
        </Table>
      </TableContainer>
      <AddGanttTemplateModal
        ganttTemplateData={ganttTemplateData}
        ganttTemplateAdded={handleGanttTemplateAdded}
        isOpen={openAddDialog}
        onClose={handleCloseAddDialog}
      />
      <CustomDialog
        title={'Usuwanie templatki gantta'}
        onCloseDialog={handleCloseDeleteDialog}
        body={
          <>
            {'Na pewno chcesz usunąć templatkę gantta: '}
            {getGanttTemplateNameById(selectedGanttTemplateId)}
          </>
        }
        isOpen={openDeleteDialog}
        dialogActions={
          <>
            <Button color={'primary'} onClick={handleDeleteGanttTemplate}>
              Tak
            </Button>
            <Button color={'primary'} autoFocus onClick={handleCloseDeleteDialog}>
              Nie
            </Button>
          </>
        }
      />
      <Snackbar
        open={showNotification}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={handleSnackbarClose}
      >
        <SnackbarAlert message={notificationText} />
      </Snackbar>
    </div>
  );
}
