import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
  },
  tableContainer: {
    maxHeight: 500,
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20,
  },
  bold: {
    fontWeight: 'bold',
  },
  materialContainer: {
    width: 250,
  },
  fieldContainer: {
    display: 'flex',
    alignItems: 'baseline',
  },
  jw: {
    width: 100,
  },
}));
