import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import NumberFormat from 'react-number-format';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';

import { ResourceService } from 'app/services/resource.service';
import { ResourceInterface } from 'app/shared/interfaces/Resource.interface';
import { getParams } from 'app/shared/helpers/pagination';
import { ResourcesTableProps } from './ResourcesTable.interfaces';
import { handlingCatch } from 'app/shared/helpers/handlingCatch';
import { SnackbarAlert } from 'app/components/SnackbarAlert/SnackbarAlert';
import { CustomIconButton } from 'app/components/CustomIconButton/CustomIconButton';
import { CustomDialog } from 'app/components/CustomDialog/CustomDialog';
import { AddResourceModal } from 'app/components/AddResourceModal/AddResourceModal';
import { CustomTablePagination } from 'app/components/CustomTablePagination/CustomTablePagination';

import { useStyles } from './ResourcesTable.styles';

export function ResourcesTable(props: ResourcesTableProps) {
  const { resources, shouldUpdateResources, resourceTypes, totalRecords, addClick } = props;
  const classes = useStyles();
  const [selectedResource, setSelectedResource] = useState<number>(0);
  const [selectedPage, setSelectedPage] = useState<number>(0);
  const [resourceData, setResourceData] = useState<ResourceInterface | null>(null);

  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [openAddDialog, setOpenAddDialog] = useState<boolean>(false);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [notificationText, setNotificationText] = useState<string>('');

  const handleSnackbarClose = () => {
    setShowNotification(false);
  };

  const handleAddResource = () => {
    setResourceData(null);
    setOpenAddDialog(true);
  };

  const handleOpenEditResource = (id: number) => {
    const resource = getResourceById(id);
    if (resource) {
      setResourceData(resource);
      setOpenAddDialog(true);
    }
  };

  const handleOpenDeleteResource = (id: number) => {
    setSelectedResource(id);
    setOpenDeleteDialog(true);
  };

  const handleDeleteResource = () => {
    ResourceService.deleteResource(selectedResource)
      .then(() => {
        setOpenDeleteDialog(false);
        shouldUpdateResources(getParams(selectedPage));
      })
      .catch((error) => {
        const response = handlingCatch(error.response);
        const { status } = error.response;
        if (status === 400) {
          setNotificationText(response.message);
          setShowNotification(true);
          setOpenDeleteDialog(false);
        }
      });
  };

  const handleCloseDeleteResourceDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
    setResourceData(null);
  };

  const handleResourceAdded = () => {
    shouldUpdateResources(getParams(selectedPage));
    setOpenAddDialog(false);
    setResourceData(null);
  };

  const getResourceById = (id: number) => resources.find((resource) => resource.id === id);

  const getResourceName = (id: number) => {
    const resource = getResourceById(id);
    return resource ? resource.name : '';
  };

  const getResourceTypeName = (resource: ResourceInterface) => {
    return resource.resourceType ? resource.resourceType.name : '';
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    shouldUpdateResources(getParams(page));
    setSelectedPage(page);
  };

  useEffect(() => {
    if (addClick) {
      handleAddResource();
    }
  }, [addClick]);

  return (
    <div>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Nazwa</TableCell>
              <TableCell>Opis</TableCell>
              <TableCell>Typ zasobu</TableCell>
              <TableCell align={'center'}>Długość [m]</TableCell>
              <TableCell align={'center'}>Szerokość [m]</TableCell>
              <TableCell align={'center'}>Czas TPZ</TableCell>
              <TableCell align={'center'}>WSP TJ</TableCell>
              <TableCell align={'center'}>Jakość</TableCell>
              <TableCell align={'right'}>Koszt stały [PLN]</TableCell>
              <TableCell align={'right'}>Koszt zmienny [PLN]</TableCell>
              <TableCell align={'right'}>Cena [PLN]</TableCell>
              <TableCell align={'center'}>Pojemność</TableCell>
              <TableCell className={classes.buttonsTableCell} align={'right'} />
            </TableRow>
          </TableHead>
          <TableBody>
            {resources.map((resource) => (
              <TableRow key={resource.id}>
                <TableCell style={{ background: resource.color }} className={classes.colorCell} />
                <TableCell className={clsx(classes.wrap, classes.bold)}>{resource.name}</TableCell>
                <TableCell className={classes.wrap}>{resource.description}</TableCell>
                <TableCell>{getResourceTypeName(resource)}</TableCell>
                <TableCell align={'center'}>{resource.length}</TableCell>
                <TableCell align={'center'}>{resource.width}</TableCell>
                <TableCell align={'center'}>{resource.timeSetup}</TableCell>
                <TableCell align={'center'}>{resource.wtj}</TableCell>
                <TableCell align={'center'}>{resource.quality}</TableCell>
                <TableCell align={'right'}>
                  <NumberFormat
                    displayType={'text'}
                    value={resource.costFixed}
                    fixedDecimalScale
                    decimalScale={2}
                    thousandSeparator={' '}
                  />
                </TableCell>
                <TableCell align={'right'}>
                  <NumberFormat
                    displayType={'text'}
                    value={resource.costVariable}
                    fixedDecimalScale
                    decimalScale={2}
                    thousandSeparator={' '}
                  />
                </TableCell>
                <TableCell align={'right'}>
                  <NumberFormat
                    displayType={'text'}
                    value={resource.price}
                    fixedDecimalScale
                    decimalScale={2}
                    thousandSeparator={' '}
                  />
                </TableCell>
                <TableCell align={'center'}>{resource.capacity}</TableCell>
                <TableCell align={'right'} className={classes.buttonsTableCell}>
                  <CustomIconButton onClick={() => handleOpenEditResource(resource.id)} type={'edit'} />
                  <CustomIconButton onClick={() => handleOpenDeleteResource(resource.id)} type={'delete'} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <CustomTablePagination
            tablePaginationConfig={{
              count: totalRecords,
              page: selectedPage,
            }}
            onChangePage={handleChangePage}
          />
        </Table>
      </TableContainer>
      <AddResourceModal
        isOpen={openAddDialog}
        resource={resourceData}
        resourceTypes={resourceTypes}
        onClose={handleCloseAddDialog}
        resourceAdded={handleResourceAdded}
      />
      <CustomDialog
        title={'Usuwanie zasobu produkcyjnego'}
        onCloseDialog={handleCloseDeleteResourceDialog}
        body={
          <>
            {'Na pewno chcesz usunąć zasób produkcyjny: '}
            {getResourceName(selectedResource)}
          </>
        }
        isOpen={openDeleteDialog}
        dialogActions={
          <>
            <Button color={'primary'} onClick={handleDeleteResource}>
              Tak
            </Button>
            <Button color={'primary'} autoFocus onClick={handleCloseDeleteResourceDialog}>
              Nie
            </Button>
          </>
        }
      />
      <Snackbar
        open={showNotification}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={handleSnackbarClose}
      >
        <SnackbarAlert message={notificationText} />
      </Snackbar>
    </div>
  );
}
