import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';

import { CustomDialog } from 'app/components/CustomDialog/CustomDialog';
import { AddActionsToProcessModalProps, SelectedActionsInterface } from './AddActionsToProcessModal.interface';
import { AddProcessActivityInterface } from 'app/shared/interfaces/Process.interface';
import { useStyles } from './AddActionsToProcessModal.styles';

export function AddActionsToProcessModal({
  isOpen,
  actions,
  onClose,
  addActions,
  processActivity,
  isOpenForm,
}: AddActionsToProcessModalProps) {
  const classes = useStyles();
  const [selectedActionId, setSelectedActionId] = useState<number>(-1);
  const [selectedActions, setSelectedActions] = useState<Array<SelectedActionsInterface>>([]);
  const [selectedActionsTmp, setSelectedActionsTmp] = useState<Array<SelectedActionsInterface>>([]);
  const [activeSelectedAction, setActiveSelectedAction] = useState<string>('');

  const reorder = (list: Array<SelectedActionsInterface>, startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const createID = () => {
    return (
      Array(16)
        .fill(0)
        .map(() => String.fromCharCode(Math.floor(Math.random() * 26) + 97))
        .join('') + Date.now().toString(24)
    );
  };

  const onDragEnd = (result: DropResult) => {
    if (result.destination) {
      const newOrder = reorder(selectedActions, result.source.index, result.destination.index);
      setSelectedActions([...newOrder]);
    }
  };

  const handleSelectAction = (actionId: number) => setSelectedActionId(selectedActionId === actionId ? -1 : actionId);

  const handleAddAction = (id: number) =>
    setSelectedActions([...selectedActions, { actionId: id, uniqId: createID(), '@id': getActionStringIdById(id) }]);

  const handleRemoveAction = (id: string) => {
    const newSelectedActions = selectedActions.filter((action) => action.uniqId !== id);

    setSelectedActions([...newSelectedActions]);
    setActiveSelectedAction('');
  };

  const handleCloseModal = () => {
    setSelectedActions(selectedActionsTmp);
    onClose();
  };

  const handleSelectActiveAction = (id: string) => setActiveSelectedAction(activeSelectedAction === id ? '' : id);

  const getActionById = (id: number) => actions.find((action) => action.id === id);

  const getActionNameById = (id: number) => {
    const action = getActionById(id);
    return action ? action.name : '';
  };

  const getActionStringIdById = (id: number) => {
    const action = getActionById(id);
    return action && action['@id'] ? action['@id'] : '';
  };

  const handleAdd = () => {
    const choosenActions: Array<AddProcessActivityInterface> = selectedActions.map((action, index) => ({
      activityOrder: index + 1,
      activity: { '@id': action['@id'] },
    }));
    setSelectedActionsTmp(selectedActions);
    addActions(choosenActions);
  };

  useEffect(() => {
    if (processActivity) {
      const actions: Array<SelectedActionsInterface> = processActivity.map((activity, index) => ({
        actionId: activity.activity.id,
        '@id': activity.activity['@id'] || '',
        processActivityId: activity['@id'] || '',
        uniqId: createID(),
      }));

      setSelectedActions(actions);
      setSelectedActionsTmp(actions);
    } else {
      setSelectedActions([]);
    }
    setSelectedActionId(-1);
  }, [processActivity, isOpenForm]);

  return (
    <CustomDialog
      title={'Dodaj czynności do procesu'}
      onCloseDialog={handleCloseModal}
      body={
        <div>
          <div className={classes.body}>
            <div>
              <div className={classes.title}>Czynności</div>
              <div className={classes.listContainer}>
                {actions.map((action) => (
                  <div
                    key={action.id}
                    className={clsx(classes.item, { [classes.itemActive]: selectedActionId === action.id })}
                    onClick={() => handleSelectAction(action.id)}
                  >
                    {action.name}
                  </div>
                ))}
              </div>
            </div>
            <div className={classes.buttonContainer}>
              <Button
                color={'primary'}
                variant={'contained'}
                size={'small'}
                className={classes.addButton}
                disabled={selectedActionId === -1}
                onClick={() => handleAddAction(selectedActionId)}
              >
                {'> >'}
              </Button>
              <Button
                color={'primary'}
                variant={'contained'}
                size={'small'}
                disabled={!activeSelectedAction}
                onClick={() => handleRemoveAction(activeSelectedAction)}
              >
                {'< <'}
              </Button>
            </div>
            <div>
              <div className={classes.title}>Wybrane czynności</div>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId={'droppable'}>
                  {(providedd, snapshot) => (
                    <div {...providedd.droppableProps} ref={providedd.innerRef} className={classes.listContainer}>
                      {selectedActions.map((action, index) => (
                        <Draggable key={action.uniqId} draggableId={action.uniqId} index={index}>
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={provided.draggableProps.style}
                              className={clsx(classes.item, {
                                [classes.itemDragging]: snapshot.isDragging,
                                [classes.itemActive]: activeSelectedAction === action.uniqId,
                              })}
                              onClick={() => handleSelectActiveAction(action.uniqId)}
                            >
                              {getActionNameById(action.actionId)}
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {providedd.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </div>
          <div className={classes.actionButtons}>
            <Button color={'primary'} onClick={handleAdd}>
              {process ? 'Zapisz' : 'Dodaj'}
            </Button>
            <Button color={'primary'} autoFocus onClick={handleCloseModal}>
              Anuluj
            </Button>
          </div>
        </div>
      }
      isOpen={isOpen}
    />
  );
}
