import React, { useState } from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';

import { getParams } from 'app/shared/helpers/pagination';
import { CustomTablePagination } from 'app/components/CustomTablePagination/CustomTablePagination';

import { useStyles } from './ResourceTypesTable.styles';
import { ResourceTypesTableProps } from './ResourceTypesTable.interfaces';

export function ResourceTypesTable(props: ResourceTypesTableProps) {
  const { resourceTypes, shouldUpdateResourceTypes, totalRecords } = props;
  const classes = useStyles();
  const [selectedPage, setSelectedPage] = useState<number>(0);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    shouldUpdateResourceTypes(getParams(page));
    setSelectedPage(page);
  };

  return (
    <div>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Nazwa</TableCell>
              <TableCell>Opis</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {resourceTypes.map((resourceType) => (
              <TableRow key={resourceType.id}>
                <TableCell className={classes.bold}>{resourceType.name}</TableCell>
                <TableCell>{resourceType.description}</TableCell>
              </TableRow>
            ))}
          </TableBody>
          <CustomTablePagination
            tablePaginationConfig={{
              count: totalRecords,
              page: selectedPage,
            }}
            onChangePage={handleChangePage}
          />
        </Table>
      </TableContainer>
    </div>
  );
}
