import React from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';

import { useStyles } from './GameOwnProducts.styles';
import { GameOwnProductsInterface } from './GameOwnProducts.interface';

export function GameOwnProducts(props: GameOwnProductsInterface) {
  const { gameProducts } = props;

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.tableContainer}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHead}>Nazwa</TableCell>
              <TableCell className={classes.tableHead} align={'center'}>
                Ilość w magazynie
              </TableCell>
              <TableCell className={classes.tableHead} align={'center'}>
                Jakość
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {gameProducts.map((gameProduct) => (
              <TableRow key={gameProduct.id}>
                <TableCell>{gameProduct.name}</TableCell>
                <TableCell align={'center'}>{gameProduct.warehouseAmount}</TableCell>
                <TableCell align={'center'}>{gameProduct.quality}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}
