import React from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';

import { GameProductsInterface } from './GameProducts.interface';

import { useStyles } from './GameProducts.styles';

export function GameProducts({ actualProcesses }: GameProductsInterface) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Nazwa</TableCell>
            <TableCell>Opis</TableCell>
            <TableCell>Cena [PLN]</TableCell>
            <TableCell>Materiał</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {actualProcesses.map((actualProcess) => (
            <TableRow key={actualProcess.productActual?.id}>
              <TableCell>{actualProcess.productActual?.name}</TableCell>
              <TableCell>{actualProcess.productActual?.description}</TableCell>
              <TableCell>{actualProcess.productActual?.price.toFixed(2)}</TableCell>
              <TableCell>{actualProcess.productActual?.materialTypeActual?.name}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
