import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  mainContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 10,
    backgroundColor: '#fff',
    height: 72,
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 20px',
  },
  title: {
    fontSize: 24,
    color: '#767577',
    fontWeight: 'bold',
    marginRight: 20,
    width: 100,
    textAlign: 'left',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 40,
    '& button': {
      backgroundColor: '#C3C7F2',
      borderRadius: 20,
      padding: '2px 8px',
      margin: 4,
      fontSize: 11,
    },
  },
  button: {
    borderRadius: 20,
    fontWeight: 'bold',
  },
  widgetContainer: {
    display: 'flex',
  },
}));
