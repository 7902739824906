import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
         toolbar: {
           display: 'flex',
           justifyContent: 'center',
         },
         spacer: {
           display: 'none',
         },
         caption: {
           display: 'none',
         },
       }));
