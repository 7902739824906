import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Popper from '@material-ui/core/Popper';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { getGameFactoryResourceForProductionModal, getIsAutomation } from 'app/store/userRoom';
import { EditGameFactoryResourceModal } from 'app/components/EditGameFactoryResourceModal/EditGameFactoryResourceModal';

import { EditResourceFromFactoryPopperProps } from './EditResourceFromFactoryPopper.interface';
import { useStyles } from './EditResourceFromFactoryPopper.styles';
import { SnackbarAlert } from 'app/components/SnackbarAlert/SnackbarAlert';
import { getActiveRoundGanttOrders } from 'app/store/gantt';
import { ActualResourceInterface } from 'app/shared/interfaces/ActualResource.interface';

export function EditResourceFromFactoryPopper({
  isOpen,
  anchorEl,
  onClose,
  onMove,
  onDelete,
  idFactoryResource,
  selectedGameFactoryResourceData,
  onReload,
}: EditResourceFromFactoryPopperProps) {
  const classes = useStyles();
  const isAutomation = useSelector(getIsAutomation);
  const [anyButtonClicked, setAnyButtonClicked] = useState<boolean>(false);

  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [notificationText, setNotificationText] = useState<string>('');
  const gameFactoryResourceForProductionModal = useSelector(getGameFactoryResourceForProductionModal);
  const activeRoundGanttOrders = useSelector(getActiveRoundGanttOrders);

  const handleOnMove = () => {
    setAnyButtonClicked(true);
    onMove();
  };
  const handleOpenEdit = () => {
    setOpenEditModal(true);
  };

  const handleSnackbarClose = () => {
    setShowNotification(false);
    setNotificationText('');
  };

  const isResourceUsedInGannt = useCallback(
    (resource: ActualResourceInterface) =>
      activeRoundGanttOrders.some((order) =>
        order.gameGanttOrderItems.some(
          (item) =>
            item.gameFactoryResourceFirst?.resourceActual?.id === resource.id ||
            item.gameFactoryResourceSecond?.resourceActual?.id === resource.id ||
            item.gameFactoryResourceTransport?.resourceActual?.id === resource.id
        )
      ),
    [activeRoundGanttOrders]
  );

  const handleOnDelete = useCallback(() => {
    setAnyButtonClicked(true);

    const resource = selectedGameFactoryResourceData?.resourceActual;
    if (resource && isResourceUsedInGannt(resource)) {
      // Deleting resource used in gantt order
      setNotificationText('Przed sprzedażą zwolnij zasób ze zlecenia');
      setShowNotification(true);
    } else if (
      selectedGameFactoryResourceData?.resourceActual?.resourceTypeActual?.name === 'PO' &&
      selectedGameFactoryResourceData.holdingFieldConnection
    ) {
      // Deleting PO with connected resource
      setNotificationText('Nie możesz usunąć Pola odkładczego z powiązanym zasobem');
      setShowNotification(true);
    } else {
      onDelete();
    }

    onClose();
  }, [
    onDelete,
    setAnyButtonClicked,
    setNotificationText,
    setShowNotification,
    selectedGameFactoryResourceData,
    onClose,
    isResourceUsedInGannt,
  ]);

  const handleOnClose = () => {
    setAnyButtonClicked(true);
    onClose();
  };

  const handleCloseEditDialog = () => {
    setOpenEditModal(false);
  };

  const handleResourceUpdate = () => {
    onReload();
    setOpenEditModal(false);
  };

  const handleDelete = useCallback(
    (e: KeyboardEvent) => {
      if (idFactoryResource !== -1 && e.key === 'Delete') {
        handleOnDelete();
      }
    },
    [idFactoryResource, handleOnDelete]
  );

  useEffect(() => {
    setAnyButtonClicked(false);
  }, [idFactoryResource]);

  useEffect(() => {
    document.addEventListener('keydown', handleDelete);
    return () => document.removeEventListener('keydown', handleDelete);
  }, [handleDelete]);

  return (
    <>
      <Popper className={classes.root} anchorEl={anchorEl} placement={'right'} open={isOpen}>
        <div>
          <IconButton className={classes.closeButton} onClick={handleOnClose}>
            <CloseIcon fontSize={'small'} />
          </IconButton>
          <div className={classes.header}>Edycja</div>
          <Button
            size={'small'}
            color={'primary'}
            variant="contained"
            className={classes.button}
            onClick={handleOnMove}
            disabled={anyButtonClicked}
          >
            Przenieś
          </Button>
          {selectedGameFactoryResourceData?.resourceActual.resourceTypeActual?.name === 'PO' && isAutomation && (
            <Button
              size={'small'}
              color={'primary'}
              variant="contained"
              className={classes.button}
              onClick={handleOpenEdit}
              disabled={anyButtonClicked}
            >
              Edytuj
            </Button>
          )}
          <Button
            size={'small'}
            color={'primary'}
            variant="contained"
            className={classes.button}
            onClick={handleOnDelete}
            disabled={anyButtonClicked}
          >
            Usuń
          </Button>
          <Button
            size={'small'}
            color={'primary'}
            variant="contained"
            className={classes.button}
            onClick={handleOnClose}
            disabled={anyButtonClicked}
          >
            Anuluj
          </Button>
          <EditGameFactoryResourceModal
            isOpen={openEditModal}
            onClose={handleCloseEditDialog}
            onResourcesUpdated={handleResourceUpdate}
            selectedGameFactoryResource={selectedGameFactoryResourceData}
            availableGameFactoryResources={gameFactoryResourceForProductionModal}
          />
        </div>
      </Popper>
      <Snackbar
        open={showNotification}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={handleSnackbarClose}
      >
        <SnackbarAlert message={notificationText} />
      </Snackbar>
    </>
  );
}
