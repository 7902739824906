import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  ganttOrderContainer: {
    position: 'relative',
    marginBottom: 40,
    height: 40,
  },
  notification: {
    backgroundColor: 'red',
    color: '#fff',
    fontWeight: 'bold',
    padding: 15,
    borderRadius: 10,
  },
});
