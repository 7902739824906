import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles(({ maxHeightTable }: Theme) => ({
  tableContainer: {
    margin: '0 20px',
    width: 'calc(100% - 40px)',
    maxHeight: maxHeightTable,
  },
  buttonsTableCell: {
    padding: 5,
    width: 150,
    minWidth: 150,
  },
  actionCell: {
    display: 'flex',
  },
  subTableCell: {
    padding: 0,
    height: 80,
  },
  subTable: {
    height: '100%',
  },
  bold: {
    fontWeight: 'bold',
  },
  roomName: {
    maxWidth: 400,
    wordWrap: 'break-word',
  },
}));
