import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: '#C3C7F2',
    padding: '0 20px',
  },
  round: {
    color: '#fff',
    fontWeight: 'bold',
    marginTop: 5,
  },
  currentRound: {
    color: '#000',
    marginLeft: 10,
  },
  button: {
    color: '#535EDB',
    fontWeight: 'bold',
  },
}));
