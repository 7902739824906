import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
import InputLabel from '@material-ui/core/InputLabel';
import { InputColorPickerProps } from './InputColorPicker.interfaces';

import { useStyles } from './InputColorPicker.styles';

const isHex = (value: string) => {
  const RegExp = /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i;
  return RegExp.test(value);
};

export function InputColorPicker(props: InputColorPickerProps) {
  const { form, field, label } = props;
  const classes = useStyles(props);
  const defaultValue = isHex(field.value) ? field.value : '#ffffff';
  const [selectedColor, setSelectedColor] = useState<string>(defaultValue);
  const [displayColorPicker, setDisplayColorPicker] = useState<boolean>(false);

  const handleOpen = () => {
    setDisplayColorPicker(true);
  };
  const handleClose = () => {
    setDisplayColorPicker(false);
  };
  return (
    <div className={classes.root}>
      <div>
        <InputLabel shrink>{label}</InputLabel>
      </div>
      <div className={classes.swatchWrapper}>
        <div className={classes.swatch} onClick={handleOpen}>
          <div
            className={classes.color}
            style={{
              background: selectedColor,
            }}
          />
        </div>
        {displayColorPicker ? (
          <div className={classes.popover}>
            <div className={classes.cover} onClick={handleClose} />
            <SketchPicker
              color={selectedColor}
              onChange={(color) => {
                setSelectedColor(color.hex);
              }}
              onChangeComplete={(color) => form.setFieldValue(field.name, color.hex)}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}
