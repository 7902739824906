import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field, FieldArray } from 'formik';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import Button from '@material-ui/core/Button';

import { fetchLatestUserRoomById, getUserRoomId } from 'app/store/userRoom';
import { TextFieldComponent } from 'app/components/TextFieldComponent/TextFieldComponent';
import {
  DeliveryMaterialToWarehouseFormData,
  DeliveryMaterialToWarehouseModalProps,
} from './DeliveryMaterialToWarehouseModal.interface';
import { CustomDialog } from 'app/components/CustomDialog/CustomDialog';
import { UserRoomService } from 'app/services/userRoom.service';
import { UnloadMaterialService } from 'app/services/unloadMaterial.service';
import { UnloadMaterialInterface } from 'app/shared/interfaces/UnloadMaterial.interface';

import { useStyles } from './DeliveryMaterialToWarehouseModal.styles';

export function DeliveryMaterialToWarehouseModal({
  isOpen,
  materialUnload,
  warehouses,
  onClose,
}: DeliveryMaterialToWarehouseModalProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const userRoomId = useSelector(getUserRoomId);

  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [dataRequest, setDataRequest] = useState<Array<UnloadMaterialInterface>>([]);
  const [isSubmiting, setIsSubmiting] = useState<boolean>(false);

  const formData: DeliveryMaterialToWarehouseFormData = {
    materials: warehouses.map((warehouse) => materialUnload.map((material) => '')),
  };

  const validate = (values: DeliveryMaterialToWarehouseFormData) => {
    const errors: Partial<DeliveryMaterialToWarehouseFormData> = {};
    const sumMatArray: Array<number> = [];
    const sumWareArray: Array<number> = [];

    for (let x = 0; x < materialUnload.length; x++) {
      sumMatArray[x] = 0;
    }

    for (let x = 0; x < warehouses.length; x++) {
      sumWareArray[x] = 0;
    }

    for (const warehouseIndex in values.materials) {
      for (const materialIndex in values.materials[warehouseIndex]) {
        // tyklo liczby
        if (
          !(values.materials[warehouseIndex][materialIndex] === '') &&
          !/^\d+$/.test(values.materials[warehouseIndex][materialIndex])
        ) {
          if (!errors.materials) {
            errors.materials = [];
          }
          if (!errors.materials[warehouseIndex]) {
            errors.materials[warehouseIndex] = [];
          }
          errors.materials[warehouseIndex][materialIndex] = 'Wartość musi być liczbą';
        } else {
          sumMatArray[materialIndex] += values.materials[warehouseIndex][materialIndex]
            ? parseInt(values.materials[warehouseIndex][materialIndex])
            : 0;

          sumWareArray[warehouseIndex] += values.materials[warehouseIndex][materialIndex]
            ? parseInt(values.materials[warehouseIndex][materialIndex])
            : 0;
        }
      }
    }

    for (const warehouseIndex in values.materials) {
      for (const materialIndex in values.materials[warehouseIndex]) {
        // niewystarczajaca ilosc materialow
        if (sumMatArray[materialIndex] > materialUnload[materialIndex].quantity) {
          if (!errors.materials) {
            errors.materials = [];
          }
          if (!errors.materials[warehouseIndex]) {
            errors.materials[warehouseIndex] = [];
          }
          errors.materials[warehouseIndex][
            materialIndex
          ] = `Suma materiałów musi być mniejsza równa ${materialUnload[materialIndex].quantity}`;
        }

        // za mala pojemnosc magazynu
        if (
          sumWareArray[warehouseIndex] * (materialUnload[materialIndex].gameMaterial.actualMaterial?.amount || 1) >
          warehouses[warehouseIndex].resourceActual.capacity - warehouses[warehouseIndex].storageFillLevel
        ) {
          if (!errors.materials) {
            errors.materials = [];
          }
          if (!errors.materials[warehouseIndex]) {
            errors.materials[warehouseIndex] = [];
          }
          errors.materials[warehouseIndex][materialIndex] = `Za mała pojemność magazynu`;
        }
      }
    }

    return errors;
  };

  const addMaterialsToWarehouses = () => {
    setIsSubmiting(true);

    UnloadMaterialService.addUnloadMaterial(dataRequest, userRoomId).then(() => {
      UserRoomService.removeNotUnloadedMaterials(userRoomId).then(() => {
        dispatch(fetchLatestUserRoomById(userRoomId));
      });
      setIsSubmiting(false);
      setOpenConfirmDialog(false);
      onClose();
    });
  };

  const showCapacityMaterial = (materialAmount: string, capacity: number) =>
    isNaN(materialAmount as any) || !materialAmount ? '0.00' : (parseInt(materialAmount) * capacity).toFixed(2);

  const closeConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  return (
    <>
      <CustomDialog
        maxWidth={'md'}
        title={'Rozmieszczanie materiałów w magazynach'}
        hideCloseButton
        body={
          <div className={classes.container}>
            <div className={classes.materialContainer}>
              <div className={classes.bold}>Materiały do przydzielenia</div>
              {materialUnload.map((mat) => (
                <div
                  key={mat.id}
                >{`- ${mat.gameMaterial.actualMaterial?.name}: ${mat.quantity} (${mat.gameMaterial.actualMaterial?.amount} jw)`}</div>
              ))}
            </div>
            <Formik
              validate={validate}
              initialValues={formData}
              onSubmit={(values) => {
                const req: Array<UnloadMaterialInterface> = [];
                setOpenConfirmDialog(true);

                for (const warehouseIndex in values.materials) {
                  for (const materialIndex in values.materials[warehouseIndex]) {
                    if (
                      values.materials[warehouseIndex][materialIndex] &&
                      parseInt(values.materials[warehouseIndex][materialIndex]) !== 0
                    )
                      req.push({
                        gameFactoryResourceId: warehouses[warehouseIndex].id,
                        materialUnloadingId: materialUnload[materialIndex].id,
                        storageFillLevel: values.materials[warehouseIndex][materialIndex]
                          ? parseInt(values.materials[warehouseIndex][materialIndex])
                          : 0,
                      });
                  }
                }
                setDataRequest(req);
              }}
            >
              {({ submitForm, values, isValid }) => (
                <Form>
                  <div>
                    <TableContainer component={Paper} className={classes.tableContainer}>
                      <Table stickyHeader>
                        <TableHead>
                          <TableRow>
                            <TableCell>Magazyn</TableCell>
                            <TableCell>Pojemność [jw]</TableCell>
                            <TableCell>Wolne miejsce [jw]</TableCell>
                            <TableCell>Przydziel</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <FieldArray
                            name={'materials'}
                            render={(arrayHelpers) => {
                              return values.materials.map((warehouseArr, indexWarehouse) => (
                                <TableRow key={indexWarehouse}>
                                  <TableCell>{`${warehouses[indexWarehouse].resourceActual.name} (${warehouses[indexWarehouse].resourceNumber})`}</TableCell>
                                  <TableCell>{warehouses[indexWarehouse].resourceActual.capacity}</TableCell>
                                  <TableCell>
                                    {(
                                      warehouses[indexWarehouse].resourceActual.capacity -
                                      warehouses[indexWarehouse].storageFillLevel
                                    ).toFixed(2)}
                                  </TableCell>
                                  <TableCell>
                                    {values.materials[indexWarehouse].map((mat, indexMat) => (
                                      <div key={indexMat} className={classes.fieldContainer}>
                                        <div className={classes.jw}>
                                          (
                                          {showCapacityMaterial(
                                            values.materials[indexWarehouse][indexMat],
                                            materialUnload[indexMat].gameMaterial.actualMaterial?.amount || 1
                                          )}{' '}
                                          jw)
                                        </div>
                                        <Field
                                          component={TextFieldComponent}
                                          name={`materials.${indexWarehouse}.${indexMat}`}
                                          label={materialUnload[indexMat].gameMaterial.actualMaterial?.name}
                                        />
                                      </div>
                                    ))}
                                  </TableCell>
                                </TableRow>
                              ));
                            }}
                          />
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                  <div className={classes.actionButtons}>
                    <Button color={'primary'} variant={'contained'} onClick={submitForm}>
                      Przydziel
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        }
        isOpen={isOpen}
      />
      <CustomDialog
        title={'Potwierdzenie'}
        body={
          <div>
            Na pewno chcesz przydzielić materiały do wybranych magazynów? Po zatwierdzeniu nie będzie możliwości zmiany,
            a nieprzydzielone materiały przepadną!
          </div>
        }
        onCloseDialog={closeConfirmDialog}
        dialogActions={
          <>
            <Button color={'primary'} variant={'contained'} disabled={isSubmiting} onClick={addMaterialsToWarehouses}>
              Zatwierdź
            </Button>
            <Button color={'primary'} variant={'contained'} disabled={isSubmiting} onClick={closeConfirmDialog}>
              Anuluj
            </Button>
          </>
        }
        isOpen={openConfirmDialog}
      />
    </>
  );
}
