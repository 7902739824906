import React from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import { useStyles } from './BotSettingsModal.styles';

import { CustomDialog } from 'app/components/CustomDialog/CustomDialog';
import { BotSettingsModalProps } from 'app/components/BotSettingsModal/BotSettingsModal.interfaces';
import { TextFieldComponent } from 'app/components/TextFieldComponent/TextFieldComponent';
import { BotSettingsInterface } from 'app/shared/interfaces/BotSettings.interface';
import { SelectComponent } from 'app/components/SelectComponent/SelectComponent';

export function BotsSettingsModal(props: BotSettingsModalProps) {
  const { isOpen, onClose, changeSettings, settings, disabled } = props;
  const classes = useStyles();

  const validationSchema = Yup.object({
    weightProfitableInProcess: Yup.number()
      .min(0, 'Wartość musi być większa lub równa 0')
      .required('Musisz podać wartość wagi')
      .typeError('Wartość musi być liczbą'),
    leftCashAfterBoughtMissingResourcesInProcess: Yup.number()
      .min(0, 'Wartość musi być większa lub równa 0')
      .required('Musisz podać wartość pozostałej gotówki')
      .typeError('Wartość musi być liczbą'),
    weightProcessCostInAuction: Yup.number()
      .min(0, 'Wartość musi być większa lub równa 0')
      .required('Musisz podać wartość wagi')
      .typeError('Wartość musi być liczbą'),
    weightResourceCostInAuction: Yup.number()
      .min(0, 'Wartość musi być większa lub równa 0')
      .required('Musisz podać wartość wagi')
      .typeError('Wartość musi być liczbą'),
    weightMaterialCostInAuction: Yup.number()
      .min(0, 'Wartość musi być większa lub równa 0')
      .required('Musisz podać wartość wagi')
      .typeError('Wartość musi być liczbą'),
    weightQualityControlCostInAuction: Yup.number()
      .min(0, 'Wartość musi być większa lub równa 0')
      .required('Musisz podać wartość wagi')
      .typeError('Wartość musi być liczbą'),
    weightHowManyUsersBiddingInAuction: Yup.number()
      .min(0, 'Wartość musi być większa lub równa 0')
      .required('Podaj wartość wagi')
      .typeError('Wartość musi być liczbą'),
    weightForHowManyUsersBiddingInAuction: Yup.number()
      .min(0, 'Wartość musi być większa lub równa 0')
      .required('Podaj wartość wagi')
      .typeError('Wartość musi być liczbą'),
    profitabilityInAuction: Yup.number()
      .min(0, 'Wartość musi być większa lub równa 0')
      .required('Podaj wartość progu opłacalności')
      .typeError('Wartość musi być liczbą'),
    calculateBiddingPriceBaseOnOtherPlayersPrices: Yup.bool().required('Wybierz opcję').typeError('Wybierz opcję'),
    numberOfLossAuctionsBeforeStart: Yup.number()
      .integer('Wartość musi być liczbą całkowitą')
      .min(0, 'Wartość musi być większa lub równa 0')
      .required('Podaj ilość licytacji')
      .typeError('Wartość musi być liczbą całkowitą'),
  });

  const formData: BotSettingsInterface = settings;

  return (
    <CustomDialog
      title={'Właściwości botów'}
      isOpen={isOpen}
      onCloseDialog={onClose}
      body={
        <Formik
          initialValues={formData}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            changeSettings(values);
            onClose();
          }}
        >
          {({ submitForm }) => (
            <Form>
              <Field
                component={TextFieldComponent}
                name="profitabilityInAuction"
                label="Próg opłacalności w decydowaniu czy licytować aukcje"
                disabled={disabled}
                fullWidth
              />
              <Field
                component={TextFieldComponent}
                name="weightProfitableInProcess"
                label="Waga do obliczania progu opłacalności procesu w aukcjach"
                disabled={disabled}
                fullWidth
              />
              <Field
                component={TextFieldComponent}
                name="leftCashAfterBoughtMissingResourcesInProcess"
                label="Wartość pozostałej gotówki po zakupie potrzebnych zasobów dla procesu"
                disabled={disabled}
                fullWidth
              />
              <Field
                component={TextFieldComponent}
                name="weightProcessCostInAuction"
                label="Waga do obliczenia kosztów procesów w aukcjach"
                disabled={disabled}
                fullWidth
              />
              <Field
                component={TextFieldComponent}
                name="weightResourceCostInAuction"
                label="Waga do obliczania kosztów brakujących zasobów w aukcjach"
                disabled={disabled}
                fullWidth
              />
              <Field
                component={TextFieldComponent}
                name="weightMaterialCostInAuction"
                label="Waga do obliczenia kosztów materiałów w aukcjach"
                disabled={disabled}
                fullWidth
              />
              <Field
                component={TextFieldComponent}
                name="weightQualityControlCostInAuction"
                label="Waga do obliczania kosztu kontroli jakości w aukcjach"
                disabled={disabled}
                fullWidth
              />
              <Field
                component={TextFieldComponent}
                name="weightHowManyUsersBiddingInAuction"
                label="Waga do decyzji czy ktoś licytuje w aukcjach"
                disabled={disabled}
                fullWidth
              />
              <Field
                component={TextFieldComponent}
                name="weightForHowManyUsersBiddingInAuction"
                label="Waga do wagi decydującej czy ktoś licytuje w aukcjach"
                disabled={disabled}
                fullWidth
              />
              <Field
                component={SelectComponent}
                fullWidth
                label="Czy analizować cenę licytacji na podstawie ofert innych graczy"
                name={'calculateBiddingPriceBaseOnOtherPlayersPrices'}
                options={[
                  {
                    label: 'Tak',
                    value: true,
                  },
                  {
                    label: 'Nie',
                    value: false,
                  },
                ]}
              />
              <Field
                component={TextFieldComponent}
                name="numberOfLossAuctionsBeforeStart"
                label="Liczba licytacji jakie bot odpuści zanim zacznie licytować"
                disabled={disabled}
                fullWidth
              />
              <div className={classes.actionButtons}>
                {!disabled && (
                  <Button color={'primary'} onClick={submitForm}>
                    Zapisz
                  </Button>
                )}
                <Button color={'primary'} autoFocus onClick={onClose}>
                  Anuluj
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      }
    />
  );
}
