import { AxiosPromise } from 'axios';

import { ApiService } from './api.service';
import { GetParamsInterface } from 'app/shared/interfaces/RequestInterface.interface';
import { AuctionItemInterface } from 'app/shared/interfaces/AuctionItem.interface';

export const AuctionItemService = {
  getAuctionItems(params: GetParamsInterface = {}): AxiosPromise<AuctionItemInterface> {
    return ApiService.get('auction_items', params);
  },
  addAuctionItem(data: Omit<AuctionItemInterface, 'id'>): AxiosPromise {
    return ApiService.post('auction_items', data);
  },
};
