import { AxiosPromise } from 'axios';

import { ApiService } from './api.service';
import { GetParamsInterface } from 'app/shared/interfaces/RequestInterface.interface';
import { MaterialUnloadingResponse } from 'app/shared/interfaces/MaterialUnloading.interface';

export const MaterialUnloadingService = {
  getMaterialUnloading(userRoomId: number, params: GetParamsInterface = {}): AxiosPromise<MaterialUnloadingResponse> {
    return ApiService.get(`material_unloadings?gameMaterial.userRoom=${userRoomId}`, params);
  },
};
