import { AxiosPromise } from 'axios';

import { ActualOrderAddInterface } from 'app/shared/interfaces/ActualOrder.interface';

import { ApiService } from './api.service';

export const ActualOrderService = {
  replaceAction(id: number, data: ActualOrderAddInterface): AxiosPromise {
    return ApiService.put(`actual_orders/${id}`, data);
  },
};
