import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { intersection } from 'lodash';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import { menu, submenu } from 'app/shared/menu';
import { getCurrentUser, getIsUserGameMaster } from 'app/store/user';
import { MenuInterface, SubmenuInterface } from 'app/shared/interfaces/Menu.interface';

import { useStyles } from './Homepage.styles';

function HomePage() {
  const classes = useStyles();
  const currentUser = useSelector(getCurrentUser);
  const history = useHistory();
  const isUserGameMaster = useSelector(getIsUserGameMaster);

  if (!isUserGameMaster) {
    history.push('/player_rooms');
  }

  const haveAccess = (menuItem: MenuInterface | SubmenuInterface) =>
    currentUser && !(menuItem.access && intersection(currentUser.roles, menuItem.access).length === 0);

  return (
    <div className={classes.root}>
      <Typography variant={'h1'}>Home</Typography>
      <div className={classes.container}>
        <div className={classes.cardContainer}>
          {menu
            .filter((menuItem) => menuItem.id !== 0 && haveAccess(menuItem))
            .map((menuItem) => (
              <Card key={menuItem.id} className={classes.card}>
                <CardHeader
                  avatar={menuItem.icon}
                  title={menuItem.label}
                  classes={{ root: classes.cardHeaderRoot, content: classes.cardHeaderTitle }}
                />
                <CardContent>
                  {submenu
                    .filter((submenuItem) => submenuItem.menuItemId === menuItem.id && haveAccess(submenuItem))
                    .map((sItem, index) => (
                      <Link
                        key={`${index}-${menuItem.id}-${sItem.menuItemId}`}
                        to={sItem.link}
                        className={classes.linkRoot}
                      >
                        <div className={classes.link}>
                          {sItem.icon}
                          <span>{sItem.label}</span>
                        </div>
                      </Link>
                    ))}
                </CardContent>
              </Card>
            ))}
        </div>
      </div>
    </div>
  );
}

export default HomePage;
