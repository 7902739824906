import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { factoryReducer } from './factory';
import { userRoomReducer } from './userRoom';
import { ganttReducer } from './gantt';
import { userReducer } from './user';
import { gameRoundsReducer } from './gameRounds';

export const store = configureStore({
  reducer: {
    factory: factoryReducer,
    userRoom: userRoomReducer,
    gantt: ganttReducer,
    user: userReducer,
    gameRounds: gameRoundsReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
