import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import AccountBoxIcon from '@material-ui/icons/AccountBox';

import { ReactComponent as LogoIcon } from 'app/shared/images/logo_goshaper.svg';
import Dashboard from 'app/components/Dashboard/Dashboard';
import { useStyles } from './Layout.styles';
import { routes } from 'app/shared/constants';
import { getCurrentUser } from 'app/store/user';
import Version from 'app/components/Version/Version';

export function Layout() {
  const classes = useStyles();
  const currentUser = useSelector(getCurrentUser);
  const [openMenu, setOpenMenu] = useState<boolean>(false);

  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  };

  const handleClose = () => setOpenMenu(false);

  return (
    <>
      {currentUser ? (
        <>
          <AppBar position={'static'} className={classes.appBar}>
            <Toolbar className={classes.toolbar}>
              <IconButton className={classes.menuIcon} color="inherit" aria-label="menu" onClick={toggleMenu}>
                {openMenu ? <MenuOpenIcon /> : <MenuIcon />}
              </IconButton>
              <LogoIcon className={classes.logo} />
              <Typography variant="h6" className={classes.title}>
                <div>GOSHAPER</div>
                <Version />
              </Typography>
              <div className={classes.userName}>{currentUser.email}</div>
              <AccountBoxIcon className={classes.accountIcon} />
              <Link to={routes.LOGOUT.route} className={classes.logoutButton}>
                Wyloguj
              </Link>
            </Toolbar>
          </AppBar>
          <Dashboard open={openMenu} onCLose={handleClose} />
        </>
      ) : null}
    </>
  );
}
