import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  root: {
    padding: '0 20px',
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  ganttContainer: {
    width: 1160,
  },
  accordionContainer: {
    width: 'calc(100% - 1200px)',
  },
  tablesContainer: {
    display: 'flex',
  },
  buttonContanier: {
    display: 'flex',
    marginBottom: 20,
  },
  button: {
    marginRight: 20,
  },
  textfield: {
    width: 130,
  },
  editButtonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 20,
  },
  borderTop: {
    marginTop: 10,
  },
  borderBottom: {
    marginBottom: 10,
  },
  notification: {
    backgroundColor: 'red',
    color: '#fff',
    fontWeight: 'bold',
    padding: 15,
    borderRadius: 10,
  },
  successNotification: {
    backgroundColor: 'green',
    color: '#fff',
    fontWeight: 'bold',
    padding: 15,
    borderRadius: 10,
  },
  showMoveContainer: {
    display: 'flex',
  },
  showMoveFirstColumn: {
    width: '43%',
  },
  showMoveSecondColumn: {
    marginLeft: '2%',
  },
  showMoveSubSection: {
    width: '100%',
  },
  cancelButtonContainer: {
    marginLeft: 20,
  },
}));
