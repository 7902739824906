import React from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';

import { CustomDialog } from 'app/components/CustomDialog/CustomDialog';

import { AddMarkModalProps, AddMarkModalFormDataInterface } from './AddMarkModal.interfaces';
import { useStyles } from './AddMarkModal.styles';
import { TextFieldComponent } from 'app/components/TextFieldComponent/TextFieldComponent';
import { UserRoomService } from 'app/services/userRoom.service';
import { UserRoomMarkInterface } from 'app/shared/interfaces/UserRoom.interface';
import { userRoomGameStatus } from 'app/shared/constants';
import { convertToFloat } from 'app/shared/helpers/numberConverter';

export function AddMarkModal(props: AddMarkModalProps) {
  const { isOpen, onClose, userRoom, markAdded, readonly } = props;
  const classes = useStyles();

  const formData: AddMarkModalFormDataInterface = {
    mark: userRoom && userRoom.mark !== null ? userRoom.mark.toString() : '',
    markDescription: userRoom && userRoom.markDescription ? userRoom.markDescription : '',
    status: userRoomGameStatus.GAME_MARK_AND_END.status.toString(),
  };
  const validationShema = Yup.object().shape({
    mark: Yup.number()
      .min(0, 'Liczba musi się zawierać w zakresie od 0 do 100')
      .max(100, 'Liczba musi się zawierać w zakresie od 0 do 100')
      .typeError('Wartość musi być liczbą')
      .required('Podaj ocenę'),
    markDescription: Yup.string().max(255, 'Maksymalna długość komentarza wynosi 255 znaków'),
  });

  return (
    <CustomDialog
      title={'Ocena gracza'}
      isOpen={isOpen}
      onCloseDialog={onClose}
      body={
        <Formik
          initialValues={formData}
          validationSchema={validationShema}
          onSubmit={(values) => {
            const userRoomMark: UserRoomMarkInterface = {
              mark: convertToFloat(values.mark),
              markDescription: values.markDescription,
              status: parseInt(values.status),
            };
            UserRoomService.replaceUserRoomsMark(userRoom.id, userRoomMark).then(() => {
              markAdded();
            });
          }}
        >
          {({ submitForm }) => (
            <Form>
              <Field disabled={readonly} component={TextFieldComponent} name={'mark'} label={'Ocena'} fullWidth />
              <Field
                disabled={readonly}
                component={TextFieldComponent}
                name={'markDescription'}
                label={'Komentarz'}
                multiline
                rowsMax={4}
                fullWidth
              />
              <div className={classes.actionButtons}>
                {!readonly ? (
                  <Button color={'primary'} onClick={submitForm}>
                    Zapisz
                  </Button>
                ) : null}
                <Button color={'primary'} autoFocus onClick={onClose}>
                  Anuluj
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      }
    />
  );
}
