import { AppThunk } from 'app/store/store';
import { UserRoomService } from 'app/services/userRoom.service';
import { setLatestUserRoomState } from './actions';
import { setSelectedUserRoomState } from './actions';
import { setIsActiveRound } from './actions';
import { UserRoomState } from './reducer';

export const fetchLatestUserRoomById = (id: number): AppThunk => (dispatch) => {
  UserRoomService.getUserRoomsById(id).then(({ data }) => {
    dispatch(setLatestUserRoomState(data));
    dispatch(setSelectedUserRoom(data));
    dispatch(setIsActiveRound(true));
  });
};

export const setSelectedUserRoom = (userRoomState: UserRoomState): AppThunk => (dispatch) => {
  dispatch(setSelectedUserRoomState(userRoomState));
};

export const setSelectedUserRoomWithState = (userRoomState: UserRoomState, isActive: boolean): AppThunk => (
  dispatch
) => {
  dispatch(setSelectedUserRoomState(userRoomState));
  dispatch(setIsActiveRound(isActive));
};
