import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';

import { SnackbarAlert } from 'app/components/SnackbarAlert/SnackbarAlert';
import { ProcessActivityActualInterface } from 'app/shared/interfaces/Process.interface';
import { getGameProcesses, getRoom, getSelectedUserRoom } from 'app/store/userRoom';
import { GameProcessDetailsModal } from 'app/components/GameProcessDetailsModal/GameProcessDetailsModal';
import { RoomService } from 'app/services/room.service';
import { GameProcessService } from 'app/services/gameProcess.service';
import { canEdit } from 'app/shared/helpers/canEdit';
import { GameProcessesInterface } from './GameProcesses.interface';
import { GameProcessInterface } from 'app/shared/interfaces/GameProcess.interface';
import { ActualProcessInterface } from 'app/shared/interfaces/ActualProcess.interface';
import { messages } from 'app/shared/constants';

import { useStyles } from './GameProcesses.styles';

export function GameProcesses(props: GameProcessesInterface) {
  const { updateRoom, actualProcesses, userRoom, showMode } = props;
  const gameProcesses = useSelector(getGameProcesses);
  const room = useSelector(getRoom);
  const [selectedActualProcess, setSelectedActualProcess] = useState<ActualProcessInterface | null>(null);
  const [processesActivity, setProcessesActivity] = useState<Array<ProcessActivityActualInterface>>([]);
  const [processDetailsModalOpen, setProcessDetailsModalOpen] = useState<boolean>(false);
  const [disabledBuyButtons, setDisabledBuyButtons] = useState<Array<number>>([]);
  const selectedUserRoom = useSelector(getSelectedUserRoom);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [notificationText, setNotificationText] = useState<string>('');

  const classes = useStyles();

  const handleSnackbarClose = () => {
    setShowNotification(false);
  };

  const handleOpenProcessDetailModal = (actualProcess: ActualProcessInterface) => {
    if (room) {
      RoomService.getRoomProcessesAndActivity(room.id).then(({ data: { 'hydra:member': responseData } }) => {
        setProcessesActivity(responseData);
        setSelectedActualProcess(actualProcess);
        setProcessDetailsModalOpen(true);
      });
    }
  };

  const handleCloseProcessDetailModal = () => {
    setSelectedActualProcess(null);
    setProcessDetailsModalOpen(false);
  };

  const isGameProcessBought = (id: number) => {
    const process = gameProcesses.find(
      (gameProcess) => gameProcess.actualProcess && gameProcess.actualProcess.id === id
    );

    return !!process;
  };

  const handleBuyProcess = (actualProcess: ActualProcessInterface) => {
    if (actualProcess.price > selectedUserRoom.cashBalance) {
      setNotificationText(messages.NOT_ENOUGH_CASH);
      setShowNotification(true);
      return;
    }

    const boughtProcessData: Omit<GameProcessInterface, 'id'> = {
      actualProcess: actualProcess,
      userRoom: userRoom,
    };

    setDisabledBuyButtons([...disabledBuyButtons, actualProcess.id]);

    GameProcessService.buyProcesses(boughtProcessData).then(() => {
      if (updateRoom) {
        updateRoom();
      }
    });
  };

  const isButtonDisabled = (id: number) => {
    return disabledBuyButtons.includes(id);
  };

  return (
    <div className={classes.root}>
      {!showMode && <h2>Procesy</h2>}
      <div className={clsx(classes.tableContainer, { [classes.tableContainerHeight]: !showMode })}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Nazwa</TableCell>
              <TableCell>Opis</TableCell>
              <TableCell align={'right'}>Cena [PLN]</TableCell>
              <TableCell>Akcja</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {actualProcesses.map((actualProcess) => (
              <TableRow key={actualProcess.id}>
                <TableCell>{actualProcess.name}</TableCell>
                <TableCell>{actualProcess.description}</TableCell>
                <TableCell align={'right'}>{actualProcess.price.toFixed(2)}</TableCell>
                <TableCell>
                  <div className={classes.actionWrapper}>
                    <Button
                      variant="contained"
                      color="default"
                      onClick={() => handleOpenProcessDetailModal(actualProcess)}
                    >
                      Szczegóły
                    </Button>
                    {!showMode && (
                      <Button
                        variant="contained"
                        color="default"
                        onClick={() => handleBuyProcess(actualProcess)}
                        disabled={
                          isGameProcessBought(actualProcess.id) || !canEdit() || isButtonDisabled(actualProcess.id)
                        }
                      >
                        {isGameProcessBought(actualProcess.id) ? 'KUPIONY' : 'KUP'}
                      </Button>
                    )}
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <GameProcessDetailsModal
          processesActivity={processesActivity}
          actualProcess={selectedActualProcess}
          isOpen={processDetailsModalOpen}
          onClose={handleCloseProcessDetailModal}
        />
      </div>
      <Snackbar
        open={showNotification}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={handleSnackbarClose}
      >
        <SnackbarAlert message={notificationText} />
      </Snackbar>
    </div>
  );
}
