import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';

import { FactoryArea } from 'app/components/FactoryArea/FactoryArea';
import { FactoryAllResources } from 'app/components/FactoryAllResources/FactoryAllResources';
import { ResourceInterface } from 'app/shared/interfaces/Resource.interface';
import { ResourceService } from 'app/services/resource.service';
import { useStyles } from './FactoryPage.styles';
import { FactoryOwnRecources } from 'app/components/FactoryOwnResources/FactoryOwnResources';
import { fetchFactoryById, clearFactory, getFactoryName } from 'app/store/factory';

function FactoryPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { factoryId } = useParams();
  const factoryName = useSelector(getFactoryName);

  const [resources, setResources] = useState<Array<ResourceInterface>>([]);

  const currentFactoryId = factoryId && !isNaN(parseInt(factoryId)) ? parseInt(factoryId) : -1;

  const getResources = () =>
    ResourceService.getResources().then(({ data: { 'hydra:member': responseData } }) => setResources(responseData));

  const getFactoryById = useCallback(
    (factoryId: number) => {
      dispatch(fetchFactoryById(factoryId));
    },
    [dispatch]
  );

  useEffect(() => {
    getResources();
    getFactoryById(currentFactoryId);
    return () => {
      dispatch(clearFactory());
    };
  }, [currentFactoryId, getFactoryById, dispatch]);

  return (
    <div className={classes.root}>
      <Typography variant={'h1'}>Fabryka: {factoryName}</Typography>
      <div className={classes.factoryContainer}>
        <div className={classes.factoryAreaContainer}>
          <FactoryArea resources={resources} />
        </div>
        <div className={classes.factoryToolbarContainer}>
          <FactoryAllResources
            resources={resources}
            shouldRefreshOwnResources={() => getFactoryById(currentFactoryId)}
          />
          <FactoryOwnRecources
            resources={resources}
            shouldRefreshOwnResources={() => getFactoryById(currentFactoryId)}
          />
        </div>
      </div>
    </div>
  );
}

export default FactoryPage;
