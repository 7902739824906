import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  root: {
    padding: '0 20px',
    margin: '0 auto',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  cardContainer: {
    display: 'flex',
    flexFlow: 'wrap',
    marginTop: 20,
    maxWidth: 960,
  },
  card: {
    width: 300,
    margin: 10,
    height: 270,
    boxShadow: '0 0 10px #ddd',
  },
  cardHeaderRoot: {
    borderBottom: '1px solid #bccc',
    color: '#b4b4b4',
  },
  cardHeaderTitle: {
    flex: 'none',

    '& span': {
      fontSize: 18,
    },
  },
  linkRoot: {
    textDecoration: 'none',
  },
  link: {
    display: 'flex',
    fontSize: 16,
    marginLeft: 20,
    marginBottom: 20,
    color: '#939ae8',
    '& span': {
      paddingLeft: 10,
      color: '#535edb',
    },
  },
}));
