import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { FactoryArea } from 'app/components/FactoryArea/FactoryArea';
import { GamePrepareModal } from 'app/components/GamePrepareModal/GamePrepareModal';
import { GameHeader } from 'app/components/GameHeader/GameHeader';
import {
  clearUserRoom,
  fetchLatestUserRoomById,
  getActivitiesActualFromGameActivities,
  getGameFactory,
  getGameGantt,
  getLatestUserRoom,
  getLoadingRoom,
  getRoom,
  getSelectedUserRoom,
  setSelectedUserRoomWithState,
  getIsAutomation,
} from 'app/store/userRoom';
import { clearGanttTemplate, fetchGanttOrders } from 'app/store/gantt';
import { getIsUserGameMaster } from 'app/store/user';
import { ActualSupplierInterface } from 'app/shared/interfaces/ActualSupplier.interface';
import { ActualProcessInterface } from 'app/shared/interfaces/ActualProcess.interface';
import { RoomService } from 'app/services/room.service';
import { ResourceInterface } from 'app/shared/interfaces/Resource.interface';
import { clearFactory, fetchGameFactoryById, getActualSupplier } from 'app/store/factory';
import { ScenarioActualInterface } from 'app/shared/interfaces/ScenarioActual.interface';
import { GameGanttContainer } from 'app/components/GameGanttContainer/GameGanttContainer';
import { MaterialUnloadingService } from 'app/services/materialUnloading.service';
import { MaterialUnloadingInterface } from 'app/shared/interfaces/MaterialUnloading.interface';
import { DeliveryMaterialToWarehouseModal } from 'app/components/DeliveryMaterialToWarehouseModal/DeliveryMaterialToWarehouseModal';
import { GameFactoryResourceService } from 'app/services/gameFactoryResource.service';
import { GameFactoryResourceInterface } from 'app/shared/interfaces/GameFactoryResource.interface';
import { GameFactoryAccordion } from 'app/components/GameFactoryAccordion/GameFactoryAccordion';

import { useStyles } from './GameViewPage.styles';
import { GameRoomService } from 'app/services/gameRoomService';
import { last } from 'lodash';
import { clearRounds, setRounds } from 'app/store/gameRounds';
import { userRoomGameStatus } from 'app/shared/constants';
import { UserGameRoundService } from 'app/services/userGameRound.service';
import { ActualOrderInterface } from 'app/shared/interfaces/ActualOrder.interface';
import { AuctionItemInterface } from 'app/shared/interfaces/AuctionItem.interface';
import { GamePlayerRankingInterface } from 'app/shared/interfaces/GamePlayerRanking.interface';
import { handleRoundFinishedNotificationEvent } from 'app/services/webSocket.service';
import { NewRoundEvent } from 'app/shared/interfaces/WebSocketEvent.interface';

function GameViewPage() {
  const classes = useStyles();
  const [actualSuppliers, setActualSuppliers] = useState<Array<ActualSupplierInterface>>([]);
  const [gamePlayerRankings, setGamePlayerRankings] = useState<Array<GamePlayerRankingInterface>>([]);
  const [actualProcesses, setActualProcesses] = useState<Array<ActualProcessInterface>>([]);
  const [actualScenario, setActualScenario] = useState<ScenarioActualInterface | null>(null);
  const [actualOrders, setActualOrders] = useState<Array<ActualOrderInterface>>([]);
  const [myAuctions, setMyAuctions] = useState<Array<AuctionItemInterface>>([]);
  const [resources, setResources] = useState<Array<ResourceInterface>>([]);
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);
  const [showGamePrepareModal, setShowGamePrepareModal] = useState<boolean>(false);
  const [isSuppliersLoaded, setIsSuppliersLoaded] = useState<boolean>(false);
  const [showGantt, setShowGantt] = useState<boolean>(false);
  const [showSelectRoundComponent, setShowSelectRoundComponent] = useState<boolean>(false);
  const [materialUnload, setMaterialUnload] = useState<Array<MaterialUnloadingInterface>>([]);
  const [warehouses, setWarehouses] = useState<Array<GameFactoryResourceInterface>>([]);
  const [showDeliveryMaterialToWarehouseModal, setShowDeliveryMaterialToWarehouseModal] = useState(false);

  const dispatch = useDispatch();
  const room = useSelector(getRoom);
  const gameFactory = useSelector(getGameFactory);
  const isAutomation = useSelector(getIsAutomation);
  const latestUserRoom = useSelector(getLatestUserRoom);
  const selectedUserRoom = useSelector(getSelectedUserRoom);
  const gameActivities = useSelector(getActivitiesActualFromGameActivities);
  const isLoadingRoom = useSelector(getLoadingRoom);
  const actualSupplier = useSelector(getActualSupplier);
  const isUserGameMaster = useSelector(getIsUserGameMaster);
  const gameGantt = useSelector(getGameGantt);
  const { userRoomId } = useParams();
  const currentUserRoomId = userRoomId && !isNaN(parseInt(userRoomId)) ? parseInt(userRoomId) : -1;
  const [loadRoomTimestamp, setLoadRoomTimestamp] = useState<number>(new Date().getTime());
  const getSuppliers = useCallback(
    (roomId: number) =>
      RoomService.getRoomSuppliers(roomId).then(({ data: { 'hydra:member': responseData } }) => {
        setActualSuppliers(responseData);
        setIsSuppliersLoaded(true);
      }),
    []
  );

  const getProcesses = useCallback(
    (roomId: number) =>
      RoomService.getRoomProcesses(roomId).then(({ data: { 'hydra:member': responseData } }) =>
        setActualProcesses(responseData)
      ),
    []
  );

  const getScenario = useCallback(
    (roomId: number) => RoomService.getRoomScenario(roomId).then(({ data }) => setActualScenario(data)),
    []
  );

  const getGamePlayerRanking = useCallback(
    (roomId: number) =>
      RoomService.getGamePlayerRanking(roomId).then(({ data: { 'hydra:member': responseData } }) =>
        setGamePlayerRankings(responseData)
      ),
    []
  );

  const getMyAuctions = useCallback(
    (roomId: number) =>
      RoomService.getMyAuctions(roomId, currentUserRoomId).then(({ data: { 'hydra:member': responseData } }) =>
        setMyAuctions(responseData)
      ),
    [currentUserRoomId]
  );

  const getUserRoomById = useCallback(
    (userRoomId: number) => {
      dispatch(fetchLatestUserRoomById(userRoomId));
      setLoadRoomTimestamp(new Date().getTime());
    },
    [dispatch]
  );

  const getActualOrders = useCallback(
    (roomId: number) =>
      RoomService.getActualOrders(roomId, { visibility: true }).then(({ data: { 'hydra:member': responseData } }) => {
        setActualOrders(responseData);
      }),
    []
  );

  const updateRoom = (roomId?: number) => {
    getUserRoomById(currentUserRoomId);
    getMaterialUnload(currentUserRoomId);
    if (roomId) {
      getMyAuctions(roomId);
    }
  };

  const initializeUserRoomRounds = useCallback(() => {
    GameRoomService.getGameRoomRounds(currentUserRoomId).then(({ data: { 'hydra:member': responseData } }) => {
      dispatch(setRounds(responseData));
      const latestUserRoomRound = last(responseData)?.roundState;
      const isNotActiveGame = latestUserRoomRound?.room.status !== userRoomGameStatus.GAME_PENDING.status;
      if ((isUserGameMaster || isNotActiveGame) && latestUserRoomRound) {
        dispatch(setSelectedUserRoomWithState(latestUserRoomRound, false));
      }
      setShowSelectRoundComponent(true);
    });
  }, [dispatch, currentUserRoomId, isUserGameMaster]);

  const getMaterialUnload = (userRoomId: number) => {
    MaterialUnloadingService.getMaterialUnloading(userRoomId).then(({ data: { 'hydra:member': responseData } }) => {
      setMaterialUnload(responseData);
    });
  };

  const getWarehouses = (userRoomId: number) =>
    GameFactoryResourceService.getGameFactoryWarehouses(userRoomId).then(
      ({ data: { 'hydra:member': responseData } }) => {
        setWarehouses(responseData);
      }
    );

  const loadData = useCallback(() => {
    if (room && gameFactory && gameGantt) {
      if (isAutomation) {
        initializeUserRoomRounds();
      }
      RoomService.getActualResources(room.id).then(({ data: { 'hydra:member': responseData } }) =>
        setResources(responseData)
      );
      dispatch(fetchGameFactoryById(gameFactory.id));
      dispatch(fetchGanttOrders(gameGantt.id));
      getScenario(room.id);
      getSuppliers(room.id);
      getProcesses(room.id);
      getActualOrders(room.id);
      getMyAuctions(room.id);
      getGamePlayerRanking(room.id);
      getWarehouses(currentUserRoomId);
      getMaterialUnload(currentUserRoomId);
    }
  }, [
    dispatch,
    isAutomation,
    room,
    gameFactory,
    getScenario,
    getSuppliers,
    getProcesses,
    gameGantt,
    getActualOrders,
    initializeUserRoomRounds,
    getMyAuctions,
    getGamePlayerRanking,
    currentUserRoomId,
  ]);

  const handleSupplierChosen = () => {
    setShowGamePrepareModal(false);
  };

  const handleShowGantt = (show: boolean) => {
    setShowGantt(show);
  };

  const handleViewFactory = () => {
    setShowGantt(false);
  };

  useEffect(() => {
    if (materialUnload.length && !isUserGameMaster) {
      getWarehouses(currentUserRoomId).then(() => {
        setShowDeliveryMaterialToWarehouseModal(true);
      });
    }
  }, [materialUnload, currentUserRoomId, isUserGameMaster]);

  useEffect(() => {
    if (!isLoadingRoom && isSuppliersLoaded && !actualSupplier && actualSuppliers.length && !isUserGameMaster) {
      setShowGamePrepareModal(true);
    }
  }, [isLoadingRoom, isSuppliersLoaded, actualSupplier, actualSuppliers, isUserGameMaster]);

  useEffect(() => {
    if (room && !isDataLoaded) {
      handleRoundFinishedNotificationEvent(room.id, (event: NewRoundEvent) => {
        if (event.currentRound > room.currentRound) {
          window.location.reload();
        }
      });
      setIsDataLoaded(true);
      loadData();
    }
  }, [room, loadData, isDataLoaded]);

  useEffect(() => {
    getUserRoomById(currentUserRoomId);
    return () => {
      dispatch(clearUserRoom());
      dispatch(clearFactory());
      dispatch(clearGanttTemplate());
      dispatch(clearRounds());
    };
  }, [currentUserRoomId, getUserRoomById, dispatch]);

  const gamePrepareModalRender = () => (
    <GamePrepareModal
      isOpen={showGamePrepareModal}
      suppliers={actualSuppliers}
      gameFactoryId={gameFactory ? gameFactory.id : -1}
      supplierChosen={handleSupplierChosen}
      showMode={false}
      actualProcesses={actualProcesses}
      actualScenario={actualScenario}
      resources={resources}
    />
  );

  const onTimeLeft = () => {
    if (!isUserGameMaster) {
      UserGameRoundService.endUserRound(latestUserRoom, updateRoom);
      updateRoom();
    }
  };

  const getTimeLeftInMillis = () => {
    const timeLeftInMillis = latestUserRoom.roundTimeLeft - (new Date().getTime() - loadRoomTimestamp);
    return timeLeftInMillis > 0 ? timeLeftInMillis : 0;
  };

  const handleClose = () => {
    setShowDeliveryMaterialToWarehouseModal(false);
  };

  const renderGameView = () => (
    <div className={classes.root}>
      {showDeliveryMaterialToWarehouseModal && (
        <DeliveryMaterialToWarehouseModal
          isOpen={showDeliveryMaterialToWarehouseModal}
          materialUnload={materialUnload}
          warehouses={warehouses}
          onClose={handleClose}
        />
      )}
      <GameHeader
        latestUserRoom={latestUserRoom}
        selectedUserRoom={selectedUserRoom}
        updateRoom={updateRoom}
        timeLeftInMillis={getTimeLeftInMillis()}
        onTimeLeft={onTimeLeft}
        gamePlayerRankings={gamePlayerRankings}
        showGantt={handleShowGantt}
        actualScenario={actualScenario}
        isFactory
      />
      <div className={classes.factoryContainer}>
        <div className={classes.factoryAreaContainer}>
          {latestUserRoom.user && <FactoryArea resources={resources} />}
        </div>
        <div className={classes.factoryToolbarContainer}>
          <GameFactoryAccordion
            updateRoom={updateRoom}
            resources={resources}
            actualProcesses={actualProcesses}
            actualOrders={actualOrders}
            actualSuppliers={actualSuppliers}
            myAuctions={myAuctions}
            showSelectRoundComponent={showSelectRoundComponent}
          />
        </div>
      </div>
    </div>
  );

  if (isLoadingRoom) {
    return <div className={classes.loading}>Wczytuje dane, proszę czekać...</div>;
  }

  return showGamePrepareModal ? (
    gamePrepareModalRender()
  ) : showGantt ? (
    <GameGanttContainer
      userRoomId={currentUserRoomId}
      resources={resources}
      actualActivities={gameActivities}
      updateRoom={updateRoom}
      timeLeftInMillis={getTimeLeftInMillis()}
      onTimeLeft={onTimeLeft}
      viewFactory={handleViewFactory}
      gamePlayerRankings={gamePlayerRankings}
      actualScenario={actualScenario}
      actualProcesses={actualProcesses}
    />
  ) : (
    renderGameView()
  );
}

export default GameViewPage;
