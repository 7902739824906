import React, { useCallback, useEffect, useState } from 'react';

import { GridProps } from './Grid.interface';
import { useStyles } from './Grid.styles';

export function Grid(props: GridProps) {
  const { areaProps, offsetX, offsetY } = props;
  const { rows, cols } = areaProps;
  const classes = useStyles(props);
  const [area, setArea] = useState<Array<JSX.Element>>([]);

  const createArea = useCallback(() => {
    const area: Array<JSX.Element> = [];

    for (let y = 0; y < rows; y++) {
      const children: Array<JSX.Element> = [];

      for (let x = 0; x < cols; x++) {
        children.push(<div key={x} className={classes.areaCell} />);
      }
      area.push(
        <div key={y} className={classes.areaRow}>
          {children}
        </div>
      );
    }

    setArea(area);
  }, [rows, cols, classes]);

  const createLegend = (): Array<JSX.Element> => {
    const legend: Array<JSX.Element> = [];
    for (let y = 0; y <= rows; y++) {
      const children: Array<JSX.Element> = [];

      if (y === 0) {
        children.push(<div key={0} className={classes.legendCell} />);
        for (let x = 1; x <= cols; x++) {
          children.push(
            <div key={x} className={classes.legendCell}>
              {x + offsetX - 1}
            </div>
          );
        }
      } else {
        children.push(
          <div key={y} className={classes.legendCell}>
            {y - offsetY - 1}
          </div>
        );
      }
      legend.push(
        <div key={y} className={classes.legendRow}>
          {children}
        </div>
      );
    }

    return legend;
  };

  useEffect(() => {
    createArea();
  }, [createArea]);

  return (
    <div>
      <div className={classes.grid}>{area}</div>
      <div className={classes.legend}>{createLegend()}</div>
    </div>
  );
}
