import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    borderRadius: 5,
    marginBottom: 20,
  },
  tableContainer: {
    height: 300,
    overflow: 'auto',
  },
  tableHead: {
    fontWeight: 'bold',
  },
  tableCell: {
    padding: '5px 20px',
  },
  title: {
    position: 'relative',
  },
  showSuppliersButton: {
    position: 'absolute',
    fontSize: 13,
    cursor: 'pointer',
    width: 70,
    color: 'blue',
    top: 0,
    right: 0,
  },
  nameCell: {
    width: '30%',
  },
  actionCell: {
    width: 100,
  },
}));
