import { makeStyles } from '@material-ui/core';
import { GridProps } from './Grid.interface';

export const useStyles = makeStyles({
  grid: {
    position: 'relative',
    paddingLeft: 40,
    paddingTop: 40,
  },
  legend: {
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  legendRow: {
    display: 'flex',
    '&:first-child': {
      '& div': {
        height: 40,
        border: 'none',
        writingMode: 'vertical-rl',
        transform: 'rotate(180deg)',
      },
    },
  },
  legendCell: ({ areaProps: { cellHeight, cellWidth } }: GridProps) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: cellWidth,
    height: cellHeight,
    backgroundColor: 'white',
    border: '1px solid #ccc',
    '&:first-child': {
      width: 40,
      border: 'none',
    },
  }),
  areaRow: {
    display: 'flex',
  },
  areaCell: ({ areaProps: { cellHeight, cellWidth } }: GridProps) => ({
    width: cellWidth,
    height: cellHeight,
    backgroundColor: 'white',
    border: '1px solid #ccc',
  }),
});
