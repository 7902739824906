import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  root: {
    backgroundColor: '#e4ffe9',
    borderRadius: 5,
    padding: 15,
    border: '1px solid #ccc',
    zIndex: 5,
    margin: 20,
  },
  header: {
    marginBottom: 15,
    fontSize: 18,
  },
  button: {
    '&:not(:last-child)': {
      marginRight: 10,
    },
  },
  closeButton: {
    position: 'absolute',
    top: 5,
    right: 5,
  },
});
