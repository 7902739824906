import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';

import { CustomDialog } from 'app/components/CustomDialog/CustomDialog';
import { AddDistributionTemplateModal } from 'app/components/AddDistributionTemplateModal/AddDistributionTemplateModal';
import { CustomTablePagination } from 'app/components/CustomTablePagination/CustomTablePagination';
import { getParams } from 'app/shared/helpers/pagination';
import { CustomIconButton } from 'app/components/CustomIconButton/CustomIconButton';
import { DistributionTemplateInterface } from 'app/shared/interfaces/DistributionTemplate.interface';
import { DistributionTemplateService } from 'app/services/distributionTemplate.service';
import { SnackbarAlert } from 'app/components/SnackbarAlert/SnackbarAlert';
import { handlingCatch } from 'app/shared/helpers/handlingCatch';

import { useStyles } from './DistributionTemplatesTable.styles';
import { DistributionTemplatesTableProps } from './DistributionTemplatesTable.interfaces';

export function DistributionTemplatesTable(props: DistributionTemplatesTableProps) {
  const {
    distributionTemplates,
    shouldUpdateDistributionTemplates,
    totalRecords,
    distributionAttributesTemplatesType,
    addClick,
  } = props;
  const classes = useStyles();
  const [selectedDistributionTemplate, setSelectedDistributionTemplate] = useState<number>(0);
  const [selectedPage, setSelectedPage] = useState<number>(0);
  const [distributionTemplateData, setDistributionTemplateData] = useState<DistributionTemplateInterface | null>(null);

  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [openAddDialog, setOpenAddDialog] = useState<boolean>(false);

  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [notificationText, setNotificationText] = useState<string>('');

  const handleSnackbarClose = () => {
    setShowNotification(false);
  };

  const handleAddDistributionTemplate = () => {
    setDistributionTemplateData(null);
    setOpenAddDialog(true);
  };

  const handleOpenEditDistributionTemplate = (id: number) => {
    const distributionTemplate = getDistributionTemplateById(id);
    if (distributionTemplate) {
      setDistributionTemplateData(distributionTemplate);
      setOpenAddDialog(true);
    }
  };

  const handleOpenDeleteDistributionTemplate = (id: number) => {
    setSelectedDistributionTemplate(id);
    setOpenDeleteDialog(true);
  };

  const handleDeleteDistributionTemplate = () => {
    DistributionTemplateService.deleteDistributionTemplate(selectedDistributionTemplate)
      .then(() => {
        setOpenDeleteDialog(false);
        shouldUpdateDistributionTemplates(getParams(selectedPage));
      })
      .catch((error) => {
        const response = handlingCatch(error.response);
        const { status } = error.response;
        if (status === 400) {
          setNotificationText(response.message);
          setShowNotification(true);
          setOpenDeleteDialog(false);
        }
      });
  };

  const handleCloseDeleteDistributionTemplateDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
    setDistributionTemplateData(null);
  };

  const handleDistributionTemplateAdded = () => {
    shouldUpdateDistributionTemplates(getParams(selectedPage));
    setOpenAddDialog(false);
    setDistributionTemplateData(null);
  };

  const getDistributionTemplateById = (id: number) =>
    distributionTemplates.find((distributionTemplate) => distributionTemplate.id === id);

  const getDistributionTemplateName = (id: number) => {
    const distributionTemplate = getDistributionTemplateById(id);
    return distributionTemplate ? distributionTemplate.name : '';
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    shouldUpdateDistributionTemplates(getParams(page));
    setSelectedPage(page);
  };

  useEffect(() => {
    if (addClick) {
      handleAddDistributionTemplate();
    }
  }, [addClick]);

  return (
    <div>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Nazwa</TableCell>
              <TableCell>Opis</TableCell>
              <TableCell className={classes.buttonsTableCell} align={'right'} />
            </TableRow>
          </TableHead>
          <TableBody>
            {distributionTemplates.map((distributionTemplate) => (
              <TableRow key={distributionTemplate.id}>
                <TableCell className={clsx(classes.wrap, classes.bold)}>{distributionTemplate.name}</TableCell>
                <TableCell className={classes.wrap}>{distributionTemplate.description}</TableCell>
                <TableCell align={'right'} className={classes.buttonsTableCell}>
                  <CustomIconButton
                    onClick={() => handleOpenEditDistributionTemplate(distributionTemplate.id)}
                    type={'preview'}
                  />
                  <CustomIconButton
                    onClick={() => handleOpenDeleteDistributionTemplate(distributionTemplate.id)}
                    type={'delete'}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <CustomTablePagination
            tablePaginationConfig={{
              count: totalRecords,
              page: selectedPage,
            }}
            onChangePage={handleChangePage}
          />
        </Table>
      </TableContainer>
      <AddDistributionTemplateModal
        isOpen={openAddDialog}
        data={distributionTemplateData}
        onClose={handleCloseAddDialog}
        distributionTemplateAdded={handleDistributionTemplateAdded}
        distributionAttributesTemplatesType={distributionAttributesTemplatesType}
      />
      <CustomDialog
        title={'Usuwanie rozkładu'}
        onCloseDialog={handleCloseDeleteDistributionTemplateDialog}
        body={
          <>
            {'Na pewno chcesz usunąć rozkład: '}
            {getDistributionTemplateName(selectedDistributionTemplate)}
          </>
        }
        isOpen={openDeleteDialog}
        dialogActions={
          <>
            <Button color={'primary'} onClick={handleDeleteDistributionTemplate}>
              Tak
            </Button>
            <Button color={'primary'} autoFocus onClick={handleCloseDeleteDistributionTemplateDialog}>
              Nie
            </Button>
          </>
        }
      />
      <Snackbar
        open={showNotification}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={handleSnackbarClose}
      >
        <SnackbarAlert message={notificationText} />
      </Snackbar>
    </div>
  );
}
