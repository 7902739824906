import { GetParamsInterface } from 'app/shared/interfaces/RequestInterface.interface';

export const defaultParams: GetParamsInterface = {
  page: 1,
  pagination: true,
};

export const getParams = (page: number) => {
  return { page: page + 1, pagination: true };
};
