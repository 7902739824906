import React from 'react';
import NumberFormat from 'react-number-format';
import Typography from '@material-ui/core/Typography';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';

import { BalanceStatisticsProps } from './BalanceStatistics.interface';

import { useStyles } from './BalanceStatistics.styles';

export function BalanceStatistics({
  currentCompanyValue,
  currentEarnings,
  currentLoss,
  cashBalance,
  gameOrders,
}: BalanceStatisticsProps) {
  const classes = useStyles();

  const getOrdersValue = () => {
    let sum: number = 0;
    gameOrders.map((order) => {
      sum += order.offerPrice ? order.offerPrice : 0;
      return order;
    });

    return sum;
  };

  return (
    <>
      <Typography variant={'h1'}>Bilans</Typography>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHead}>Aktywa</TableCell>
              <TableCell className={classes.tableHead}>Pasywa</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                Wartość firmy:{' '}
                <NumberFormat
                  displayType={'text'}
                  value={currentCompanyValue}
                  fixedDecimalScale
                  decimalScale={2}
                  thousandSeparator={' '}
                  suffix={' PLN'}
                />
              </TableCell>
              <TableCell>
                Gotówka gracza:{' '}
                <NumberFormat
                  displayType={'text'}
                  value={cashBalance}
                  fixedDecimalScale
                  decimalScale={2}
                  thousandSeparator={' '}
                  suffix={' PLN'}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                Wartość zamówień::{' '}
                <NumberFormat
                  displayType={'text'}
                  value={getOrdersValue()}
                  fixedDecimalScale
                  decimalScale={2}
                  thousandSeparator={' '}
                  suffix={' PLN'}
                />
              </TableCell>
              <TableCell>
                <div>Zysk / strata</div>
                <NumberFormat
                  displayType={'text'}
                  value={currentEarnings}
                  fixedDecimalScale
                  decimalScale={2}
                  thousandSeparator={' '}
                />
                {' / '}
                <NumberFormat
                  displayType={'text'}
                  value={currentLoss}
                  fixedDecimalScale
                  decimalScale={2}
                  thousandSeparator={' '}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                Suma:{' '}
                <NumberFormat
                  displayType={'text'}
                  value={currentCompanyValue + getOrdersValue()}
                  fixedDecimalScale
                  decimalScale={2}
                  thousandSeparator={' '}
                  suffix={' PLN'}
                />
              </TableCell>
              <TableCell>
                Suma:{' '}
                <NumberFormat
                  displayType={'text'}
                  value={cashBalance + currentEarnings - currentLoss}
                  fixedDecimalScale
                  decimalScale={2}
                  thousandSeparator={' '}
                  suffix={' PLN'}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
