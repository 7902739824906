import React, { useEffect, useState } from 'react';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';

import { RoomInterface } from 'app/shared/interfaces/Room.interface';
import { RoomService } from 'app/services/room.service';
import { CustomDialog } from 'app/components/CustomDialog/CustomDialog';
import { TextFieldComponent } from 'app/components/TextFieldComponent/TextFieldComponent';
import { MultipleSelectComponent } from 'app/components/MultipleSelectComponent/MultipleSelectComponent';
import { SelectComponent } from 'app/components/SelectComponent/SelectComponent';
import { BotsSettingsModal } from 'app/components/BotSettingsModal/BotSettingsModal';
import { roomGameStatus, roomGameStatusArray, userRoomGameStatus, roles } from 'app/shared/constants';
import { UserRoomInterface } from 'app/shared/interfaces/UserRoom.interface';
import { UserInterface } from 'app/shared/interfaces/User.interface';

import { useStyles } from './AddRoomModal.styles';
import { AddRoomFormDataInterface, AddRoomModalProps } from './AddRoomModal.interfaces';
import { handlingCatch } from 'app/shared/helpers/handlingCatch';
import { convertToInt } from 'app/shared/helpers/numberConverter';
import { BotSettingsInterface } from 'app/shared/interfaces/BotSettings.interface';

export function AddRoomModal(props: AddRoomModalProps) {
  const { isOpen, onClose, room, roomAdded, users, scenarios } = props;
  const classes = useStyles();
  const [roundAvailable, setRoundAvailable] = useState<boolean>(true);
  const [isBotSettingsModalOpen, setIsBotSettingsModalOpen] = useState<boolean>(false);

  const handleOpenBotsSettingsModal = () => {
    setIsBotSettingsModalOpen(true);
  };

  const handleCloseBotsSettingsModal = () => {
    setIsBotSettingsModalOpen(false);
  };

  const formData: AddRoomFormDataInterface = {
    name: room ? room.name : '',
    users: room
      ? room.users.map((user) => {
          return user && user.id ? user.id : 0;
        })
      : [],
    scenarioTemplate: room && room.scenarioTemplate ? room.scenarioTemplate.id.toString() : '',
    timeRound: room ? room.timeRound.toString() : '10',
    status: room ? room.status.toString() : roomGameStatus.GAME_IDLE.status.toString(),
    rounds: room ? room.rounds.toString() : '0',
    currentRound: room ? room.timeRound.toString() : '0',
    numberOfBots: room && room.numberOfBots ? room.numberOfBots.toString() : '0',
    botSettings: room?.botSettings
      ? room.botSettings
      : {
          weightProfitableInProcess: '0',
          leftCashAfterBoughtMissingResourcesInProcess: '0',
          weightProcessCostInAuction: '0',
          weightResourceCostInAuction: '0',
          weightMaterialCostInAuction: '0',
          weightQualityControlCostInAuction: '0',
          weightHowManyUsersBiddingInAuction: '0',
          weightForHowManyUsersBiddingInAuction: '0',
          profitabilityInAuction: '0',
          calculateBiddingPriceBaseOnOtherPlayersPrices: false,
          numberOfLossAuctionsBeforeStart: 0,
        },
  };
  const validationShema = Yup.object().shape({
    name: Yup.string()
      .required('Podaj nazwę')
      .min(1, 'Nazwa nie może być pusta')
      .max(255, 'Nazwa może mieć maksymalnie 255 znaków'),
    scenarioTemplate: Yup.string().required('Wybierz scenariusz'),
    users: Yup.string().required('Wybierz graczy'),
    timeRound: Yup.number()
      .min(1, 'Podaj czas trwania tury, max 24h')
      .max(1440, 'Podaj czas trwania tury, max 24h')
      .typeError('Podaj czas trwania tury, max 24h')
      .integer('Podaj czas trwania tury, max 24h')
      .required('Podaj czas trwania tury, max 24h'),
    rounds: Yup.number().when('scenarioTemplate', {
      is: (scenarioId) => scenarios.find((scenario) => scenario.id === convertToInt(scenarioId))?.automation,
      then: Yup.number()
        .min(1, 'Ilość tur powinna być większa od 0.')
        .typeError('Podaj ilość tur, wartość powinna być liczbą całkowitą')
        .integer('Podaj ilość tur, wartość powinna być liczbą całkowitą')
        .required('Podaj ilość tur, wartość powinna być liczbą całkowitą'),
    }),
    numberOfBots: Yup.number()
      .min(0, 'Ilość botów powinna być większa lub równa 0.')
      .typeError('Podaj ilość botów, wartość powinna być liczbą całkowitą')
      .integer('Podaj ilość botów, wartość powinna być liczbą całkowitą')
      .required('Podaj ilość botów, wartość powinna być liczbą całkowitą'),
  });

  const isDoubleRequest = (room: RoomInterface, formRoomStatus: number) => {
    return room.status !== formRoomStatus && roomGameStatus.GAME_IDLE.status === room.status;
  };

  const getUpdateStatus = (roomData: Omit<RoomInterface, 'id' | 'userRooms'>) => {
    return {
      status: roomData.status,
    };
  };

  const getUpdateFields = (roomData: Omit<RoomInterface, 'id' | 'userRooms'>) => {
    if (room && isDoubleRequest(room, roomData.status)) {
      return { ...roomData, status: room.status };
    }
    if (roomData.status !== (room && room.status ? room.status : 0)) {
      return {
        status: roomData.status,
      };
    }
    return roomData;
  };

  useEffect(() => {
    if (room && room.id && room.status === roomGameStatus.GAME_IDLE.status) {
      setRoundAvailable(!!(room.scenarioTemplate && room.scenarioTemplate.automation === 0));
    } else {
      setRoundAvailable(true);
    }
  }, [isOpen, room]);

  const allUsersHasBeenMark = (userRooms: Array<UserRoomInterface>) =>
    userRooms.every((userRoom) => userRoom.status === userRoomGameStatus.GAME_MARK_AND_END.status);

  const availableGameStatusItems = (room: RoomInterface | null): number[] => {
    switch (room?.status) {
      case roomGameStatus.GAME_IDLE.status:
        return [
          roomGameStatus.GAME_PENDING.status,
          roomGameStatus.GAME_IDLE.status,
          roomGameStatus.GAME_FINISHED.status,
        ];

      case roomGameStatus.GAME_PENDING.status:
        return [roomGameStatus.GAME_PENDING.status, roomGameStatus.GAME_FINISHED.status];

      case roomGameStatus.GAME_FINISHED.status:
        return allUsersHasBeenMark(room.userRooms)
          ? [roomGameStatus.GAME_FINISHED.status, roomGameStatus.GAME_ARCHIVED.status]
          : [roomGameStatus.GAME_FINISHED.status];

      // When creating new room
      default:
        return [roomGameStatus.GAME_IDLE.status, roomGameStatus.GAME_PENDING.status];
    }
  };

  const getScenarioName = (room: RoomInterface) => {
    return room.scenarioTemplateActual ? room.scenarioTemplateActual.name : '';
  };

  const getScenarioLabel = (label: string) => (room && room.id ? getScenarioName(room) : label);

  const romStatusIsIdle = () => {
    if (room && room.id && room.status === roomGameStatus.GAME_IDLE.status) {
      return true;
    } else if (!room) {
      return true;
    }
    return false;
  };

  const getOnlyActiveUsers = (users: Array<UserInterface>) => {
    const usersFiltred = users.filter((user) => user.status === 1);
    if (room) {
      usersFiltred.push({ roles: [''], status: 0, id: 0, email: 'BOT' });
    }
    return usersFiltred;
  };

  const isOnlyBotInGame = (usersCount: number, actions: FormikHelpers<any>): boolean => {
    if (!usersCount) {
      actions.setFieldError('users', 'W grze musi brać udział przynajmniej jeden gracz, który nie jest botem.');
    }
    return usersCount === 0;
  };

  return (
    <>
      <CustomDialog
        title={room ? 'Edytuj pokój' : 'Dodaj pokój'}
        isOpen={isOpen}
        onCloseDialog={onClose}
        body={
          <Formik
            initialValues={formData}
            validationSchema={validationShema}
            onSubmit={(values, actions) => {
              const roomData: Omit<RoomInterface, 'id' | 'userRooms'> = {
                name: values.name.trim(),
                numberOfBots: parseInt(values.numberOfBots),
                users: users.filter((user) => values.users.includes(user.id || -1)),
                scenarioTemplate: scenarios.find((scenario) => scenario.id === parseInt(values.scenarioTemplate)),
                timeRound: convertToInt(values.timeRound),
                rounds: convertToInt(values.rounds),
                currentRound: parseInt(values.currentRound),
                status: parseInt(values.status),
                botSettings: {
                  ...values.botSettings,
                  numberOfLossAuctionsBeforeStart: convertToInt(
                    String(values.botSettings.numberOfLossAuctionsBeforeStart)
                  ),
                },
              };
              if (isOnlyBotInGame(roomData.users.length, actions)) {
                return;
              }
              if (room && room.id) {
                RoomService.replaceRoom(room.id, getUpdateFields(roomData))
                  .then(() => {
                    if (!isDoubleRequest(room, roomData.status)) {
                      roomAdded();
                      return;
                    }
                    RoomService.replaceRoom(room.id, getUpdateStatus(roomData)).then(() => {
                      roomAdded();
                    });
                  })
                  .catch((error) => {
                    const response = handlingCatch(error.response);
                    actions.setFieldError(response.fieldError, response.message);
                  });
              } else {
                RoomService.addRoom(roomData)
                  .then(() => {
                    roomAdded();
                  })
                  .catch((error) => {
                    const response = handlingCatch(error.response);
                    actions.setFieldError(response.fieldError, response.message);
                  });
              }
            }}
          >
            {({ values, submitForm, setFieldValue }) => (
              <Form>
                <Field
                  disabled={!romStatusIsIdle()}
                  component={TextFieldComponent}
                  name={'name'}
                  label={'Nazwa'}
                  fullWidth
                />
                <Field
                  component={MultipleSelectComponent}
                  fullWidth
                  label={'Gracze'}
                  name={'users'}
                  options={getOnlyActiveUsers(users)
                    .filter((user) => !user.roles.includes(roles.ROLE_GAME_MASTER))
                    .map((user) => ({
                      label: user.email,
                      value: user.id,
                      disabled: user.email === 'BOT',
                    }))}
                  disabled={!romStatusIsIdle()}
                />
                <Field
                  component={SelectComponent}
                  fullWidth
                  label={'Scenariusz'}
                  name={'scenarioTemplate'}
                  onChange={(event: { target: { value: number } }) => {
                    const scenarioId: number = event.target.value;
                    const scenario = scenarios.find((scenario) => scenario.id === scenarioId);
                    const scenarioWithEngine = scenario ? scenario.automation : 0;
                    setFieldValue('rounds', '0');
                    setRoundAvailable(scenarioWithEngine === 0);
                    setFieldValue('scenarioTemplate', scenarioId);
                  }}
                  options={scenarios.map((scenario) => ({
                    label: getScenarioLabel(scenario.name),
                    value: scenario.id,
                  }))}
                  disabled={!!(room && room.id)}
                />
                <Field
                  component={SelectComponent}
                  fullWidth
                  label={'Status gry'}
                  name={'status'}
                  options={roomGameStatusArray.map((gameStatus) => ({
                    label: gameStatus.label.room,
                    value: gameStatus.status,
                    disabled: !availableGameStatusItems(room).includes(gameStatus.status),
                  }))}
                />
                <Field
                  disabled={!romStatusIsIdle()}
                  component={TextFieldComponent}
                  name={'timeRound'}
                  label={'Czas trwania tury [min]'}
                  fullWidth
                />
                <Field
                  component={TextFieldComponent}
                  disabled={roundAvailable}
                  name={'rounds'}
                  label={'Ilość tur'}
                  fullWidth
                />
                <Field
                  component={TextFieldComponent}
                  disabled={roundAvailable}
                  name={'numberOfBots'}
                  label={'Liczba botów'}
                  fullWidth
                />
                <Button className={classes.botSettingsButton} onClick={handleOpenBotsSettingsModal}>
                  Właściwości botów
                </Button>
                <div className={classes.actionButtons}>
                  <Button color={'primary'} onClick={submitForm}>
                    {room ? 'Zapisz' : 'Dodaj'}
                  </Button>
                  <Button color={'primary'} autoFocus onClick={onClose}>
                    Anuluj
                  </Button>
                </div>
                <BotsSettingsModal
                  onClose={handleCloseBotsSettingsModal}
                  isOpen={isBotSettingsModalOpen}
                  changeSettings={(settings: BotSettingsInterface) => {
                    setFieldValue('botSettings', settings);
                  }}
                  settings={values.botSettings}
                  disabled={!romStatusIsIdle()}
                />
              </Form>
            )}
          </Formik>
        }
      />
    </>
  );
}
