import React, { useState } from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';

import { CustomTablePagination } from 'app/components/CustomTablePagination/CustomTablePagination';
import { getParams } from 'app/shared/helpers/pagination';

import { useStyles } from './ActionTypesTable.styles';
import { ActionTypesTableProps } from './ActionTypesTable.interfaces';

export function ActionTypesTable(props: ActionTypesTableProps) {
  const { actionTypes, shouldUpdateActionTypes, totalRecords } = props;
  const classes = useStyles();
  const [selectedPage, setSelectedPage] = useState<number>(0);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    shouldUpdateActionTypes(getParams(page));
    setSelectedPage(page);
  };

  return (
    <div>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Nazwa</TableCell>
              <TableCell>Opis</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {actionTypes.map((actionType) => (
              <TableRow key={actionType.id}>
                <TableCell className={classes.bold}>{actionType.name}</TableCell>
                <TableCell>{actionType.description}</TableCell>
              </TableRow>
            ))}
          </TableBody>
          <CustomTablePagination
            tablePaginationConfig={{
              count: totalRecords,
              page: selectedPage,
            }}
            onChangePage={handleChangePage}
          />
        </Table>
      </TableContainer>
    </div>
  );
}
