import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import AddTimeButton from '@material-ui/icons/Update';
import Button from '@material-ui/core/Button';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { RoomService } from 'app/services/room.service';
import { GameService } from 'app/services/game.service';
import { UserRoomInterface } from 'app/shared/interfaces/UserRoom.interface';
import { RoomInterface } from 'app/shared/interfaces/Room.interface';
import { getParams } from 'app/shared/helpers/pagination';
import { CustomTablePagination } from 'app/components/CustomTablePagination/CustomTablePagination';
import { CustomDialog } from 'app/components/CustomDialog/CustomDialog';
import { AddRoomModal } from 'app/components/AddRoomModal/AddRoomModal';
import { SelectUserRoomModal } from 'app/components/SelectUserRoomModal/SelectUserRoomModal';
import { ScenarioActualInterface } from 'app/shared/interfaces/ScenarioActual.interface';
import { convertMinutesToHours } from 'app/shared/helpers/numberConverter';
import { RoomsTableProps } from './RoomsTable.interfaces';
import {
  getRoomCellBackgroundStatus,
  getRoomStatus,
  getRoomStatusColor,
  getUserRoomConfigStatus,
} from 'app/shared/helpers/userGame';
import { roomGameStatus, scenarioOptions, statusesAllowingRoomDeletion, scenarioValue } from 'app/shared/constants';
import { CustomIconButton } from 'app/components/CustomIconButton/CustomIconButton';

import { useStyles } from './RoomsTable.styles';

export function RoomsTable(props: RoomsTableProps) {
  const { rooms, shouldUpdateRooms, totalRecords, users, scenarios, showAddButton, addClick } = props;
  const classes = useStyles();
  const history = useHistory();

  const [selectedRoom, setSelectedRoom] = useState<number>(0);
  const [selectedPage, setSelectedPage] = useState<number>(0);
  const [roomData, setRoomData] = useState<RoomInterface | null>(null);
  const [usersRoom, setUsersRoom] = useState<Array<UserRoomInterface>>([]);

  const [openFirstStepDeleteDialog, setOpenFirstStepDeleteDialog] = useState<boolean>(false);
  const [openSecondStepDeleteRoomDialog, setOpenSecondStepDeleteRoomDialog] = useState<boolean>(false);
  const [openNextRoundDialog, setOpenNextRoundDialog] = useState<boolean>(false);
  const [openSelectUserRoomDialog, setOpenSelectUserRoomDialog] = useState<boolean>(false);
  const [openAddDialog, setOpenAddDialog] = useState<boolean>(false);

  const handleAddResourceType = () => {
    setRoomData(null);
    setOpenAddDialog(true);
  };

  const handleOpenEditRoom = (id: number) => {
    const room = getRoomTypeById(id);
    if (room) {
      setRoomData(room);
      setOpenAddDialog(true);
    }
  };

  const handleOpenDeleteRoom = (id: number) => {
    setSelectedRoom(id);
    setOpenFirstStepDeleteDialog(true);
  };

  const handleOpenNextRound = (id: number) => {
    setSelectedRoom(id);
    setOpenNextRoundDialog(true);
  };

  const handleOpenSelectUserDialog = (userRooms: Array<UserRoomInterface>) => {
    setUsersRoom(userRooms);
    setOpenSelectUserRoomDialog(true);
  };

  const handleCloseSelectUserDialog = () => {
    setOpenSelectUserRoomDialog(false);
  };

  const handleFirstStepDeleteRoom = () => {
    const room = getRoomTypeById(selectedRoom);
    if (room && room.status === roomGameStatus.GAME_ARCHIVED.status) {
      setOpenFirstStepDeleteDialog(false);
      setOpenSecondStepDeleteRoomDialog(true);
    } else {
      handleDeleteRoomRequest();
    }
  };

  const handleSecondStepDeleteRoom = () => {
    handleDeleteRoomRequest();
  };

  function handleDeleteRoomRequest() {
    RoomService.deleteRoom(selectedRoom).then(() => {
      setOpenFirstStepDeleteDialog(false);
      setOpenSecondStepDeleteRoomDialog(false);
      shouldUpdateRooms(getParamsForRequest());
    });
  }

  const handleCloseFirstStepDeleteRoomDialog = () => {
    setOpenFirstStepDeleteDialog(false);
  };

  const handleCloseSecondStepDeleteRoomDialog = () => {
    setOpenSecondStepDeleteRoomDialog(false);
  };

  const handleNextRound = () => {
    if (openNextRoundDialog) {
      setOpenNextRoundDialog(false);
      GameService.nextRound(selectedRoom).then(() => {
        shouldUpdateRooms(getParamsForRequest());
      });
    }
  };

  const handleCloseNextRoundDialog = () => {
    setOpenNextRoundDialog(false);
  };

  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
    setRoomData(null);
  };

  const getParamsForRequest = (page: number = selectedPage) => {
    return { ...getParams(page), showArchivedRoom: !showAddButton };
  };

  const handleRoomAdded = () => {
    shouldUpdateRooms(getParamsForRequest());
    setOpenAddDialog(false);
    setRoomData(null);
  };

  const getRoomTypeById = (id: number) => rooms.find((room) => room.id === id);

  const getRoomName = (id: number) => {
    const room = getRoomTypeById(id);
    return room ? room.name : '';
  };

  const getRoomStatusName = (id: number) => {
    const room = getRoomTypeById(id);
    return room ? getRoomStatus(room) : '';
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    shouldUpdateRooms(getParamsForRequest(page));
    setSelectedPage(page);
  };

  const getUserGameStatus = (userRoom: UserRoomInterface) => {
    const userGameStatus = getUserRoomConfigStatus(userRoom.status);
    const userGameMark = userRoom.mark ? `Wynik: ${userRoom.mark}` : '';
    return userGameStatus ? `${userGameStatus.label.room} ${userGameMark}` : '';
  };

  const getUserRoomStatusColor = (userRoom: UserRoomInterface) => {
    const userGameStatus = getUserRoomConfigStatus(userRoom.status);
    return userGameStatus ? userGameStatus.cell_color : '';
  };

  const getScenarioName = (room: RoomInterface) => {
    return room.scenarioTemplateActual ? room.scenarioTemplateActual.name : '';
  };

  const getScenarioType = (room: RoomInterface) => {
    return room.scenarioTemplateActual ? getAutomationStatus(room.scenarioTemplateActual) : 'Brak Informacji';
  };

  const getAutomationStatus = (scenario: ScenarioActualInterface) => {
    const scenarioOption = scenarioOptions.find((scenarioOption) => scenarioOption.value === scenario.automation);
    return scenarioOption ? scenarioOption.label : 'Brak Informacji';
  };

  const handleOpenGameView = (id: number) => {
    history.push(`/game_view/${id}/`);
  };

  const showDeleteRoomButton = (status: number) => {
    return statusesAllowingRoomDeletion.includes(status);
  };

  const getUserRoomView = (room: RoomInterface, showStatus: boolean = false) => {
    const userRooms: Array<UserRoomInterface> = room.userRooms;
    return (
      <Table className={classes.subTable}>
        <TableBody>
          {userRooms.map((userRoom, index) => {
            const cellBackground = getRoomCellBackgroundStatus(room)
              ? getRoomStatusColor(room)
              : getUserRoomStatusColor(userRoom);
            return (
              <TableRow key={userRoom.id} style={{ backgroundColor: cellBackground }}>
                {showStatus ? (
                  <TableCell>{getUserGameStatus(userRoom)}</TableCell>
                ) : (
                  <TableCell style={{ backgroundColor: cellBackground }}>
                    {userRoom.user && userRoom.user.email ? userRoom.user.email : `BOT ${userRoom.id}`}
                  </TableCell>
                )}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  };

  useEffect(() => {
    if (addClick) {
      handleAddResourceType();
    }
  }, [addClick]);

  return (
    <div>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Nazwa</TableCell>
              <TableCell>Gracze</TableCell>
              <TableCell>Status Graczy</TableCell>
              <TableCell>Status gry</TableCell>
              <TableCell>Scenariusz</TableCell>
              <TableCell>Typ Scenariusza</TableCell>
              <TableCell align={'center'}>Czas trwania tury [HH:MM]</TableCell>
              <TableCell align={'center'}>Ilość tur</TableCell>
              <TableCell align={'center'}>Ilość botów</TableCell>
              <TableCell className={classes.buttonsTableCell} align={'right'} />
            </TableRow>
          </TableHead>
          <TableBody>
            {rooms.map((room) => (
              <TableRow key={room.id}>
                <TableCell className={clsx(classes.roomName, classes.bold)}>{room.name}</TableCell>
                <TableCell className={classes.subTableCell}>{getUserRoomView(room)}</TableCell>
                <TableCell className={classes.subTableCell}>{getUserRoomView(room, true)}</TableCell>
                <TableCell style={{ backgroundColor: getRoomStatusColor(room) }}>
                  <div>{getRoomStatus(room)}</div>
                  {room.scenarioTemplate?.automation === scenarioValue.AUTOMATION_ON ? (
                    <div>Runda {room.currentRound} </div>
                  ) : null}
                </TableCell>
                <TableCell>{getScenarioName(room)}</TableCell>
                <TableCell>{getScenarioType(room)}</TableCell>
                <TableCell align={'center'}>{convertMinutesToHours(room.timeRound)}</TableCell>
                <TableCell align={'center'}>{room.rounds}</TableCell>
                <TableCell align={'center'}>{room.numberOfBots ? room.numberOfBots : 0}</TableCell>
                <TableCell align={'right'}>
                  <div className={classes.actionCell}>
                    <IconButton onClick={() => handleOpenSelectUserDialog(room.userRooms)} title="Wystaw ocenę/podgląd">
                      <VisibilityIcon fontSize={'small'} />
                    </IconButton>
                    {showAddButton && (
                      <CustomIconButton
                        onClick={() => handleOpenEditRoom(room.id)}
                        type={'edit'}
                        title="Edytuj pokój"
                      />
                    )}
                    {showDeleteRoomButton(room.status) && (
                      <CustomIconButton
                        onClick={() => handleOpenDeleteRoom(room.id)}
                        type={'delete'}
                        title="Usuń pokój"
                      />
                    )}
                    {room.scenarioTemplate &&
                      room.scenarioTemplate.automation === 1 &&
                      roomGameStatus.GAME_PENDING.status === room.status && (
                        <IconButton onClick={() => handleOpenNextRound(room.id)} title="Przekręć turę">
                          <AddTimeButton fontSize={'small'} />
                        </IconButton>
                      )}
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <CustomTablePagination
            tablePaginationConfig={{
              count: totalRecords,
              page: selectedPage,
            }}
            onChangePage={handleChangePage}
          />
        </Table>
      </TableContainer>
      <AddRoomModal
        isOpen={openAddDialog}
        users={users}
        scenarios={scenarios}
        room={roomData}
        onClose={handleCloseAddDialog}
        roomAdded={handleRoomAdded}
      />
      <SelectUserRoomModal
        isOpen={openSelectUserRoomDialog}
        usersRoom={usersRoom}
        onClose={handleCloseSelectUserDialog}
        roomSelected={handleOpenGameView}
      />
      <CustomDialog
        title={'Usuwanie pokoju'}
        onCloseDialog={handleCloseFirstStepDeleteRoomDialog}
        body={
          <>
            {'Na pewno chcesz usunąć pokój: '}
            {getRoomName(selectedRoom)}
          </>
        }
        isOpen={openFirstStepDeleteDialog}
        dialogActions={
          <>
            <Button color={'primary'} onClick={handleFirstStepDeleteRoom}>
              Tak
            </Button>
            <Button color={'primary'} autoFocus onClick={handleCloseFirstStepDeleteRoomDialog}>
              Nie
            </Button>
          </>
        }
      />
      <CustomDialog
        title={'Usuwanie pokoju'}
        body={
          <>
            {'Czy na pewno chcesz usunąć pokój o statusie: '}
            {getRoomStatusName(selectedRoom)}
          </>
        }
        isOpen={openSecondStepDeleteRoomDialog}
        dialogActions={
          <>
            <Button color={'primary'} onClick={handleSecondStepDeleteRoom}>
              Tak
            </Button>
            <Button color={'primary'} autoFocus onClick={handleCloseSecondStepDeleteRoomDialog}>
              Nie
            </Button>
          </>
        }
      />

      <CustomDialog
        title={'Następna runda'}
        onCloseDialog={handleCloseNextRoundDialog}
        body={
          <>
            {'Na pewno chcesz przejść do następnej rundy? '}
            {getRoomName(selectedRoom)}
          </>
        }
        isOpen={openNextRoundDialog}
        dialogActions={
          <>
            <Button color={'primary'} onClick={handleNextRound}>
              Tak
            </Button>
            <Button color={'primary'} autoFocus onClick={handleCloseNextRoundDialog}>
              Nie
            </Button>
          </>
        }
      />
    </div>
  );
}
