import React from 'react';
import clsx from 'clsx';
import Tooltip from '@material-ui/core/Tooltip';
import { ActionInterface } from 'app/shared/interfaces/Action.interface';
import { GanttItemInterface } from 'app/shared/interfaces/GanttTemplate.interface';
import { GameGanttOrderInterface } from 'app/shared/interfaces/GameGanttOrder.interface';
import { AddGameGanttOrderItemInterface } from 'app/shared/interfaces/GameGanttOrderItem.interface';
import { getGanttProcessingErrorDescription } from './GanttProcessingErrors';

export const PIXEL_PER_DURATION = 4;

interface CreateTemplateItemsInterface {
  ganttTemplateItems: Array<GanttItemInterface>;
  actions: Array<ActionInterface>;
  handleItemClick: (e: React.MouseEvent<HTMLDivElement>, id: number) => void;
  offsetX: number;
  classes: any;
}

interface CreateItemsInterface {
  orderId: number;
  ganttOrders?: Array<GameGanttOrderInterface>;
  classes: any;
  actions: Array<ActionInterface>;
  isUserGameMaster: boolean;
  offsetX: number;
  handleItemClick: (e: React.MouseEvent<HTMLDivElement>, id: number) => void;
  isAutomation: boolean;
}

interface IsCollisionInterface {
  orderId: number;
  ganttOrders?: Array<GameGanttOrderInterface>;
  position: number;
  duration: number;
  itemId?: number;
}

export const GanttSerivce = {
  getActionById(actions: Array<ActionInterface>, id: string) {
    return actions.find((action) => action['@id'] === id);
  },
  getActivityNameById(actions: Array<ActionInterface>, activityId: number) {
    const activity = actions.find((action) => action.id === activityId);
    return activity ? activity.description : '';
  },
  createTemplateItems({
    ganttTemplateItems,
    actions,
    handleItemClick,
    offsetX,
    classes,
  }: CreateTemplateItemsInterface) {
    return ganttTemplateItems.map((ganttItem) => {
      const action = GanttSerivce.getActionById(actions, ganttItem.action);
      return (
        <div
          key={ganttItem.id}
          className={classes.item}
          style={{
            left: ganttItem.position * PIXEL_PER_DURATION - offsetX * 40,
            width: 40,
            backgroundColor: action ? action.color : 'red',
          }}
          onClick={(e) => handleItemClick(e, ganttItem.id)}
        >
          {action ? (action.description ? action.description : action.name) : ''}
        </div>
      );
    });
  },
  createItems({
    orderId,
    ganttOrders,
    classes,
    actions,
    isUserGameMaster,
    offsetX,
    handleItemClick,
    isAutomation,
  }: CreateItemsInterface) {
    const order = ganttOrders && ganttOrders.find((order) => order.id === orderId);
    const orderItems = order ? order.gameGanttOrderItems : [];
    return orderItems.map((item) => (
      <Tooltip
        key={item.id}
        title={
          <div className={classes.tooltipRoot}>
            <div>Czynność: {GanttSerivce.getActivityNameById(actions, item.activityActual.id)}</div>
            <div>Zasób: {item.gameFactoryResourceFirst ? item.gameFactoryResourceFirst.resourceActual.name : ''}</div>
            {item.gameFactoryResourceSecond && <div>Zasób: {item.gameFactoryResourceSecond.resourceActual.name}</div>}
            {item.gameFactoryResourceTransport && (
              <div>Sposób transportu: {item.gameFactoryResourceTransport.resourceActual.name}</div>
            )}
            <div>Początek: {item.position}</div>
            <div>Koniec: {item.position + item.duration}</div>
            {item.ganttProcessingError && (
              <div>Błąd produkcji: {getGanttProcessingErrorDescription(item.ganttProcessingErrorStatus)}</div>
            )}
          </div>
        }
        placement={'top'}
      >
        <div
          className={clsx(classes.item, {
            [classes.itemBorder]:
              item.ganttProcessingError || (!item.gameFactoryResourceFirst && (isUserGameMaster || isAutomation)),
          })}
          style={{
            left: item.position * PIXEL_PER_DURATION - offsetX * 40,
            width: item.duration * PIXEL_PER_DURATION,
            backgroundColor: item.activityActual.color,
          }}
          onClick={(e) => handleItemClick(e, item.id)}
        >
          {GanttSerivce.getActivityNameById(actions, item.activityActual.id) || item.activityActual.name}
        </div>
      </Tooltip>
    ));
  },
  isCollision({ ganttOrders, orderId, position, duration, itemId }: IsCollisionInterface) {
    const order = ganttOrders && ganttOrders.find((order) => order.id === orderId);
    if (order) {
      const collision = order.gameGanttOrderItems.find((item) => {
        if (itemId && item.id === itemId) {
          return false;
        }
        return (
          (item.position > position && item.position < position + duration) ||
          (item.position + item.duration > position && item.position + item.duration < position + duration) ||
          (position > item.position && position < item.position + item.duration) ||
          (position + duration > item.position && position + duration < item.position + item.duration) ||
          item.position === position ||
          item.position + item.duration === position + duration
        );
      });
      return !!collision;
    }
    return false;
  },
  calculatePositionX(e: React.MouseEvent<HTMLDivElement>, rootEl: HTMLDivElement | null, halfDuration: number): number {
    if (rootEl) {
      const position = Math.floor((e.clientX - rootEl.offsetLeft - 315 + window.scrollX) / 4 - halfDuration);
      return position > 0 ? position : 0;
    }
    return 0;
  },
  getGanttOrderItemById(id: number, ganttOrders?: Array<GameGanttOrderInterface>) {
    let ganttItem: AddGameGanttOrderItemInterface | undefined;
    ganttOrders &&
      ganttOrders.map((order) =>
        order.gameGanttOrderItems.map((item) => {
          if (item.id === id) {
            ganttItem = item;
          }
          return item;
        })
      );

    return ganttItem;
  },
};
