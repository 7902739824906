import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { intersection } from 'lodash';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import { CssBaseline, Drawer, List, ListItem, ListItemIcon, ListItemText, Collapse } from '@material-ui/core';

import { NavigateBefore as NavigateBeforeIcon } from '@material-ui/icons';
import { getCurrentUser } from 'app/store/user';

import { DashboardProps } from './Dashboard.interfaces';
import { MenuInterface, SubmenuInterface } from 'app/shared/interfaces/Menu.interface';
import { menu, submenu } from 'app/shared/menu';
import { useStyles } from './Dashboard.styles';

function Dashboard({ open, onCLose }: DashboardProps) {
  const classes = useStyles();
  const currentUser = useSelector(getCurrentUser);
  const [selectedSubmenu, setSelectedSubmenu] = React.useState(-1);

  const location = useLocation();

  const haveAccess = (menuItem: MenuInterface | SubmenuInterface) =>
    currentUser && !(menuItem.access && intersection(currentUser.roles, menuItem.access).length === 0);

  const toggleSubmenu = (id: number) => {
    const submenuStatus = id === selectedSubmenu ? -1 : id;
    setSelectedSubmenu(submenuStatus);
  };

  const getSubmenuList = (menuIndex: number) => {
    const submenuItems = submenu.filter((item) => item.menuItemId === menuIndex);
    const submenuIsOpen = selectedSubmenu === menuIndex;
    return submenuItems.map((submenuItem, index) => {
      if (haveAccess(submenuItem)) {
        return (
          <Collapse in={submenuIsOpen} timeout="auto" unmountOnExit key={submenuItem.link}>
            <List component="div" disablePadding>
              <Link className={classes.link} to={submenuItem.link}>
                <Tooltip title={submenuItem.label} placement={'left'} disableHoverListener={open}>
                  <ListItem button className={classes.nested}>
                    <ListItemIcon
                      className={clsx(classes.submenuIcon, {
                        [classes.submenuIconActive]: submenuItem.link === location.pathname,
                      })}
                    >
                      {submenuItem.icon}
                    </ListItemIcon>
                    <ListItemText
                      className={clsx(classes.submenuIcon, {
                        [classes.submenuIconActive]: submenuItem.link === location.pathname,
                      })}
                      primary={submenuItem.label}
                    />
                  </ListItem>
                </Tooltip>
              </Link>
            </List>
          </Collapse>
        );
      } else {
        return null;
      }
    });
  };

  const getMenuLink = (menuItem: MenuInterface) => (
    <Link className={classes.link} to={menuItem.link ? menuItem.link : '/'} key={menuItem.label}>
      <Tooltip title={menuItem.label} placement={'left'} disableHoverListener={open}>
        <ListItem button>
          <ListItemIcon
            className={clsx(classes.menuIcon, {
              [classes.menuIconActive]: menuItem.link ? menuItem.link === location.pathname : false,
            })}
          >
            {menuItem.icon}
          </ListItemIcon>
          <ListItemText
            className={clsx(classes.menuIcon, {
              [classes.menuIconActive]: menuItem.link ? menuItem.link === location.pathname : false,
            })}
            primary={menuItem.label}
          />
        </ListItem>
      </Tooltip>
    </Link>
  );

  const isMenuActive = (menu: MenuInterface) => {
    const _submenu = submenu.find((smenu) => smenu.link === location.pathname);

    return _submenu ? _submenu.menuItemId === menu.id : false;
  };

  const getMenuList = (menuItem: MenuInterface, menuIndex: number) => {
    if (haveAccess(menuItem)) {
      return (
        <Tooltip title={menuItem.label} placement={'left'} key={menuItem.label} disableHoverListener={open}>
          <ListItem onClick={() => toggleSubmenu(menuIndex)} button key={menuItem.label}>
            <ListItemIcon className={clsx(classes.menuIcon, { [classes.menuIconActive]: isMenuActive(menuItem) })}>
              {menuItem.icon}
            </ListItemIcon>
            <ListItemText
              className={clsx(classes.menuIcon, {
                [classes.menuIconActive]: isMenuActive(menuItem),
              })}
              primary={menuItem.label}
            />
          </ListItem>
        </Tooltip>
      );
    } else {
      return null;
    }
  };

  const wrapperMenuItem = (item: Array<JSX.Element | null>, key: string) => <div key={key}>{item}</div>;

  const getMenu = () =>
    menu
      .filter((menuI) => haveAccess(menuI))
      .map((menuItem, index) =>
        menuItem.link
          ? wrapperMenuItem([getMenuLink(menuItem)], menuItem.label)
          : wrapperMenuItem([getMenuList(menuItem, menuItem.id), ...getSubmenuList(menuItem.id)], menuItem.label)
      );

  return (
    <div className={classes.root}>
      <CssBaseline />
      {currentUser && (
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx(classes.paper, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <List className={classes.list}>{getMenu()}</List>
          <IconButton
            size={'small'}
            className={clsx(classes.closeButton, { [classes.closeButtonHidden]: !open })}
            color={'primary'}
            onClick={onCLose}
          >
            <NavigateBeforeIcon />
          </IconButton>
        </Drawer>
      )}
    </div>
  );
}

export default Dashboard;
