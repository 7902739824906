import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Paper } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { useStyles } from './LoginPage.styles';
import AuthService from 'app/services/auth.service';
import logoUE from 'app/shared/images/logo_ue.jpg';

import { TextFieldComponent } from 'app/components/TextFieldComponent/TextFieldComponent';

function LoginPage() {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.loginPageContainer}>
      <div className={classes.containerItems}>
        <Paper className={classes.paper}>
          <Typography variant={'h1'}>Logowanie</Typography>
          <div>
            <span>
              Nie masz konta? <Link to={'/register'}>Zarejestruj się</Link>
            </span>
          </div>
          <Formik
            initialValues={{
              email: '',
              password: '',
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().required('Podaj adres email'),
              password: Yup.string().required('Podaj hasło'),
            })}
            onSubmit={(values, actions) => {
              AuthService.login(values)
                .then((tokenResponse) => {
                  AuthService.setToken(tokenResponse?.data.token);
                  history.push('/');
                })
                .catch((error) => {
                  if (!error.response) {
                    actions.setFieldError('password', 'Brak odpowiedzi ze strony serwera');
                    return;
                  }
                  const { status } = error.response;
                  if (status === 400) {
                    actions.setFieldError('password', 'Twoje konto jest nieaktywne');
                  } else if (status === 401) {
                    actions.setFieldError('password', 'Błędne dane logowania');
                  } else {
                    actions.setFieldError('password', 'Problem podczas ładowania');
                  }
                });
            }}
          >
            <Form>
              <Field component={TextFieldComponent} name={'email'} label={'Email'} fullWidth />
              <Field component={TextFieldComponent} name={'password'} type={'password'} label={'Hasło'} fullWidth />
              <Button color={'primary'} type={'submit'}>
                Zaloguj
              </Button>
            </Form>
          </Formik>
        </Paper>
      </div>
      <div className={classes.containerItems}>
        <img src={logoUE} alt={'logo_ue'} />
      </div>
    </div>
  );
}

export default LoginPage;
