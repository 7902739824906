import React, { ChangeEvent, useEffect, useState } from 'react';
import { Formik, Form, Field, FieldArray, FastField } from 'formik';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import { AddScenarioInterface } from 'app/shared/interfaces/Scenario.interface';
import { ScenarioSerivce } from 'app/services/scenario.service';
import { TextFieldComponent } from 'app/components/TextFieldComponent/TextFieldComponent';
import { MultipleSelectComponent } from 'app/components/MultipleSelectComponent/MultipleSelectComponent';
import { CustomDialog } from 'app/components/CustomDialog/CustomDialog';
import { AddSuppliersToScenarioModal } from 'app/components/AddSuppliersToScenarioModal/AddSuppliersToScenarioModal';
import { OrderService } from 'app/services/order.service';
import { OrderInterface } from 'app/shared/interfaces/Order.interface';
import { SelectComponent } from 'app/components/SelectComponent/SelectComponent';
import { scenarioOptions, scenarioValue } from 'app/shared/constants';
import { handlingCatch } from 'app/shared/helpers/handlingCatch';
import { ProcessInterface } from 'app/shared/interfaces/Process.interface';
import { AddProcessScenarioTemplateDistributionTemplateInterface } from 'app/shared/interfaces/ProcessScenarioTemplateDistributionTemplate.interface';
import { upToTwoDecimalPlaces } from 'app/shared/helpers/validators';
import { convertToFloat, convertToInt } from 'app/shared/helpers/numberConverter';

import { AddScenarioFormDataInterface, AddScenarioModalProps } from './AddScenarioModal.interfaces';
import { useStyles } from './AddScenarioModal.styles';

export function AddScenarioModal(props: AddScenarioModalProps) {
  const {
    isOpen,
    onClose,
    scenario,
    resources,
    suppliers,
    processes,
    scenarioAdded,
    factories,
    orders,
    distributionTemplate,
  } = props;
  const classes = useStyles();
  const [isOpenSuppliersModal, setIsOpenSuppliersModal] = useState<boolean>(false);
  const [selectedSuppliers, setSelectedSuppliers] = useState<Array<number>>([]);
  const [selectedProcess, setSelectedProcess] = useState<Array<ProcessInterface>>([]);
  const [availableOrders, setAvailableOrders] = useState<Array<OrderInterface>>([]);
  const [isAutomationEngine, setIsAutomationEngine] = useState<boolean>(false);

  useEffect(() => {
    if (scenario) {
      setIsAutomationEngine(scenario.automation === scenarioValue.AUTOMATION_ON);
      setSelectedProcess(scenario.processes);
    } else {
      setSelectedProcess([]);
    }
  }, [scenario, isOpen]);

  const formData: AddScenarioFormDataInterface = {
    name: scenario ? scenario.name : '',
    content: scenario ? scenario.content : '',
    cash: scenario ? scenario.cash.toString() : '1000',
    lossRatio: scenario ? scenario.lossRatio.toString() : '20',
    automation: scenario ? scenario.automation.toString() : '',
    suppliers: scenario ? scenario.scenarioTemplateSuppliers.map((supplier) => supplier.id) : [],
    processes: scenario ? scenario.processes.map((process) => process.id) : [],
    resources: scenario ? scenario.resources.map((resource) => resource.id) : [],
    factory: scenario && scenario.factories[0] ? scenario.factories[0].id.toString() : '',
    orders: scenario ? scenario.orders.map((order) => order.id) : [],
    factoryUnitCost:
      scenario && scenario.factoryUnitCost ? scenario.factoryUnitCost.toString() : isAutomationEngine ? '' : '0',
    productDistributionTemplate:
      scenario && scenario.processScenarioTemplateDistributionTemplates
        ? scenario.processScenarioTemplateDistributionTemplates.map((item) => item.distributionTemplate.id.toString())
        : [],
    priceChangeFactor: scenario ? scenario.priceChangeFactor.toString() : '',
    numberOfRoundToReduceBalance: scenario ? scenario.numberOfRoundToReduceBalance.toString() : isAutomationEngine ? '' : '0',
  };

  const validationShema = Yup.object().shape({
    name: Yup.string()
      .required('Podaj nazwę')
      .min(1, 'Nazwa nie może być pusta')
      .max(255, 'Nazwa może mieć maksymalnie 255 znaków'),
    content: Yup.string().required('Podaj opis'),
    automation: Yup.string().required('Wybierz typ scenariusza'),
    cash: Yup.number()
      .test('is-incorrect', 'Podaj gotówkę w liczbie rzeczywistej, max. dwa miejsca po przecinku', upToTwoDecimalPlaces)
      .min(0, 'Podaj gotówkę w liczbie rzeczywistej, max. dwa miejsca po przecinku')
      .typeError('Podaj gotówkę w liczbie rzeczywistej, max. dwa miejsca po przecinku')
      .required('Podaj gotówkę w liczbie rzeczywistej, max. dwa miejsca po przecinku'),
    numberOfRoundToReduceBalance: Yup.number()
      .min(1, 'Podaj wskaźnik rundy kosztów stałych większy od 0')
      .typeError('Podaj wskaźnik rundy kosztów stałych')
      .integer('Podaj wskaźnik rundy kosztów stałych')
      .required('Podaj wskaźnik rundy kosztów stałych'),
    lossRatio: Yup.number()
      .min(0, 'Podaj wartość straty w procentach 0-100')
      .max(100, 'Podaj wartość straty w procentach 0-100')
      .typeError('Podaj wartość straty w procentach 0-100')
      .integer('Podaj wartość straty w procentach 0-100')
      .required('Podaj wartość straty w procentach 0-100'),
    factoryUnitCost: Yup.number()
      .test('is-incorrect', 'Podaj gotówkę w liczbie rzeczywistej, max. dwa miejsca po przecinku', upToTwoDecimalPlaces)
      .min(0, 'Podaj gotówkę w liczbie rzeczywistej, max. dwa miejsca po przecinku')
      .typeError('Podaj gotówkę w liczbie rzeczywistej, max. dwa miejsca po przecinku')
      .required('Podaj koszt stały fabryki'),
    factory: Yup.number().required('Wybierz fabrykę'),
    productDistributionTemplate: Yup.array(Yup.string().required('Wybierz rozkład')),
    priceChangeFactor: Yup.number()
      .test(
        'is-incorrect',
        'Podaj współczynnik zmiany ceny w zakresie od 0 do 2 max. dwa miejsca po przecinku',
        upToTwoDecimalPlaces
      )
      .min(0, 'Podaj współczynnik zmiany ceny w zakresie od 0 do 2 max. dwa miejsca po przecinku')
      .max(2, 'Podaj współczynnik zmiany ceny w zakresie od 0 do 2 max. dwa miejsca po przecinku')
      .typeError('Podaj współczynnik zmiany ceny w zakresie od 0 do 2 max. dwa miejsca po przecinku')
      .required('Podaj współczynnik zmiany ceny w zakresie od 0 do 2 max. dwa miejsca po przecinku'),
  });

  const handelCloseSupplierModal = () => setIsOpenSuppliersModal(false);

  const handleOpenAddSupplier = () => {
    setIsOpenSuppliersModal(true);
  };

  const handleAddSuppliers = (suppliers: Array<number>) => {
    setSelectedSuppliers(suppliers);
    setIsOpenSuppliersModal(false);
  };

  const getSupplierNameById = (id: number) => {
    const supplier = suppliers.find((supplier) => supplier.id === id);

    return supplier ? supplier.name : '';
  };

  useEffect(() => {
    if (scenario) {
      setSelectedSuppliers(scenario.scenarioTemplateSuppliers.map((supplier) => supplier.supplier.id));
      getAvailableOrders(scenario.processes.map((process) => process.id));
    }
    return () => setSelectedSuppliers([]);
  }, [scenario, isOpen]);

  const getAvailableOrders = (processes: Array<number>) => {
    const processArray = processes.length ? processes : [-1];
    OrderService.getOrders({ process: processArray }).then(
      ({ data: { 'hydra:member': responseData, 'hydra:totalItems': totalRecords } }) => {
        setAvailableOrders(responseData);
      }
    );
  };

  return (
    <>
      <CustomDialog
        title={scenario ? 'Edycja scenariusza' : 'Dodanie scenariusza'}
        isOpen={isOpen}
        onCloseDialog={onClose}
        body={
          <Formik
            onSubmit={(values, actions) => {
              const scenarioData: AddScenarioInterface = {
                name: values.name.trim(),
                content: values.content,
                cash: convertToFloat(values.cash),
                priceChangeFactor: convertToFloat(values.priceChangeFactor),
                lossRatio: convertToInt(values.lossRatio),
                factoryUnitCost: convertToFloat(values.factoryUnitCost),
                automation: parseInt(values.automation),
                scenarioTemplateSuppliers: selectedSuppliers.map((supplierId, index) => ({
                  supplier: { '@id': `/api/suppliers/${supplierId}` },
                  supplierOrder: index + 1,
                })),
                numberOfRoundToReduceBalance: convertToInt(values.numberOfRoundToReduceBalance),
                processes: processes.filter((process) => values.processes.includes(process.id)),
                resources: resources.filter((resource) => values.resources.includes(resource.id)),
                factories: factories.filter((factory) => [parseInt(values.factory)].includes(factory.id)),
                orders: orders.filter((order) => values.orders.includes(order.id)),
                processScenarioTemplateDistributionTemplates: values.productDistributionTemplate.map(
                  (item, index): AddProcessScenarioTemplateDistributionTemplateInterface => {
                    return {
                      process: processes.find((process) => values.processes[index] === process.id),
                      distributionTemplate: distributionTemplate.find(
                        (distribution) => parseInt(item) === distribution.id
                      ),
                    };
                  }
                ),
              };
              if (scenario) {
                ScenarioSerivce.replaceScenario(scenario.id, scenarioData)
                  .then(() => scenarioAdded())
                  .catch((error) => {
                    const response = handlingCatch(error.response);
                    actions.setFieldError(response.fieldError, response.message);
                  });
              } else {
                ScenarioSerivce.addScenario(scenarioData)
                  .then(() => scenarioAdded())
                  .catch((error) => {
                    const response = handlingCatch(error.response);
                    actions.setFieldError(response.fieldError, response.message);
                  });
              }
            }}
            initialValues={formData}
            validationSchema={validationShema}
          >
            {({ submitForm, setFieldValue, values }) => (
              <Form>
                <FastField component={TextFieldComponent} name={'name'} label={'Nazwa'} fullWidth />
                <FastField
                  component={TextFieldComponent}
                  name={'content'}
                  label={'Zawartość'}
                  fullWidth
                  multiline
                  rowsMax={4}
                  inputProps={{ maxLength: 5000 }}
                />
                <Field component={TextFieldComponent} name={'cash'} label={'Gotówka'} fullWidth />
                <Field
                  component={TextFieldComponent}
                  name={'priceChangeFactor'}
                  label={'Współczynnik zmiany ceny'}
                  fullWidth
                />
                <Field component={TextFieldComponent} name={'lossRatio'} label={'Strata wartości [%]'} fullWidth />
                <Field
                  onChange={(event: { target: { value: number } }) => {
                    const idArray: number = event.target.value;
                    setIsAutomationEngine(idArray === scenarioValue.AUTOMATION_ON);
                    setFieldValue('orders', []);
                    setFieldValue('productDistributionTemplate', []);
                    setFieldValue('factoryUnitCost', '0');
                    setFieldValue('numberOfRoundToReduceBalance', '5');
                    if (idArray === scenarioValue.AUTOMATION_ON) {
                      setFieldValue('factoryUnitCost', '');
                      setFieldValue('numberOfRoundToReduceBalance', '');
                      setFieldValue(
                        'productDistributionTemplate',
                        values.processes.map(() => '')
                      );
                    }
                    setFieldValue('automation', idArray);
                  }}
                  component={SelectComponent}
                  fullWidth
                  label={'Typ scenariusza'}
                  name={'automation'}
                  options={scenarioOptions}
                />
                {isAutomationEngine && (
                  <Field
                    component={TextFieldComponent}
                    name={'factoryUnitCost'}
                    label={'Koszt stały fabryki [PLN]'}
                    fullWidth
                  />
                )}
                {isAutomationEngine && (
                  <Field
                    component={TextFieldComponent}
                    name={'numberOfRoundToReduceBalance'}
                    label={'Wskaźnik rundy kosztów stałych'}
                    fullWidth
                  />
                )}
                <Field
                  component={MultipleSelectComponent}
                  onChange={(e: ChangeEvent, value: Array<{ label: string; value: number }>) => {
                    const idArray: Array<number> = value.map((val) => val.value);
                    getAvailableOrders(idArray);
                    setFieldValue('processes', idArray);
                    setFieldValue('productDistributionTemplate', []);
                    if (isAutomationEngine) {
                      if (!idArray.length) {
                        setFieldValue('productDistributionTemplate', []);
                      }
                      setFieldValue(
                        'productDistributionTemplate',
                        idArray.map(() => '')
                      );
                    }
                    setSelectedProcess(processes.filter((process) => idArray.includes(process.id)));
                  }}
                  fullWidth
                  label={'Procesy'}
                  name={'processes'}
                  options={processes.map((process) => ({
                    label: process.name,
                    value: process.id,
                  }))}
                />
                {!isAutomationEngine ? (
                  <Field
                    disabled={!availableOrders.length}
                    component={MultipleSelectComponent}
                    fullWidth
                    label={'Zamówienia'}
                    name={'orders'}
                    options={availableOrders.map((order) => ({
                      label: order.name,
                      value: order.id,
                    }))}
                  />
                ) : (
                  <div>
                    {selectedProcess.length
                      ? selectedProcess.map((processes, index) => {
                          return (
                            <FieldArray
                              name="productDistributionTemplate"
                              key={processes['@id']}
                              render={(arrayHelpers) => (
                                <>
                                  <Field
                                    key={processes.product.id}
                                    component={SelectComponent}
                                    fullWidth
                                    label={`Rozkład dla produktu: ${processes.product.name}`}
                                    name={`productDistributionTemplate.${index}`}
                                    options={distributionTemplate.map((distributionTemplateItem) => ({
                                      label: distributionTemplateItem.name,
                                      value: distributionTemplateItem.id,
                                    }))}
                                  />
                                </>
                              )}
                            />
                          );
                        })
                      : null}
                  </div>
                )}
                <Field
                  component={MultipleSelectComponent}
                  fullWidth
                  label={'Zasoby Produkcyjne'}
                  name={'resources'}
                  options={resources.map((resource) => ({
                    label: resource.name,
                    value: resource.id,
                  }))}
                />
                <Field
                  component={SelectComponent}
                  fullWidth
                  label={'Fabryka'}
                  name={'factory'}
                  options={factories.map((factory) => ({
                    label: factory.name,
                    value: factory.id,
                  }))}
                />
                <TextField
                  label="Dostawcy"
                  disabled
                  fullWidth
                  value={selectedSuppliers.map((supplierId) => getSupplierNameById(supplierId)).join(', ')}
                />
                <Button color={'primary'} onClick={handleOpenAddSupplier} className={classes.addSupplierButton}>
                  Dodaj dostawców
                </Button>
                <div className={classes.actionButtons}>
                  <Button color={'primary'} onClick={submitForm}>
                    {scenario ? 'Zapisz' : 'Dodaj'}
                  </Button>
                  <Button color={'primary'} autoFocus onClick={onClose}>
                    Anuluj
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        }
      />
      <>
        <AddSuppliersToScenarioModal
          isOpen={isOpenSuppliersModal}
          suppliers={suppliers}
          onClose={handelCloseSupplierModal}
          addSuppliers={handleAddSuppliers}
          activeSuppliers={selectedSuppliers}
        />
      </>
    </>
  );
}
