import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  root: {
    width: '100%',
    border: '1px solid #ccc',
    borderRadius: 5,
    marginBottom: 20,
  },
  tableContainer: {
    height: 300,
    overflow: 'auto',
  },
  tableCell: {
    padding: '5px 20px',
  },
});
