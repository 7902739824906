import React from 'react';
import { useStyles } from './GameOwnMaterialQueues.styles';
import { GameOwnMaterialQueuesInterface } from './GameOwnMaterialQueues.interface';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';

export function GameOwnMaterialQueues(props: GameOwnMaterialQueuesInterface) {
  const { gameMaterialQueues } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.tableContainer}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Materiał</TableCell>
              <TableCell align={'center'}>Docelowa tura dostarczenia materiału</TableCell>
              <TableCell align={'center'}>Ilość</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {gameMaterialQueues.map((gameMaterialQueue) => (
              <TableRow key={gameMaterialQueue.id}>
                <TableCell>{gameMaterialQueue.gameMaterial.actualMaterial?.name}</TableCell>
                <TableCell align={'center'}>{gameMaterialQueue.deliveryRound}</TableCell>
                <TableCell align={'center'}>{gameMaterialQueue.gameMaterial.amount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}
