import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import { BulletedListProps } from './BulletedList.interface';
import { useStyles } from './BulletedList.styles';

export function BulletedList({ list }: BulletedListProps) {
  const classes = useStyles();

  return (
    <List disablePadding>
      {list.map((item) => (
        <ListItem key={item.id} dense disableGutters className={classes.listItem}>
          {`- ${item.label}`}
        </ListItem>
      ))}
    </List>
  );
}
