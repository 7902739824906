import { AxiosPromise } from 'axios';

import { ApiService } from './api.service';
import { ResourceTypeInterface, ResourceTypeResponse } from 'app/shared/interfaces/ResourceType.interface';
import { GetParamsInterface } from 'app/shared/interfaces/RequestInterface.interface';

export const ResourceTypeService = {
  getResourceTypes(params: GetParamsInterface = {}): AxiosPromise<ResourceTypeResponse> {
    return ApiService.get('resource_types', params);
  },

  addResourceType(data: Omit<ResourceTypeInterface, 'id'>): AxiosPromise {
    return ApiService.post('resource_types', data);
  },

  replaceResourceType(id: number, data: Omit<ResourceTypeInterface, 'id'>) {
    return ApiService.put(`resource_types/${id}`, data);
  },

  deleteResourceType(id: number): AxiosPromise {
    return ApiService.delete(`resource_types/${id}`);
  },
};
