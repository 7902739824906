import { AxiosPromise } from 'axios';

import { ApiService } from './api.service';
import { ProductInterface, ProductResponse } from 'app/shared/interfaces/Product.interface';
import { GetParamsInterface } from 'app/shared/interfaces/RequestInterface.interface';

export const ProductService = {
  getProducts(params: GetParamsInterface = {}): AxiosPromise<ProductResponse> {
    return ApiService.get('products', params);
  },
  addProduct(data: Omit<ProductInterface, 'id'>): AxiosPromise {
    return ApiService.post('products', data);
  },
  replaceProduct(id: number, data: Omit<ProductInterface, 'id'>): AxiosPromise {
    return ApiService.put(`products/${id}`, data);
  },
  deleteProduct(id: number): AxiosPromise {
    return ApiService.delete(`products/${id}`);
  },
};
