import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles(({ maxHeightTable }: Theme) => ({
  tableContainer: {
    margin: '0 20px',
    width: 'calc(100% - 40px)',
    maxHeight: maxHeightTable,
  },
  buttonsTableCell: {
    padding: 5,
    minWidth: 150,
    width: 150,
  },
  bold: {
    fontWeight: 'bold',
  },
  wrap: {
    maxWidth: '35vw',
    wordWrap: 'break-word',
  },
}));
