import { AppThunk } from 'app/store/store';

import {
  clearActiveResource,
  setFactory,
  setFactoryResources,
  setActiveRoundFactoryResources,
  setActualSupplier,
} from './actions';
import { FactoryService } from 'app/services/factory.service';
import { GameFactoryService } from 'app/services/gameFactory.service';
import { FactoryResourcesService } from 'app/services/factoryResources.service';
import { FactoryResourceInterface } from 'app/shared/interfaces/FactoryResource.interface';
import { GameFactoryResourceService } from 'app/services/gameFactoryResource.service';
import {
  AddGameFactoryResourceInterface,
  GameFactoryResourceInterface,
} from 'app/shared/interfaces/GameFactoryResource.interface';
import { FactoryResourcesInterface } from 'app/shared/interfaces/Factory.interface';
import { fetchLatestUserRoomById, setIsLoadingRoom } from 'app/store/userRoom';

export const fetchFactoryById = (id: number): AppThunk => (dispatch) => {
  FactoryService.getFactoryById(id).then(({ data }) => {
    dispatch(setFactory(data));
    dispatch(setActiveRoundFactoryResources(data.factoryResources));
  });
};

export const deleteFactoryResourceById = (factoryResourceId: number, factoryId: number): AppThunk => (dispatch) => {
  FactoryResourcesService.deleteFactoryResource(factoryResourceId).then(() => {
    dispatch(fetchFactoryById(factoryId));
  });
};

export const replaceFactoryById = (
  factoryResourceId: number,
  factoryId: number,
  data: Omit<FactoryResourceInterface, 'id' | 'factory'>
): AppThunk => (dispatch) => {
  FactoryResourcesService.replaceFactoryResource(factoryResourceId, data).then(() => {
    dispatch(fetchFactoryById(factoryId));
  });
};

export const addFactoryResource = (factoryId: number, data: Omit<FactoryResourceInterface, 'id'>): AppThunk => (
  dispatch
) => {
  FactoryResourcesService.addFactoryResource(data).then(() => {
    dispatch(fetchFactoryById(factoryId));
    dispatch(clearActiveResource());
  });
};

const getFactoryResources = (gameFactoryResource: Array<GameFactoryResourceInterface>) => {
  return gameFactoryResource.map((res) => ({
    '@id': res['@id'],
    id: res.id,
    x: res.x,
    y: res.y,
    resource: res.resourceActual,
    resourceNumber: res.resourceNumber,
  }));
};

export const fetchGameFactoryResources = (id: number): AppThunk => (dispatch) => {
  GameFactoryService.getGameFactoryById(id).then(({ data: { gameFactoryResources: responseData } }) => {
    const gameResources: Array<FactoryResourcesInterface> = getFactoryResources(responseData);
    dispatch(setFactoryResources(gameResources));
    dispatch(setActiveRoundFactoryResources(gameResources));
  });
};

export const fetchGameFactoryById = (id: number): AppThunk => (dispatch) => {
  GameFactoryService.getGameFactoryById(id).then(({ data }) => {
    const gameResources: Array<FactoryResourcesInterface> = getFactoryResources(data.gameFactoryResources);
    dispatch(setActualSupplier(data.actualSupplier));
    dispatch(setFactoryResources(gameResources));
    dispatch(setActiveRoundFactoryResources(gameResources));
    dispatch(setIsLoadingRoom(false));
  });
};

export const addResourceToUserRoom = (
  data: AddGameFactoryResourceInterface,
  factoryId: number,
  userRoomId: number
): AppThunk => (dispatch) => {
  GameFactoryResourceService.addGameFactoryResource(data).then(() => {
    dispatch(fetchGameFactoryResources(factoryId));
    dispatch(clearActiveResource());
    dispatch(fetchLatestUserRoomById(userRoomId));
  });
};

export const replaceGameFactoryResources = (
  resourceId: number,
  data: Omit<AddGameFactoryResourceInterface, 'resourceActual' | 'gameFactory'>,
  factoryId: number,
  userRoomId: number
): AppThunk => (dispatch) => {
  GameFactoryResourceService.replaceGameFactoryResource(resourceId, data).then(() => {
    dispatch(fetchGameFactoryResources(factoryId));
    dispatch(clearActiveResource());
    dispatch(fetchLatestUserRoomById(userRoomId));
  });
};

export const deleteGameFactoryResource = (
  gameFactoryResourceId: number,
  factoryId: number,
  userRoomId: number
): AppThunk => (dispatch) => {
  GameFactoryResourceService.deleteGameFactoryResource(gameFactoryResourceId).then(() => {
    dispatch(fetchGameFactoryResources(factoryId));
    dispatch(fetchLatestUserRoomById(userRoomId));
  });
};

export const reloadGameFactoryResource = (factoryId: number, userRoomId: number): AppThunk => (dispatch) => {
  dispatch(fetchGameFactoryResources(factoryId));
  dispatch(fetchLatestUserRoomById(userRoomId));
};
