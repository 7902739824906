import { AxiosPromise } from 'axios';

import { ApiService } from './api.service';
import { GetParamsInterface } from 'app/shared/interfaces/RequestInterface.interface';
import { GanttInterface, GanttTemplateInterface } from 'app/shared/interfaces/GanttTemplate.interface';

export const GanttTemplateService = {
  getGanttTemplate(params: GetParamsInterface = {}): AxiosPromise<GanttTemplateInterface> {
    return ApiService.get('gantt_templates', params);
  },

  getGanttTemplateById(id: number): AxiosPromise<GanttInterface> {
    return ApiService.get(`gantt_templates/${id}`);
  },

  addGanttTemplate(data: Omit<GanttInterface, 'id' | 'ganttTemplateItems'>): AxiosPromise {
    return ApiService.post('gantt_templates', data);
  },

  replaceGanttTemplate(id: number, data: Omit<GanttInterface, 'id' | 'ganttTemplateItems'>): AxiosPromise {
    return ApiService.put(`gantt_templates/${id}`, data);
  },

  deleteGanttTemplate(id: number): AxiosPromise {
    return ApiService.delete(`gantt_templates/${id}`);
  },
};
