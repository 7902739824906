import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import SettingsIcon from '@material-ui/icons/Settings';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';

import { useStyles } from './FactoriesTable.styles';

import { FactoriesTableProps } from './FactoriesTable.interfaces';
import { CustomDialog } from 'app/components/CustomDialog/CustomDialog';
import { FactoryService } from 'app/services/factory.service';
import { AddFactoryModal } from 'app/components/AddFactoryModal/AddFactoryModal';
import { FactoryInterface } from 'app/shared/interfaces/Factory.interface';
import { CustomTablePagination } from 'app/components/CustomTablePagination/CustomTablePagination';
import { getParams } from 'app/shared/helpers/pagination';
import { handlingCatch } from 'app/shared/helpers/handlingCatch';
import { SnackbarAlert } from 'app/components/SnackbarAlert/SnackbarAlert';
import { CustomIconButton } from '../CustomIconButton/CustomIconButton';

export function FactoriesTable(props: FactoriesTableProps) {
  const { factories, shouldUpdateFactories, totalRecords, addClick } = props;
  const classes = useStyles();
  const [selectedFactoryId, setSelectedFactoryId] = useState<number>(0);
  const [selectedPage, setSelectedPage] = useState<number>(0);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [openAddDialog, setOpenAddDialog] = useState<boolean>(false);
  const [factoryData, setFactoryData] = useState<FactoryInterface | null>(null);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [notificationText, setNotificationText] = useState<string>('');

  const handleSnackbarClose = () => {
    setShowNotification(false);
  };

  const handleOpenAddFactory = () => {
    setFactoryData(null);
    setOpenAddDialog(true);
  };

  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
    setFactoryData(null);
  };

  const handleOpenEditFactory = (id: number) => {
    const factory = getFactoryById(id);
    if (factory) {
      setFactoryData(factory);
      setOpenAddDialog(true);
    }
  };

  const handleFactoryAdded = () => {
    shouldUpdateFactories(getParams(selectedPage));
    setFactoryData(null);
    setOpenAddDialog(false);
  };

  const handleOpenDeleteFactory = (id: number) => {
    setSelectedFactoryId(id);
    setOpenDeleteDialog(true);
  };

  const handleDeleteFactory = () => {
    FactoryService.deleteFactory(selectedFactoryId)
      .then(() => {
        setOpenDeleteDialog(false);
        shouldUpdateFactories(getParams(selectedPage));
      })
      .catch((error) => {
        const response = handlingCatch(error.response);
        const { status } = error.response;
        if (status === 400) {
          setNotificationText(response.message);
          setShowNotification(true);
          setOpenDeleteDialog(false);
        }
      });
  };

  const handleCloseDeleteDialog = () => setOpenDeleteDialog(false);

  const getFactoryById = (id: number) => factories.find((factory) => factory.id === id);

  const getFactoryNameById = (id: number) => {
    const factory = getFactoryById(id);
    return factory ? factory.name : '';
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    shouldUpdateFactories(getParams(page));
    setSelectedPage(page);
  };

  useEffect(() => {
    if (addClick) {
      handleOpenAddFactory();
    }
  }, [addClick]);

  return (
    <div>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Nazwa</TableCell>
              <TableCell>Opis</TableCell>
              <TableCell align={'right'} className={classes.buttonsTableCell} />
            </TableRow>
          </TableHead>
          <TableBody>
            {factories.map((factory) => (
              <TableRow key={factory.id}>
                <TableCell className={clsx(classes.wrap, classes.bold)}>{factory.name}</TableCell>
                <TableCell className={classes.wrap}>{factory.description}</TableCell>
                <TableCell align={'right'} className={classes.buttonsTableCell}>
                  <Link to={`factory/${factory.id}`}>
                    <IconButton>
                      <SettingsIcon fontSize={'small'} />
                    </IconButton>
                  </Link>
                  <CustomIconButton onClick={() => handleOpenEditFactory(factory.id)} type={'edit'} />
                  <CustomIconButton onClick={() => handleOpenDeleteFactory(factory.id)} type={'delete'} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <CustomTablePagination
            tablePaginationConfig={{
              count: totalRecords,
              page: selectedPage,
            }}
            onChangePage={handleChangePage}
          />
        </Table>
      </TableContainer>
      <AddFactoryModal
        isOpen={openAddDialog}
        factoryData={factoryData}
        onClose={handleCloseAddDialog}
        factoryAdded={handleFactoryAdded}
      />
      <CustomDialog
        title={'Usuwanie fabryki'}
        onCloseDialog={handleCloseDeleteDialog}
        body={
          <>
            {'Na pewno chcesz usunąć fabrykę: '}
            {getFactoryNameById(selectedFactoryId)}
          </>
        }
        isOpen={openDeleteDialog}
        dialogActions={
          <>
            <Button color={'primary'} onClick={handleDeleteFactory}>
              Tak
            </Button>
            <Button color={'primary'} autoFocus onClick={handleCloseDeleteDialog}>
              Nie
            </Button>
          </>
        }
      />
      <Snackbar
        open={showNotification}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={handleSnackbarClose}
      >
        <SnackbarAlert message={notificationText} />
      </Snackbar>
    </div>
  );
}
