import React from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';

import { CustomDialog } from 'app/components/CustomDialog/CustomDialog';
import { TextFieldComponent } from 'app/components/TextFieldComponent/TextFieldComponent';
import { UserChangeInterface } from 'app/shared/interfaces/User.interface';
import { UserService } from 'app/services/user.service';

import { useStyles } from './ChangePasswordUserModal.styles';
import {
  ChangePasswordUserModalFormDataInterface,
  ChangePasswordUserModalProps,
} from './ChangePasswordUserModal.interfaces';

export function ChangePasswordUserModal(props: ChangePasswordUserModalProps) {
  const { isOpen, onClose, data, passwordChanged } = props;
  const classes = useStyles();

  const formData: ChangePasswordUserModalFormDataInterface = {
    password: '',
    passwordConfirmation: '',
  };

  const validationShema = Yup.object().shape({
    password: Yup.string().min(8, 'Hasło powinno zawierać minimalnie 8 znaków').required('Podaj hasło'),
    passwordConfirmation: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Hasła nie mogą różnić się od siebie')
      .required('Powtórz hasło'),
  });

  return (
    <CustomDialog
      title={'Edycja hasła'}
      isOpen={isOpen}
      onCloseDialog={onClose}
      body={
        <Formik
          initialValues={formData}
          validationSchema={validationShema}
          onSubmit={(values) => {
            const userPasswordData: Omit<UserChangeInterface, 'id' | 'roles'> = {
              password: values.password,
              passwordConfirmation: values.passwordConfirmation,
            };

            if (data && data.id) {
              UserService.replacePassword(data.id, userPasswordData).then(() => {
                passwordChanged();
              });
            }
          }}
        >
          {({ submitForm }) => (
            <Form>
              <Field component={TextFieldComponent} name={'password'} label={'Hasło'} fullWidth />
              <Field component={TextFieldComponent} name={'passwordConfirmation'} label={'Potwiedź hasło'} fullWidth />
              <div className={classes.actionButtons}>
                <Button color={'primary'} onClick={submitForm}>
                  {'Zapisz'}
                </Button>
                <Button color={'primary'} autoFocus onClick={onClose}>
                  Anuluj
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      }
    />
  );
}
