import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActionInterface } from 'app/shared/interfaces/Action.interface';
import { GanttItemInterface } from 'app/shared/interfaces/GanttTemplate.interface';
import { ActualActivityInterface } from 'app/shared/interfaces/ActualActivities.interface';
import { GameGanttOrderInterface } from 'app/shared/interfaces/GameGanttOrder.interface';

interface GanttInterface {
  id: number;
  name: string;
  ganttTemplateItems: Array<GanttItemInterface>;
  activeAction?: ActionInterface;
  activeResourceId1?: number;
  activeResourceId2?: number;
  activeResourceTransportId?: number;
  ganttOrders?: Array<GameGanttOrderInterface>;
  duration?: number;
  editGanttItemId?: number;
}

interface GanttStateInterface {
  selectedGantt: GanttInterface;
  activeRoundGanttOrders: Array<GameGanttOrderInterface>;
}

const initializeGantt: GanttInterface = {
  id: 0,
  name: '',
  ganttTemplateItems: [],
};

const initialState: GanttStateInterface = {
  selectedGantt: initializeGantt,
  activeRoundGanttOrders: [],
};

export const gantt = createSlice({
  name: 'gantt',
  initialState,
  reducers: {
    setGanttTemplate: (state, action: PayloadAction<GanttInterface>) => {
      state.selectedGantt = action.payload;
    },
    clearGanttTemplate: (state) => {
      return { ...initialState };
    },
    setActiveAction: (state, action: PayloadAction<ActionInterface | ActualActivityInterface>) => {
      state.selectedGantt.activeAction = action.payload;
    },
    clearActiveAction: (state) => {
      state.selectedGantt.activeAction = undefined;
      state.selectedGantt.activeResourceId1 = undefined;
      state.selectedGantt.activeResourceId2 = undefined;
      state.selectedGantt.activeResourceTransportId = undefined;
      state.selectedGantt.duration = undefined;
    },
    setActiveResourceId1: (state, action: PayloadAction<number>) => {
      state.selectedGantt.activeResourceId1 = action.payload;
    },
    clearActiveResourceId1: (state) => {
      state.selectedGantt.activeResourceId1 = undefined;
    },
    setActiveResourceTransportId: (state, action: PayloadAction<number>) => {
      state.selectedGantt.activeResourceTransportId = action.payload;
    },
    clearActiveResourceTransportId: (state) => {
      state.selectedGantt.activeResourceTransportId = undefined;
    },
    setActiveResourceId2: (state, action: PayloadAction<number>) => {
      state.selectedGantt.activeResourceId2 = action.payload;
    },
    clearActiveResourceId2: (state) => {
      state.selectedGantt.activeResourceId2 = undefined;
    },
    setGanttOrders: (state, action: PayloadAction<Array<GameGanttOrderInterface>>) => {
      state.selectedGantt.ganttOrders = action.payload;
    },
    setActiveRoundsGanttOrders: (state, action: PayloadAction<Array<GameGanttOrderInterface>>) => {
      state.activeRoundGanttOrders = action.payload;
    },
    setDuration: (state, action: PayloadAction<number>) => {
      state.selectedGantt.duration = action.payload;
    },
    clearDuration: (state) => {
      state.selectedGantt.duration = undefined;
    },
    setEditGanttItemId: (state, action: PayloadAction<number>) => {
      state.selectedGantt.editGanttItemId = action.payload;
    },
    clearEditGanttItemId: (state) => {
      state.selectedGantt.editGanttItemId = undefined;
    },
  },
});

export default gantt.reducer;
