import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Button from '@material-ui/core/Button';

import { getRoom } from 'app/store/userRoom';
import { RoomService } from 'app/services/room.service';
import { ProcessActivityActualInterface } from 'app/shared/interfaces/Process.interface';
import { GameProcessDetailsModal } from 'app/components/GameProcessDetailsModal/GameProcessDetailsModal';
import { ActualProcessInterface } from 'app/shared/interfaces/ActualProcess.interface';

import { useStyles } from './GameOwnProcesses.styles';
import { GameOwnProcessesInterface } from './GameOwnProcesses.interface';

export function GameOwnProcesses(props: GameOwnProcessesInterface) {
  const { gameProcesses } = props;
  const classes = useStyles();
  const room = useSelector(getRoom);

  const [selectedActualProcess, setSelectedActualProcess] = useState<ActualProcessInterface | null>(null);
  const [processDetailsModalOpen, setProcessDetailsModalOpen] = useState<boolean>(false);
  const [processesActivity, setProcessesActivity] = useState<Array<ProcessActivityActualInterface>>([]);

  const getActualProcessName = (actualProcess: ActualProcessInterface | null) =>
    actualProcess && actualProcess.name ? actualProcess.name : '';
  const getActualProcessPrice = (actualProcess: ActualProcessInterface | null) =>
    actualProcess && actualProcess.price ? actualProcess.price.toFixed(2) : '';
  const getActualProcessDescription = (actualProcess: ActualProcessInterface | null) =>
    actualProcess && actualProcess.description ? actualProcess.description : '';

  const handleOpenProcessDetailModal = (actualProcess: ActualProcessInterface | null) => {
    if (room) {
      RoomService.getRoomProcessesAndActivity(room.id).then(({ data: { 'hydra:member': responseData } }) => {
        setProcessesActivity(responseData);
        setSelectedActualProcess(actualProcess);
        setProcessDetailsModalOpen(true);
      });
    }
  };

  const handleCloseProcessDetailModal = () => {
    setSelectedActualProcess(null);
    setProcessDetailsModalOpen(false);
  };

  return (
    <div className={classes.root}>
      <div className={classes.tableContainer}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Nazwa</TableCell>
              <TableCell>Opis</TableCell>
              <TableCell align={'right'}>Cena [PLN]</TableCell>
              <TableCell>Akcja</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {gameProcesses.map((process, index) => (
              <TableRow key={process.id ? `/api/process/${process.id}` : index}>
                <TableCell>{getActualProcessName(process.actualProcess)}</TableCell>
                <TableCell>{getActualProcessDescription(process.actualProcess)}</TableCell>
                <TableCell align={'right'}>{getActualProcessPrice(process.actualProcess)}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="default"
                    onClick={() => handleOpenProcessDetailModal(process.actualProcess)}
                  >
                    Szczegóły
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <GameProcessDetailsModal
        processesActivity={processesActivity}
        actualProcess={selectedActualProcess}
        isOpen={processDetailsModalOpen}
        onClose={handleCloseProcessDetailModal}
      />
    </div>
  );
}
