import React from 'react';

export const componentDecorator = (
  href: string | undefined,
  text: React.ReactNode,
  key: string | number | undefined
) => (
  <a href={href} key={key} target="_blank" rel="noopener noreferrer">
    {text}
  </a>
);
