import { AxiosPromise } from 'axios';

import { ApiService } from './api.service';
import { UnloadMaterialInterface } from 'app/shared/interfaces/UnloadMaterial.interface';

export const UnloadMaterialService = {
  addUnloadMaterial(data: Array<UnloadMaterialInterface>, userRoomId: number): AxiosPromise {
    return ApiService.post(`${userRoomId}/unload_materials`, data);
  },
};
