import { AxiosPromise } from 'axios';

import { ApiService } from './api.service';
import { AddGameGanttOrderItemInterface } from 'app/shared/interfaces/GameGanttOrderItem.interface';
import { MoveAllGameGanttOrder } from 'app/shared/interfaces/GameGanttOrder.interface';

export const GameGanttOrderItemService = {
  addGameGanttOrderItem(data: AddGameGanttOrderItemInterface): AxiosPromise {
    return ApiService.post('game_gantt_order_items', data);
  },

  replaceGameGanttOrder(id: number, data: Omit<AddGameGanttOrderItemInterface, 'duration'>): AxiosPromise {
    return ApiService.put(`game_gantt_order_items/${id}`, data);
  },

  deleteGameGanttOrder(id: number): AxiosPromise {
    return ApiService.delete(`game_gantt_order_items/${id}`);
  },

  moveAllGameGanttOrders(id: number, data: MoveAllGameGanttOrder): AxiosPromise {
    return ApiService.put(`game_gantt_order_items/${id}`, data);
  },
};
