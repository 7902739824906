import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    borderRadius: 5,
    marginBottom: 20,
  },
  roundSelector: {
    margin: 5,
  },
  roundPhaseButton: {
    margin: 10,
  },
}));
