import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FactoryInterface, FactoryResourcesInterface } from 'app/shared/interfaces/Factory.interface';
import { ResourceInterface } from 'app/shared/interfaces/Resource.interface';
import { ActualSupplierInFactoryInterface } from 'app/shared/interfaces/Supplier.interface';
import { initialAreaProps } from 'app/components/FactoryArea/GridFunctions';

interface Factory {
  id: number;
  name: string;
  description?: string | null;
  factoryResources: Array<FactoryResourcesInterface>;
  activeFactoryResourceId?: number;
  activeResource?: ResourceInterface;
  actualSupplier?: ActualSupplierInFactoryInterface;
  resourceNumber?: number;
}

interface FactoryState {
  selectedFactory: Factory;
  activeRoundFactoryResources: Array<FactoryResourcesInterface>;
  gridOptions: { x: number; y: number; scale: number | null };
}

const initialFactory: Factory = {
  id: 0,
  name: '',
  description: '',
  factoryResources: [],
};

const initialState: FactoryState = {
  selectedFactory: initialFactory,
  activeRoundFactoryResources: [],
  gridOptions: { x: -Math.floor(initialAreaProps.cols / 2), y: Math.floor(initialAreaProps.rows / 2), scale: null },
};

export const factory = createSlice({
  name: 'factory',
  initialState,
  reducers: {
    clearFactory: (state) => {
      return { ...initialState };
    },
    setFactory: (state, action: PayloadAction<FactoryInterface>) => {
      state.selectedFactory = action.payload;
    },
    setFactoryResources: (state, action: PayloadAction<Array<FactoryResourcesInterface>>) => {
      state.selectedFactory.factoryResources = action.payload;
    },
    setActiveRoundFactoryResources: (state, action: PayloadAction<Array<FactoryResourcesInterface>>) => {
      state.activeRoundFactoryResources = action.payload;
    },
    setActiveFactoryResourceId: (state, action: PayloadAction<number>) => {
      state.selectedFactory.activeFactoryResourceId = action.payload;
    },
    clearActiveFactoryResourceId: (state) => {
      state.selectedFactory.activeFactoryResourceId = undefined;
    },
    setActiveResource: (state, action: PayloadAction<ResourceInterface>) => {
      state.selectedFactory.activeResource = action.payload;
    },
    clearActiveResource: (state) => {
      state.selectedFactory.activeResource = undefined;
    },
    setActualSupplier: (state, action: PayloadAction<any>) => {
      state.selectedFactory.actualSupplier = action.payload;
    },
    setGridOptions: (state, action: PayloadAction<any>) => {
      state.gridOptions = { ...state.gridOptions, ...action.payload };
    },
  },
});

export default factory.reducer;
