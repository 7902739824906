import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { routes } from 'app/shared/constants';
import { clearStorage } from 'app/shared/helpers/localStorage';
import { clearCurrentUser } from 'app/store/user';

function LogoutPage() {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      clearStorage();
      dispatch(clearCurrentUser());
    };
  }, [dispatch]);

  return <Redirect to={routes.LOGIN.route} />;
}

export default LogoutPage;
