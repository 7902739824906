import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  container: {
    minWidth: 300,
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20,
  },
});
