import React from 'react';
import { Formik, Form, Field } from 'formik';
import Button from '@material-ui/core/Button';

import { CustomDialog } from 'app/components/CustomDialog/CustomDialog';

import { useStyles } from './EditGameFactoryResourceModal.styles';
import { GameFactoryResourceService } from 'app/services/gameFactoryResource.service';
import { GameFactoryResourceInterface } from 'app/shared/interfaces/GameFactoryResource.interface';
import {
  EditGameFactoryResourceModalProps,
  AddFactoryResourceDataInterface,
} from './EditGameFactoryResourceModal.interfaces';
import { SelectComponent } from 'app/components/SelectComponent/SelectComponent';

export function EditGameFactoryResourceModal({
  isOpen,
  onClose,
  selectedGameFactoryResource,
  availableGameFactoryResources,
  onResourcesUpdated,
}: EditGameFactoryResourceModalProps) {
  const classes = useStyles();

  const formData: AddFactoryResourceDataInterface = {
    holdingFieldConnection:
      selectedGameFactoryResource && selectedGameFactoryResource.holdingFieldConnection
        ? selectedGameFactoryResource.holdingFieldConnection.id.toString()
        : '-1',
  };
  return (
    <CustomDialog
      title={'Edycja zasobu fabryki'}
      onCloseDialog={onClose}
      body={
        <Formik
          initialValues={formData}
          onSubmit={(values, actions) => {
            if (selectedGameFactoryResource) {
              const gameFactoryData: Omit<GameFactoryResourceInterface, 'id'> = {
                ...selectedGameFactoryResource,
                holdingFieldConnection:
                  availableGameFactoryResources.find(
                    (gameFactoryResource) => gameFactoryResource.id === parseInt(values.holdingFieldConnection)
                  ) || null,
              };
              GameFactoryResourceService.replaceGameFactoryResource(selectedGameFactoryResource.id, gameFactoryData)
                .then(() => {
                  onResourcesUpdated();
                })
                .catch((error) => {
                  const { status } = error.response;
                  if (status === 400) {
                    actions.setFieldError('holdingFieldConnection', 'Zasób jest zajęty');
                  } else {
                    actions.setFieldError('holdingFieldConnection', 'Błąd podczas zapisu');
                  }
                });
            }
          }}
        >
          {({ submitForm }) => (
            <Form>
              <Field
                component={SelectComponent}
                fullWidth
                label={'Zasób powiązany'}
                name={'holdingFieldConnection'}
                options={[
                  {
                    label: 'Niepowiązane',
                    value: -1,
                  },
                  ...availableGameFactoryResources.map((gameFactoryResource) => ({
                    label: `${gameFactoryResource.resourceActual.name} (${gameFactoryResource.resourceNumber})`,
                    value: gameFactoryResource.id,
                  })),
                ]}
              />
              <div className={classes.actionButtons}>
                <Button color={'primary'} onClick={submitForm}>
                  Zapisz
                </Button>
                <Button color={'primary'} autoFocus onClick={onClose}>
                  Anuluj
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      }
      isOpen={isOpen}
    />
  );
}
