import React from 'react';
import { useSelector } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Tooltip from '@material-ui/core/Tooltip';

import { createItemTooltip } from 'app/components/FactoryArea/GridFunctions';
import { getGameFactoryResource } from 'app/store/userRoom';
import { getFactoryResources } from 'app/store/factory';
import { FactoryResourcesInterface } from 'app/shared/interfaces/Factory.interface';

import { useStyles } from './GameOwnResources.styles';

export function GameOwnResources() {
  const classes = useStyles();
  const factoryResources = useSelector(getFactoryResources);
  const gameFactoryResources = useSelector(getGameFactoryResource);

  const getGameFactoryResourcePriceAfterBuy = (resource: FactoryResourcesInterface) => {
    if (gameFactoryResources) {
      const gameFactory = gameFactoryResources.find((item) => item.id === resource.id);
      return gameFactory ? gameFactory.priceAfterBuy.toFixed(2) : 0;
    }
    return 0;
  };
  return (
    <div className={classes.root}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHead}>Nazwa</TableCell>
            <TableCell className={classes.tableHead} align={'right'}>
              Cena [PLN]
            </TableCell>
            <TableCell className={classes.tableHead} align={'center'}>
              Wy.
            </TableCell>
            <TableCell className={classes.tableHead} align={'center'}>
              Sz.
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {factoryResources.map((resource) => (
            <Tooltip key={resource.id} title={createItemTooltip(resource, gameFactoryResources)} placement={'top'}>
              <TableRow key={resource.id}>
                <TableCell>{resource.resource.name}</TableCell>
                <TableCell align={'right'}>{getGameFactoryResourcePriceAfterBuy(resource)}</TableCell>
                <TableCell align={'center'}>{resource.resource.width}</TableCell>
                <TableCell align={'center'}>{resource.resource.length}</TableCell>
              </TableRow>
            </Tooltip>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
