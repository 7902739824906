import { store } from 'app/store/store';
import { getGameFactory, getIsActiveRound, getIsGamePending, getUserRoom } from 'app/store/userRoom';
import { getIsUserGameMaster } from 'app/store/user';

export const canEdit = (): boolean => {
  const state = store.getState();
  const isBot = !getUserRoom(state).selectedRoundState.user;
  return (
    isBot ||
    (((getGameFactory(state) && getIsGamePending(state)) || !getGameFactory(state)) &&
      !getIsUserGameMaster(state) &&
      getIsActiveRound(state))
  );
};
