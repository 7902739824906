import socketIOClient from 'socket.io-client';
import { JoinEvent, NewRoundEvent } from 'app/shared/interfaces/WebSocketEvent.interface';

const WEB_SOCKET_URL = process.env.REACT_APP_WEB_SOCKET_URL;

export const handleRoundFinishedNotificationEvent = (roomId: number, onEvent: (event: NewRoundEvent) => void) => {
  if (WEB_SOCKET_URL) {
    const socketClient = socketIOClient(WEB_SOCKET_URL);
    const joinEvent: JoinEvent = {
      room: roomId,
    };
    socketClient.emit('join', joinEvent);
    socketClient.on('roundFinishedNotification', (event: NewRoundEvent) => onEvent(event));
  }
};
