import React from 'react';

import { GanttOrderBackgroundProps } from './GanttOrderBackground.interface';
import { useStyles } from './GanttOrderBackground.styles';

export function GanttOrderBackground(props: GanttOrderBackgroundProps) {
  const classes = useStyles();

  return <div className={classes.root}></div>;
}
