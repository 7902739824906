import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';

import { CustomDialog } from 'app/components/CustomDialog/CustomDialog';
import { getGameProcesses, getGameGantt, getGameMaterials } from 'app/store/userRoom';
import { SelectComponent } from 'app/components/SelectComponent/SelectComponent';
import { AddGanttOrderFormDataInterface, AddGanttOrderModalProps } from './AddGanttOrderModal.interface';
import { GameGanttOrderService } from 'app/services/gameGanttOrder';
import { TextFieldComponent } from 'app/components/TextFieldComponent/TextFieldComponent';
import { fetchGanttOrders } from 'app/store/gantt';
import { useStyles } from './AddGanttOrderModal.styles';
import { getMaterialNotInQueue } from 'app/shared/helpers/filters';
import { transportOptions } from 'app/shared/constants';
import { convertToInt } from 'app/shared/helpers/numberConverter';

export function AddGanttOrderModal({ isOpen, onClose }: AddGanttOrderModalProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const gameProcesses = useSelector(getGameProcesses);
  const gameMaterials = useSelector(getGameMaterials);
  const gameGantt = useSelector(getGameGantt);
  const [addGanttOrderButtonDisabled, setAddGanttOrderButtonDisabled] = useState<boolean>(false);

  const initialValues: AddGanttOrderFormDataInterface = {
    process: '',
    amount: '',
    gantt: '',
    gameMaterial: '',
    saveToNextRound: 'false',
  };

  const validationShema = Yup.object().shape({
    process: Yup.string().required('Wybierz proces'),
    amount: Yup.number().typeError('Wartość musi być liczbą').min(1, 'Musi być więcej niż 0').required('Podaj ilość'),
    gantt: Yup.string().required('Wybierz czy chcesz dodać templatkę gantta'),
    gameMaterial: Yup.string().required('Wybierz materiał'),
  });

  const getGanttTemplateActualId = (processId: number) => {
    const process = gameProcesses.find(
      (gameProcess) => gameProcess.actualProcess && gameProcess.actualProcess.id === processId
    );

    return process && process.actualProcess && process.actualProcess.ganttTemplateActual
      ? process.actualProcess.ganttTemplateActual.id
      : undefined;
  };

  const gameGanttOrder = (
    processId: number,
    amount: number,
    addGantt: boolean,
    gameMaterial: string,
    saveToNextRound: string
  ) => {
    const ganttTemplateActualId = getGanttTemplateActualId(processId);
    return {
      gameGantt: { '@id': gameGantt ? gameGantt['@id'] : '' },
      actualProcess: { '@id': `/api/actual_processes/${processId}` },
      ganttTemplateActual:
        ganttTemplateActualId && addGantt ? { '@id': `/api/actual_gantts/${ganttTemplateActualId}` } : undefined,
      amount: amount,
      gameMaterial: { '@id': `/api/game_materials/${gameMaterial}` },
      saveToNextRound: saveToNextRound.toString() === 'true',
    };
  };

  return (
    <CustomDialog
      title={'Dodaj zlecenie'}
      onCloseDialog={onClose}
      body={
        <Formik
          validationSchema={validationShema}
          initialValues={initialValues}
          onSubmit={(values) => {
            setAddGanttOrderButtonDisabled(true);
            GameGanttOrderService.addGameGanttOrder(
              gameGanttOrder(
                parseInt(values.process),
                convertToInt(values.amount),
                values.gantt === 'yes',
                values.gameMaterial,
                values.saveToNextRound
              )
            )
              .then(() => {
                if (gameGantt) {
                  dispatch(fetchGanttOrders(gameGantt.id));
                  onClose();
                }
              })
              .finally(() => setAddGanttOrderButtonDisabled(false));
          }}
        >
          {({ submitForm }) => (
            <Form>
              <Field
                component={SelectComponent}
                fullWidth
                label={'Proces'}
                name={'process'}
                options={gameProcesses.map((process) => ({
                  label: process.actualProcess ? process.actualProcess.name : '',
                  value: process.actualProcess ? process.actualProcess.id : -1,
                }))}
              />
              <Field
                component={SelectComponent}
                fullWidth
                label={'Dodać templatkę gantta?'}
                name={'gantt'}
                options={[
                  {
                    label: 'Tak',
                    value: 'yes',
                  },
                  {
                    label: 'Nie',
                    value: 'no',
                  },
                ]}
              />
              <Field component={TextFieldComponent} fullWidth label={'Ilość'} name={'amount'} />
              <Field
                component={SelectComponent}
                fullWidth
                label={'Materiał'}
                name={'gameMaterial'}
                options={getMaterialNotInQueue(gameMaterials)
                  .filter((value) => value.amount > 0)
                  .map((gameMaterial) => ({
                    label: gameMaterial.actualMaterial
                      ? `${gameMaterial.actualMaterial.name} (${gameMaterial.orderNumber}) (${gameMaterial.processStep} / ${gameMaterial.processActivityCount})`
                      : '',
                    value: gameMaterial.id,
                  }))}
              />
              <Field
                component={SelectComponent}
                fullWidth
                label={'Zapisać zlecenie?'}
                name={'saveToNextRound'}
                options={transportOptions.map((transportOption) => ({
                  label: transportOption.label,
                  value: transportOption.value,
                }))}
              />
              <div className={classes.actionButtons}>
                <Button color={'primary'} onClick={submitForm} disabled={addGanttOrderButtonDisabled}>
                  Dodaj
                </Button>
                <Button color={'primary'} autoFocus onClick={onClose}>
                  Anuluj
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      }
      isOpen={isOpen}
    />
  );
}
