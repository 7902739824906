import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';

import { getLatestUserRoom } from 'app/store/userRoom';
import { getIsUserGameMaster } from 'app/store/user';
import { GameHeaderRoundInterface } from './GameHeaderRound.interface';
import { UserRoomMarkInterface } from 'app/shared/interfaces/UserRoom.interface';
import { userRoomGameStatus } from 'app/shared/constants';
import { UserRoomService } from 'app/services/userRoom.service';
import { useStyles } from './GameHeaderRound.styles';
import { CustomDialog } from 'app/components/CustomDialog/CustomDialog';

export function GameHeaderRound({ isAutomation, room, updateRoom }: GameHeaderRoundInterface) {
  const classes = useStyles();
  const latestUserRoom = useSelector(getLatestUserRoom);
  const [enableEndRound, setEnableEndRound] = useState<boolean>(false);
  const [disabledButton, setDisabledButton] = useState<boolean>(false);
  const [openEndRoundDialog, setOpenEndRoundDialog] = useState<boolean>(false);
  const isUserGameMaster = useSelector(getIsUserGameMaster);

  const handleCloseEndRoundDialog = () => {
    setOpenEndRoundDialog(false);
  };

  const handleOpenEndRoundDialog = () => {
    setOpenEndRoundDialog(true);
  };

  const handleEndRound = () => {
    setOpenEndRoundDialog(false);
    let userRoomStatus: Omit<UserRoomMarkInterface, 'mark' | 'markDescription'>;
    if (latestUserRoom.room?.scenarioTemplate?.automation === 1) {
      userRoomStatus = {
        status: userRoomGameStatus.GAME_WAITING_FOR_NEXT_ROUND.status,
      };
    } else {
      userRoomStatus = {
        status: userRoomGameStatus.GAME_WAITING_FOR_MARK.status,
      };
    }
    UserRoomService.replaceUserRoomsRound(latestUserRoom.id, userRoomStatus).then(() => {
      updateRoom();
    });
    setDisabledButton(true);
  };

  const getButtonDesc = () => {
    if (latestUserRoom) {
      switch (latestUserRoom.status) {
        case userRoomGameStatus.GAME_MARK_AND_END.status:
          return userRoomGameStatus.GAME_MARK_AND_END.label.game;
        case userRoomGameStatus.GAME_PENDING.status:
          return userRoomGameStatus.GAME_PENDING.label.game;
        case userRoomGameStatus.GAME_WAITING_FOR_MARK.status:
          return userRoomGameStatus.GAME_WAITING_FOR_MARK.label.game;
        case userRoomGameStatus.GAME_WAITING_FOR_NEXT_ROUND.status:
          return userRoomGameStatus.GAME_WAITING_FOR_NEXT_ROUND.label.game;
      }
    }
    return '';
  };

  useEffect(() => {
    if (latestUserRoom) {
      setEnableEndRound(latestUserRoom.status === userRoomGameStatus.GAME_PENDING.status);
    }
  }, [latestUserRoom]);

  useEffect(() => {
    if (!enableEndRound || isUserGameMaster) {
      setDisabledButton(true);
    } else {
      setDisabledButton(false);
    }
  }, [enableEndRound, isUserGameMaster]);

  return (
    <div className={classes.root}>
      {isAutomation && (
        <div className={classes.round}>
          TURA
          <span className={classes.currentRound}>
            {room?.currentRound} / {room?.rounds}
          </span>
        </div>
      )}
      <Button className={classes.button} size={'small'} disabled={disabledButton} onClick={handleOpenEndRoundDialog}>
        {getButtonDesc()}
      </Button>
      <CustomDialog
        title="Zakończenie tury"
        body="Czy na pewno chcesz zakończyć turę?"
        isOpen={openEndRoundDialog}
        onCloseDialog={handleCloseEndRoundDialog}
        dialogActions={
          <>
            <Button color={'primary'} onClick={handleEndRound}>
              Tak
            </Button>
            <Button color={'primary'} autoFocus onClick={handleCloseEndRoundDialog}>
              Nie
            </Button>
          </>
        }
      />
    </div>
  );
}
