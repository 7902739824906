import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';

import { TextFieldComponent } from 'app/components/TextFieldComponent/TextFieldComponent';
import { CustomDialog } from 'app/components/CustomDialog/CustomDialog';
import { upToThreeDecimalPlaces } from 'app/shared/helpers/validators';

import { useStyles } from './AddOrderModal.styles';
import { AddOrderFormDataInterface, AddOrderModalProps } from './AddOrderModal.interfaces';
import { AddOrderInterface } from 'app/shared/interfaces/Order.interface';
import { OrderService } from 'app/services/order.service';
import { SelectComponent } from 'app/components/SelectComponent/SelectComponent';
import { handlingCatch } from 'app/shared/helpers/handlingCatch';
import { convertToFloat } from 'app/shared/helpers/numberConverter';

export function AddOrderModal({ isOpen, orderData, onClose, orderAdded, products }: AddOrderModalProps) {
  const classes = useStyles();
  const initialValues: AddOrderFormDataInterface = {
    name: orderData ? orderData.name : '',
    description: orderData && orderData.description ? orderData.description : '',
    quality: orderData ? orderData.quality.toString() : '',
    amount: orderData ? orderData.amount.toString() : '',
    product: orderData && orderData.product ? orderData.product.id.toString() : '',
  };
  const validationShema = Yup.object().shape({
    name: Yup.string().required('Podaj nazwę'),
    quality: Yup.number()
      .test('is-incorrect', 'Podaj jakość w zakresie od 0 do 1 max. trzy miejsca po przecinku', upToThreeDecimalPlaces)
      .min(0, 'Podaj jakość w zakresie od 0 do 1 max. trzy miejsca po przecinku')
      .max(1, 'Podaj jakość w zakresie od 0 do 1 max. trzy miejsca po przecinku')
      .typeError('Podaj jakość w zakresie od 0 do 1 max. trzy miejsca po przecinku')
      .required('Podaj jakość w zakresie od 0 do 1 max. trzy miejsca po przecinku'),
    amount: Yup.number()
      .min(0, 'Podaj ilość, wartość powinna być liczbą całkowitą')
      .typeError('Podaj ilość, wartość powinna być liczbą całkowitą')
      .integer('Podaj ilość, wartość powinna być liczbą całkowitą')
      .required('Podaj ilość, wartość powinna być liczbą całkowitą'),
    product: Yup.string().required('Wybierz produkt'),
  });

  return (
    <CustomDialog
      title={orderData ? 'Edytowanie zamówienia' : 'Dodawanie zamówienia'}
      onCloseDialog={onClose}
      body={
        <Formik
          initialValues={initialValues}
          validationSchema={validationShema}
          onSubmit={(values, actions) => {
            const data: AddOrderInterface = {
              name: values.name.trim(),
              description: values.description,
              quality: convertToFloat(values.quality),
              amount: parseInt(values.amount),
              product: products.find((product) => product.id === parseInt(values.product)),
            };

            if (orderData) {
              OrderService.replaceOrder(orderData.id, data)
                .then(() => {
                  orderAdded();
                })
                .catch((error) => {
                  const response = handlingCatch(error.response);
                  actions.setFieldError(response.fieldError, response.message);
                });
            } else {
              OrderService.addOrder(data)
                .then(() => {
                  orderAdded();
                })
                .catch((error) => {
                  const response = handlingCatch(error.response);
                  actions.setFieldError(response.fieldError, response.message);
                });
            }
          }}
        >
          {({ submitForm }) => (
            <Form>
              <Field component={TextFieldComponent} name={'name'} label={'Nazwa'} fullWidth />
              <Field
                component={TextFieldComponent}
                name={'description'}
                label={'Opis'}
                multiline
                rowsMax={4}
                fullWidth
              />
              <Field component={TextFieldComponent} name={'quality'} label={'Wymagana jakość'} fullWidth />
              <Field component={TextFieldComponent} name={'amount'} label={'Ilość'} fullWidth />
              <Field
                component={SelectComponent}
                fullWidth
                label={'Produkty'}
                name={'product'}
                options={products.map((product) => ({
                  label: product.name,
                  value: product.id,
                }))}
              />
              <div className={classes.actionButtons}>
                <Button color={'primary'} onClick={submitForm}>
                  {orderData ? 'Zapisz' : 'Dodaj'}
                </Button>
                <Button color={'primary'} autoFocus onClick={onClose}>
                  Anuluj
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      }
      isOpen={isOpen}
    />
  );
}
