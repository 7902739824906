export const removeWhiteSpaces = (value: string): string => {
  return value ? value.replace(/\s+/g, '').trim() : '';
};

/** Removes all whitespaces and returns int */
export const convertToInt = (value: string): number => {
  return parseInt(removeWhiteSpaces(value), 10);
};

/** Removes whitespaces and returns float number. Accepts both dot- and comma-separated values */
export const convertToFloat = (value: string): number => {
  return parseFloat(removeWhiteSpaces(value).replace(',', '.'));
};

export const convertMinutesToHours = (mins: number) => {
  let hour = Math.floor(mins / 60);
  let minutes = mins % 60;
  return `${hour < 10 ? '0' + hour : hour}:${minutes < 10 ? '0' + minutes : minutes}`;
};
