import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';

import { GanttTemplateService } from 'app/services/ganttTemplate.service';
import { GanttInterface } from 'app/shared/interfaces/GanttTemplate.interface';
import { GetParamsInterface } from 'app/shared/interfaces/RequestInterface.interface';
import { defaultParams } from 'app/shared/helpers/pagination';
import { HeaderButton } from 'app/components/HeaderButton/HeaderButton';
import { GanttTemplatesTable } from 'app/components/GanttTemplatesTable/GanttTemplatesTable';

import { useStyles } from './GanttsPage.styles';

function GanttsPage() {
  const classes = useStyles();
  const [ganttTemplates, setGanttTemplates] = useState<Array<GanttInterface>>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [addButtonClicked, setAddButtonClicked] = useState<boolean>(false);

  const getGanttTemplates = (params: GetParamsInterface = defaultParams) => {
    GanttTemplateService.getGanttTemplate(params).then(
      ({ data: { 'hydra:member': responseData, 'hydra:totalItems': totalRecords } }) => {
        setGanttTemplates(responseData);
        setTotalRecords(totalRecords);
      }
    );
  };

  const handleAddButton = () => setAddButtonClicked(true);

  useEffect(() => {
    getGanttTemplates();
  }, []);

  useEffect(() => {
    if (addButtonClicked) {
      setAddButtonClicked(false);
    }
  }, [addButtonClicked]);

  return (
    <div className={classes.root}>
      <Typography variant={'h1'}>
        <span>Templatki gantta</span>
        <div>
          <HeaderButton onClick={handleAddButton} label={'Dodaj templatkę gantta'} />
        </div>
      </Typography>
      <GanttTemplatesTable
        ganttTemplates={ganttTemplates}
        totalRecords={totalRecords}
        shouldUpdateGanttTemplates={getGanttTemplates}
        addClick={addButtonClicked}
      />
    </div>
  );
}

export default GanttsPage;
