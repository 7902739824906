import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import NumberFormat from 'react-number-format';

import { MaterialService } from 'app/services/material.service';
import { MaterialInterface } from 'app/shared/interfaces/Material.interface';
import { AddMaterialModal } from 'app/components/AddMaterialModal/AddMaterialModal';
import { CustomDialog } from 'app/components/CustomDialog/CustomDialog';
import { MaterialsTableProps } from './MaterialsTable.interfaces';
import { CustomIconButton } from 'app/components/CustomIconButton/CustomIconButton';
import { CustomTablePagination } from 'app/components/CustomTablePagination/CustomTablePagination';
import { getParams } from 'app/shared/helpers/pagination';
import { SnackbarAlert } from 'app/components/SnackbarAlert/SnackbarAlert';
import { handlingCatch } from 'app/shared/helpers/handlingCatch';

import { useStyles } from './MaterialsTable.styles';

export function MaterialsTable(props: MaterialsTableProps) {
  const classes = useStyles();
  const { materials, materialTypes, shouldUpdateMaterials, totalRecords, addClick } = props;

  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [openAddDialog, setOpenAddDialog] = useState<boolean>(false);
  const [selectedMaterial, setSelectedMaterial] = useState<number>(0);
  const [selectedPage, setSelectedPage] = useState<number>(0);
  const [materialData, setMaterialData] = useState<MaterialInterface | null>(null);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [notificationText, setNotificationText] = useState<string>('');

  const handleSnackbarClose = () => {
    setShowNotification(false);
  };

  const handleAddMaterial = () => {
    setMaterialData(null);
    setOpenAddDialog(true);
  };

  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
    setMaterialData(null);
  };

  const handleMaterialAdded = () => {
    shouldUpdateMaterials(getParams(selectedPage));
    setOpenAddDialog(false);
    setMaterialData(null);
  };

  const handleOpenEditMaterial = (id: number) => {
    const material = getMaterialById(id);
    if (material) {
      setMaterialData(material);
      setOpenAddDialog(true);
    }
  };

  const handleOpenDeleteMaterial = (id: number) => {
    setSelectedMaterial(id);
    setOpenDeleteDialog(true);
  };

  const handleDeleteMaterial = () => {
    MaterialService.deleteMaterial(selectedMaterial)
      .then(() => {
        setOpenDeleteDialog(false);
        shouldUpdateMaterials(getParams(selectedPage));
      })
      .catch((error) => {
        const response = handlingCatch(error.response);
        const { status } = error.response;
        if (status === 400) {
          setNotificationText(response.message);
          setShowNotification(true);
          setOpenDeleteDialog(false);
        }
      });
  };

  const handleCloseDeleteDialog = () => setOpenDeleteDialog(false);

  const getMaterialById = (id: number) => materials.find((material) => material.id === id);

  const getMaterialName = (id: number) => {
    const material = getMaterialById(id);

    return material ? material.name : '';
  };

  const getMaterialTypeName = (material: MaterialInterface) => {
    return material.materialType ? material.materialType.name : '';
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    shouldUpdateMaterials(getParams(page));
    setSelectedPage(page);
  };

  useEffect(() => {
    if (addClick) {
      handleAddMaterial();
    }
  }, [addClick]);

  return (
    <div>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Nazwa</TableCell>
              <TableCell>Opis</TableCell>
              <TableCell>Typ materiału</TableCell>
              <TableCell align={'center'}>Jakość</TableCell>
              <TableCell align={'center'}>Wielkość [jw]</TableCell>
              <TableCell align={'right'}>Cena [PLN]</TableCell>
              <TableCell align={'center'}>Czas zamawiania [tura]</TableCell>
              <TableCell className={classes.buttonsTableCell} align={'right'} />
            </TableRow>
          </TableHead>
          <TableBody>
            {materials.map((material) => (
              <TableRow key={material.id}>
                <TableCell className={clsx(classes.wrap, classes.bold)}>{material.name}</TableCell>
                <TableCell className={classes.wrap}>{material.description}</TableCell>
                <TableCell>{getMaterialTypeName(material)}</TableCell>
                <TableCell align={'center'}>{material.quality}</TableCell>
                <TableCell align={'center'}>{material.amount}</TableCell>
                <TableCell align={'right'}>
                  <NumberFormat
                    displayType={'text'}
                    value={material.price}
                    fixedDecimalScale
                    decimalScale={2}
                    thousandSeparator={' '}
                  />
                </TableCell>
                <TableCell align={'center'}>{material.timeOrder}</TableCell>
                <TableCell align={'right'} className={classes.buttonsTableCell}>
                  <CustomIconButton onClick={() => handleOpenEditMaterial(material.id)} type={'edit'} />
                  <CustomIconButton onClick={() => handleOpenDeleteMaterial(material.id)} type={'delete'} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <CustomTablePagination
            tablePaginationConfig={{
              count: totalRecords,
              page: selectedPage,
            }}
            onChangePage={handleChangePage}
          />
        </Table>
      </TableContainer>
      <AddMaterialModal
        isOpen={openAddDialog}
        material={materialData}
        materialTypes={materialTypes}
        onClose={handleCloseAddDialog}
        materialAdded={handleMaterialAdded}
      />
      <CustomDialog
        title={'Usuwanie materiału'}
        onCloseDialog={handleCloseDeleteDialog}
        body={
          <>
            {'Na pewno chcesz usunąć materiał: '}
            {getMaterialName(selectedMaterial)}
          </>
        }
        isOpen={openDeleteDialog}
        dialogActions={
          <>
            <Button color={'primary'} onClick={handleDeleteMaterial}>
              Tak
            </Button>
            <Button color={'primary'} autoFocus onClick={handleCloseDeleteDialog}>
              Nie
            </Button>
          </>
        }
      />
      <Snackbar
        open={showNotification}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={handleSnackbarClose}
      >
        <SnackbarAlert message={notificationText} />
      </Snackbar>
    </div>
  );
}
