import { UserRoomMarkInterface } from 'app/shared/interfaces/UserRoom.interface';
import { userRoomGameStatus } from 'app/shared/constants';
import { UserRoomService } from './userRoom.service';
import { UserRoomState } from 'app/store/userRoom/reducer';

export const UserGameRoundService = {
  endUserRound(userRoom: UserRoomState, onEndUserRoom: () => void): void {
    if (userRoom.status === userRoomGameStatus.GAME_PENDING.status) {
      let userRoomStatus: Omit<UserRoomMarkInterface, 'mark' | 'markDescription'>;
      const status =
        userRoom?.room?.scenarioTemplate?.automation === 1
          ? userRoomGameStatus.GAME_WAITING_FOR_NEXT_ROUND.status
          : userRoomGameStatus.GAME_WAITING_FOR_MARK.status;
      userRoomStatus = {
        status: status,
      };
      UserRoomService.replaceUserRoomsRound(userRoom.id, userRoomStatus).then(() => {
        onEndUserRoom();
      });
    }
  },
};
