import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 20,
    color: '#767577',
    fontWeight: 'bold',
    padding: '0 20px',
  },
  cash: {
    color: '#000',
    marginLeft: 10,
  },
}));
