import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UserInterface as CurrentUserInterface } from 'app/shared/interfaces/User.interface';

interface UserInterface {
  currentUser: CurrentUserInterface | null;
}

const initialState: UserInterface = {
  currentUser: null,
};

export const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearCurrentUser: (state) => {
      return {
        ...state,
        currentUser: null,
      };
    },
    setCurrentUser: (state, action: PayloadAction<CurrentUserInterface>) => {
      return {
        ...state,
        currentUser: action.payload,
      };
    },
  },
});

export default user.reducer;
