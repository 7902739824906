import { RootState } from 'app/store/store';

export const getGantt = (state: RootState) => state.gantt.selectedGantt;

export const getActiveAction = (state: RootState) => getGantt(state).activeAction;

export const getGantTemplateItems = (state: RootState) => getGantt(state).ganttTemplateItems;

export const getGanttTemplateId = (state: RootState) => getGantt(state).id;

export const getActiveResourceId1 = (state: RootState) => getGantt(state).activeResourceId1;

export const getActiveResourceId2 = (state: RootState) => getGantt(state).activeResourceId2;

export const getActiveResourceTransportId = (state: RootState) => getGantt(state).activeResourceTransportId;

export const getGanttOrders = (state: RootState) => getGantt(state).ganttOrders;

export const getActiveRoundGanttOrders = (state: RootState) => state.gantt.activeRoundGanttOrders;

export const getDuration = (state: RootState) => getGantt(state).duration;

export const getEditGanttItemId = (state: RootState) => getGantt(state).editGanttItemId;
