import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';

import { clearActiveAction, getActiveAction, setActiveAction } from 'app/store/gantt';
import { canEdit } from 'app/shared/helpers/canEdit';
import { GameGanttAllActivitiesProps } from './GameGanttAllActivities.interface';
import { ActualActivityTypeInterface } from 'app/shared/interfaces/ActualActivityType.interface';
import { useStyles } from './GameGanttAllActivities.styles';

export function GameGanttAllActivities(props: GameGanttAllActivitiesProps) {
  const { actualActivities, afterActivitySelected, accordion } = props;
  const classes = useStyles();
  const activeAction = useSelector(getActiveAction);
  const dispatch = useDispatch();

  const getActionById = (id: number) => actualActivities.find((act) => act.id === id);

  const handleRowClick = (e: React.MouseEvent<HTMLDivElement>, id: number) => {
    if (!canEdit() || accordion) {
      return;
    }
    const action = getActionById(id);

    if (action && activeAction && action.id === activeAction.id) {
      dispatch(clearActiveAction());
    } else if (action) {
      dispatch(setActiveAction(action));
    }
    if (afterActivitySelected) {
      afterActivitySelected();
    }
  };

  const showActionTypes = (activityTypesActual: Array<ActualActivityTypeInterface> | undefined) =>
    activityTypesActual
      ? activityTypesActual.map((activityTypeActual) => (
          <div key={activityTypeActual.id}>{activityTypeActual.description}</div>
        ))
      : '';

  return (
    <div className={clsx(classes.root, { [classes.accordion]: accordion })}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Typy czynności</TableCell>
            <TableCell>Opis</TableCell>
            {!accordion && <TableCell>Nazwa</TableCell>}
            <TableCell>TJ</TableCell>
            <TableCell>WSP TPZ</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {actualActivities.map((action) => (
            <TableRow
              key={action.id}
              className={clsx(classes.tableRow, {
                [classes.activeRow]: activeAction && activeAction.id === action.id && !accordion,
              })}
              onClick={(e) => handleRowClick(e, action.id)}
            >
              <TableCell className={classes.tableCell}>{showActionTypes(action.activityTypesActual)}</TableCell>
              <TableCell className={classes.tableCell}>{action.description || ''}</TableCell>
              {!accordion && <TableCell className={classes.tableCell}>{action.name}</TableCell>}
              <TableCell className={classes.tableCell}>{action.timeWorking}</TableCell>
              <TableCell className={classes.tableCell}>{action.WTP}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
