import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { clearCurrentUser, getCurrentUser, setCurrentUser } from 'app/store/user';
import AuthService from 'app/services/auth.service';
import { clearStorage } from 'app/shared/helpers/localStorage';

import { IsAuthorizedProps } from './IsAuthorized.interfaces';

export function IsAuthorized(props: IsAuthorizedProps) {
  const history = useHistory();
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser);

  const token = AuthService.getToken();

  if (!token) {
    history.push('/login');
  } else if (!currentUser) {
    AuthService.getCurrentUser()
      .then(({ data }) => {
        dispatch(setCurrentUser(data));
      })
      .catch((error) => {
        clearStorage();
        dispatch(clearCurrentUser());
        history.push('/login');
      });
  }

  return token ? props.children : <></>;
}
