import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';

import { CustomDialog } from 'app/components/CustomDialog/CustomDialog';
import { AddSuppliersToScenarioModalProps } from './AddSuppliersToScenarioModal.interface';
import { useStyles } from './AddSuppliersToScenarioModal.styles';

export function AddSuppliersToScenarioModal({
  isOpen,
  onClose,
  suppliers,
  addSuppliers,
  activeSuppliers,
}: AddSuppliersToScenarioModalProps) {
  const classes = useStyles();
  const [selectedSupplierId, setSelectedSupplierId] = useState<number>(-1);
  const [activeSelectedSupplierId, setActiveSelectedSupplierId] = useState<number>(-1);
  const [selectedSuppliers, setSelectedSuppliers] = useState<Array<number>>([]);

  const handleSelectSupplier = (actionId: number) =>
    setSelectedSupplierId(selectedSupplierId === actionId ? -1 : actionId);

  const reorder = (list: Array<number>, startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result: DropResult) => {
    if (result.destination) {
      const newOrder = reorder(selectedSuppliers, result.source.index, result.destination.index);
      setSelectedSuppliers([...newOrder]);
    }
  };

  const getSupplierById = (id: number) => suppliers.find((supplier) => supplier.id === id);

  const handleAddSupplier = (id: number) => {
    setSelectedSuppliers([...selectedSuppliers, selectedSupplierId]);
    setSelectedSupplierId(-1);
  };

  const handleRemoveSupplier = (id: number) => {
    const newSelectedSuppliers = selectedSuppliers.filter((supplier) => supplier !== id);

    setSelectedSuppliers([...newSelectedSuppliers]);
    setActiveSelectedSupplierId(-1);
  };

  const handleSelectActiveSupplier = (id: number) =>
    setActiveSelectedSupplierId(activeSelectedSupplierId === id ? -1 : id);

  const getSupplierNameById = (id: number) => {
    const supplier = getSupplierById(id);
    return supplier ? supplier.name : '';
  };

  const handleAdd = () => {
    addSuppliers(selectedSuppliers);
  };

  useEffect(() => {
    setSelectedSuppliers(activeSuppliers);
  }, [activeSuppliers]);

  return (
    <CustomDialog
      title={'Dodaj dostawców'}
      onCloseDialog={onClose}
      body={
        <div>
          <div className={classes.body}>
            <div>
              <div className={classes.title}>Dostawcy</div>
              <div className={classes.listContainer}>
                {suppliers
                  .filter((supplier) => !selectedSuppliers.includes(supplier.id))
                  .map((supplier) => (
                    <div
                      key={supplier.id}
                      className={clsx(classes.item, { [classes.itemActive]: selectedSupplierId === supplier.id })}
                      onClick={() => handleSelectSupplier(supplier.id)}
                    >
                      {supplier.name}
                    </div>
                  ))}
              </div>
            </div>
            <div className={classes.buttonContainer}>
              <Button
                color={'primary'}
                variant={'contained'}
                size={'small'}
                className={classes.addButton}
                disabled={selectedSupplierId === -1}
                onClick={() => handleAddSupplier(selectedSupplierId)}
              >
                {'> >'}
              </Button>
              <Button
                color={'primary'}
                variant={'contained'}
                size={'small'}
                disabled={activeSelectedSupplierId === -1}
                onClick={() => handleRemoveSupplier(activeSelectedSupplierId)}
              >
                {'< <'}
              </Button>
            </div>
            <div>
              <div className={classes.title}>Wybrani dostawcy</div>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId={'droppable'}>
                  {(providedd, snapshot) => (
                    <div {...providedd.droppableProps} ref={providedd.innerRef} className={classes.listContainer}>
                      {selectedSuppliers.map((supplier, index) => (
                        <Draggable key={supplier} draggableId={`id${supplier}`} index={index}>
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={provided.draggableProps.style}
                              className={clsx(classes.item, {
                                [classes.itemDragging]: snapshot.isDragging,
                                [classes.itemActive]: activeSelectedSupplierId === supplier,
                              })}
                              onClick={() => handleSelectActiveSupplier(supplier)}
                            >
                              {getSupplierNameById(supplier)}
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {providedd.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </div>
          <div className={classes.actionButtons}>
            <Button color={'primary'} onClick={handleAdd}>
              {process ? 'Zapisz' : 'Dodaj'}
            </Button>
            <Button color={'primary'} autoFocus onClick={onClose}>
              Anuluj
            </Button>
          </div>
        </div>
      }
      isOpen={isOpen}
    />
  );
}
