export const ganttProcessingErrors = [
  {
    status: 'TO_LOW_AMOUNT_OF_MATERIALS',
    description: 'Za mało materiałów.',
  },
  {
    status: 'NOT_ENOUGH_TIME_TO_PROCESS_ACTIVITY',
    description: 'Przydzielono za mało czasu na wykonanie czynności',
  },
  {
    status: 'WRONG_ACTIVITY_TYPE',
    description: 'Nieprawidłowa czynność na danej pozycji',
  },
  {
    status: 'NO_RESOURCE_SET_FOR_ACTIVITY',
    description: 'Zasób nie został przypisany do czynności',
  },
  {
    status: 'PROCESS_SHOULD_START_IN_WAREHOUSE',
    description: 'Proces powinien zaczynać się od pobrania towaru z magazynu',
  },
  {
    status: 'MATERIAL_WAS_NOT_TRANSPORTED_TO_THE_MACHINE_STORAGE_FIELD',
    description: 'Materiał nie został przetransportowany do pola odkładczego maszyny',
  },
  {
    status: 'NO_FREE_CAPACITY_IN_RESOURCE',
    description: 'Brak miejsca w docelowym zasobie',
  },
  {
    status: 'WRONG_MATERIAL_TYPE',
    description: 'Nieprawidłowy typ materiału dla zlecenia',
  },
  {
    status: 'WRONG_SOURCE_OR_TARGET_RESOURCES_FOR_TRANSPORT',
    description: 'Transport materiału możliwy jest jedynie pomiędzy magazynami lub polami odkładczymi maszyny',
  },
  {
    status: 'NO_STORAGE_FIELD_ASSIGNED',
    description: 'Do maszyny nie przypisano pola odkładczego',
  },
  {
    status: 'CHANGEOVER_IS_NOT_DONE',
    description: 'Maszyna nie jest przezbrojona',
  },
  {
    status: 'QUALITY_CONTROL_SHOULD_BE_THE_LAST_ACTIVITY',
    description: 'Kontrola jakości powinna znajdować się na końcu procesu',
  },
  {
    status: 'TROLLEY_IS_NOT_ASSIGNED_TO_ACTIVITY',
    description: 'Nie przypisano wózka do transportu',
  },
  {
    status: 'NO_FREE_CAPACITY_IN_TROLLEY',
    description: 'Za mała pojemność wózka',
  },
  {
    status: 'NO_FREE_TROLLEYS_LEFT',
    description: 'Brak wolnych wózków',
  },
  {
    status: 'NOT_ENOUGH_MATERIAL_IN_SOURCE_RESOURCE',
    description: 'Brak lub za mało odpowiedniego materiału do przewiezienia w zasobie źródłowym',
  },
  {
    status: 'MACHINE_IS_IN_USE',
    description: 'Maszyna jest w uzyciu',
  },
  {
    status: 'WRONG_LOCATION_OF_STORAGE_FIELD',
    description: 'Pole odkładcze nie znajduje się bezpośrednio przy maszynie',
  },
  {
    status: 'NO_ROUTE_CONNECTION',
    description: 'Brak drogi pomiędzy zasobami',
  },
  {
    status: 'NO_WAREHOUSE_ACTIVITY',
    description: 'Brak czynności magazynowania',
  },
  {
    status: 'WRONG_MATERIAL_PROCESS_STEP',
    description: 'Niepoprawny stan procesu materiału',
  },
  {
    status: 'OK',
    description: 'OK',
  },
];

export const getGanttProcessingErrorDescription = (errorStatus: string) => {
  const error = ganttProcessingErrors.find((errorObject) => errorObject.status === errorStatus);
  return error ? error.description : '';
};
