import { AxiosPromise } from 'axios';

import { ApiService } from './api.service';
import { FactoryInterface, FactoryResponse } from 'app/shared/interfaces/Factory.interface';
import { GetParamsInterface } from 'app/shared/interfaces/RequestInterface.interface';

export const FactoryService = {
  getFactories(params: GetParamsInterface = {}): AxiosPromise<FactoryResponse> {
    return ApiService.get('factories', params);
  },

  getFactoryById(id: number): AxiosPromise<FactoryInterface> {
    return ApiService.get(`factories/${id}`);
  },

  addFactory(data: Omit<FactoryInterface, 'id' | 'factoryResources'>): AxiosPromise {
    return ApiService.post('factories', data);
  },

  replaceFactory(id: number, data: Omit<FactoryInterface, 'id' | 'factoryResources'>): AxiosPromise {
    return ApiService.put(`factories/${id}`, data);
  },

  deleteFactory(id: number): AxiosPromise {
    return ApiService.delete(`factories/${id}`);
  },
};
