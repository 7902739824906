import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';

import { SupplierService } from 'app/services/supplier.service';
import { SupplierInterface } from 'app/shared/interfaces/Supplier.interface';
import { getParams } from 'app/shared/helpers/pagination';
import { CustomIconButton } from 'app/components/CustomIconButton/CustomIconButton';
import { AddSupplierModal } from 'app/components/AddSupplierModal/AddSupplierModal';
import { CustomDialog } from 'app/components/CustomDialog/CustomDialog';
import { CustomTablePagination } from 'app/components/CustomTablePagination/CustomTablePagination';
import { handlingCatch } from 'app/shared/helpers/handlingCatch';
import { SnackbarAlert } from 'app/components/SnackbarAlert/SnackbarAlert';

import { useStyles } from './SuppliersTable.styles';
import { SuppliersTableProps } from './SuppliersTable.interfaces';

export function SuppliersTable(props: SuppliersTableProps) {
  const { suppliers, materials, shouldUpdateSuppliers, totalRecords, addClick } = props;
  const classes = useStyles();
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [openAddDialog, setOpenAddDialog] = useState<boolean>(false);
  const [selectedSupplierId, setSelectedSupplierId] = useState<number>(0);
  const [selectedPage, setSelectedPage] = useState<number>(0);
  const [supplierData, setSupplierData] = useState<SupplierInterface | null>(null);

  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [notificationText, setNotificationText] = useState<string>('');

  const handleSnackbarClose = () => {
    setShowNotification(false);
  };

  const handleAddSupplier = () => {
    setSupplierData(null);
    setOpenAddDialog(true);
  };

  const handleSupplierAdded = () => {
    shouldUpdateSuppliers(getParams(selectedPage));
    setOpenAddDialog(false);
    setSupplierData(null);
  };

  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
    setSupplierData(null);
  };

  const handleOpenEditSupplier = (id: number) => {
    const supplier = getSupplierById(id);
    if (supplier) {
      setSupplierData(supplier);
      setOpenAddDialog(true);
    }
  };

  const handleOpenDeleteSupplier = (id: number) => {
    setSelectedSupplierId(id);
    setOpenDeleteDialog(true);
  };

  const handleDeleteSupplier = () => {
    SupplierService.deleteSupplier(selectedSupplierId)
      .then(() => {
        setOpenDeleteDialog(false);
        shouldUpdateSuppliers(getParams(selectedPage));
      })
      .catch((error) => {
        const response = handlingCatch(error.response);
        const { status } = error.response;
        if (status === 400) {
          setNotificationText(response.message);
          setShowNotification(true);
          setOpenDeleteDialog(false);
        }
      });
  };

  const handleCloseDeleteDialog = () => setOpenDeleteDialog(false);

  const getSupplierById = (id: number) => suppliers.find((supplier) => supplier.id === id);

  const getSupplierName = (id: number) => {
    const supplier = getSupplierById(id);

    return supplier ? supplier.name : '';
  };

  const showMaterials = (supplier: SupplierInterface) =>
    supplier.materials.map((material) => {
      return (
        <p key={material.id} className={classes.p}>
          Nazwa: {material.name} Typ: {material.materialType?.name} Jakość: {material.quality} Cena:{' '}
          {material.price.toFixed(2)} PLN
        </p>
      );
    });

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    shouldUpdateSuppliers(getParams(page));
    setSelectedPage(page);
  };

  useEffect(() => {
    if (addClick) {
      handleAddSupplier();
    }
  }, [addClick]);

  return (
    <div>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Nazwa</TableCell>
              <TableCell>Opis</TableCell>
              <TableCell>Materiały</TableCell>
              <TableCell align={'right'} className={classes.buttonsTableCell} />
            </TableRow>
          </TableHead>
          <TableBody>
            {suppliers.map((supplier) => (
              <TableRow key={supplier.id}>
                <TableCell className={clsx(classes.wrap, classes.bold)}>{supplier.name}</TableCell>
                <TableCell className={classes.wrap}>{supplier.description}</TableCell>
                <TableCell className={classes.wrap}>{showMaterials(supplier)}</TableCell>
                <TableCell align={'right'} className={classes.buttonsTableCell}>
                  <CustomIconButton onClick={() => handleOpenEditSupplier(supplier.id)} type={'edit'} />
                  <CustomIconButton onClick={() => handleOpenDeleteSupplier(supplier.id)} type={'delete'} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <CustomTablePagination
            tablePaginationConfig={{
              count: totalRecords,
              page: selectedPage,
            }}
            onChangePage={handleChangePage}
          />
        </Table>
      </TableContainer>
      <CustomDialog
        title={'Usuwanie dostawcy'}
        onCloseDialog={handleCloseDeleteDialog}
        body={
          <>
            {'Na pewno chcesz usunąć dostawcę: '}
            {getSupplierName(selectedSupplierId)}
          </>
        }
        isOpen={openDeleteDialog}
        dialogActions={
          <>
            <Button color={'primary'} onClick={handleDeleteSupplier}>
              Tak
            </Button>
            <Button color={'primary'} autoFocus onClick={handleCloseDeleteDialog}>
              Nie
            </Button>
          </>
        }
      />
      <AddSupplierModal
        isOpen={openAddDialog}
        onClose={handleCloseAddDialog}
        supplier={supplierData}
        materials={materials}
        supplierAdded={handleSupplierAdded}
      />
      <Snackbar
        open={showNotification}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={handleSnackbarClose}
      >
        <SnackbarAlert message={notificationText} />
      </Snackbar>
    </div>
  );
}
