export const setStorage = (name: string, value: string) => {
  window.localStorage.setItem(name, value);
};

export const getStorage = (name: string) => {
  return window.localStorage.getItem(name);
};

export const removeStorage = (name: string) => {
  return window.localStorage.removeItem(name);
};

export const clearStorage = () => {
  return window.localStorage.clear();
};
