import { AxiosPromise } from 'axios';

import { ApiService } from './api.service';
import { ResourceInterface, ResourceResponse } from 'app/shared/interfaces/Resource.interface';
import { GetParamsInterface } from 'app/shared/interfaces/RequestInterface.interface';

export const ResourceService = {
  getResources(params: GetParamsInterface = {}): AxiosPromise<ResourceResponse> {
    return ApiService.get('resources', params);
  },
  addResource(data: Omit<ResourceInterface, 'id'>): AxiosPromise {
    return ApiService.post('resources', data);
  },
  replaceResource(id: number, data: Omit<ResourceInterface, 'id'>): AxiosPromise {
    return ApiService.put(`resources/${id}`, data);
  },
  deleteResource(id: number): AxiosPromise {
    return ApiService.delete(`resources/${id}`);
  },
};
