import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';

import { MaterialService } from 'app/services/material.service';
import { MaterialTypeService } from 'app/services/materialType.service';
import { MaterialInterface } from 'app/shared/interfaces/Material.interface';
import { MaterialTypeInterface } from 'app/shared/interfaces/MaterialType.interface';
import { MaterialsTable } from 'app/components/MaterialsTable/MaterialsTable';
import { GetParamsInterface } from 'app/shared/interfaces/RequestInterface.interface';
import { defaultParams } from 'app/shared/helpers/pagination';
import { HeaderButton } from 'app/components/HeaderButton/HeaderButton';

import { useStyles } from './MaterialsPage.styles';

function MaterialsPage() {
  const classes = useStyles();
  const [materials, setMaterials] = useState<Array<MaterialInterface>>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [addButtonClicked, setAddButtonClicked] = useState<boolean>(false);

  const [materialTypes, setMaterialTypes] = useState<Array<MaterialTypeInterface>>([]);

  const getMaterials = (params: GetParamsInterface = defaultParams) => {
    MaterialService.getMaterials(params).then(
      ({ data: { 'hydra:member': responseData, 'hydra:totalItems': totalRecords } }) => {
        setMaterials(responseData);
        setTotalRecords(totalRecords);
      }
    );
  };

  const getMaterialTypes = () => {
    MaterialTypeService.getMaterialTypes().then(({ data: { 'hydra:member': responseData } }) => {
      setMaterialTypes(responseData);
    });
  };

  const handleAddButton = () => setAddButtonClicked(true);

  useEffect(() => {
    getMaterials();
    getMaterialTypes();
  }, []);

  useEffect(() => {
    if (addButtonClicked) {
      setAddButtonClicked(false);
    }
  }, [addButtonClicked]);

  return (
    <div className={classes.root}>
      <Typography variant={'h1'}>
        <span>Materiały</span>
        <div>
          <HeaderButton onClick={handleAddButton} label={'Dodaj materiał'} />
        </div>
      </Typography>
      <MaterialsTable
        materials={materials}
        materialTypes={materialTypes}
        shouldUpdateMaterials={getMaterials}
        totalRecords={totalRecords}
        addClick={addButtonClicked}
      />
    </div>
  );
}

export default MaterialsPage;
