import React, { useCallback, useEffect, useState } from 'react';
import { InputLabel } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useStyles } from './GameRounds.styles';
import {
  getCurrentRoundNumber,
  getIsGameActive,
  getLatestUserRoom,
  getSelectedUserRoom,
  setSelectedUserRoomWithState,
} from 'app/store/userRoom';
import { getGameRounds, getRoundPhaseStatus, setRoundPhaseStatus } from 'app/store/gameRounds';
import { UserRoomInterface } from 'app/shared/interfaces/UserRoom.interface';
import { gameRoundPhaseStatus } from 'app/shared/constants';
import { getActiveRoundFactoryResources, setFactoryResources } from 'app/store/factory';
import { GameFactoryResourceInterface } from 'app/shared/interfaces/GameFactoryResource.interface';
import { getActiveRoundGanttOrders, setGanttOrders, setActiveRoundsGanttOrders } from 'app/store/gantt';
import { getIsUserGameMaster } from 'app/store/user';
import { roundPhaseStatues } from 'app/store/gameRounds/reducer';

export function GameRounds() {
  const dispatch = useDispatch();
  const classes = useStyles();

  const gameRounds = useSelector(getGameRounds);
  const selectedRoundPhaseStatus = useSelector(getRoundPhaseStatus);
  const latestUserRoom = useSelector(getLatestUserRoom);
  const selectedUserRoom = useSelector(getSelectedUserRoom);
  const isGameActive = useSelector(getIsGameActive);
  const currentRoundNumber = useSelector(getCurrentRoundNumber);
  const isUserGameMaster = useSelector(getIsUserGameMaster);
  const activeRoundFactoryResources = useSelector(getActiveRoundFactoryResources);
  const activeRoundGanttOrder = useSelector(getActiveRoundGanttOrders);
  const [startPhaseOfCurrentRound, setStartPhaseOfCurrentRound] = useState<UserRoomInterface | null>(null);
  const [endPhaseOfCurrentRound, setEndPhaseOfCurrentRound] = useState<UserRoomInterface | null>(null);
  const [initialized, setInitialized] = useState<boolean>(false);
  const [selectedRoundNumber, setSelectedRoundNumber] = useState<number>(-1);

  const handleGoToBeginningOfTheCurrentRound = () => {
    selectStartPhaseOfCurrentRound(startPhaseOfCurrentRound);
  };

  const handleGoToEndOfTheCurrentRound = () => {
    selectEndPhaseOfCurrentRound(endPhaseOfCurrentRound);
  };

  const handleGoToActiveRound = () => {
    selectActiveRound();
  };

  const selectActiveRound = useCallback(() => {
    setSelectedRoundNumber(currentRoundNumber);
    dispatch(setSelectedUserRoomWithState(latestUserRoom, true));
    dispatch(setFactoryResources(activeRoundFactoryResources));
    dispatch(setActiveRoundsGanttOrders(activeRoundGanttOrder));
    dispatch(setRoundPhaseStatus(roundPhaseStatues.ACTIVE));
  }, [dispatch, latestUserRoom, currentRoundNumber, activeRoundFactoryResources, activeRoundGanttOrder]);

  const findRound = useCallback(
    (roundNumber: number, status: number) => {
      return gameRounds.find((gameRound) => gameRound.roundNumber === roundNumber && gameRound.status === status);
    },
    [gameRounds]
  );

  const getFactoryResources = (gameFactoryResource: Array<GameFactoryResourceInterface> | undefined) => {
    if (!gameFactoryResource) {
      return [];
    }
    return gameFactoryResource.map((res) => ({
      '@id': res['@id'],
      id: res.id,
      x: res.x,
      y: res.y,
      resource: res.resourceActual,
      resourceNumber: res.resourceNumber,
    }));
  };

  const loadUserRoomParts = useCallback(
    (userRoom: UserRoomInterface) => {
      const gameResources = getFactoryResources(userRoom.gameFactory.gameFactoryResources);
      dispatch(setFactoryResources(gameResources));
      const gameGanttOrders = userRoom.gameGanttOrders ? userRoom.gameGanttOrders : [];
      dispatch(setGanttOrders(gameGanttOrders));
    },
    [dispatch]
  );

  const selectStartPhaseOfCurrentRound = useCallback(
    (userRoom: UserRoomInterface | null) => {
      if (userRoom) {
        dispatch(setSelectedUserRoomWithState(userRoom, false));
        dispatch(setRoundPhaseStatus(roundPhaseStatues.START));
        loadUserRoomParts(userRoom);
      }
    },
    [dispatch, loadUserRoomParts]
  );

  const selectEndPhaseOfCurrentRound = useCallback(
    (endPhaseOfCurrentRound: UserRoomInterface | null) => {
      if (endPhaseOfCurrentRound) {
        dispatch(setSelectedUserRoomWithState(endPhaseOfCurrentRound, false));
        dispatch(setRoundPhaseStatus(roundPhaseStatues.END));
        loadUserRoomParts(endPhaseOfCurrentRound);
      }
    },
    [dispatch, loadUserRoomParts]
  );

  const handleChangeRound = useCallback(
    (selectedRound: number) => {
      setSelectedRoundNumber(selectedRound);
      const startPhaseUserRoom = findRound(selectedRound, gameRoundPhaseStatus.START_PHASE)?.roundState || null;
      const endPhaseGameRoom = findRound(selectedRound, gameRoundPhaseStatus.END_PHASE);
      const endPhaseUserRoom = endPhaseGameRoom ? endPhaseGameRoom.roundState : null;
      setStartPhaseOfCurrentRound(startPhaseUserRoom);
      setEndPhaseOfCurrentRound(endPhaseUserRoom);
      const loadActiveRoundState =
        !isUserGameMaster &&
        isGameActive &&
        currentRoundNumber === selectedRound &&
        (selectedRoundPhaseStatus === roundPhaseStatues.ACTIVE ||
          selectedRoundPhaseStatus === roundPhaseStatues.NOT_LOADED ||
          initialized);

      const totalRoomRounds = latestUserRoom?.room?.rounds || 0;
      if (loadActiveRoundState) {
        selectActiveRound();
      } else if (
        endPhaseUserRoom &&
        (selectedRoundPhaseStatus === roundPhaseStatues.END ||
          (selectedRoundPhaseStatus === roundPhaseStatues.NOT_LOADED && selectedRound === totalRoomRounds))
      ) {
        selectEndPhaseOfCurrentRound(endPhaseUserRoom);
      } else if (selectedRoundPhaseStatus === roundPhaseStatues.END && !initialized) {
        selectEndPhaseOfCurrentRound(startPhaseUserRoom);
      } else {
        selectStartPhaseOfCurrentRound(startPhaseUserRoom);
      }
    },
    [
      latestUserRoom,
      initialized,
      selectedRoundPhaseStatus,
      selectEndPhaseOfCurrentRound,
      isUserGameMaster,
      isGameActive,
      findRound,
      selectActiveRound,
      currentRoundNumber,
      selectStartPhaseOfCurrentRound,
    ]
  );

  const handleInitializeLastRound = useCallback(() => {
    if (!initialized) {
      const selectedRoundNumber = selectedUserRoom?.room?.currentRound || 0;
      handleChangeRound(selectedRoundNumber);
      setInitialized(true);
    }
  }, [selectedUserRoom, initialized, handleChangeRound]);

  useEffect(() => {
    handleInitializeLastRound();
  }, [handleInitializeLastRound]);

  return (
    <div className={classes.root}>
      <div>
        <div className={classes.roundSelector}>
          <InputLabel>Wybierz turę</InputLabel>
          {selectedRoundPhaseStatus !== roundPhaseStatues.NOT_LOADED && selectedRoundNumber > -1 && (
            <Select
              value={selectedRoundNumber}
              defaultValue={currentRoundNumber}
              onChange={(e) => {
                const roundNumber = e.target.value as number;
                handleChangeRound(roundNumber);
              }}
            >
              {Array.from({ length: currentRoundNumber + 1 }, (value, key) => (
                <MenuItem key={key} value={key}>
                  {key}
                </MenuItem>
              ))}
            </Select>
          )}
        </div>

        <Button
          className={classes.roundPhaseButton}
          variant="contained"
          color={selectedRoundPhaseStatus === roundPhaseStatues.START ? 'primary' : 'default'}
          onClick={handleGoToBeginningOfTheCurrentRound}
        >
          Początek tury
        </Button>
        <Button
          className={classes.roundPhaseButton}
          variant="contained"
          disabled={isGameActive && currentRoundNumber === selectedRoundNumber}
          color={selectedRoundPhaseStatus === roundPhaseStatues.END ? 'primary' : 'default'}
          onClick={handleGoToEndOfTheCurrentRound}
        >
          Koniec tury
        </Button>
        {!isUserGameMaster && isGameActive && (
          <Button
            className={classes.roundPhaseButton}
            variant="contained"
            color={selectedRoundPhaseStatus === roundPhaseStatues.ACTIVE ? 'primary' : 'default'}
            onClick={handleGoToActiveRound}
          >
            Aktywna tura
          </Button>
        )}
      </div>
    </div>
  );
}
