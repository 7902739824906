import React from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Checkbox from '@material-ui/core/Checkbox';
import { useSelector } from 'react-redux';

import { ActualOrderAddInterface, ActualOrderInterface } from 'app/shared/interfaces/ActualOrder.interface';
import { getGameOrders, getIsAutomation } from 'app/store/userRoom';
import { ActualOrderService } from 'app/services/actualOrder.service';
import { gameOwnOrdersStatus } from 'app/shared/constants';
import { GameOwnOrdersPropsInterface } from './GameOwnOrdersPropsInterface';

import { useStyles } from './GameOwnOrders.styles';
import { GameOrderInterface } from '../../shared/interfaces/GameOrder.interface';

export function GameOwnOrders(props: GameOwnOrdersPropsInterface) {
  const { myAuctions, updateRoom } = props;
  const classes = useStyles();
  const gameOrders = useSelector(getGameOrders);
  const isAutomation = useSelector(getIsAutomation);

  const getGameOrderName = (actualOrder: ActualOrderInterface | null) =>
    actualOrder && actualOrder.name ? actualOrder.name : '';
  const getGameOrderQuality = (actualOrder: ActualOrderInterface | null) =>
    actualOrder && actualOrder.quality ? actualOrder.quality : '';
  const getGameOrderAmount = (actualOrder: ActualOrderInterface | null) =>
    actualOrder && actualOrder.amount ? actualOrder.amount : '';
  const getGameOrderTimeExecution = (actualOrder: ActualOrderInterface | null) =>
    actualOrder && actualOrder.timeExecution !== null ? actualOrder.timeExecution : '';
  const getGameOrderTimeDelay = (actualOrder: ActualOrderInterface | null) =>
    actualOrder && actualOrder.timeDelay !== null ? actualOrder.timeDelay : '';

  const getAuctionConnectedWithOrder = (actualOrderId: number) =>
    myAuctions.find((auctionItem) => auctionItem.auction.actualOrder.id === actualOrderId);

  const getAuctionOffer = (actualOrder: ActualOrderInterface | null) => {
    if (actualOrder) {
      const auction = getAuctionConnectedWithOrder(actualOrder.id);
      return auction ? `${auction.offer.toFixed(2)} PLN` : '';
    }
  };

  const getOrderStatus = (actualOrder: ActualOrderInterface | null) =>
    actualOrder && actualOrder.status ? actualOrder.status : -1;

  const getOrderStatusChecked = (actualOrder: ActualOrderInterface | null) => {
    const orderStats = getOrderStatus(actualOrder);
    return [gameOwnOrdersStatus.toDo, gameOwnOrdersStatus.toDoDelay].includes(orderStats);
  };

  const getOrderStatusDisabled = (actualOrder: ActualOrderInterface | null) => {
    const orderStats = getOrderStatus(actualOrder);
    return ![
      gameOwnOrdersStatus.toDo,
      gameOwnOrdersStatus.toDoDelay,
      gameOwnOrdersStatus.inProgress,
      gameOwnOrdersStatus.inProgressDelay,
    ].includes(orderStats);
  };

  const getOrderStatusName = (actualOrder: ActualOrderInterface | null) => {
    const orderStats = getOrderStatus(actualOrder);
    if ([gameOwnOrdersStatus.inProgress, gameOwnOrdersStatus.inProgressDelay].includes(orderStats)) {
      return 'Zrealizuj';
    } else if ([gameOwnOrdersStatus.toDo, gameOwnOrdersStatus.toDoDelay].includes(orderStats)) {
      return 'Realizacja';
    } else if (orderStats === gameOwnOrdersStatus.done) {
      return 'Zrealizowano';
    } else {
      return 'Nie zrealizowano';
    }
  };

  const getNewOrderStatus = (status: number) => {
    switch (status) {
      case gameOwnOrdersStatus.toDo:
        return gameOwnOrdersStatus.inProgress;
      case gameOwnOrdersStatus.toDoDelay:
        return gameOwnOrdersStatus.inProgressDelay;
      case gameOwnOrdersStatus.inProgressDelay:
        return gameOwnOrdersStatus.toDoDelay;
      case gameOwnOrdersStatus.inProgress:
        return gameOwnOrdersStatus.toDo;
      default:
        return gameOwnOrdersStatus.notDone;
    }
  };

  const handleUpdateOrder = (actualOrder: ActualOrderInterface | null) => {
    if (actualOrder === null || actualOrder.status === gameOwnOrdersStatus.notDone) {
      return;
    }
    const newOrderStatus = getNewOrderStatus(actualOrder.status);
    if (newOrderStatus === gameOwnOrdersStatus.notDone) {
      return;
    }

    const actualOrderBody: ActualOrderAddInterface = {
      status: newOrderStatus,
    };

    ActualOrderService.replaceAction(actualOrder.id, actualOrderBody).then(() => {
      updateRoom();
    });
  };

  const showOrder = (gameOrder: GameOrderInterface) => {
    const orderStats = getOrderStatus(gameOrder.productOrderActual);
    return ![gameOwnOrdersStatus.done, gameOwnOrdersStatus.notDone].includes(orderStats);
  };
  return (
    <div className={classes.root}>
      <div className={classes.tableContainer}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Produkt</TableCell>
              <TableCell align={'center'}>Jakość</TableCell>
              {isAutomation && <TableCell align={'center'}>Czas wykonania / opóźnienia</TableCell>}
              <TableCell align={'center'}>Ilość</TableCell>
              {isAutomation && <TableCell>Akcja</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {gameOrders
              .filter((gameOrder) => showOrder(gameOrder))
              .map((gameOrder) => (
                <TableRow key={`api/gameOrders/${gameOrder['@id']}`}>
                  <TableCell>
                    <p>{`Nazwa: ${getGameOrderName(gameOrder.productOrderActual)}`}</p>
                    <p>{`Cena: ${getAuctionOffer(gameOrder.productOrderActual)}`}</p>
                  </TableCell>
                  <TableCell align={'center'}>{getGameOrderQuality(gameOrder.productOrderActual)}</TableCell>
                  {isAutomation && (
                    <TableCell align={'center'}>
                      {getGameOrderTimeExecution(gameOrder.productOrderActual)} /{' '}
                      {getGameOrderTimeDelay(gameOrder.productOrderActual)}
                    </TableCell>
                  )}
                  <TableCell align={'center'}>{getGameOrderAmount(gameOrder.productOrderActual)}</TableCell>
                  {isAutomation && (
                    <TableCell>
                      <Checkbox
                        disabled={getOrderStatusDisabled(gameOrder.productOrderActual)}
                        checked={getOrderStatusChecked(gameOrder.productOrderActual)}
                        onChange={() => handleUpdateOrder(gameOrder.productOrderActual)}
                      />
                      {getOrderStatusName(gameOrder.productOrderActual)}
                    </TableCell>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}
