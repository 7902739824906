import { AxiosPromise } from 'axios';

import { ApiService } from './api.service';

export const GameService = {
  nextRound(id: number): AxiosPromise {
    return ApiService.get(`game_engine/${id}/next_round`);
  },

  recalculateGantt(id: number): AxiosPromise {
    return ApiService.get(`game_gantt/${id}/recalculation`);
  },
};
