import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import IconButton from '@material-ui/core/IconButton';

import DeleteIcon from '@material-ui/icons/Delete';
import { FactoryOwnResourcesProps } from './FactoryOwnResources.interface';
import { useStyles } from './FactoryOwnResources.styles';
import { RemoveResourceFromFactoryPopper } from 'app/components/RemoveResourceFromFactoryPopper/RemoveResourceFromFactoryPopper';
import { getFactoryResources, getFactoryName } from 'app/store/factory';
import { setActiveFactoryResourceId, clearActiveFactoryResourceId } from 'app/store/factory';

export function FactoryOwnRecources({ resources, shouldRefreshOwnResources }: FactoryOwnResourcesProps) {
  const classes = useStyles();
  const factoryResources = useSelector(getFactoryResources);
  const factoryName = useSelector(getFactoryName);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [openRemovePopper, setOpenRemovePopper] = useState<boolean>(false);
  const [selectedFactoryResourceId, setSelectedFactoryResourceId] = useState<number>(-1);

  const handleRefreshOwnResources = () => {
    shouldRefreshOwnResources();
    setOpenRemovePopper(false);
  };

  const handleDeleteResource = (e: React.MouseEvent<HTMLButtonElement>, id: number) => {
    setSelectedFactoryResourceId(id);
    setAnchorEl(e.currentTarget);
    setOpenRemovePopper(true);
  };

  const handleCloseRemovePopper = () => {
    setOpenRemovePopper(false);
  };

  const setActiveRecource = (id: number) => dispatch(setActiveFactoryResourceId(id));

  const setInactiveRecource = () => dispatch(clearActiveFactoryResourceId());

  return (
    <div className={classes.root}>
      <h2>{`Zasoby fabryki ${factoryName}`}</h2>
      <div className={classes.tableContainer}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Nazwa</TableCell>
              <TableCell>Cena [PLN]</TableCell>
              <TableCell>X</TableCell>
              <TableCell>Y</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody onMouseLeave={setInactiveRecource}>
            {factoryResources.map((factoryResource) => {
              const resource = resources.find((res) => res.id === factoryResource.resource.id);
              return (
                <TableRow key={factoryResource.id} onMouseEnter={() => setActiveRecource(factoryResource.id)}>
                  <TableCell className={classes.tableCell}>{resource ? resource.name : ''}</TableCell>
                  <TableCell className={classes.tableCell}>{resource ? resource.price.toFixed(2) : ''}</TableCell>
                  <TableCell className={classes.tableCell}>{factoryResource.x}</TableCell>
                  <TableCell className={classes.tableCell}>{factoryResource.y}</TableCell>
                  <TableCell className={classes.tableCell} align={'right'}>
                    <IconButton size={'small'} onClick={(e) => handleDeleteResource(e, factoryResource.id)}>
                      <DeleteIcon fontSize={'small'} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
      <RemoveResourceFromFactoryPopper
        onClose={handleCloseRemovePopper}
        anchorEl={anchorEl}
        factoryResourceId={selectedFactoryResourceId}
        isOpen={openRemovePopper}
        shouldRefreshOnwResources={handleRefreshOwnResources}
      />
    </div>
  );
}
