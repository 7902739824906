import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GanttMouseActionAreaProps } from './GanttMouseActionArea.interface';
import {
  addGanttItemToOrder,
  addGanttTemplateItem,
  clearActiveAction,
  getActiveAction,
  getActiveResourceId1,
  getActiveResourceId2,
  getActiveResourceTransportId,
  getDuration,
  getEditGanttItemId,
  getGanttOrders,
  getGanttTemplateId,
  replaceGanttItemInOrder,
  replaceGanttTemplateItem,
} from 'app/store/gantt';
import { GanttSerivce, PIXEL_PER_DURATION } from 'app/components/GanttArea/Gantt.service';
import Snackbar from '@material-ui/core/Snackbar';
import { AddGameGanttOrderItemInterface } from 'app/shared/interfaces/GameGanttOrderItem.interface';
import { getGameGantt } from 'app/store/userRoom';

import { useStyles } from './GanttMouseActionArea.styles';

export function GanttMouseActionArea({
  template,
  offsetY,
  offsetX,
  tmpItemEl,
  rootEl,
  selectedGanttItemId,
  isElementMoving,
  selectedGanttItem,
  ganttItemAdded,
}: GanttMouseActionAreaProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const ganttOrders = useSelector(getGanttOrders);
  const gameGanttDuration = useSelector(getDuration);
  const activeAction = useSelector(getActiveAction);
  const ganttTemplateId = useSelector(getGanttTemplateId);
  const editGanttItemId = useSelector(getEditGanttItemId);
  const gameGantt = useSelector(getGameGantt);
  const activeResourceId1 = useSelector(getActiveResourceId1);
  const activeResourceId2 = useSelector(getActiveResourceId2);
  const activeResourceTransportId = useSelector(getActiveResourceTransportId);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [halfDuration, setHalfDuration] = useState<number>(0);

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>, order: number) => {
    if (tmpItemEl) {
      const hDuration = template ? 5 : gameGanttDuration ? gameGanttDuration / 2 : 0;
      setHalfDuration(hDuration);
      const tmpItemPositionX = GanttSerivce.calculatePositionX(e, rootEl, hDuration);
      const calculatePositionLeft = tmpItemPositionX * PIXEL_PER_DURATION;
      tmpItemEl.style.left = calculatePositionLeft + 'px';
      tmpItemEl.style.top = order * 80 + 'px';
      tmpItemEl.childNodes[0].textContent = tmpItemPositionX + offsetX * 10 + '';
    }
  };

  const ganttTemplateAddItem = (e: React.MouseEvent<HTMLDivElement>, orderId: number) => {
    if (activeAction && rootEl) {
      const data = {
        position: GanttSerivce.calculatePositionX(e, rootEl, halfDuration) + offsetX * 10,
        action: { '@id': `/api/actions/${activeAction.id}` },
        ganttTemplate: { '@id': `/api/gantt_templates/${ganttTemplateId}` },
      };

      dispatch(addGanttTemplateItem(ganttTemplateId, data));
      dispatch(clearActiveAction());
      ganttItemAdded();
    }
    if (isElementMoving && selectedGanttItem && rootEl) {
      const data = {
        position: GanttSerivce.calculatePositionX(e, rootEl, halfDuration) + offsetX * 10,
      };
      dispatch(replaceGanttTemplateItem(ganttTemplateId, selectedGanttItemId, data));
      ganttItemAdded();
    }
  };

  const validateAddItem = () => {
    const properNumberOfResourcesSelected =
      activeAction && (activeAction?.isTransport ? activeResourceId1 && activeResourceId2 : activeResourceId1);
    return properNumberOfResourcesSelected && gameGanttDuration;
  };

  const ganttAddItem = (e: React.MouseEvent<HTMLDivElement>, orderId: number) => {
    if (validateAddItem()) {
      const newItem: AddGameGanttOrderItemInterface = {
        gameGanttOrder: {
          '@id': `/api/game_gantt_orders/${orderId}`,
        },
        gameFactoryResourceFirst: {
          '@id': `/api/game_factory_resources/${activeResourceId1}`,
        },
        activityActual: {
          '@id': `/api/actual_activities/${activeAction!.id}`,
        },
        position: GanttSerivce.calculatePositionX(e, rootEl, halfDuration) + offsetX * 10,
        duration: gameGanttDuration,
      };

      if (activeResourceId2) {
        newItem.gameFactoryResourceSecond = {
          '@id': `/api/game_factory_resources/${activeResourceId2}`,
        };
      }
      if (activeResourceTransportId) {
        newItem.gameFactoryResourceTransport = {
          '@id': `/api/game_factory_resources/${activeResourceTransportId}`,
        };
      }

      dispatch(addGanttItemToOrder(newItem, gameGantt ? gameGantt.id : -1));
      dispatch(clearActiveAction());

      ganttItemAdded();
    }
    if (!template && isElementMoving && selectedGanttItemId !== -1) {
      const item: Omit<AddGameGanttOrderItemInterface, 'duration'> = {
        gameGanttOrder: {
          '@id': `/api/game_gantt_orders/${orderId}`,
        },
        position: GanttSerivce.calculatePositionX(e, rootEl, halfDuration) + offsetX * 10,
      };

      dispatch(replaceGanttItemInOrder(item, selectedGanttItemId, gameGantt ? gameGantt.id : -1));

      ganttItemAdded();
    }
  };

  const handleAddItem = (e: React.MouseEvent<HTMLDivElement>, orderId: number) => {
    if (editGanttItemId) {
      return;
    }
    let dur = gameGanttDuration ? gameGanttDuration : 0;
    if (selectedGanttItemId !== -1) {
      const ganttItem = GanttSerivce.getGanttOrderItemById(selectedGanttItemId, ganttOrders);
      dur = ganttItem && ganttItem.duration ? ganttItem.duration : dur;
    }
    const collision = GanttSerivce.isCollision({
      ganttOrders,
      orderId,
      position: GanttSerivce.calculatePositionX(e, rootEl, halfDuration) + offsetX * 10,
      duration: dur,
      itemId: selectedGanttItemId !== -1 ? selectedGanttItemId : undefined,
    });
    if (collision) {
      setShowNotification(true);
      return;
    }
    if (template) {
      ganttTemplateAddItem(e, orderId);
    } else {
      ganttAddItem(e, orderId);
    }
  };

  const handleSnackbarClose = () => {
    setShowNotification(false);
  };

  return (
    <>
      {template ? (
        <div
          className={classes.ganttOrderContainer}
          onMouseMove={(e) => handleMouseMove(e, 0)}
          onClick={(e) => handleAddItem(e, 0)}
          id={'ganttClickContainer'}
        />
      ) : (
        ganttOrders &&
        ganttOrders.map((order, index) =>
          index >= offsetY && index - offsetY < 3 ? (
            <div
              key={order.id}
              className={classes.ganttOrderContainer}
              onMouseMove={(e) => handleMouseMove(e, index - offsetY)}
              onClick={(e) => handleAddItem(e, order.id)}
              id={'ganttClickContainer' + order.id}
            />
          ) : null
        )
      )}
      <Snackbar
        open={showNotification}
        autoHideDuration={2000}
        message={'Nie można postawić czynności w tym miejscu'}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={handleSnackbarClose}
      >
        <div className={classes.notification}>Nie można postawić czynności w tym miejscu</div>
      </Snackbar>
    </>
  );
}
