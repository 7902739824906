import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';

import { ScenarioSerivce } from 'app/services/scenario.service';
import { SupplierService } from 'app/services/supplier.service';
import { ResourceService } from 'app/services/resource.service';
import { FactoryService } from 'app/services/factory.service';
import { HeaderButton } from 'app/components/HeaderButton/HeaderButton';
import { ScenariosTable } from 'app/components/ScenariosTable/ScenariosTable';
import { ScenarioInterface } from 'app/shared/interfaces/Scenario.interface';
import { SupplierInterface } from 'app/shared/interfaces/Supplier.interface';
import { ResourceInterface } from 'app/shared/interfaces/Resource.interface';
import { FactoryInterface } from 'app/shared/interfaces/Factory.interface';
import { GetParamsInterface } from 'app/shared/interfaces/RequestInterface.interface';
import { defaultParams } from 'app/shared/helpers/pagination';
import { ProcessService } from 'app/services/process.service';
import { ProcessInterface } from 'app/shared/interfaces/Process.interface';
import { OrderService } from 'app/services/order.service';
import { OrderInterface } from 'app/shared/interfaces/Order.interface';
import { DistributionTemplateService } from 'app/services/distributionTemplate.service';
import { DistributionTemplateInterface } from 'app/shared/interfaces/DistributionTemplate.interface';

import { useStyles } from './ScenariosPage.styles';

function ScenariosPage() {
  const classes = useStyles();
  const [scenarios, setScenarios] = useState<Array<ScenarioInterface>>([]);
  const [distributionTemplate, setDistributionTemplate] = useState<Array<DistributionTemplateInterface>>([]);
  const [suppliers, setSuppliers] = useState<Array<SupplierInterface>>([]);
  const [resources, setResources] = useState<Array<ResourceInterface>>([]);
  const [factories, setFactories] = useState<Array<FactoryInterface>>([]);
  const [processes, setProcesses] = useState<Array<ProcessInterface>>([]);
  const [orders, setOrders] = useState<Array<OrderInterface>>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [addButtonClicked, setAddButtonClicked] = useState<boolean>(false);

  const getScenarios = (params: GetParamsInterface = defaultParams) =>
    ScenarioSerivce.getScenarios(params).then(
      ({ data: { 'hydra:member': responseData, 'hydra:totalItems': totalRecords } }) => {
        setScenarios(responseData);
        setTotalRecords(totalRecords);
      }
    );

  const getOrders = () =>
    OrderService.getOrders().then(({ data: { 'hydra:member': responseData, 'hydra:totalItems': totalRecords } }) => {
      setOrders(responseData);
    });

  const getSuppliers = () =>
    SupplierService.getSuppliers().then(({ data: { 'hydra:member': responseData } }) => setSuppliers(responseData));

  const getResources = () =>
    ResourceService.getResources().then(({ data: { 'hydra:member': responseData } }) => setResources(responseData));

  const getFactories = () =>
    FactoryService.getFactories().then(({ data: { 'hydra:member': responseData } }) => setFactories(responseData));

  const getProcesses = () =>
    ProcessService.getProcesses().then(({ data: { 'hydra:member': responseData } }) => setProcesses(responseData));

  const getDistributionTemplate = () =>
    DistributionTemplateService.getDistributionTemplate().then(({ data: { 'hydra:member': responseData } }) =>
      setDistributionTemplate(responseData)
    );

  const handleAddButton = () => setAddButtonClicked(true);

  useEffect(() => {
    getScenarios();
    getSuppliers();
    getResources();
    getFactories();
    getProcesses();
    getOrders();
    getDistributionTemplate();
  }, []);

  useEffect(() => {
    if (addButtonClicked) {
      setAddButtonClicked(false);
    }
  }, [addButtonClicked]);

  return (
    <div className={classes.root}>
      <Typography variant={'h1'}>
        <span>Scenariusze</span>
        <div>
          <HeaderButton onClick={handleAddButton} label={'Dodaj scenariusz'} />
        </div>
      </Typography>
      <ScenariosTable
        scenarios={scenarios}
        resources={resources}
        suppliers={suppliers}
        factories={factories}
        processes={processes}
        distributionTemplate={distributionTemplate}
        orders={orders}
        totalRecords={totalRecords}
        shouldUpdateScenarios={getScenarios}
        addClick={addButtonClicked}
      />
    </div>
  );
}

export default ScenariosPage;
