import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';

import { ResourceTypesTable } from 'app/components/ResourceTypesTable/ResourceTypesTable';
import { ResourceTypeInterface } from 'app/shared/interfaces/ResourceType.interface';
import { ResourceTypeService } from 'app/services/resourceType.service';
import { GetParamsInterface } from 'app/shared/interfaces/RequestInterface.interface';
import { defaultParams } from 'app/shared/helpers/pagination';

import { useStyles } from './ResourceTypesPage.styles';

function ResourceTypesPage() {
  const classes = useStyles();
  const [resourceTypes, setResourceTypes] = useState<Array<ResourceTypeInterface>>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);

  const getResourceTypes = (params: GetParamsInterface = defaultParams) => {
    ResourceTypeService.getResourceTypes(params).then(
      ({ data: { 'hydra:member': responseData, 'hydra:totalItems': totalRecords } }) => {
        setResourceTypes(responseData);
        setTotalRecords(totalRecords);
      }
    );
  };

  useEffect(() => {
    getResourceTypes();
  }, []);

  return (
    <div className={classes.root}>
      <Typography variant={'h1'}>Typy zasobów produkcyjnych</Typography>
      <ResourceTypesTable
        resourceTypes={resourceTypes}
        totalRecords={totalRecords}
        shouldUpdateResourceTypes={getResourceTypes}
      />
    </div>
  );
}

export default ResourceTypesPage;
