import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import Slider from '@material-ui/core/Slider';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import { GanttAreaProps } from './GanttArea.interface';
import { GanttGraph } from 'app/components/GanttGraph/GanttGraph';
import { GanttOrderBackground } from 'app/components/GanttOrderBackground/GanttOrderBackground';
import {
  getActiveAction,
  clearActiveAction,
  getGantTemplateItems,
  getGanttTemplateId,
  deleteGanttTemplateItem,
  getActiveResourceId2,
  getActiveResourceId1,
  getGanttOrders,
  deleteGanttItem,
  getDuration,
  setEditGanttItemId,
  setActiveAction,
  getEditGanttItemId,
  setDuration,
} from 'app/store/gantt';
import { GanttSerivce, PIXEL_PER_DURATION } from './Gantt.service';
import { getGameGantt, getIsAutomation } from 'app/store/userRoom';
import { EditGanttTemplateItemPopper } from 'app/components/EditGanttTemplateItemPopper/EditGanttTemplateItemPopper';
import { GanttItemInterface } from 'app/shared/interfaces/GanttTemplate.interface';
import { canEdit } from 'app/shared/helpers/canEdit';
import { getIsUserGameMaster } from 'app/store/user';
import { GanttMouseActionArea } from 'app/components/GanttMouseActionArea/GanttMouseActionArea';
import { maxCell, sliderMax } from 'app/shared/constants';

import { useStyles } from './GanttArea.styles';

export function GanttArea({ actions, template, onMoveAll, hideActivitiesSection }: GanttAreaProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const ganttTemplateItems = useSelector(getGantTemplateItems);
  const ganttTemplateId = useSelector(getGanttTemplateId);
  const activeAction = useSelector(getActiveAction);
  const activeResourceId1 = useSelector(getActiveResourceId1);
  const activeResourceId2 = useSelector(getActiveResourceId2);
  const ganttOrders = useSelector(getGanttOrders);
  const gameGantt = useSelector(getGameGantt);
  const gameGanttDuration = useSelector(getDuration);
  const editGanttItemId = useSelector(getEditGanttItemId);
  const isUserGameMaster = useSelector(getIsUserGameMaster);
  const isAutomation = useSelector(getIsAutomation);
  const rootEl = useRef<HTMLDivElement>(null);
  const tmpItemEl = useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [selectedGanttItemId, setSelectedGanttItemId] = useState<number>(-1);
  const [selectedGanttItem, setSelectedGanttItem] = useState<GanttItemInterface | undefined>();
  const [isElementMoving, setIsElementMoving] = useState<boolean>(false);
  const [offsetX, setOffsetX] = useState<number>(0);
  const [offsetY, setOffsetY] = useState<number>(0);

  const { getGanttOrderItemById, createTemplateItems, createItems } = GanttSerivce;

  const getDurationById = (id: number) => {
    let duration = 0;

    ganttOrders &&
      ganttOrders.map((order) => {
        order.gameGanttOrderItems.map((item) => {
          if (item.id === id) {
            duration = item.duration;
          }
          return item;
        });
        return order;
      });
    return duration;
  };

  const handleItemClick = (e: React.MouseEvent<HTMLDivElement>, id: number) => {
    if (!activeAction && (template || canEdit())) {
      setSelectedGanttItemId(id);
      setSelectedGanttItem(getGanttTemplateItemById(id));
      setAnchorEl(e.currentTarget);
      setOpenEditModal(true);

      dispatch(setDuration(getDurationById(id)));
    }
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    setSelectedGanttItemId(-1);
  };

  const handleDeleteGanttItem = () => {
    if (template) {
      dispatch(deleteGanttTemplateItem(ganttTemplateId, selectedGanttItemId));
    } else {
      dispatch(deleteGanttItem(selectedGanttItemId, gameGantt ? gameGantt.id : -1));
    }
    setOpenEditModal(false);
    setSelectedGanttItemId(-1);
  };

  const handleOnMove = () => {
    setOpenEditModal(false);
    setIsElementMoving(true);
    if (tmpItemEl && tmpItemEl.current) {
      showTmpElement(tmpItemEl.current);
    }
  };

  const handleOnMoveAll = (id: number) => {
    handleCloseEditModal();
    if (onMoveAll) {
      onMoveAll(id);
    }
  };

  const getActutalActivityFromGanttItemId = (id: number) => {
    let actualActivityId = -1;
    ganttOrders &&
      ganttOrders.map((order) =>
        order.gameGanttOrderItems.map((item) => {
          if (item.id === id) {
            actualActivityId = item.activityActual.id;
          }
          return item;
        })
      );
    return actualActivityId;
  };

  const handleOnEdit = (ganttItemId: number) => {
    handleCloseEditModal();
    const actualActivityId = getActutalActivityFromGanttItemId(ganttItemId);
    if (actualActivityId !== -1) {
      const action = getActivityById(actualActivityId);
      if (action) {
        dispatch(setEditGanttItemId(ganttItemId));
        dispatch(setActiveAction(action));
      }
    }
  };

  const getGanttTemplateItemById = (id: number) => ganttTemplateItems.find((item) => item.id === id);

  const getActivityById = (id: number) => actions.find((activity) => activity.id === id);

  const showTmpElement = useCallback(
    (itemEl: HTMLDivElement | null) => {
      if (itemEl && !editGanttItemId) {
        if (activeAction) {
          itemEl.style.backgroundColor = activeAction.color;
        } else if (selectedGanttItem) {
          const action = actions.find((action) => action['@id'] === selectedGanttItem.action);
          itemEl.style.backgroundColor = action ? action.color : '';
        }

        itemEl.style.display = 'flex';
        if (!template && gameGanttDuration) {
          itemEl.style.width = `${gameGanttDuration * 4}px`;
        }
        if (!template && selectedGanttItemId !== -1) {
          const ganttItem = getGanttOrderItemById(selectedGanttItemId, ganttOrders);
          if (ganttItem) {
            itemEl.style.width = (ganttItem && ganttItem.duration && ganttItem.duration * PIXEL_PER_DURATION) + 'px';
          }
        }
      }
    },
    [
      activeAction,
      selectedGanttItem,
      actions,
      gameGanttDuration,
      template,
      selectedGanttItemId,
      getGanttOrderItemById,
      editGanttItemId,
      ganttOrders,
    ]
  );

  const hideTmpElement = (itemEl: HTMLDivElement | null) => {
    if (itemEl) {
      itemEl.style.display = 'none';
    }
  };

  const handleEscepe = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        if (isElementMoving) {
          setIsElementMoving(false);
          setSelectedGanttItemId(-1);
        }
        if (activeAction) {
          dispatch(clearActiveAction());
        }
        if (openEditModal) {
          handleCloseEditModal();
        }
      }
    },
    [isElementMoving, activeAction, openEditModal, dispatch]
  );

  const handleSliderX = (e: React.ChangeEvent<{}>, value: number | number[]) => {
    setOffsetX(typeof value === 'number' ? value : 0);
  };

  const handleSliderY = (e: React.ChangeEvent<{}>, value: number | number[]) => {
    setOffsetY(typeof value === 'number' ? Math.abs(value) : 0);
  };

  const handleGanttItemAdded = () => {
    setIsElementMoving(false);
    setSelectedGanttItemId(-1);
    hideActivitiesSection && hideActivitiesSection();
  };

  const renderOrderBackground = () =>
    template ? (
      <GanttOrderBackground />
    ) : (
      ganttOrders && ganttOrders.map((order, index) => (index < 3 ? <GanttOrderBackground key={order.id} /> : null))
    );

  const renderOrders = () => {
    return (
      ganttOrders &&
      ganttOrders.map((order, index) =>
        index >= offsetY && index - offsetY < 3 ? (
          <div key={order.id} className={classes.ganttOrderContainer}>
            {createItems({
              orderId: order.id,
              offsetX,
              actions,
              isUserGameMaster,
              classes,
              ganttOrders,
              handleItemClick,
              isAutomation,
            })}
          </div>
        ) : null
      )
    );
  };

  const renderItemsContainer = () =>
    template ? (
      <div className={classes.ganttOrderContainer}>
        {createTemplateItems({ actions, handleItemClick, ganttTemplateItems, classes, offsetX })}
      </div>
    ) : (
      renderOrders()
    );

  useEffect(() => {
    if (tmpItemEl && tmpItemEl.current) {
      if (template) {
        activeAction ? showTmpElement(tmpItemEl.current) : hideTmpElement(tmpItemEl.current);
      } else {
        if (
          activeAction &&
          gameGanttDuration &&
          ((activeAction.isTransport && activeResourceId1 && activeResourceId2) ||
            (!activeAction.isTransport && activeResourceId1))
        ) {
          showTmpElement(tmpItemEl.current);
        } else {
          hideTmpElement(tmpItemEl.current);
        }
      }
    }
  }, [activeAction, showTmpElement, activeResourceId1, activeResourceId2, template, gameGanttDuration]);

  useEffect(() => {
    document.addEventListener('keydown', handleEscepe);
    return () => document.removeEventListener('keydown', handleEscepe);
  }, [handleEscepe]);

  useEffect(() => {
    setOpenEditModal(false);
  }, [offsetX, offsetY]);

  const getSliderYMin = () => (ganttOrders && -(ganttOrders.length - 3)) || 0;

  const handleClickOutsideGantt = () => {
    setIsElementMoving(false);
    setSelectedGanttItem(undefined);
    setSelectedGanttItemId(-1);
  };

  return (
    <ClickAwayListener onClickAway={handleClickOutsideGantt}>
      <div>
        <div className={classes.ganttGraphContainer} ref={rootEl}>
          <div className={classes.ganttWithSliderContainer}>
            <div className={classes.ganttOrdersContainer}>{renderOrderBackground()}</div>
            <GanttGraph
              cellWidth={40}
              numberOfCell={maxCell}
              height={300}
              offsetX={offsetX}
              offsetY={offsetY}
              ganttOrders={ganttOrders}
            />
            <div className={classes.ganttOrdersContainer}>{renderItemsContainer()}</div>
            <div
              className={clsx(classes.ganttOrdersContainer, {
                [classes.ganttOrdersContainerHidden]: !(!!activeAction || isElementMoving),
              })}
            >
              <div className={classes.tmpItem} ref={tmpItemEl}>
                <div className={classes.tmpItemText} />
              </div>
              <div>
                <GanttMouseActionArea
                  template={template}
                  offsetY={offsetY}
                  offsetX={offsetX}
                  tmpItemEl={tmpItemEl.current}
                  rootEl={rootEl.current}
                  selectedGanttItemId={selectedGanttItemId}
                  isElementMoving={isElementMoving}
                  selectedGanttItem={selectedGanttItem}
                  ganttItemAdded={handleGanttItemAdded}
                />
              </div>
            </div>
            {!template && (
              <div className={classes.sliderYContainer}>
                <Slider
                  step={1}
                  min={getSliderYMin()}
                  max={0}
                  value={-offsetY}
                  onChange={handleSliderY}
                  track={false}
                  orientation={'vertical'}
                />
              </div>
            )}
          </div>
          <div className={classes.sliderXContainer}>
            <Slider step={1} min={0} max={sliderMax} value={offsetX} onChange={handleSliderX} track={false} />
          </div>
        </div>
        <EditGanttTemplateItemPopper
          ganttTemplateItemId={selectedGanttItemId}
          isOpen={openEditModal}
          onClose={handleCloseEditModal}
          anchorEl={anchorEl}
          onDelete={handleDeleteGanttItem}
          onMove={handleOnMove}
          onEdit={handleOnEdit}
          onMoveAll={handleOnMoveAll}
          isTemplate={!!template}
        />
      </div>
    </ClickAwayListener>
  );
}
