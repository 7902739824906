export const roles = {
  ROLE_USER: 'ROLE_USER',
  ROLE_REGULAR_PLAYER: 'ROLE_REGULAR_PLAYER',
  ROLE_GAME_MASTER: 'ROLE_GAME_MASTER',
};

export const roomGameStatus = {
  GAME_IDLE: {
    status: 2,
    label: {
      room: 'Oczekiwanie na rozpoczęcie rozgrywki',
    },
    cell_color: '#cecece',
    full_cell_color: true,
  },
  GAME_PENDING: {
    status: 3,
    label: {
      room: 'Gra w trakcie',
    },
    cell_color: '#98ff8ddd',
    full_cell_color: false,
  },
  GAME_FINISHED: {
    status: 4,
    label: {
      room: 'Zakończona',
    },
    cell_color: '#ffbaba',
    full_cell_color: false,
  },
  GAME_ARCHIVED: {
    status: 5,
    label: {
      room: 'Zarchiwizowane',
    },
    cell_color: '#bec7ff',
    full_cell_color: true,
  },
};

export const roomGameStatusArray = [
  {
    status: roomGameStatus.GAME_IDLE.status,
    label: roomGameStatus.GAME_IDLE.label,
    availableForNewRoom: true,
  },
  {
    status: roomGameStatus.GAME_PENDING.status,
    label: roomGameStatus.GAME_PENDING.label,
    availableForNewRoom: true,
  },
  {
    status: roomGameStatus.GAME_FINISHED.status,
    label: roomGameStatus.GAME_FINISHED.label,
    availableForNewRoom: false,
  },
  {
    status: roomGameStatus.GAME_ARCHIVED.status,
    label: roomGameStatus.GAME_ARCHIVED.label,
    availableForNewRoom: false,
  },
];

export const statusesAllowingRoomDeletion = [roomGameStatus.GAME_IDLE.status, roomGameStatus.GAME_ARCHIVED.status];

export const userRoomGameStatus = {
  GAME_IDLE: {
    status: 2,
    label: {
      room: 'Oczekiwanie na rozpoczęcie rozgrywki',
      game: 'Zakończ Turę',
    },
    cell_color: '#cecece',
  },
  GAME_PENDING: {
    status: 3,
    label: {
      room: 'Gra w trakcie',
      game: 'Zakończ Turę',
    },
    cell_color: '#98ff8ddd',
  },
  GAME_WAITING_FOR_MARK: {
    status: 4,
    label: {
      room: 'Zakończył turę i czeka na ocenę',
      game: 'Oczekuje na ocenę',
    },
    cell_color: '#fbffc4',
  },
  GAME_MARK_AND_END: {
    status: 5,
    label: {
      room: 'Oceniony',
      game: 'Ocenione/Zakończone',
    },
    cell_color: '#ffbaba',
  },
  GAME_WAITING_FOR_NEXT_ROUND: {
    status: 6,
    label: {
      room: 'Zakończył turę',
      game: 'Oczekuje na następną turę',
    },
    cell_color: '#ffbaba',
  },
};

export const routes = {
  HOME: {
    route: '/',
  },
  LOGOUT: {
    route: '/logout',
  },
  LOGIN: {
    route: '/login',
  },
  REGISTER: {
    route: '/register',
  },
  MATERIAL_TYPES: {
    route: '/material_types',
    access: [roles.ROLE_GAME_MASTER],
  },
  MATERIALS: {
    route: '/materials',
    access: [roles.ROLE_GAME_MASTER],
  },
  SUPPLIERS: {
    route: '/suppliers',
    access: [roles.ROLE_GAME_MASTER],
  },
  RESOURCE_TYPES: {
    route: '/resource_types',
    access: [roles.ROLE_GAME_MASTER],
  },
  RESOURCES: {
    route: '/resources',
    access: [roles.ROLE_GAME_MASTER],
  },
  ACTION_TYPES: {
    route: '/action_types',
    access: [roles.ROLE_GAME_MASTER],
  },
  ACTIONS: {
    route: '/actions',
    access: [roles.ROLE_GAME_MASTER],
  },
  DISTRIBUTION_TEMPLATE: {
    route: '/distribution_template',
    access: [roles.ROLE_GAME_MASTER],
  },
  PROCESSES: {
    route: '/processes',
    access: [roles.ROLE_GAME_MASTER],
  },
  GANTTS: {
    route: '/gantts',
    access: [roles.ROLE_GAME_MASTER],
  },
  GANTT: {
    route: '/gantt/:ganttTemplateId',
    access: [roles.ROLE_GAME_MASTER],
  },
  FACTORIES: {
    route: '/factories',
    access: [roles.ROLE_GAME_MASTER],
  },
  FACTORY: {
    route: '/factory/:factoryId',
    access: [roles.ROLE_GAME_MASTER],
  },
  PRODUCTS: {
    route: '/products',
    access: [roles.ROLE_GAME_MASTER],
  },
  ORDERS: {
    route: '/orders',
    access: [roles.ROLE_GAME_MASTER],
  },
  SCENARIOS: {
    route: '/scenarios',
    access: [roles.ROLE_GAME_MASTER],
  },
  ROOMS: {
    route: '/rooms',
    access: [roles.ROLE_GAME_MASTER],
  },
  ROOMS_ARCHIVED: {
    route: '/rooms_archived',
    access: [roles.ROLE_GAME_MASTER],
  },
  GAME_VIEW: {
    route: '/game_view/:userRoomId',
  },
  PLAYER_ROOMS: {
    route: '/player_rooms',
    access: [roles.ROLE_REGULAR_PLAYER],
  },
  USERS: {
    route: '/users',
    access: [roles.ROLE_GAME_MASTER],
  },
};

export const transportOptions = [
  {
    label: 'Tak',
    value: true,
  },
  {
    label: 'Nie',
    value: false,
  },
];

export const userStatusOptions = [
  {
    label: 'Aktywny',
    value: 1,
  },
  {
    label: 'Nieaktywny',
    value: 0,
  },
];

export const scenarioValue = {
  AUTOMATION_ON: 1,
  AUTOMATION_OFF: 0,
};
export const deliveryMaterialValue = {
  FAST_DELIVERY: 1,
  NORMAL_DELIVERY: 0,
};

export const deliveryMaterialOptions = [
  {
    value: deliveryMaterialValue.NORMAL_DELIVERY.toString(),
    label: 'Normalna - 80% ceny, transport uwzględnia wszystkie czasy',
  },
  {
    value: deliveryMaterialValue.FAST_DELIVERY.toString(),
    label: 'Szybka - 100% ceny, transport nie uwzględnia czasu zamawiania',
  },
];

export const scenarioOptions = [
  {
    label: 'Wolna konkurencja',
    value: scenarioValue.AUTOMATION_ON,
  },
  {
    label: 'Samotnik',
    value: scenarioValue.AUTOMATION_OFF,
  },
];

export const routeNameResourceType = 'D';

export const routeNameResourceTransportType = ['WR', 'WS'];

/** Names of resource types valid as transport source or destination */
export const storingPlaceResourceTypeNames = ['MAG', 'PO'];

export const sliderMax = 28;

export const maxCell = 20;

export const maxGanttLength = (maxCell + 28) * 10;

export const gameRoundPhaseStatus = {
  START_PHASE: 0,
  END_PHASE: 1,
};

export const actionTypeProductionAlias = 'P';

export const resourceTypeProduction = {
  production: 'SW',
  qualityControl: 'KJ',
};
export const resourceTypeForCreateRelationWithProduction = [
  resourceTypeProduction.production,
  resourceTypeProduction.qualityControl,
];

export const gameOwnOrdersStatus = {
  inProgress: 3,
  inProgressDelay: 6,
  toDo: 4,
  toDoDelay: 5,
  done: 7,
  notDone: 8,
};

export const factoryCost = {
  calculateRound: 5,
};

export const messages = {
  NOT_ENOUGH_CASH: 'Brak wystarczających środków',
};
