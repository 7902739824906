import { factory } from './reducer';

export const {
  clearFactory,
  setFactory,
  setFactoryResources,
  setActiveRoundFactoryResources,
  setActiveFactoryResourceId,
  clearActiveFactoryResourceId,
  setActiveResource,
  clearActiveResource,
  setActualSupplier,
  setGridOptions,
} = factory.actions;
