import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';

import { ProductInterface } from 'app/shared/interfaces/Product.interface';
import { MaterialTypeInterface } from 'app/shared/interfaces/MaterialType.interface';
import { ProductService } from 'app/services/product.service';
import { HeaderButton } from 'app/components/HeaderButton/HeaderButton';
import { MaterialTypeService } from 'app/services/materialType.service';
import { ProductsTable } from 'app/components/ProductsTable/ProductsTable';
import { GetParamsInterface } from 'app/shared/interfaces/RequestInterface.interface';
import { defaultParams } from 'app/shared/helpers/pagination';

import { useStyles } from './Products.styles';

function ProductsPage() {
  const classes = useStyles();
  const [products, setProducts] = useState<Array<ProductInterface>>([]);
  const [materialTypes, setMaterialTypes] = useState<Array<MaterialTypeInterface>>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [addButtonClicked, setAddButtonClicked] = useState<boolean>(false);

  const getProducts = (params: GetParamsInterface = defaultParams) => {
    ProductService.getProducts(params).then(
      ({ data: { 'hydra:member': responseData, 'hydra:totalItems': totalRecords } }) => {
        setProducts(responseData);
        setTotalRecords(totalRecords);
      }
    );
  };

  const getMaterialTypes = () => {
    MaterialTypeService.getMaterialTypes().then(({ data: { 'hydra:member': responseData } }) => {
      setMaterialTypes(responseData);
    });
  };

  const handleAddButton = () => setAddButtonClicked(true);

  useEffect(() => {
    getProducts();
    getMaterialTypes();
  }, []);

  useEffect(() => {
    if (addButtonClicked) {
      setAddButtonClicked(false);
    }
  }, [addButtonClicked]);

  return (
    <div className={classes.root}>
      <Typography variant={'h1'}>
        <span>Produkty</span>
        <div>
          <HeaderButton onClick={handleAddButton} label={'Dodaj produkt'} />
        </div>
      </Typography>
      <ProductsTable
        products={products}
        totalRecords={totalRecords}
        materialTypes={materialTypes}
        shouldUpdateProducts={getProducts}
        addClick={addButtonClicked}
      />
    </div>
  );
}

export default ProductsPage;
