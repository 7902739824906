import { AxiosPromise } from 'axios';

import { ApiService } from './api.service';
import { AuthInterface } from 'app/shared/interfaces/Auth.interface';
import { setStorage, getStorage } from 'app/shared/helpers/localStorage';

export const AuthService = {
  register(data: AuthInterface): AxiosPromise {
    return ApiService.post('register', data, 'auth/');
  },

  login(data: AuthInterface): AxiosPromise {
    return ApiService.post('login', data, 'auth/');
  },

  getCurrentUser(): AxiosPromise {
    return ApiService.get('user/aboutme');
  },

  setToken(token: string) {
    setStorage('token', token);
  },

  getToken() {
    return getStorage('token');
  },
};

export default AuthService;
