import React from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';

import { useStyles } from './GameOwnMaterials.styles';
import { GameOwnMaterialsInterface } from './GameOwnMaterials.interface';
import { MaterialInterface } from 'app/shared/interfaces/Material.interface';
import { GameMaterialInterface } from 'app/shared/interfaces/GameMaterial.interface';

export function GameOwnMaterials(props: GameOwnMaterialsInterface) {
  const { gameMaterials } = props;
  const classes = useStyles();

  const getActualMaterialName = (material: MaterialInterface | null) =>
    material && material.name ? material.name : '';
  const getActualMaterialQuality = (material: MaterialInterface | null) =>
    material && material.quality ? material.quality : '';

  const getMaterialInQueue = (gameMaterials: Array<GameMaterialInterface>) => {
    return gameMaterials.length
      ? gameMaterials.filter((material) => material.deliveryStatus === 1 && material.amount > 0)
      : [];
  };

  return (
    <div className={classes.root}>
      <div className={classes.tableContainer}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Nazwa</TableCell>
              <TableCell align={'center'}>Jakość</TableCell>
              <TableCell align={'right'}>Cena [PLN]</TableCell>
              <TableCell align={'center'}>Ilość</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getMaterialInQueue(gameMaterials)
              .sort((a, b) => a.orderNumber - b.orderNumber)
              .map((gameMaterial) => (
                <TableRow key={gameMaterial.id}>
                  <TableCell>
                    {getActualMaterialName(gameMaterial.actualMaterial)} ({gameMaterial.orderNumber}) (
                    {gameMaterial.processStep} / {gameMaterial.processActivityCount})
                  </TableCell>
                  <TableCell align={'center'}>{getActualMaterialQuality(gameMaterial.actualMaterial)}</TableCell>
                  <TableCell align={'right'}>{gameMaterial.purchasePrice.toFixed(2)}</TableCell>
                  <TableCell align={'center'}>{gameMaterial.amount}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}
