import { AxiosPromise } from 'axios';

import { ApiService } from './api.service';
import {
  DistributionTemplateResponse,
  DistributionTemplateInterface,
  AddDistributionTemplateInterface
} from 'app/shared/interfaces/DistributionTemplate.interface';
import { GetParamsInterface } from 'app/shared/interfaces/RequestInterface.interface';

export const DistributionTemplateService = {
  getDistributionTemplate(params: GetParamsInterface = {}): AxiosPromise<DistributionTemplateResponse> {
    return ApiService.get('distribution_templates', params);
  },
  addDistributionTemplate(
    data: Omit<AddDistributionTemplateInterface, 'id'>
  ): AxiosPromise<DistributionTemplateInterface> {
    return ApiService.post('distribution_templates', data);
  },
  replaceDistributionTemplate(id: number, data: Omit<DistributionTemplateInterface, 'id'>): AxiosPromise {
    return ApiService.put(`distribution_templates/${id}`, data);
  },
  deleteDistributionTemplate(id: number): AxiosPromise {
    return ApiService.delete(`distribution_templates/${id}`);
  },
};
