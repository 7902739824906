import { AxiosPromise } from 'axios';

import { ApiService } from './api.service';
import { GameFactoryResponseInterface } from 'app/shared/interfaces/GameFactory.interface';

export const GameFactoryService = {
  getGameFactoryById(id: number): AxiosPromise<GameFactoryResponseInterface> {
    return ApiService.get(`game_factories/${id}`);
  },

  setGameFactoryActualSupplier(id: number, supplierId: number): AxiosPromise {
    return ApiService.put(`game_factories/${id}`, { actualSupplier: { '@id': `/api/actual_suppliers/${supplierId}` } });
  },
};
