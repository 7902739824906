import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  root: {
    width: 300,
    border: '1px solid #ccc',
    borderRadius: 5,
    marginBottom: 20,
    marginLeft: 20,
    maxHeight: 400,
    overflow: 'auto',
  },
  tableRow: {
    cursor: 'pointer',
  },
  tableCell: {
    padding: '5px 20px',
  },
  activeRow: {
    '& td': {
      backgroundColor: '#adffb8',
    },
  },
}));
