import React from 'react';

import { GameHeaderWidgetProps } from './GameHeaderWidget.interface';
import { useStyles } from './GameHeaderWidget.styles';

export function GameHeaderWidget({ title, value }: GameHeaderWidgetProps) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.title}>{title}</div>
      <div className={classes.value}>{value}</div>
    </div>
  );
}
